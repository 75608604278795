import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import classes from './style.module.css'
import { formatDate } from '../../config/HelperFunction'

export default class NegotiationChat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      product: {},
      negotiation_status: null,
      merchant: {
        assets: [
          { src: '' }
        ]
      },
    }
  }

  getProductDetail = () => {
    let slug = this.props.match.params.product
    let body = {
      slug
    }

    this.props.apiManager.makeCall('get_product_detail', body, (res) => {
      if (res.message === '1171') {
        this.setState({ product: res.product }, () => {
          this.getMerchantDetail()
        })
      }
    })
  }


  getMerchantDetail = () => {
    let body = {
      email: this.state.product.parent
    }
    this.props.apiManager.makeCall('get_merchant_detail', body, (res) => {
      if (res.message === '1083') {
        this.setState({ merchant: res.merchant }, () => {
          this.getProductNegotiationStatus()
        })
      }
    })
  }

  getProductNegotiationStatus = () => {
    let slug = this.props.match.params.product
    let body = {
      slug
    }
    this.props.apiManager.makeCall('product_negotiation_status', body, (res) => {
      if (res.negotiationSummary) {
        this.setState({
          negotiation_status: res.negotiationSummary,
        });
      } else if (res.message === '1367') {
        this.setState({
          negotiation_status: null,

        });
      }
    })
  }

  componentDidMount() {
    this.getProductDetail();
  }


  renderMerchantInfo = () => {
    let { name, image } = this.state.merchant
    return (
      <div className={classes.merchantContainer}>
        <div>
          <img src={image} alt="Nothing" className={classes.image} />
        </div>
        <div className={classes.merchantName}>
          <h1 className={classes.title}>{name}</h1>
        </div>
      </div>
    )
  }

  renderTop = () => {
    let { colors, specifications } = this.state.product
    let productPrice = JSON.parse(localStorage.getItem('price'))
    let unit = JSON.parse(localStorage.getItem('price-unit'))
    let image = colors && colors[0].icon && colors && colors[0].icon.assets && colors[0].icon.assets[0].src
    let defaultPriceUnit = specifications && specifications[0].price && specifications[0].price[0].unit
    let priceUnit = unit ? unit : defaultPriceUnit
    
    return (
      <div className={classes.topContainer}>
        {this.renderMerchantInfo()}
        <div className={classes.productContainer}>
          <div className={classes.productImage}>
            <img src={image} alt="Nothing" className={classes.image} />
          </div>
          <div className={classes.productTitle}>
            <h1 className={classes.title}>{this.state.product.name}</h1>
            <h1 className={classes.title}>{priceUnit} {productPrice} </h1>
          </div>
        </div>
      </div>
    )
  }

  renderChat = () => {
    return (
      this.state.negotiation_status.items.map((item, index) => {
        let date = formatDate(new Date(item.createdAt))
        let { originalPrice } = this.state.negotiation_status;
        let deduction = item.amount / 100 * originalPrice[0].figure;
        item.negotiationPrice = originalPrice[0].figure - deduction;

        return <div className={classes.chatContainer} key={index}>
          <div className={classes.chatRow}>
            <p style={{ color: 'rgb(0,142,258)', fontSize: '16px', fontWeight: 'bold' }}>Request:</p>
            <p style={{ color: 'rgb(0,142,258)', fontSize: '16px', fontWeight: 'bold' }}>{item.amount} % off</p>
          </div>
          <div className={classes.chatRow}>
            <p className={classes.negotiationPrice}>Negotiation price:</p>
            <p className={classes.negotiationPriceItem}>RM {item.negotiationPrice}</p>
          </div>
          <div className={classes.chatRow}>
            <p className={classes.negotiationPrice}>Created At:</p>
            <p className={classes.negotiationPriceItem}>{date}</p>
          </div>
        </div>
      })
    )
  }

  goToNegotiationRequest = async (slug) => {
    let productPrice = JSON.parse(localStorage.getItem('price'))
    const { specifications} = this.state.product
    let totalPrice = productPrice ? productPrice : specifications && specifications[0].price[0].figure
    // let priceUnit = (this.state.price.unit === undefined) ? specifications && specifications[0].price[0].unit : this.state.price.unit
    // await localStorage.setItem('price-unit', JSON.stringify(this.state.price.unit ? this.state.price.unit : priceUnit))
    await localStorage.setItem('price', JSON.stringify(totalPrice))
    this.props.history.push(`/NegotiationRequest/${slug}`)
  }

  renderFooter = () => {
    let { slug } = this.state.product
    // let productPrice = JSON.parse(localStorage.getItem('price'))
    let productSlug = slug ? slug : this.props.match.params.product
    let leftTurn = 3
    
    if (this.state.negotiation_status) {

      let { negotiationTurn, maximumAllowedNegotiationAttempts } = this.state.negotiation_status;
      leftTurn = maximumAllowedNegotiationAttempts - negotiationTurn;
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* <Link to={`${process.env.PUBLIC_URL}/NegotiationRequest/${slug}`}
          // className={classes.footer} 
          className="btn btn-solid" style={{ width: '18%', alignSelf: 'center', justifyContent: 'center', display: 'flex' }}
        >
          Request({leftTurn})
        </Link> */}
        <div 
          onClick={() => this.goToNegotiationRequest(slug)}
          className="btn btn-solid" 
          style={{ width: '18%', alignSelf: 'center', justifyContent: 'center', display: 'flex', margin:10 }} >
          Request({leftTurn})
        </div>
      </div>
    )
  }


  renderNoNegotiation = () => {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', padding: '10px', minHeight: '300px', backgroundColor: 'white' }}>
        <p className={classes.negotiationPrice}>
          You have nothing to Negotiate yet, Send Request & start Negotiation
        </p>
      </div>
    )
  }

  renderItems = () => {
    this.renderChat()
    this.renderFooter()
  }

  render() {
    return (
      <div className={classes.mainChatContainer}>
        <Container>
          <div style={{ padding: '50px', }}>
            {this.renderTop()}
            {
              this.state.negotiation_status === null ? this.renderNoNegotiation() : this.renderChat()
            }
            {this.renderFooter()}
          </div>
        </Container>
      </div>
    )
  }
}
