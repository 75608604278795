import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductListItem from "./product-list-item";
import LoadingLoader from './../../Loader/Loader'

class ProductListing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      limit: 12,
      hasMoreItems: true,
      loading: false,
      colSize: (this.props.match.params.colSize === undefined) ? this.props.colSize : this.props.match.params.colSize
    };
  }

  fetchMoreItems = () => {
    if (this.state.limit >= this.props.product.length) {
      // this.setState({ hasMoreItems: false });
      this.props.loadMore()
      if (this.props.end === true) {
        this.setState({
          hasMoreItems: false
        })
      }
    }
    setTimeout(() => {
      this.setState({
        limit: this.state.limit + 8
      });
    }, 100);
  }

  render() {
    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', backgroundColor: 'white' }}>
      <LoadingLoader visible={this.state.loading} />
    </div>
    const { product, addToCart, symbol, addToWishlist, addToCompare,isCategory } = this.props;
    if (product.length === 0) {
      return (
        <div style={{ width: '100%', display: 'flex', alignSelf: 'center' }}>
          <div className="col-sm-12 text-center section-b-space no-found" style={isCategory == true ? {marginTop:'0px'} : {marginTop:'3rem'}}>
            {isCategory == true ?
            <img width={100} height={100} src={`${process.env.PUBLIC_URL}/assets/images/coming-soon.svg`} className="img-fluid" alt="Nothing" />
            :
            <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="Nothing" />
            }
            <h3 style={{ marginBottom: 25, }}>{isCategory == true ? "Coming Soon" :"Sorry! Couldn't Find The Item You Were Looking For!!!"}    </h3>
            <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
          </div>
        </div>
      )
    }
    else {
      return (
        <div style={{ width: '100%' }}>
          <InfiniteScroll
            dataLength={this.state.limit} //This is important field to render the next data
            next={this.fetchMoreItems}
            hasMore={this.state.hasMoreItems}
            loader={<div></div>}
            endMessage={
              <p className="seen-cls seen-it-cls">
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className='row'>
              {product.slice(0, this.state.limit).map((products, index) =>
                <div className={`${this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.props.colSize}`} key={index}>
                  <ProductListItem
                    {...this.props}
                    product={products}
                    apiManager={this.props.apiManager}
                    saveUser={this.props.saveUser}
                    user={this.props.user}
                    alert={this.props.alert}
                    symbol={symbol}
                    onAddToCompareClicked={() => addToCompare(product)}
                    onAddToWishlistClicked={() => addToWishlist(product)}
                    onAddToCartClicked={addToCart} key={index} />
                </div>)
              }
            </div>

          </InfiniteScroll>
        </div>
      )
    }
  }
}

export default ProductListing
