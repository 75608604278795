import React, { Component } from "react";
import * as Yup from "yup";
// import Buffer from "buffer"
import { SuperHOC } from "../HOC";
import { Manager } from "../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import NegativeAlert from "../components/CustomAlert/CustomAlert";

class VerifyEmailRequest extends Component {
    state = {
        currentEmail: "",
        errorMessage: "",
        showPositiveAlert: false,
        showNegativeAlert: false,
    };

    componentDidMount() {
        const registData = "";

        Manager.getItem("currentEmail").then((res) => {
            let stringWithoutComma = res.replaceAll('"', "");
            this.setState({
                currentEmail: stringWithoutComma,
            });
        });
    }

    verifyEmailFunction = (registerData) => {
        let payload = {
            email: this.state.currentEmail,
        };

        let body = {
            email: payload.email,
        };

        this.props.apiManager.makeCall(
            "emailVerificationRequest",
            body,
            (res) => {
                if (res.message === 7073) {
                    this.setState({
                        showPositiveAlert: true,
                        successLogin: true,
                        // errorMessage:res.message,
                        loading: false,
                        errorMessage: "Email has been sent to your account, Please verify!",
                    });
                    setTimeout(() => {
                        this.props.history.push("/account");
                    }, 2000);
                } else if (res.message === 7000) {
                    this.setState({
                        faildRegistration: true,
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: "Email already Verified",
                    });
                    setTimeout(() => {
                        this.props.history.push("/account");
                    }, 2000);
                } else {
                    this.setState({
                        faildRegistration: true,
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: "Email already Verified",
                    });
                }
            }
        );
    };

    render() {
        return (
            <div>
                <Breadcrumb title={"create account"} />
                <Formik
                    initialValues={{
                        email: this.state.currentEmail,
                    }}
                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({})}
                    onSubmit={(values, { setSubmitting }) => {
                        this.verifyEmailFunction(values);

                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Verify Email
                                        </span>

                                        <div
                                            className="wrap-input100 validate-input"
                                            style={{
                                                width: "70%",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Field
                                                type="email"
                                                name="email"
                                                id="defaultRegisterFormEmail"
                                                className="input100"
                                                placeholder="Enter Email"
                                                value={this.state.currentEmail}
                                                readonly
                                                disabled
                                            />
                                            <span className="focus-input100"></span>
                                            <span className="symbol-input100">
                                                <i className="fa fa-envelope"></i>
                                            </span>
                                            {this.props.takenEmail !== null ? (
                                                this.props.takenEmail ? (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Email is taken
                                                    </p>
                                                ) : null
                                            ) : null}

                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="email"
                                                component="p"
                                            />
                                        </div>

                                        {/* <div className="col-xl-4 col-sx-12"> */}

                                        <div
                                            className="container-register100-form-btn"
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                alignSelf: "center",
                                                width: "50%",
                                            }}
                                        >
                                            <button
                                                className="login100-form-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Send VERIFICATION EMAIL
                                            </button>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Success"}
                    message={this.state.errorMessage}
                />
                <NegativeAlert
                    error
                    title={"error"}
                    open={this.state.showNegativeAlert}
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(VerifyEmailRequest);
