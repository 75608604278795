import React, { Component } from 'react';
import Slider from 'react-slick';
import '../common/index.scss';
import { SuperHOC } from '../../HOC'
// import custom Components
import DetailsWithPrice from "./DetailsWithPrice";
import ImageZoom from '../products/common/product/image-zoom'
import PositiveAlert from "../CustomAlert/CustomAlert";
import NegativeAlert from "../CustomAlert/CustomAlert";

class BookSchedule extends Component {

    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            isActive: null,
            scheduleList: [],
            page: '',
            pages: '',
            end: false,
            selectedItem: '',
            showPositiveAlert: false,
            showNegativeAlert: false,
        };
    }

    getNextSchedules = (pageNum = 1) => {
        let slug = this.props.match.params.item
        let body = {
            slug,
            page: pageNum,
            numRecords: '10',
        }
        this.props.apiManager.makeCall('get_next_schedules', body, (res) => {
            if (res) {
                let { page, pages, docs } = res.message;
                if (page <= pages) {
                    this.setState({
                        scheduleList: this.state.scheduleList.concat(docs),
                        page,
                        pages,
                    }, () => (page === pages) ? this.setState({ end: true }) : null);
                }
                else {
                    this.setState({
                        end: true,
                    });
                }
            }
            else if (res.message) {
                this.setState({
                    end: true,
                });
            }
        })
    }
    componentDidMount() {
        this.getNextSchedules()
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });

    }

    toggleActive = (i, item) => {
        //Remove the if statement if you don't want to unselect an already selected item
        if (i === this.state.isActive) {
            this.setState({
                isActive: null,
            });
        } else {
            this.setState({
                isActive: i,
                selectedItem: item
            });
        }
    };

    bookSchedule = () => {
        let slug = this.props.match.params.item
        let body = {
            timeStart: this.state.selectedItem.timeStart,
            timeEnd: this.state.selectedItem.timeEnd,
            scheduledId: slug
        }
        this.props.apiManager.makeCall('book_schedule', body, res => {
            this.setState({
                showPositiveAlert: true,
                errorMessage: res.decodedMessage
            })
        })
    }

    render() {
        let name = this.props.location.state.name
        let img = this.props.location.state.img
        let price = this.props.location.state.price
        let amountDue = this.props.location.state.amountDue

        const { symbol, item, addToCart, addToCartUnsafe, addToWishlist } = this.props
        var products = {
            fade: true
        };

        function formatDate(date) {
            return date.getDate() + "-" + date.getMonth() + 1 + "-" + date.getFullYear();
        }
        function formatTime(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        return (
            <div>
                {/*Section Start*/}
                <section >
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 product-thumbnail">
                                    <Slider {...products} className="product-slick">
                                        <div>
                                            <ImageZoom image={img} className="img-fluid image_zoom_cls-0" />
                                        </div>
                                    </Slider>
                                </div>
                                <DetailsWithPrice name={name} price={price} amountDue={amountDue} symbol={symbol} item={item} navOne={this.state.nav1} addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe} addToWishlistClicked={addToWishlist} />
                            </div>
                        </div>
                    </div>
                </section>
                {/*Section End*/}

                <section className="tab-product m-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                {/* <DetailsTopTabs item={item} /> */}
                                <h3 style={{ marginTop: 30, textAlign: 'center' }}>Preferable Time</h3>
                                <table className="table mb-0" >
                                    <thead>
                                        <tr className='table-row'>
                                            <th>Sr</th>
                                            <th>Date</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ backgroundColor: 'white' }}>
                                        {this.state.scheduleList.map((item, i) => {
                                            return (
                                                <tr style={
                                                    this.state.isActive === i
                                                        ? { background: 'lightgrey', cursor: "pointer" }
                                                        : { background: 'white', cursor: "pointer" }
                                                }
                                                    key={i}
                                                    onClick={() => this.toggleActive(i, item)}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.createdAt.substring(0, 10).split('-').reverse().join('-')}</td>
                                                    <td>{formatTime(new Date(item.timeStart))}</td>
                                                    <td>{formatTime(new Date(item.timeEnd))}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 0, }} onClick={() => this.getNextSchedules(this.state.page + 1)}>
                                    <h6 style={{ alignSelf: 'center', alignItems: 'center', paddingTop: 7, marginRight: 5, cursor: 'pointer' }}>
                                        Load More
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="product-buttons" style={{ marginTop: 20, marginBottom: 20 }}>
                            <div className="btn btn-solid" onClick={this.bookSchedule} >Book Now</div>
                        </div>
                    </div>
                </section>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={'Success'}
                    message={this.state.errorMessage}
                />
                <NegativeAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={'Error'}
                    message={this.state.errorMessage}
                />
            </div>
        )
    }
}



export default SuperHOC(BookSchedule)