import React, { Component } from 'react'
import { SuperHOC } from "../HOC";
import OrderDetail from '../components/order/OrderDetail';


class OrderDetailScreen extends Component {
  render() {
    // let { params } = this.props.match;
    return (
      <div>
        <OrderDetail
          {...this.props}
          api={'get_single_order_details'}
        />
      </div>
    )
  }
}
export default SuperHOC(OrderDetailScreen)