import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings'

class SideImageItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      totalRating: ''
    }
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }
  componentDidMount() {
    const { productRating, totalRatings } = this.props.product
    {
      (this.calculateRating(productRating !== undefined ? productRating : 0) / totalRatings * 5).toFixed(2)
    }
  }

  renderNegotiation = () => {
    return (
      <div style={{ backgroundColor: "#ff4c3b", top: 5, position: "absolute", textAlign: 'center', fontSize: '6px', padding: "6px", zIndex: 1 }}>
        <p style={{ color: "#ffffff", margin: 0, padding: 0 }}>
          Negotiable
        </p>
      </div>
    )
  }

  renderOff = () => {
    let product = this.props.product;
    return (
      <div style={{ fontSize: '14px', fontWeight: '700', top: '0', backgroundColor: "rgba(192,192,192,1)", position: "absolute", right: '0', transform: "rotate(0deg)", letterSpacing: '0.1em', writingMode: "vertical-rl" }}>
        <p style={{ color: "#ffffff", margin: 0, padding: 5, paddingTop: 15, paddingBottom: 15 }}>
          {product.price[0].discountPercent.toFixed(2)} % off
        </p>
      </div>
    )
  }

  calculateRating(productRating) {
    const numberOfOneStar = productRating.rating1 !== undefined ? productRating.rating1 : 0
    const numberOfTwoStar = productRating.rating2 !== undefined ? productRating.rating2 * 2 : 0
    const numberOfThreeStar = productRating.rating3 !== undefined ? productRating.rating3 * 3 : 0
    const numberOfFourStar = productRating.rating4 !== undefined ? productRating.rating4 * 4 : 0
    const numberOfFiveStar = productRating.rating5 !== undefined ? productRating.rating5 * 5 : 0
    const totalRating = numberOfOneStar + numberOfTwoStar + numberOfThreeStar + numberOfFourStar + numberOfFiveStar
    return totalRating
  }

  renderRating = () => {
    const { productRating, totalRatings } = this.props.product
    let A = (this.calculateRating(productRating !== undefined ? productRating : 0) / totalRatings * 5).toFixed(2)
    return (
      <StarRatings
        rating={A === 'NaN' ? 0 : parseFloat(A)}
        starRatedColor={"#ffb508"}
        changeRating={this.changeRating}
        numberOfStars={5}
        name='rating'
        starDimension="20px"
        starSpacing="3px"
      />
    )
  }

  render() {
    const { product, } = this.props;
    let slug = product.slug
    let RatingStars = []
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i}></i>)
    }

    // let convertNameIntoDashes = product.name.split(' ').join('-');
    // const gotoProductDetail = {
    //   pathname: `/product/${convertNameIntoDashes}`,
    //   state: {
    //     slug: product.slug
    //   }
    // }
    var enc = window.btoa(product.slug);
    let removePerc = product.name ? product.name.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    var redirectLink='/product/' + convertNameIntoDashes + '?id=' + enc

    return (
      <div className="product-box2">
        <a href={redirectLink} className="media d-block" style={{ position: 'relative' }}>
          {/* {
            product.isNegotiable ? this.renderNegotiation() : null
          } */}
          {/* <Link to={`${process.env.PUBLIC_URL}/product/${slug}`}> */}
          <img
          className=' w-ms-75 w-xs-75 m-auto pt-lg-0 pt-2 d-block'
            src={product.color ?
              this.state.image ? this.state.image : product.color[0].icon.asset.src
              : product.asset.src}
            width='auto'
            height='100%'
            alt=""
            style={{ cursor: 'pointer', display: 'block', marginRight: 'auto', marginLeft: 'auto', }}
            onClick={() => this.props.history.push('/product/' + convertNameIntoDashes + '?id=' + enc)}
          />
          {/* </Link> */}
          <div className="media-body align-self-center" style={{ cursor: 'pointer' }}>
            <div style={{ display: 'block', padding: '15px 0 15px 15px' }}>
              <div>
                <div
                  onClick={() => this.props.history.push('/product/' + convertNameIntoDashes + '?id=' + enc)}
                  style={{ textDecoration: 'none' }}
                >
                  <h6 className="description m-0">{product.name.substring(0, 30)}</h6>
                  {/* <div className="rating">
                    {RatingStars}
                  </div> */}
                   <h4 style={{ color: 'black' }} className="m-0">
                      RM {(product.price[0].figure - product.price[0].figure * product.price[0].discountPercent / 100).toFixed(2)}
                    </h4>
                    {product.price[0].discountPercent === 0 ? <br />
                      :
                      <del><span className='money' > RM {product.price[0].figure.toFixed(2)}</span></del>
                    }
                </div >
                <div className="pl-2 pr-2" style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', bottom: 0, marginTop: 10 }}>
                <div className="">
                {product.color ?
                  <ul className="color-variant">
                    {product.color.map((vari, i) => {
                      return (
                        <li className="mt-0" key={i} onClick={() => this.onClickHandle(vari.icon.asset.src)} title={vari.name.toLowerCase()}
                          style={{ height: 23, width: 23, borderWidth: 1, borderColor: 'black', borderStyle: 'solid', backgroundColor: vari.code, borderRadius: 100, listStylePosition: 'inline' }}>
                        </li>
                      )
                    })}
                  </ul> : ''}
                </div>
                  {/* <div className="">
                  {this.renderRating(product)}
                  </div> */}
                </div>
              </div>
            </div>
            {/* {
              product.price[0].discountPercent ? this.renderOff() : null
            } */}
          </div>
        </a>
      </div>
    )
  }
}

export default SideImageItem;