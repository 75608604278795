import React, { Component } from 'react';
import Slider from 'react-slick';
import LoadingLoader from '../Loader/Loader'
//import { Link } from 'react-router-dom'
export default class componentName extends Component {
  constructor(props) {
    super(props)
    this.state = {
      banners: { items: [] },
      bannersLoaded: true
    }
  }

  componentDidMount = () => {
    this.getBanners();
  }
  onApiResponse = (res) => {
  }
  getBanners = () => {
    this.props.apiManager.makeCall('get_banner', { role: 1 }, (res) => {
      if (res.message === '1513') {
        this.setState({ banners: res.bannerJSON, bannersLoaded: false })
      } else {
        this.setState({ banners: { items: [] }, bannersLoaded: false })
      }
    })
  }

  renderBannerCarasoul = () => {
    const settings = {
      infinite: true,
      centerMode: true,
      speed: 300,
      centerPadding: "5%",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,

    };
    let { items } = this.state.banners
    //if (this.state.bannersLoaded) return <LoadingLoader visible={this.state.bannersLoaded} />
    return (
      <Slider {...settings} className="slide-1 home-slider home-banner-slider">
        {
          items.map((item, index) => {
            return (
              <img style={{width:'100%',height:'auto'}} src={item.src ? item.src : `${process.env.PUBLIC_URL}/assets/B2.jpg`} />
            )
          })}
      </Slider>
    )
  }

  render() {
    return (
      <section className="padding-bottom-cls noPaddingBottom pt-4">
        {this.renderBannerCarasoul()}
      </section>);
  }
}
