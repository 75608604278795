import React, { Component } from "react";
import "./style.css";
import * as Yup from "yup";
import Breadcrumb from "../common/breadcrumb";
import { Manager } from "../../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage } from "formik";

export default class ChangePassword extends Component {
    render() {
        var regex = /^.*$/i;

        return (
            <div className="limiter">
                <Formik
                    initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.oldPassword) {
                            errors.oldPassword = "Enter old password";
                        }
                        if (!values.newPassword) {
                            errors.newPassword =
                                "Please enter your new password";
                        }
                        if (!regex.test(values.newPassword)) {
                            errors.newPassword =
                                // "Please enter your new password";
                                "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character";
                        }
                        if (!values.confirmPassword) {
                            errors.confirmPassword =
                                "Please confirm your confirm password";
                        }
                        if (values.newPassword !== values.confirmPassword) {
                            errors.confirmPassword = "Password does not match";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        oldPassword: Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .required("Password is required"),
                        // .matches(
                        //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{8,}$/,
                        //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        // ),
                        newPassword: Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .required("Password is required")
                            .matches(
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                            ),

                        confirmpassword: Yup.string().oneOf(
                            [Yup.ref("password"), null],
                            "Passwords must match"
                        ),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.props.onSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        Change current Password
                                    </span>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="password"
                                            name="oldPassword"
                                            id="defaultRegisterFormPassword"
                                            className="input100"
                                            placeholder="old password"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="oldPassword"
                                            component="div"
                                        />
                                    </div>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="password"
                                            name="newPassword"
                                            id="defaultRegisterFormPassword"
                                            className="input100"
                                            placeholder="new password"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="newPassword"
                                            component="div"
                                        />
                                    </div>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="password"
                                            name="confirmPassword"
                                            id="defaultRegisterFormPassword"
                                            className="input100"
                                            placeholder="confirm password"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="confirmPassword"
                                            component="div"
                                        />
                                    </div>
                                    <div className="container-login100-form-btn">
                                        <button
                                            className="login100-form-btn"
                                            type="submit"
                                        >
                                            Change Password
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        );
    }
}
