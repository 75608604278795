const styles = {
  cartContainer: {
    backgroundColor: 'rgb(255,220,219)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px',
    border: '1px solid red',
    padding: '15px',
    opacity: 0
  },
  cartTimOut: {
    fontSize: '12px',
    margin: '0',
    padding: '0'
  },
  cartTimeOutText: {
    color: 'red',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '16px'
  },
  cartRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  productContainer: {
    padding: '10px',
    backgroundColor: '#f9f9f9f9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 3,
    borderColor: 'black'
  },
  productImage: {
    height: '90px',
    width: '90px',
    margin: '5px'
  },
  colorCode: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    border: '0.3px solid black',
  }
}

export {
  styles
}