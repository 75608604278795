import React, { Component } from 'react';
// import image from '../assets/terms-&-conditions.jpg';
import Breadcrumb from "../components/common/breadcrumb";

class ShippingPolicy extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div style={{ minHeight: '100vh' }}>
        <Breadcrumb title={'Shipping Policy'} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{ marginTop: 44 }}>
                <div className="banner-section">
                  {/* <img src={image} className="img-fluid" alt="" /> */}
                </div>
              </div>
              <div className="col-sm-12">
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h2>Shipping Policy</h2>
                </div>
                <br />
                <br />

                <h5>	•	We strive to deliver products purchased from Pindal.pk in excellent condition and in the quickest time conceivable.</h5>
                {/* <br /> */}

                <h5>	•	If the order is cancelled, lost or un-delivered to your preferred location, we will refund the complete order amount including any shipping charges, if paid online.</h5>
                {/* <br /> */}

                <h5>	•	While we try to get your product into your hands as fast as possible, all online orders usually take 48-72 hours to be there. In extremely rare cases, it may take up to 5 working days. Like you, our valued staff likes to enjoy life as well. As such, the only times Pindal.pk can’t deliver are Weekends and Public Holidays.</h5>
                {/* <br /> */}

                <h5>	•	If you return an order delivered to you, order shipping charges will not be refunded. </h5>
                {/* <br /> */}

                <h5>	•	Estimated delivery time depends on the following factors:</h5>
                <div style={{ margin: 10 }}>
                  <p>	•	The Seller offering the product</p>
                  <p>	•	Product's availability with the Seller</p>
                  <p>	•	The destination to which you want the order shipped to and location of the Seller.</p>
                </div>
                <br />

                <b>There are NO hidden charges when you make a purchase on Pindal.pk. List prices are final and all-inclusive. The price you see on the product page is exactly what you would pay.</b>
                <br />

                <b>*Order value is calculated after applying discounts/VAT/GST or any other applicable charges.</b>
                <br />
                <br />

                <p>Please open and check the product in front of courier staff. Pindal.pk will not take any responsibility if the package is empty/damaged afterwards.</p>
                <br />

                <p>We only deliver via top courier companies of Pakistan.</p>
                <br />
                
                <p>*It might take 1-5 business days to reach shipment depending on the size and weight of the product. Usually items heavier than 5KG's Home Appliances, Televisions, Large Printers, Home Theatre, Desktop computers, Projector Screens, Kitchen Appliances, and Generators etc take more days. Some items are not available for COD, we take 50% advance to confirm orders for COD.</p>

              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}
export default ShippingPolicy