import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
export default class CustomAlert extends Component {
    render() {
        const { error,changeName } = this.props;
        return (
            <div>
                <Modal
                    closeOnOverlayClick={false}
                    showCloseIcon={false}
                    styles={{
                        modal: {
                            backgroundColor: "#f9f9f9",
                            padding: 0,
                            borderRadius: 15,
                        },
                    }}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    center
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 250,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 25,
                                border: "red",
                            }}
                        >
                            <div
                                style={{
                                    height: 60,
                                    width: 60,
                                    border: error
                                        ? "2px solid rgb(255, 52,111)"
                                        : "2px solid #00cb51",
                                    borderRadius: 30,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={error ? faTimes : faCheck}
                                    size="2x"
                                    color={
                                        error ? "rgb(255, 52,111)" : "#00cb51"
                                    }
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 25,
                                border: "red",
                            }}
                        >
                            <h3
                                style={{
                                    color: error
                                        ? "rgb(255, 52,111)"
                                        : "#00cb51",
                                }}
                            >
                                {error ? "Oops" : "Done"}!
                            </h3>
                        </div>
                        <div
                            style={{
                                paddingTop: 25,
                                paddingBottom: 15,
                                paddingLeft: 5,
                                paddingRight: 5,
                            }}
                        >
                            <h5
                                style={{
                                    textAlign: "center",
                                    lineHeight: "30px",
                                }}
                            >
                                {this.props.message}
                            </h5>
                        </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: 15,
                                }}
                            >
                                <div
                                    onClick={this.props.goto}
                                    style={{
                                        margin:4,


                                        backgroundColor: "#00cb51",
                                        width: "100%",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        color: "white",
                                        textAlign: "center",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        borderTopLeftRadius:15,
                                        borderTopRightRadius:15,
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    }}
                                >
                                    {/* {error ? ' Try Again' : 'Go Back'} */}
                                    {changeName?
                                    "Verify Email":"Edit Profile"}
                                </div>
                                <div
                                    onClick={this.props.onClose}
                                    style={{
                                        margin:4,
                                        backgroundColor:"rgb(255, 52,111)",
                                        width: "100%",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        color: "white",
                                        textAlign: "center",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        borderTopLeftRadius:15,
                                        borderTopRightRadius:15,
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    }}
                                >
                                    {/* {error ? ' Try Again' : 'Go Back'} */}
                                    Cancel
                                </div>
                            </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
