import React, { useEffect } from 'react';
import { lifestyleItems } from '../../utils/lifestyle';
import { login } from '../../utils/session';
import './Lifestyle.css';

export const Lifestyle = () => {
  const obtainCookies = async () => {
    try {
      await login();
    } catch (error) {
      console.error('Something went wrong! Error: ', error);
    }
  };

  useEffect(() => {
    if (!window.localStorage.getItem('x-token')) {
      obtainCookies();
    }
  }, []);

  const onLinkClicked = (url) => {
    const token = localStorage.getItem('x-token');
    const cartToken = localStorage.getItem('token');

    const lifestyleURL = new URL(url);
    lifestyleURL.searchParams.set('token', encodeURIComponent(token));
    lifestyleURL.searchParams.set('cartToken', encodeURIComponent(cartToken));

    window.location.href = lifestyleURL.href;
  };

  return (
    <div className="lifestyle__container">
      <h1 className="heading">Lifestyle</h1>
      <div className="lifestyle__links__container">
        {lifestyleItems.map((lifestyle) => {
          return (
            <a
              key={lifestyle.url}
              href={lifestyle.url}
              onClick={(event) => {
                event.preventDefault();
                onLinkClicked(lifestyle.url);
              }}
            >
              <img src={lifestyle.iconURL} alt={lifestyle.url} />
              <span>{lifestyle.name}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
};
