import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import FreeScrollBar from 'react-free-scrollbar';

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: []
    };
  }

  closeNav() {
    var closemyslide = document.getElementById("mySidenav");
    if (closemyslide)
      closemyslide.classList.remove('open-side');
  }


  handleMegaSubmenu = (category) => {
    let t = 'get_latest_products/' + category;
    let tp = window.btoa(t)
    let decodedPath = window.btoa('get_latest_products')
    let path = {
      pathname: `/product-listing/${'3' + tp+'?path='+decodedPath+'?key='+category}`,
      state: {
        apiName: 'get_latest_products',
        title: 'Products',
        category: category,
      }
    }
    this.closeNav();
    if (this.props.location.pathname.split( '/' )[1] === 'product-listing') {
      this.props.history.replace(path);
      window.location.reload();
    } else
      this.props.history.push(path);
  }

  componentDidMount() {
    this.initializeMenu();
  }

  initializeMenu = () => {
    this.props.apiManager.makeCall('get_all_categories', {}, res => {
      if (res.message) {
        this.setState({ menu: res.categories })
      }
    })
  }

  openCategory = (item, name) => {
    this.closeNav();
    var replaced = item.name[0].value.split(' ').join('-');
    const CategoryProduct = {
      pathname: name === 'Products' ? `/CategoryProduct/${replaced}` : `/CategoryService/${replaced}`,
      state: {
        slug: item.slug,
        name: item.name[0].value,
      }
    }
    this.props.history.push(CategoryProduct)
  }

  render() {
    return (
      <div id="mySidenav" className="sidenav">
        <a href="javascript:void(0)" className="sidebar-overlay" onClick={this.closeNav}></a>
        <nav>
          <div onClick={this.closeNav}>
            <div className="sidebar-back text-left">
              <i className="fa fa-angle-left pr-2" aria-hidden="true"></i> Back
            </div>
          </div>
          <ul id="sub-menu" className="sidebar-menu">
            {this.state.menu ?
              this.state.menu.map((item, index) => {
                return (
                  <li key={index}>
                    <div style={{ padding: '10px 50px 20px 40px', color:"#222222", textTransform: 'uppercase', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.handleMegaSubmenu(item.slug)}>
                      {item.name[0].value}
                    </div>
                  </li>
                )
              }) : ''}
          </ul>
        </nav>
      </div>
    )
  }
}
export default SideBar;