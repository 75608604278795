import React, { Component } from "react";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import { Manager } from "../StorageManager/Storage";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
const prefix = "60";

class EditProfile extends Component {
    state = {
        showNegativeAlert: false,
        showPositiveAlert: false,
        navigationAlert: false,
        navigationEmail: "",
        currentNumber: "",
        currentEmail: "",
        oldNumber: "",
        phone: "60",
        oldEmail: "",
        token: "",
        currentName: "",
        currentlastName: "",
    };

    componentDidMount() {
        const registData = "this.props.location.state;";

        let token = Manager.getItem("token", false).then((res) => {
            this.setState({
                token: res,
            });
            this.getUserDetails();
        });
    }

    // Event Triggered //
    goToRegisterScreen = (registerData) => {
        let body = {
            firstName: registerData.firstName,
            lastName: registerData.lastName,
        };

        let tempError = "";

        this.props.apiManager.makeCall("editUserProfile", body, (res) => {
            if (res.errors != undefined) {
                let erro = res.errors.map((err) => {
                    tempError = err.msg;
                    return err.msg;
                });
                if (erro) {
                    this.setState({
                        showNegativeAlert: true,
                        errorMessage: tempError ? tempError : erro,
                    });
                }
            }
            if (res.message == 7076) {
                if (this.state.oldEmail !== this.state.currentEmail) {
                    this.changeEmail(this.state.currentEmail);
                    if (this.state.oldNumber !== this.state.phone) {
                        this.sendTacNumber(this.state.phone);
                    }
                } else if (this.state.oldNumber !== this.state.phone) {
                    this.sendTacNumber(this.state.phone);
                    if (this.state.oldEmail !== this.state.currentEmail) {
                        this.changeEmail(this.state.currentEmail);
                    }
                } else {
                    this.setState({
                        errorMessage: "Successfully User Updated",
                        showPositiveAlert: true,
                        navigationEmail: body.email,
                    });
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: "/account",
                            data: true,
                            state: {
                                fromEditProfile: true,
                            },
                        });
                    }, 2000);
                }
            } else if (res.message === 7000) {
                this.setState({
                    errorMessage: "User not found",
                    showNegativeAlert: true,
                });
            } else if (res.message === 7075) {
                this.setState({
                    errorMessage: "Email Already Exist",
                    showNegativeAlert: true,
                });
            } else if (res.message === 7077) {
                this.setState({
                    errorMessage: "Successfully User Updated!",
                    showPositiveAlert: true,
                });
                setTimeout(() => {
                    // this.props.history.goBack();
                    this.props.history.push({
                        pathname: "/account",
                        data: true,
                        state: {
                            fromEditProfile: true,
                        },
                    });
                    // window.location.reload(true);
                }, 2000);
            } else {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: res.message
                        ? res.message
                        : "Failed in updating user",
                });
            }
        });
    };

    sendTacNumber = (msisdn) => {
        this.setState({
            inputPhoneNumber: this.state.phone,
        });

        let payload = {
            newMSISDN: this.state.phone,
        };

        this.props.apiManager.makeCall("changeMSISDN", payload, (res) => {
            if (res.errors != undefined) {
                let erro;
                erro = res.errors.map((err) => {
                    return err;
                });

                if (!erro[0].msg) {
                    this.setState({
                        showPositiveAlert: true,
                        errorMessage: erro[0] ? erro[0].msg : "Invalid Number",
                    });
                } else if (!erro[1]) {
                    this.setState({
                        showPositiveAlert: true,
                        errorMessage: erro[1] ? erro[1] : "Invalid Number",
                    });
                } else {
                    this.setState({
                        showPositiveAlert: true,
                        errorMessage: "Invalid Number",
                    });
                }
            }
            if (res.message === 7100) {
                this.setState({
                    loading: false,
                    showNegativeAlert: false,
                    errorMessage:
                        "Sorry! The number you have entered is not a registered XOX Mobile Number. If you would like to continue, please ensure that the number entered is a registered XOX Mobile Number!",
                });
            }

            if (res.message === 7097) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage:
                        "This Number Belongs to another User, in the case of dispute please contact our support team!",
                });
            }

            if (res.message === 7060) {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: "Msisdn is already taken!",
                    // access_token: res.data.data.accessToken,
                });
            }
            if (res.message == 7061) {
                this.setState({
                    loading: false,
                    token: res.token,
                    showPositiveAlert: true,
                    errorMessage: `OTP sent to ${this.state.phone}`,
                });
                setTimeout(() => {
                    this.props.history.push({
                        pathname: "/VerifyChangeMsisdn",
                        hash: res.otp.token,
                    });
                }, 1000);
            }
        });
    };

    changeEmail = (email) => {
        let body = {
            newEmail: email,
        };

        this.props.apiManager.makeCall("changeEmail", body, (res) => {
            if (res.message === 7064) {
                this.setState({
                    errorMessage: "Successfully User email Updated!",
                    showPositiveAlert: true,
                    navigationEmail: body.email,
                });

                setTimeout(() => {
                    this.props.history.push("/VerifyChangeEmail");
                }, 2000);
            } else if (res.message === 7063) {
                this.setState({
                    errorMessage: "Email already exist",
                    showNegativeAlert: true,
                });
            }
        });
    };

    // Getting User Details //
    getUserDetails = () => {
        this.props.apiManager.get("getUser", {}, (res) => {
            if (res.message == 7079) {
                this.setState({
                    phone: res.result[0].MSISDN,
                    currentEmail: res.result[0].email,
                    firstName: res.result[0].firstName,
                    currentName: res.result[0].firstName,
                    currentlastName: res.result[0].lastName,
                    oldNumber: res.result[0].MSISDN,
                    oldEmail: res.result[0].email,
                });
            } else {
                this.setState({
                    errorMessage: "User not found Try different Email",
                });
            }
        });
    };

    // Removing user information //
    handleLogOut = () => {
        Manager.removeItem("token");
        Manager.removeItem("user");
        Manager.removeItem("userStatus");
        Manager.removeItem("userEmail");
        Manager.removeItem("profileIdMdlwr");
        Manager.removeItem("accessTokenMdlwr");
        window.location.href = "/";
    };

    gotoVerificationEmailRequest = () => {
        this.props.history.push({
            pathname: "/VerifyEmailRequest",
            state: { naviEmail: this.state.navigationEmail },
        });
    };

    handleOnChange = (e) => {
        this.setState({
            currentName: e.target.value,
        });
    };

    handleOnChangeForLast = (e) => {
        this.setState({
            currentlastName: e.target.value,
        });
    };

    handleChange = (e) => {
        const input = e.target.value;
        const name = e.target.name;
        let number = (e.target.value = prefix + input.substr(prefix.length));

        this.setState({
            phone: number,
        });
    };

    render() {
        let phone = this.state.phone;
        return (
            <div>
                <Breadcrumb title={"Edit Profile"} />
                <Formik
                    initialValues={{
                        firstName: this.state.currentName,
                        lastName: this.state.currentlastName,
                        email: this.state.changeEmail,
                    }}
                    validate={(values) => {
                        const errors = {};
                        values.firstName = this.state.currentName;
                        values.lastName= this.state.currentlastName

                        if (!values.firstName) {
                            errors.firstName = "Please enter first name";
                        }

                        return errors;
                    }}
                    
                    onSubmit={(values, { setSubmitting }) => {
                        this.goToRegisterScreen(values);

                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Edit Profile
                                        </span>
                                        <div className="col-xl-5 col-sx-12">
                                            <div className="wrap-input100 validate-input">
                                                <input
                                                    name="firstName"
                                                    className="input100"
                                                    value={
                                                        this.state.currentName
                                                    }
                                                    placeholder={"First Name"}
                                                    onChange={(e) =>
                                                        this.handleOnChange(e)
                                                    }
                                                    id="defaultRegisterFormEmail"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-user"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="firstName"
                                                name="firstName"
                                                component="p"
                                            />
                                            <div className="wrap-input100 validate-input">
                                                <input
                                                    // type="password"
                                                    name="lastName"
                                                    id="defaultRegisterFormPassword"
                                                    className="input100"
                                                    value={
                                                        this.state
                                                            .currentlastName
                                                    }
                                                    placeholder={"Last Name"}
                                                    onChange={(e) =>
                                                        this.handleOnChangeForLast(
                                                            e
                                                        )
                                                    }
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-user"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="lastName"
                                                name="lastName"
                                                component="p"
                                            />
                                            <div className="wrap-input100 validate-input">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="input100"
                                                    disabled={true}
                                                    value={
                                                        this.state.currentEmail
                                                    }
                                                    // onChange={(e) =>
                                                    //     this.handleOnChange(e)
                                                    // }
                                                    id="defaultRegisterFormEmail"
                                                    // placeholder="Change email address"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-envelope"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="email"
                                                name="email"
                                                component="p"
                                            />
                                            <div className="wrap-input100 validate-input">
                                                <input
                                                    type="text"
                                                    className="input100"
                                                    disabled={true}
                                                    name="phone"
                                                    placeholder="Change Msisdn"
                                                    value={this.state.phone}
                                                    // ref={(target) => {
                                                    //     // target.value = prefix;
                                                    // }}
                                                    id="defaultRegisterFormPassword"
                                                    // onChange={(e) =>
                                                    //     this.handleChange(e)
                                                    // }
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-mobile"></i>
                                                </span>
                                                <ErrorMessage
                                                    style={{ color: "red" }}
                                                    id="error"
                                                    name="phone"
                                                    component="div"
                                                />
                                            </div>
                                            <div>
                                                <TabList
                                                    className="tab-title"
                                                    style={{
                                                        flex: 1,
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Tab
                                                        direction={"column"}
                                                        style={{
                                                            paddingRight: 10,
                                                            alignItems:
                                                                "stretch",
                                                            flexDirection:
                                                                "column",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <u
                                                            className="text-primary"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    {
                                                                        pathname:
                                                                            "/ChangeEmail",
                                                                        search: `id=${this.state.oldEmail}`,
                                                                        state: {
                                                                            changeEmail: true,
                                                                            data: this
                                                                                .state
                                                                                .oldEmail,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Change Email
                                                        </u>
                                                    </Tab>
                                                    <Tab>
                                                        <u
                                                            className="text-primary"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    {
                                                                        pathname:
                                                                            "/VerifyChangeMsisdn",
                                                                        search: `id=${this.state.phone}`,
                                                                        state: {
                                                                            changeMsi: true,
                                                                            data: this
                                                                                .state
                                                                                .phone,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Change MSISDN
                                                        </u>
                                                    </Tab>
                                                    <Tab>
                                                        <u
                                                            className="text-primary"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    {
                                                                        pathname:
                                                                            "/change-password",
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Change Password
                                                        </u>
                                                    </Tab>
                                                </TabList>
                                            </div>

                                            <div
                                                className="container-register100-form-btn"
                                                style={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    alignSelf: "center",
                                                }}
                                            >
                                                <button
                                                    className="login100-form-btn"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                />

                <PositiveAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                />
                {/* <PositiveAlert
                    onClose={() => this.setState({ navigationAlert: false })}
                    open={this.state.navigationAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                /> */}
            </div>
        );
    }
}
export default SuperHOC(EditProfile);
