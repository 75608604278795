export function formatDate(date) {
  let monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  let hour = (date.getHours() % 12) < 10 ? ('0' + (date.getHours() % 12)) : date.getHours() % 12;
  let mint = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes();
  return day + ' ' + monthNames[monthIndex] + ' ' + year + ', ' + hour + ':' + mint;
}