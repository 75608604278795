import React, { Component } from 'react'
import SingleComplain from '../components/Complain/SingleComplain.js'
import { SuperHOC } from '../HOC';

class ComplainSingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            complains: []
        }
    }

    componentDidMount = () => {
        this.getComplains();
    }

    goToComplainScreen = (item) => {
        this.props.history.push(`/Complains/${item}`)
      }

    getComplains = () => {
        let today = Math.round((new Date()).getTime() / 1000);

        let date = new Date();
        date.setDate(date.getDate() - 10);
        let lastDate = date / 1000 | 0

        let body = {
            seller: false,
            startTime: lastDate,
            endTime: today
        }

        this.props.apiManager.makeCall('get_complains', body, (res) => {
            if (res.message === '1815') {
                this.setState({ complains: res.result.docs })
            }
            else {
                console.error('Error in getting replies: ', res.decodedMessage);
            }
        })
    }
    render() {
        return (
            <div>
                {/* <Breadcrumb title={'Complains'} /> */}
                <SingleComplain
                    {...this.props}
                />
            </div>
        )
    }
}

export default SuperHOC(ComplainSingle);