import React, { Component, Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'
// import Brands from "../components/Home/Brands"
// import Trending from "../components/Home/trending";
// import LatestService from "../components/List/LatestProducts";
import ThemeSettings from "../components/common/theme-settings"
import BannerCarasoul from '../components/Home/BannerCarasoul'
import { Manager } from '../StorageManager/Storage'
// import PromoSales from '../components/Home/PromoSales'
// import FeaturedCategory from '../components/Home/FeaturedCategory'
// import LatestProducts from '../components/List/InfiniteList'
// import Catagory from "../components/Home/Catagory";
import {
  svgFreeShipping,
  svgservice,
  svgoffer,
  svgpayment,
} from '../services/script'
import { SuperHOC } from "../HOC";
import { Lifestyle } from '../components/Lifestyle/Lifestyle'
import _styles from '../components/css/newlandingpage.css';
import Marquee from "react-fast-marquee";
import LoadingLoader from '../components/Loader/Loader'
const Catagory = lazy(() => import("../components/Home/Catagory"))
const LatestProducts = lazy(() => import('../components/List/InfiniteList'))
const LatestService = lazy(() => import('../components/List/LatestProducts'))
const FeaturedCategory = lazy(() => import('../components/Home/FeaturedCategory'))
const PromoSales = lazy(() => import('../components/Home/PromoSales'))
const Trending = lazy(() => import("../components/Home/trending"))
const Brands = lazy(() => import("../components/Home/Brands"))
const Trending2 = lazy(() => import("../components/Home/trending2"))

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      selectedPick: 0, //0- Best seller 1- On Sale 2- Trending,
      arrivalListLoading: true,
      logoList: [
      ],
      showModal: false
    };
  }


  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    // if(document.referrer ==='')
    // check if user status if 4. user need to change password
    this.getUserStatus();

    let previous = document.referrer.split('/')
    if (previous[3] === 'Payments') {
      let orderId = JSON.parse(localStorage.getItem("orderId"))
      if (orderId !== null) {
        this.props.history.push('/Payments')
      }
    }
    else {
      localStorage.removeItem("orderId")
    }
    if (previous[3] === 'wallet') {
      let orderId = JSON.parse(localStorage.getItem("walletOrderId"))
      if (orderId !== null) {
        this.props.history.push('/wallet')
      }
    }
    else {
      localStorage.removeItem("walletOrderId")
    }

    this.getTrendingProducts();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };

  getUserStatus = async () => {
    let userStatus = await Manager.getItem("userStatus", true);

    // if user status 4. redirect user to change password page
    if ((userStatus != undefined || userStatus != null) && userStatus === 4) {
      this.props.history.push('/change-password')
    }

  };

  renderServices = () => {
    return (
      <div className="container">
        <section className="service section-b-space border-section border-top-0">
          <div className="row partition4">
            <div className="col-lg-3 col-md-6 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
              <h4>Immediate shipping</h4>
              <p>Immediate Shipping Country Wide.</p>
            </div>
            <div className="col-lg-3 col-md-6 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgservice }} />
              <h4>24 X 7 service</h4>
              <p>Enjoy Anywhere, Anytime Service from Pindal.</p>
            </div>
            <div className="col-lg-3 col-md-6 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
              <h4>festival offer</h4>
              <p>Buy you'll need to be quick- Festivel Offer Ends Soon!</p>
            </div>
            <div className="col-lg-3 col-md-6 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgpayment }} />
              <h4>online payment</h4>
              <p>Make secure purchases in stores, in apps and now on the web.</p>
            </div>
          </div>
        </section>
      </div>
    )
  }

  goToTrendingProducts = () => {
    let t = 'get_trending_products'
    let tp = window.btoa(t)
    let path = {
      pathname: `/product-listing/${'3' + tp + '?path=' + tp}`,
      state: {
        apiName: 'get_trending_products',
        title: 'Products'
      }
    }
    this.props.history.push(path)
  }

  goToFeaturedProducts = () => {
    let t = 'get_featured_products'
    let tp = window.btoa(t)
    let path = {
      pathname: `/product-listing/${'3' + tp + '?path=' + tp}`,
      state: {
        apiName: 'get_featured_products',
        title: 'Products',
      }
    }
    this.props.history.push(path)
  }

  goToFeaturedServices = () => {
    let t = 'get_featured_services'
    let tp = window.btoa(t)
    let path = {
      pathname: `/product-listing/${'3' + tp}`,
      state: {
        apiName: 'get_featured_services',
        title: 'Services'
      }
    }
    this.props.history.push(path)
  }
  goToLatestServices = (category = null) => {
    let t = 'get_latest_services'
    let tp = window.btoa(t)
    let path = {
      pathname: `/product-listing/${'3' + tp}`,
      state: {
        apiName: 'get_latest_services',
        title: 'Services',
        category: category,
      }
    }
    this.props.history.push(path)
  }

  getTrendingProducts = (pageNum = 1) => {
    //scroll.scrollMore(2000, { delay: 1500 })
    let body = {
      page: pageNum,
      numRecords: 3
    };

    this.props.apiManager.makeCall("get_latest_products", body, res => {
      if (res.result && res.result.docs.length > 0) {
        this.setState({ arrivalList: res.result.docs.slice(0, 3), arrivalListLoading: false });
      }
      else {
        console.log("Arrival List fail-----");
      }
    })
  }
  render() {
    const { screenWidth } = this.state;
    return (
      <div>
        <BannerCarasoul
          {...this.props}
        />
        
        <div className='row_newlandingpage adBlock' style={{ padding: '0px 5%', justifyContent: 'space-evenly',margin:'0 auto' }}>
            <div className='row_newlandingpage adRow'  style={{ padding: '30px 0 30px 0', }}>
            <div className='row_newlandingpage adItem'>
              <img className='adImage' src={`${process.env.PUBLIC_URL}/assets/Icons/USP/icon_easy payment plan.svg`} />
              <div style={{ maxWidth: '100%' }}>
                <div className='adText gargantuanText semiBoldText'>0% Easy Payment Plan</div>
                <div className='adText2 mediumText lightgrayText' >Up to 6 or 12 months.</div>
              </div>
            </div>
            <div className='row_newlandingpage adItem'>
              <img className='adImage' src={`${process.env.PUBLIC_URL}/assets/Icons/USP/icon_secure payments.svg`} />
              <div style={{ maxWidth: '100%' }}>
                <div className='adText gargantuanText semiBoldText'>Secure Payments</div>
                <div className='adText2 mediumText lightgrayText' >Pay with ease and security.</div>
              </div>
            </div>
            </div>
            <div className='row_newlandingpage adRow'  style={{ padding: '30px 0 30px 0',}}>
            <div className='row_newlandingpage adItem'>
              <img className='adImage' src={`${process.env.PUBLIC_URL}/assets/Icons/USP/icon_limited time offers.svg`} />
              <div style={{ maxWidth: '100%' }}>
                <div className='adText gargantuanText semiBoldText'>Limited Time Offers</div>
                <div className='adText2 mediumText lightgrayText' >Add to cart now.</div>
              </div>
            </div>
            <div className='row_newlandingpage adItem'>
              <img className='adImage' src={`${process.env.PUBLIC_URL}/assets/Icons/USP/icon_customer service.svg`} />
              <div style={{ maxWidth: '100%' }}>
                <div className='adText gargantuanText semiBoldText'>Customer Service</div>
                <div className='adText2 mediumText lightgrayText' style={{}}>enq_blackmarket@xox.com.my</div>
              </div>
            </div>
            </div>
          </div>

        <Suspense fallback={<div> </div>}>
          <Catagory
            {...this.props}
            api={'get_all_categories'}
          />
        </Suspense>
        {/* <Suspense fallback={<div> </div>}>
          <Trending type={'watch'}
            title={'Trending products'}
            showHeader
            {...this.props}
            numOfRecords={10}
            numOfColumns={4}
            api={'get_trending_products'}
            viewMoreProducts={this.goToTrendingProducts}
          />
        </Suspense> */}

        {/* <Suspense fallback={<div> </div>}>
          <PromoSales
            {...this.props}
            api={'get_flash_sale'}
          />
        </Suspense> */}
        <Suspense fallback={<div> </div>}>
          <div className='row_newlandingpage tab-bg' style={{ backgroundColor: 'white', justifyContent: 'center',paddingBottom:'0px', gap: '20px' }}>
            <div style={{ ...styles.shopNow, maxWidth: screenWidth > 768 ? '45%' : '100%' }}>
              <div className=' semiBoldText whiteText sourceSansPro'
                style={{ ...styles.shopNowText,lineHeight:1.2,fontSize: screenWidth > 768 ? '2.8vw' : '6vw' }}>Shop All<br />Products</div>
              <img style={{ ...styles.shopNowImage }} src={`${process.env.PUBLIC_URL}/assets/images/Shop all products/shop all products@2x.png`} />
              {screenWidth > 500 ?
                <a href={`${process.env.PUBLIC_URL}/latest-product-listing/all`} className='largeText semiBoldText whiteText noHoverWhiteText onHoverurpleBG mobileHeight40'
                  style={{ ...styles.shopNowBtn }}>SHOP NOW</a>
                :
                <a href={`${process.env.PUBLIC_URL}/latest-product-listing/all`} className='largeText semiBoldText whiteText noHoverWhiteText onHoverurpleBG'
                  style={{ ...styles.shopNowSmallBtn }}>SHOP NOW</a>
              }
            </div>

            <div style={{ ...styles.shopNow, maxWidth: screenWidth > 768 ? '45%' : '100%' }}>
              <div className='semiBoldText whiteText sourceSansPro'
                style={{ ...styles.shopNowText,lineHeight:1.2, fontSize: screenWidth > 768 ? '2.8vw' : '6vw' }}>Easy Bill<br />Payments</div>
              <img style={{ ...styles.shopNowImage }} src={`${process.env.PUBLIC_URL}/assets/images/Easy bill payments/easy bill payments@2x.png`} />
              {screenWidth > 500 ?
                <a href={`${process.env.REACT_APP_BLACKMARKET_URL}/lifestyle`} className='largeText semiBoldText whiteText noHoverWhiteText onHoverurpleBG mobileHeight40'
                  style={{ ...styles.shopNowBtn }}>PAY NOW</a>
                :
                <a href={`${process.env.REACT_APP_BLACKMARKET_URL}/lifestyle`} className='largeText semiBoldText whiteText noHoverWhiteText onHoverurpleBG'
                  style={{ ...styles.shopNowSmallBtn }}>PAY NOW</a>
              }
            </div>
          </div>
          <Marquee speed={100} style={{ margin: '55px 0' }}>
            <img className='marqueeMargin' style={{ height: '35px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_mimone@2x.png`} />
            <img className='marqueeMargin' style={{ height: '34px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_cheetah@2x.png`} />
            <img className='marqueeMargin' style={{ height: '15px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_borofone@2x.png`} />
            <img className='marqueeMargin' style={{ height: '60px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_perak fc@2x.png`} />
            <img className='marqueeMargin' style={{ height: '23px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_viu@2x.png`} />
            <img className='marqueeMargin' style={{ height: '23px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_apotec@2x.png`} />
            <img className='marqueeMargin' style={{ height: '37px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_puma@2x.png`} />
            <img className='marqueeMargin' style={{ height: '19px', ...styles.marqueeItem }} src={`${process.env.PUBLIC_URL}/assets/Logo/featured brands/logo_baesus@2x.png`} />
          </Marquee>
        </Suspense>

        <Suspense fallback={<div> </div>}>
          <div className='tab-bg' style={{ textAlign: 'center' }}>
            <img style={{ maxWidth: '75%', width: '331px', marginBottom: '20px' }} src={`${process.env.PUBLIC_URL}/assets/Logo/logo_superbrand@2x.png`} />

            {screenWidth > 1024 ?
              <div className='row_newlandingpage' style={{ justifyContent: 'center' }}>
                {/* below percentage, the big one width is 868/1920 and its height is based on the actial ratio of the image*/}
                <div style={{ position: 'relative' }}>
                  {/* <div className='collosalText44 whiteText boldText' style={{ position: 'absolute', left: '5%', bottom: '4%' }}>PUMA X GREMLINS</div> */}
                  <img style={{ borderRadius: '10px', width: 'calc(100vw * 0.452)', height: 'calc(100vw * 0.41184)' }} src={`${process.env.PUBLIC_URL}/assets/images/Super Brand/BM_New Small Website Banner_R1-08.jpg`} />
                </div>
                <div className='column_newlandingpage' style={{ marginLeft: '2vw', gap: '2vw' }}>
                  <div style={{ position: 'relative' }}>
                    {/* <div className='collosalText44 whiteText boldText' style={{ position: 'absolute', left: '5%', bottom: '8%' }}>FIKA Reserved</div> */}
                    <img style={{ borderRadius: '10px', width: 'calc(100vw * 0.30625)', height: 'calc(100vw * 0.20592 - 1vw)' }} src={`${process.env.PUBLIC_URL}/assets/images/Super Brand/BM_New Small Website Banner_R1_Panasonic (588px X 380px).jpg`} />
                  </div>
                  <div style={{ position: 'relative' }}>
                    {/* <div className='collosalText44 whiteText boldText' style={{ position: 'absolute', left: '5%', bottom: '8%' }}>Baseus MagPro</div> */}
                    <img style={{ borderRadius: '10px', width: 'calc(100vw * 0.30625)', height: 'calc(100vw * 0.20592 - 1vw)' }} src={`${process.env.PUBLIC_URL}/assets/images/Super Brand/BM_New Small Website Banner_R1_Puma (588px X 380px).jpg`} />
                  </div>
                </div>
              </div>
              :
              <div>
                <div style={{ position: 'relative' }}>
                  {/* <div className='collosalText32 whiteText boldText' style={{ position: 'absolute', left: '5%', bottom: '4%' }}>PUMA X GREMLINS</div> */}
                  <img style={{ borderRadius: '10px', width: '100%', height: 'auto' }} src={`${process.env.PUBLIC_URL}/assets/images/Super Brand/BM_New Small Website Banner_R1-08.jpg`} />
                </div>

                <div style={{ position: 'relative', margin: '5% 0' }}>
                  {/* <div className='collosalText32 whiteText boldText' style={{ position: 'absolute', left: '5%', bottom: '8%' }}>FIKA Reserved</div> */}
                  <img style={{ borderRadius: '10px', width: '100%', height: 'auto' }} src={`${process.env.PUBLIC_URL}/assets/images/Super Brand/BM_New Small Website Banner_R1_Panasonic (588px X 380px).jpg`} />
                </div>
                <div style={{ position: 'relative' }}>
                  {/* <div className='collosalText32 whiteText boldText' style={{ position: 'absolute', left: '5%', bottom: '8%' }}>Baseus MagPro</div> */}
                  <img style={{ borderRadius: '10px', width: '100%', height: 'auto' }} src={`${process.env.PUBLIC_URL}/assets/images/Super Brand/BM_New Small Website Banner_R1_Puma (588px X 380px).jpg`} />
                </div>
              </div>
            }
          </div>
        </Suspense>

        <Suspense fallback={<div> </div>}>
          <div className='tab-bg' style={{ backgroundColor: 'white' }}>
            <div className='collosalText28 boldText' style={{ marginBottom: '30px', textAlign: 'center', fontSize: '28px' }}>New Arrivals</div>
            {this.state.arrivalListLoading ?
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', }}>
                <LoadingLoader visibel={this.state.loading} />
              </div>
              :
              screenWidth > 768 ?
                <div className='row_newlandingpage' style={{ justifyContent: 'center', gap: '2%' }}>
                  {this.state.arrivalList.map((item, index) => {
                    var enc = window.btoa(item.slug);
                    let removePerc = item.name ? item.name.split('%').join(' ') : ' ';
                    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
                    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
                    let redirectLink = '/product/' + convertNameIntoDashes + '?id=' + enc;
                    return (
                      <div style={{ position: 'relative', textAlign: 'center', marginBottom: '25px' }}>
                        <div className='whiteText largeText orangeBG semiBoldText' style={{ left: '3%', top: '3%', position: 'absolute', width: 'fit-content', padding: '1px 12px' }}>NEW</div>
                        <img style={{ borderRadius: '6px', width: 'calc(100vw / 3.5)', height: 'calc(100vw / 3.5)', maxWidth: '475px', maxHeight: '475px' }} src={item.color[0].icon.link} />
                        <div className='collosalText semiBoldText' style={{ margin: '5% 0', width: 'calc(100vw / 3.5)', minHeight: '50px', maxWidth: '475px', fontSize: '18px',lineHeight:1.3  }}>{item.name}</div>
                        <a href={redirectLink} className='purpleBG whiteText noHoverWhiteText onHoverLightPurpleBG semiBoldText largeText mobileHeight40' style={{ alignItems: 'center', margin: 'auto', display: 'flex', justifyContent: 'center', border: 0, borderRadius: '6px', maxWidth: 'calc(100vw / 6)', width: '180px', height: '50px' }}>SHOP NOW</a>
                      </div>
                    )
                  })}
                </div>
                :
                <div className='row_newlandingpage' style={{ justifyContent: 'center' }}>
                  {this.state.arrivalList.map((item, index) => {
                    var enc = window.btoa(item.slug);
                    let removePerc = item.name ? item.name.split('%').join(' ') : ' ';
                    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
                    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
                    let redirectLink = '/product/' + convertNameIntoDashes + '?id=' + enc;
                    return (
                      <div style={{ position: 'relative', textAlign: 'center', marginBottom: '25px' }}>
                        <div className='whiteText largeText orangeBG semiBoldText' style={{ left: '3%', top: '3%', position: 'absolute', width: 'fit-content', padding: '1px 12px' }}>NEW</div>
                        <img style={{ borderRadius: '6px', width: 'calc(100vw / 1.5)', height: 'calc(100vw / 1.5)', maxWidth: '475px', maxHeight: '475px' }} src={item.color[0].icon.link} />
                        <div className='collosalText semiBoldText' style={{ margin: '5% 0', width: 'calc(100vw / 1.5)', minHeight: '50px', maxWidth: '475px', fontSize: '18px',lineHeight:1.3  }}>{item.name}</div>
                        <a href={redirectLink} className='purpleBG whiteText noHoverWhiteText onHoverLightPurpleBG semiBoldText largeText mobileHeight40' style={{ alignItems: 'center', margin: 'auto', display: 'flex', justifyContent: 'center', border: 0, borderRadius: '6px', maxWidth: 'calc(100vw / 2.5)', width: '180px', height: '50px' }}>SHOP NOW</a>
                      </div>
                    )
                  })}
                </div>
            }
          </div>
        </Suspense>

        <Suspense fallback={<div> </div>}>
        </Suspense>
        <Suspense fallback={<div> </div>}>
          <Trending2 type={'watch'}
            title={'FEATURED PRODUCTS'}
            {...this.props}
            api={'get_featured_products'}
            numOfRecords={4}
            viewMoreProducts={this.goToFeaturedProducts}
          />
        </Suspense>
        <Suspense fallback={<div> </div>}>
          <div className='tab-bg' style={{ position: 'relative' }}>
            {screenWidth > 768 ?
              <div>
                <img style={{ width: '100%', position: 'relative' }} src={`${process.env.PUBLIC_URL}/assets/images/BLACK app download/BLACK app download@2x.png`} />
                <div style={{ position: 'absolute', left: '35%', top: '37%' }}>
                  <div className='semiBoldText whiteText sourceSansPro' style={{ fontSize: '2.2vw', textAlign: 'center' }}>Don’t miss out on the Exciting<br />Deals and Special Promos today!</div>
                  <div className='whiteText sourceSansPro' style={{ marginTop: '5%', fontSize: '1.2vw', textAlign: 'center' }}>Grab it from our app before it runs out!</div>
                  <a href="https://blackapps.xox.com.my/webview/app_download?language=en" className='largeText semiBoldText whiteText noHoverWhiteText mobileHeight40'
                    style={{ ...styles.shopNowBtn, position: 'unset', width: 'fit-content', padding: '5%', fontSize: '1.2vw', marginTop: '7.5%' }}>DOWNLOAD NOW</a>
                </div>
              </div>
              :
              <a href="https://blackapps.xox.com.my/webview/app_download?language=en">
                <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/assets/images/BLACK app download/Download black app@2x.png`} />
                {/* <div style={{ position: 'absolute', left: '0', right: '0', top: '27%' }}>
                  <div className='semiBoldText whiteText sourceSansPro' style={{ fontSize: '4vw', textAlign: 'center' }}> </div>
                  <div className='whiteText sourceSansPro' style={{ marginTop: '3%', fontSize: '3vw', textAlign: 'center' }}></div>
                  <a href="https://blackapps.xox.com.my/webview/app_download?language=en" className='largeText semiBoldText whiteText noHoverWhiteText mobileHeight40'
                    style={{ ...styles.shopNowBtn, borderRadius: '10px', marginRight: '52%', width: 'fit-content', padding: '5%', position: 'unset', fontSize: '3vw', marginTop: '13%' }}>DOWNLOAD NOW</a>

                </div> */}
              </a>
            }
          </div>
        </Suspense>
        {/* <Suspense fallback={<div> </div>}>
          <Trending type={'watch'}
            title={'FEATURED PRODUCTS'}
            {...this.props}
            api={'get_featured_products'}
            numOfRecords={10}
            numOfColumns={4}
            viewMoreProducts={this.goToFeaturedProducts}
          />
        </Suspense> */}
        {/*Featured Products End*/}
        {/* <Suspense fallback={<div> </div>}>
          <Trending type={'watch'}
            title={'Featured services'}
            showHeader
            {...this.props}
            numOfRecords={4}
            numOfColumns={2}
            colSize={6}
            api={'get_featured_services'}
            viewMoreProducts={this.goToFeaturedServices}
          />
        </Suspense> */}
        {/* Featured category */}
        {/* <Suspense fallback={<div> </div>}>
          <FeaturedCategory
            api={'get_all_featured_categories'}
            {...this.props}
          />

        </Suspense> */}
        {/* <Suspense fallback={<div> </div>}>
          <LatestService
            api={'get_latest_services'}
            {...this.props}
            numOfRecords={6}
            title={'Latest Services'}
            viewMoreProducts={this.goToLatestServices}
          />
          {/* Latest Products ends*/}
        {/* Latest Services */}
        {/* </Suspense> */}
        {/* <Suspense fallback={<div> </div>}>
          <div className='p-3 pt-5' style={{ background: '#fafafa' }}>
            <LatestProducts
              limit={10}
              title={'Latest Products'}
              colSize={3}
              api={'get_latest_products'}
              {...this.props}
              numOfRecords={8}
              numOfColumns={2}
              viewMoreProducts={this.goToLatestServices}
            />
          </div>
        </Suspense> */}
        {/* {this.renderServices()} */}
        <ThemeSettings />
      </div>
    )
  }
}

const styles = {
  marqueeItem: {
    width: 'auto',
    margin: '0 70px'
  },
  adItemMobile: {
    width: '45%',
    marginBottom: '10px',
    textAlign: 'center'
  },
  adImageMobile: {
    marginBottom: '10px',
    height: '30px',
    width: 'auto'
  },
  adImage: {
    alignItems: 'center',
    margin:'auto',
    marginRight: '10px',
    height: '35px',
    width: 'auto'
  },
  shopNow: {
    position: 'relative',
    width: '728px',
  },
  shopNowBtn: {
    width: '180px',
    height: '50px',
    border: '0',
    backgroundColor: 'black',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '6px',
    textAlign: 'center',
    bottom: '5%',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shopNowSmallBtn: {
    width: '180px',
    maxWidth: '50%',
    height: '35px',
    border: '0',
    backgroundColor: 'black',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '6px',
    textAlign: 'center',
    bottom: '2%',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shopNowImage: {
    width: '728px',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '10px'
  },
  shopNowText: {
    position: 'absolute',
    left: '5%',
    top: '6%',
  }
};

export default SuperHOC(HomeScreen)
