import React, { Component } from 'react'
import Chat from '../components/Chat/Chat'
export default class ChatScreen extends Component {
   render() {
          return (
              <div>
                  <Chat
                  {...this.props}
                  /> 
              </div>
           )
   }
}
