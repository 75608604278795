import React, { Component } from "react";
import { SuperHOC } from "../HOC";
import LoadingLoader from "../components/Loader/Loader";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import NegativeAlert from "../components/CustomAlert/CustomAlert";
import ForgotComponent from "../components/Login/ForgotComponent";
class ForgotPasswordScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showNegativeAlert: false,
            showPositiveAlert: false,
        };
    }

    goToLoginScreen = () => {
        this.props.history.push("/login");
    };

    forgetPassword = (email, password) => {
        this.setState({
            loading: true,
        });
        let body = {
            email: email,
        };
        this.props.apiManager.makeCall("forgotPassword", body, (res) => {
            if (res.message === 7066 || res.message === 7031) {
                this.setState({
                    loading: false,
                    showPositiveAlert: true,
                    errorMessage: `OTP sent to ${email}`,
                });
                setTimeout(() => {
                    this.props.history.push({
                        pathname: `/ForgetVerification`,
                        search: `id=${email}`,
                        state: { isLogin: false, hash: res.token },
                    });
                }, 2000);
            } else if (res.message === 7000) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: `Email not found ${email}`,
                });
            } else if (res.message === 6107) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: `OTP Limit Exceeded ${email}`,
                });
            } else {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: `Something went wrong ${email}`,
                });
            }
            //this.props.history.replace('/login');
        });
    };
    render() {
        if (this.state.loading)
            return (
                <div
                    style={{
                        display: "flex",
                        minHeight: "100vh",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                    }}
                >
                    <LoadingLoader visible={this.state.loading} />
                </div>
            );

        return (
            <div>
                <ForgotComponent
                    onSubmit={({ email }) => this.forgetPassword(email)}
                />
                <NegativeAlert
                    error
                    title={"Error"}
                    message={this.state.errorMessage}
                    open={this.state.showNegativeAlert}
                    onClose={() => this.setState({ showNegativeAlert: false })}
                />
                <PositiveAlert
                    title={"Error"}
                    open={this.state.showPositiveAlert}
                    message={this.state.errorMessage}
                    onClose={() => this.goToLoginScreen()}
                />
            </div>
        );
    }
}

export default SuperHOC(ForgotPasswordScreen);
