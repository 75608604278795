import React, { Component } from "react";
import LoadingLoader from "../Loader/Loader";
import Breadcrumb from "../common/breadcrumb";
import CustomAlert from "../CustomAlert/CustomAlert";
import ChangeAlert from "../CustomAlert/NegativeAlertWithTwoBtn";
import NameAlert from "../CustomAlert/NegativeAlertWithTwoBtn";
import SuccessAlert from "../CustomAlert/CustomAlert";

const CURRENCY = "MYR";
let verifyy = "";
class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            paymentMethods: [],
            paymentId: null,
            selectedColor: "",
            code: "IPAY",
            selectedIndex: null,
            bankImage: null,
            showErrorMesssage: false,
            showSuccessMesssage: false,
            successMessage: "",
            cartJson: {},
            checkutItems: {},
            checkoutId: "",
            previous: "",
            showNegativeAlert: false,
            errorPayment: false,
            userVerify: "",
            currentEmail: "",
            sucessEmailSend: false,
            noNameAlert: false,
            noNameErrorMsg: "",
            currentName: "",
            currentLastName: "",
        };
    }

    getPaymentMethods = () => {
        this.props.apiManager.makeCall("get_payment_methods", {}, (res) => {
            if (res.message === "1979") {
                this.setState({
                    paymentMethods: res.result,
                    loading: false,
                });
            } else if (res.message === "1425") {
                this.setState({
                    paymentMethods: {},
                    emptyCart: true,
                    loading: false,
                });
            } else {
                this.setState({
                    paymentMethods: {},
                    emptyCart: true,
                    loading: false,
                });
            }
        });
    };

    getCheckOut = () => {
        this.props.apiManager.makeCall("get_checkout_items", {}, (res) => {
            if (res.message === "1426") {
                this.setState({ cartJson: res.cartJson, loading: false });
            } else if (res.message === "1425") {
                this.setState({
                    cartJson: {},
                    emptyCart: true,
                    loading: false,
                });
            } else {
                this.setState({
                    cartJson: {},
                    emptyCart: true,
                    loading: false,
                });
            }
        });
    };

    getToPay = (checkout) => {
        this.props.apiManager.makeCall(
            "get_topay_checkout_items",
            { checkoutId: checkout },
            (res) => {
                if (res.message === "1426") {
                    this.setState({ cartJson: res.cartJson, loading: false });
                } else if (res.message === "1425") {
                    this.setState({
                        cartJson: {},
                        emptyCart: true,
                        loading: false,
                    });
                } else {
                    this.setState({
                        cartJson: {},
                        emptyCart: true,
                        loading: false,
                    });
                }
            }
        );
    };

    componentDidMount() {
        let previous = document.referrer.split("/");
        let same = this.props.location.pathname.split("/");
        if (previous[3] !== same[2]) {
            this.setState({ previous: previous[3] });
        }
        var a, dec, b;
        a = this.props.location.search.split("?id=");
        if (a[1] != undefined && a[1].length > 0) {
            b = a[1].split("&");
            dec = b[0];
            this.setState({
                checkoutId: dec,
            });
        }
        if (dec != undefined && dec) {
            this.getToPay(dec);
        } else {
            this.getCheckOut();
        }
    }

    handleCheck = (item) => {
        this.setState({ bankImage: item.asset.src, code: item.code });
    };

    placeOrder = () => {
        this.props.apiManager.get("getUser", {}, (res) => {
            if (res.message == 7079) {
                this.setState({
                    currentEmail: res.result[0].email,
                    currentNumber: res.result[0].MSISDN,
                    currentName: res.result[0].firstName,
                    currentLastName: res.result[0].lastName,
                    userVerify: res.result[0].emailVerified,
                });

                // IF EMail is verify this check will triggered //
                if (res.result[0].emailVerified === 0) {
                    if (res.result[0].firstName === undefined) {
                        this.setState({
                            noNameAlert: true,
                            noNameErrorMsg:
                                "Error missing User details, Please specify full name in Edit Profile",
                        });
                    } else {
                        this.setState({ showNegativeAlert: false });
                        verifyy = res.result[0].emailVerified;
                        const { paymentId, code, cartJson } = this.state;
                        if (code === "") {
                            this.setState({
                                showErrorMesssage: true,
                                errorMessage:
                                    "Please select payment method first!",
                            });
                        }
                        if (paymentId === null) {
                            this.setState({
                                errorPayment: true,
                                errorMessage:
                                    "Please select payment method first!",
                            });
                        }
                        let body = {
                            code: code,
                            toPay: cartJson.toPay,
                            checkoutId: cartJson._id,
                            clt: 1, // request from web
                        };

                        if (paymentId) {
                            const middlewareCreateOrderParams = (response) => {
                                // Product Description
                                var itemList = [];
                                var userName,
                                    phoneNum = "";
                                const merchantProducts = Object.values(
                                    cartJson.merchantProducts
                                );

                                merchantProducts.map((item) => {
                                    item.itemsJson.map((list, index) => {
                                        let name =
                                            this.state.currentName +
                                            " " +
                                            this.state.currentLastName;
                                        if (index === 0) {
                                            userName = name;
                                            phoneNum = list.buyerMobileNo;
                                        }
                                        itemList.push(
                                            "[" +
                                                item.merchant.merchantName +
                                                ", " +
                                                list.productName +
                                                " x" +
                                                list.quantity +
                                                ", " +
                                                list.specificationName +
                                                ", " +
                                                list.colorName +
                                                "]"
                                        );
                                    });
                                });

                                var totalPayment = (
                                    response.checkout.total +
                                    response.checkout.deliveryCost
                                ).toFixed(2);
                                var description =
                                    "Order Detail:\n" +
                                    itemList.toString() +
                                    "\nUser: " +
                                    userName +
                                    "\nTotal: RM " +
                                    totalPayment;

                                return {
                                    // Amount: response.checkout.total,
                                    // RefNo: response.RefNo,
                                    // access_token: "testtoken",
                                    // amount: totalPayment,
                                    // // amount: 1, // totalPayment,
                                    // currency: CURRENCY,
                                    // payment_d:
                                    //     this.state.paymentId == "credit"
                                    //         ? 2
                                    //         : 999,
                                    // product_description: description, //=fd gk,mn
                                    // ref_no: response.RefNo,
                                    // user_contact: phoneNum,
                                    // user_email: response.checkout.email,
                                    // user_name: userName,
                                    // callback_url:
                                    //     "http://xoxminimarket.azure-i.com/",

                                    actionKey: 53,
                                    amount: totalPayment,
                                    orderFrom: "BLACKAPP",
                                    msisdn: phoneNum,
                                    referanceNo: response.RefNo,
                                    description: description,
                                    paymentMethod:
                                        this.state.paymentId == "credit"
                                            ? 2
                                            : 999,
                                    userName: userName,
                                };
                            };
                            this.props.apiManager.makeCall(
                                "select_payment_method",
                                body,
                                (res) => {
                                    if (res.message === "2091") {
                                        if (code == "IPAY") {
                                            // request for ipay88 (middleware)
                                            this.setState(
                                                {
                                                    orderId: JSON.stringify(
                                                        res.RefNo
                                                    ),
                                                },
                                                this.saveToLocal
                                            );

                                            let createOrderBody = middlewareCreateOrderParams(
                                                res
                                            );

                                            this.props.apiManager.makeCall(
                                                "payment/createOrder",
                                                createOrderBody,
                                                (crRes) => {
                                                    
                                                    if (crRes.message===1051) {
                                                        window.location.href =
                                                            crRes.url+'?callback_url='+'https://blackmarket.xox.com.my/';
                                                    } else if (
                                                        crRes.code === 15066
                                                    ) {
                                                        //success
                                                        this.setState({
                                                            showSuccessMesssage: true,
                                                            successMessage:
                                                                "Email Verified",
                                                        });
                                                    } else if (
                                                        crRes.code === 15067
                                                    ) {
                                                        //failed
                                                        this.setState({
                                                            showErrorMesssage: true,
                                                            errorMessage:
                                                                "Your Email is not Verify, \nBefore Performing a Transaction on the Black Market, Please verify your email.",
                                                        });
                                                    } else {
                                                        this.setState({
                                                            showErrorMesssage: true,
                                                            errorMessage:
                                                                "Opps! something is wrong. Please try with correct data.",
                                                        });
                                                    }
                                                }
                                            );
                                        } else {
                                            window.Checkout.configure({
                                                merchant: "AzureIn",
                                                order: {
                                                    amount: function() {
                                                        //Dynamic calculation of amount
                                                        return 100;
                                                    },
                                                    currency: "PKR",
                                                    description: "description",
                                                    id:
                                                        "34569692w33erttdfjhdghhhf",
                                                },
                                                interaction: {
                                                    operation: "PURCHASE",
                                                    merchant: {
                                                        name: "nadeem",
                                                        address: {
                                                            line1:
                                                                "one hundeerr",
                                                            line2: "two line",
                                                        },
                                                    },
                                                },
                                                session: {
                                                    id: res.session,
                                                },
                                            });
                                            window.Checkout.showPaymentPage();
                                            this.setState(
                                                {
                                                    orderId: JSON.stringify(
                                                        res.orderId
                                                    ),
                                                },
                                                this.saveToLocal
                                            );
                                        }
                                    } else if (res.message === "2068") {
                                        this.setState({
                                            showSuccessMesssage: true,
                                            successMessage: res.decodedMessage,
                                        });
                                    } else if (res.message === "2093") {
                                        this.setState({
                                            showErrorMesssage: true,
                                            errorMessage: res.decodedMessage,
                                        });
                                    }
                                }
                            );
                        }
                    }

                    // }
                }

                // IF EMail is not verify this check will triggered //

                if (res.result[0].emailVerified === 1) {
                    this.setState({
                        showNegativeAlert: true,
                        errorMessage:
                            "Your Email is not Verified, \nBefore Performing a Transaction on the Black Market, Please verify your Email.",
                    });
                }
            }
            verifyy = res.result[0].emailVerified;

            // else {
            //     this.setState({
            //         errorMessage: "User not found Try different Email",
            //     });
            // }
        });
    };

    saveToLocal() {
        const local = this.state.orderId;
        localStorage.setItem("orderId", local);
    }

    changePaymentId = (e) => {
        this.setState({ paymentId: e.target.value });
    };

    renderApi = async () => {
        let orderId = await JSON.parse(localStorage.getItem("orderId"));
        if (orderId !== null) {
            let body = { orderId: orderId, type: "0" };
            this.props.apiManager.makeCall(
                "payment_gateway_reterive_order",
                body,
                (res) => {
                    if (res.message === "2068") {
                        this.setState({
                            showSuccessMesssage: true,
                            successMessage: res.decodedMessage,
                        });
                    } else if (res.message === "2069") {
                        this.setState({
                            showSuccessMesssage: true,
                            successMessage: res.decodedMessage,
                        });
                    } else {
                        this.setState({
                            showErrorMesssage: true,
                            errorMessage: "payment has been cancelled",
                        });
                    }
                }
            );
        } else {
            console.info("retrieve order data");
        }
    };

    onCompleteOrder = () => {
        this.setState({ showSuccessMesssage: false });
        localStorage.removeItem("orderId");
        this.props.history.push(`/`);
    };

    onCancelOrder = () => {
        localStorage.removeItem("orderId");
        this.setState({ showErrorMesssage: false });
    };

    verifyEmail = (registerData) => {
        this.setState({
            showNegativeAlert: false,
        });

        let payload = {
            email: registerData,
        };

        let body = {
            email: payload.email,
        };

        this.props.apiManager.makeCall(
            "emailVerificationRequest",
            body,
            (res) => {
                if (res.message == 7073) {
                    this.setState({
                        sucessEmailSend: true,
                        successLogin: true,
                        loading: false,
                        successMessage:
                            "Email has been sent to your account, Please verify!",
                    });
                    setTimeout(() => {
                        this.setState({
                            sucessEmailSend: false,
                        });
                    }, 3000);
                } else if (res.message === 7000) {
                    this.setState({
                        sucessEmailSend: false,
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: "Email already Verified",
                    });
                } else {
                    this.setState({
                        loading: false,
                        sucessEmailSend: false,
                        showNegativeAlert: true,
                        errorMessage: "Email already Verified",
                    });
                }
            }
        );
    };

    noNamePopup = () => {
        this.props.history.push("/Edit-Profile");
    };

    emptyCart = () => {
        return (
            <section
                className="cart-section section-b-space"
                style={{ paddingTop: "100px" }}
            >
                <div className="container" style={{ minHeight: "60vh" }}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div>
                                <div className="col-sm-12 empty-cart-cls text-center">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                                        className="img-fluid mb-4"
                                        alt=""
                                    />
                                    <h3>
                                        <strong>Your Payments Are Empty</strong>
                                    </h3>
                                    <h4>Explore more shortlist some items.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    render() {
        const {
            productsTotal,
            subTotal,
            total,
            deliveryCost,
            promoDiscount,
            servicesTotalAmount,
        } = this.state.cartJson;
        
        if (this.state.loading)
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "70vh",
                    }}
                >
                    {" "}
                    <LoadingLoader visible={this.state.loading} />{" "}
                </div>
            );
        return (
            <div>
                <Breadcrumb title={"Payments"} parent={this.state.previous} />
                {this.state.emptyCart ? (
                    this.emptyCart()
                ) : (
                    <section
                        className="section-b-space"
                        style={{ marginTop: "40px" }}
                    >
                        <div className="container">
                            <div className="checkout-page">
                                <div className="checkout-form">
                                    <form>
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8 col-lg-12 col-sm-12 col-xs-12">
                                                <div className="checkout-details">
                                                    <div className="order-box">
                                                        <div className="title-box">
                                                            <div>
                                                                Order Summary
                                                                <span>
                                                                    Total
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul className="qty">
                                                            <li>
                                                                Products Total
                                                                <span>
                                                                    RM{" "}
                                                                    {productsTotal
                                                                        ? productsTotal.toFixed(
                                                                              2
                                                                          )
                                                                        : 0.0}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Services Total
                                                                <span>
                                                                    RM{" "}
                                                                    {servicesTotalAmount
                                                                        ? servicesTotalAmount.toFixed(
                                                                              2
                                                                          )
                                                                        : 0.0}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Delivery Cost
                                                                <span>
                                                                    RM{" "}
                                                                    {deliveryCost.toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Promo Discount
                                                                <span>
                                                                    RM{" "}
                                                                    {promoDiscount.toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        {/* <ul className="sub-total">
                              <li>Subtotal (without discount)
                            <del className="count">
                                  RM {subTotal ? subTotal.toFixed(2) : 0}
                                </del>
                              </li>
                            </ul> */}
                                                        <ul className="sub-total">
                                                            <li>
                                                                Total
                                                                <span className="count">
                                                                    RM{" "}
                                                                    {total
                                                                        ? (
                                                                              total +
                                                                              deliveryCost
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : 0}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="payment-box">
                                                        <div className="row">
                                                            {/* <div className="col-6">
                              <div className="upper-box">
                              <div className="payment-options">
                                <ul>
                                  {
                                    this.state.paymentMethods.map((item, index) => {
                                      return (
                                        <li key={index}>
                                          <div className="radio-option">
                                            <input type="radio" name="payment-group"
                                              id={item._id}
                                              onClick={() => this.handleCheck(item)}
                                            />
                                            <label htmlFor={item._id}>{item.name}
                                              <span className="small-text">
                                                Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.
                                        </span>
                                            </label>
                                          </div>
                                        </li>
                                      )
                                    })
                                  }
                                  <li>
                                    <div className="radio-option">
                                      <span className="image">
                                        <img src={this.state.bankImage} width="150px" height="80px" style={{ marginLeft: '30px', backgroundColor: 'white', display: this.state.bankImage === null ? 'none' : 'block' }} alt="" />
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                              </div> */}
                                                            <div className="col-6">
                                                                <select
                                                                    className="form-control"
                                                                    name="paymentId"
                                                                    id="payment-id"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.changePaymentId(
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    <option
                                                                        value=""
                                                                        disabled
                                                                        selected
                                                                    >
                                                                        -- Pay
                                                                        Using --
                                                                    </option>
                                                                    <option value="credit">
                                                                        Credit
                                                                        Card
                                                                    </option>
                                                                    <option value="online">
                                                                        Online
                                                                        Banking
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="text-right">
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .placeOrder
                                                                }
                                                                className="btn-solid btn"
                                                            >
                                                                Place Order
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-2"></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <CustomAlert
                            error
                            onClose={() =>
                                this.setState({ errorPayment: false })
                            }
                            open={this.state.errorPayment}
                            title={"error"}
                            message={this.state.errorMessage}
                        />
                        <CustomAlert
                            error
                            onClose={this.onCancelOrder}
                            open={this.state.showErrorMesssage}
                            title={"error"}
                            message={this.state.errorMessage}
                        />
                        <ChangeAlert
                            error
                            changeName={true}
                            goto={() =>
                                this.verifyEmail(this.state.currentEmail)
                            }
                            onClose={() =>
                                this.setState({ showNegativeAlert: false })
                            }
                            open={this.state.showNegativeAlert}
                            title={"error"}
                            message={this.state.errorMessage}
                        />
                        <NameAlert
                            error
                            goto={() => this.noNamePopup()}
                            onClose={() =>
                                this.setState({ noNameAlert: false })
                            }
                            open={this.state.noNameAlert}
                            title={"error"}
                            message={this.state.noNameErrorMsg}
                        />
                        <SuccessAlert
                            onClose={this.onCompleteOrder}
                            open={this.state.showSuccessMesssage}
                            title={"Success"}
                            message={this.state.successMessage}
                        />
                        <SuccessAlert
                            onClose={() =>
                                this.setState({ sucessEmailSend: false })
                            }
                            open={this.state.sucessEmailSend}
                            title={"Success"}
                            message={this.state.successMessage}
                        />
                    </section>
                )}
            </div>
        );
    }
}
export default MainContainer;
