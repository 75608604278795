import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { SuperHOC } from '../../HOC';
import { toast, ToastContainer, } from 'react-toastify';
import { formatDate } from '../../config/HelperFunction'

class SingleComplain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            id: this.props.match.params.id,
            complainReply: "",
            replies: []
        }
        this.enterComplainReply = this.enterComplainReply.bind(this);
    }


    componentDidMount = () => {
        let body = {
            orderId: this.props.match.params.id
        }

        this.props.apiManager.makeCall('get_single_order_details', body, (res) => {
            if (res.message === '1729') {
                this.setState({ item: res.order })
                this.getComplainReplies(res.order.complainSlug);
            }
            else {
                console.error('Error: ', res.message)
            }
        })
    }

    enterComplainReply(event) {
        this.setState({ complainReply: event.target.value });
    }

    getComplainReplies = (complainSlug) => {
        let body = {
            complainId: complainSlug
        }

        this.props.apiManager.makeCall('get_complain_replies', body, (res) => {
            if (res.message === '1815') {
                this.setState({ replies: res.result.docs })
            }
            else {
                console.error('Error in getting replies: ', res.decodedMessage);
            }
        })
    }

    submitReply = () => {
        let body = {
            transactionId: this.state.id,
            complainMessage: this.state.complainReply,

        }
        
        this.props.apiManager.makeCall('reply_complain', body, (res) => {
            if (res.message === '1808') {
                toast.success(res.decodedMessage)
                this.getComplainReplies();
            } else {
                toast.error(res.decodedMessage)
            }
        })

        this.setState({ complainReply: '' })
    }

    render() {
        const { productName, priceAfterDiscount, _id, merchantName, deliveryAddress } = this.state.item
        let address = (deliveryAddress && deliveryAddress.address) + ", " + (deliveryAddress && deliveryAddress.cityName)

        return (
            <div>
                <Breadcrumb title={'Complain Replies'} />
                <ToastContainer />

                <br />
                <br />
                <br />
                <section className="section-b-space">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-4">

                                <table className="table table-striped mb-0">
                                    <tbody>
                                        <tr>
                                            <th>Product:</th>
                                            <td>{productName}</td>
                                        </tr>
                                        <tr>
                                            <th>Price:</th>
                                            <td>{priceAfterDiscount}</td>
                                        </tr>
                                        <tr>
                                            <th>Delivery Address:</th>
                                            <td>{address}</td>
                                        </tr>
                                        <tr>
                                            <th>Order ID</th>
                                            <td>{_id}</td>
                                        </tr>
                                        <tr>
                                            <th>Merchant</th>
                                            <td> {merchantName} </td>
                                        </tr>

                                    </tbody>
                                </table> <br />

                            </div>
                            <div className="col-lg-8">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>Complain Messages History</h2>
                                        </div>

                                        {this.state.replies.map((reply, index) => {
                                            return (
                                                <div className="form-row">

                                                    <div className="col-md-12" style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                                        <strong htmlFor="review" className="col-md-3" style={{ color: 'red' }}> {reply.addedBy} </strong>
                                                        <div className="col-md-5"></div>
                                                        <strong htmlFor="review" className="col-md-4">{formatDate(new Date(reply.createdAt))}</strong>
                                                    </div>
                                                    <div className="col-md-12" style={{ borderBottom: "1px solid grey" }}>
                                                        <br />
                                                        <p>{reply.complainMessage}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <br />
                        <br />

                        <div className="row">
                            <div className="col-lg-4">
                                {/* <p>Hello</p> */}
                            </div>
                            <div className="col-lg-8">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>Reply to Complain</h2>
                                        </div>

                                        <div className="form-row">

                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <label htmlFor="review">Complain Reply Message</label>
                                                <textarea
                                                    className="form-control"
                                                    value={this.state.complainReply}
                                                    onChange={this.enterComplainReply}
                                                    placeholder="Wrire Your Reply Details Here"
                                                    // id="exampleFormControlTextarea1"
                                                    rows="3"
                                                    required>   </textarea>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <button
                                                    className="btn btn-solid"
                                                    type="button"
                                                    onClick={this.submitReply}
                                                >
                                                    Submit Reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default SuperHOC(SingleComplain);