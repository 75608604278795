import React, { Component } from 'react'
import ProductItem from './ProductItems'
import Breadcrumb from "../common/breadcrumb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, } from '@fortawesome/free-solid-svg-icons'
import LoadingLoader from '../Loader/Loader'
import CustomAlert from './../CustomAlert/CustomAlert'
export default class MainContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      cartJson: {},
      emptyCart: false,
      selectOnly: {},
      loading: true,
      checked: null,
      allTrue: true,
      previous: '',
      promoCode : null,
      showModal : false,
      errorMessage : '',
    }
  }

  componentDidMount() {
    this.getCheckOut()
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')
    if (previous[3] !== same[2]) {
      this.setState({ previous: previous[3] })
    }
  }

  goToPaymentMaythedsScreen = () => {
    this.props.history.push(`/Payments`)
  }

  getCheckOut = () => {
    this.props.apiManager.makeCall('get_checkout_items', {}, (res) => {
      if (res.message === '1426') {
        this.setState({ cartJson: res.cartJson, loading: false })
      }
      else if (res.message === '1425') {
        this.setState({ cartJson: {}, emptyCart: true, loading: false })
      }
      else {
        this.setState({ cartJson: {}, emptyCart: true, loading: false })
      }
    })
  }

  cancelCheckout = (checkoutId) => {
    this.props.apiManager.makeCall('cancel_checkout_cart', {'checkoutId' : checkoutId}, (res) => {
      if (res.message === '1970') {
        this.getCheckOut()
      }
      else {
        console.info(res.message)
      }
    })
  }

  renderProductItem = () => {
    const { merchantProducts } = this.state.cartJson
    const newItem = Object.keys(merchantProducts).map(key => merchantProducts[key])
    if (newItem.length === 0) return null
    return (
      <>
        <div style={{ backgroundColor: '#f9f9f9f9', display: 'flex', padding: 10 }}>
          <h3 style={{ margin: 0, padding: 0 }}>Products</h3>
        </div>
        <div>
          <table className="table cart-table table-responsive">
            <thead style={{ width: '100%' }}>
              <tr className='table-row' >
                <th scope='col'>Image</th>
                <th scope='col'>Name</th>
                <th scope='col'>Spec</th>
                <th scope='col'>Color</th>
                <th scope='col'>Price</th>
                <th scope='col'>Qty</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            {
              newItem.map((item, index) => {
                let itemJson = item.itemsJson
                return (
                  <ProductItem
                    key={index}
                    item={item}
                    itemJson={itemJson}
                  />
                )
              })
            }
          </table>
        </div>
      </>
    )
  }

  renderServicesItem = () => {
    const { merchantServices } = this.state.cartJson
    const newItem = Object.keys(merchantServices).map(key => merchantServices[key])
    if (newItem.length === 0) return null
    return (
      <>
        <div style={{ marginTop: 20, padding: '10px', backgroundColor: '#f9f9f9f9', display: 'flex', flexDirection: 'row' }}>
          <h3 style={{ marginLeft: '10px' }}>Services</h3>
        </div>
        <div>
          <table className="table cart-table table-responsive">
            <thead style={{ width: '100%' }}>
              <tr className='table-row' >
                <th scope='col'>Image</th>
                <th scope='col'>Name</th>
                <th scope='col'>Spec</th>
                <th scope='col'>Color</th>
                <th scope='col'>Price</th>
                <th scope='col'>Qty</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            {
              newItem.map((item, index) => {
                let itemJson = item.itemsJson
                return (
                  <ProductItem
                    key={index}
                    item={item}
                    itemJson={itemJson}
                  />
                )
              })
            }
          </table>
        </div>
      </>
    )
  }

  emptyCart = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div >
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                    <h3>
                      <strong>Your Checkout  is Empty</strong>
                    </h3>
                    <h4>Explore more shortlist some items.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  getPromoCode = (e) => {
    this.setState({promoCode: e.target.value});
  }

  renderPromoCode = (promoValue) => {
    return(
      <>
        <div className='d-flex justify-content-between title-box'>
          <li>Promo Code <span className="count">RM {promoValue}</span></li>
          <i className='fa fa-times text-danger cursor-p' onClick={() => this.removePromoCode()}></i>
        </div>
      </>
    )
  }

  removePromoCode = () => {
    this.setState({loading: true})
    this.props.apiManager.makeCall('remove_promo_code', {}, (res) => {
      if (res.message === '1831') {
        this.getCheckOut()
      }
      else {
        this.setState({showModal : true, errorMessage : res.message, loading: false})
      }
    })
  }

  renderPromoInput = (promoApplied) => {
    return(
    <>
    {(promoApplied) ? null : <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5, height: '40px' }}>
      <input type="text" className="form-control" style={{ display: 'flex', width: '90%', backgroundColor: '#F5F2F2', height: 'auto' }} id="exampleFormControlInput"
        placeholder="Promo Code" onChange={(e) => this.getPromoCode(e) } value={this.state.promoCode}/>
      <button style={{ padding: '5px 15px 5px 15px' }} type="button" className="btn btn-solid" onClick={() => this.applyPromoCode()}>Apply</button>
    </div>}
    </>
    )
  }

  applyPromoCode = () => {
    if(this.state.promoCode){
      this.setState({loading: true})
      this.props.apiManager.makeCall('apply_promo_code', {'promoCode' : this.state.promoCode}, (res) => {
        if (res.message === '1829') {
          this.getCheckOut()
        }
        else {
          this.setState({showModal : true, errorMessage : res.message, loading: false})
        }
      })
    }else{
      this.setState({showModal : true, errorMessage : 'Please enter valid promo code', loading: false})
    }
  }

  render() {
    const {
      productsTotal,
      totalItems,
      productsItems,
      subTotal,
      productsDiscount,
      total,
      _id,
      deliveryCost,
      promoDiscount,
      promoApplied,
      deliveryAddress,
      servicesTotalAmount
    } = this.state.cartJson
    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}> <LoadingLoader visible={this.state.loading} /> </div>
    return (
      <div>
        <Breadcrumb title={'Checkout'} parent={this.state.previous} />
        {
          this.state.emptyCart ? this.emptyCart() : <section className="cart-section section-b-space">
            <div className="container">
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', }}>
                <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ marginTop: '50px' }}
                >
                  {this.renderProductItem()}
                  {/* {this.renderServicesItem()} */}
                </div>
                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 cart-side-bar">
                  <div className="checkout-details">
                    <div className="order-box">
                      <div className="title-box">
                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>Shipping & Billing</div>
                        <div className="justify-content-between" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '5px' }}>
                            <h6>City: {deliveryAddress.cityName.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {return letter.toUpperCase(); })}</h6>
                            <h6>State: {deliveryAddress.provinceName.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {return letter.toUpperCase(); })}</h6>
                            <h6>PostCode: {deliveryAddress.postalCode}</h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '5px' }}>
                          <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />
                          <h4 style={{ fontSize: '16px', marginLeft: '14px', marginTop: '2px' }}>{deliveryAddress.address}</h4>
                        </div>
                      </div>
                      <ul className="qty">
                        <li>Item(s) <span>{productsItems}</span></li>
                        {/* <li>Services Total <span>RM{servicesTotalAmount.toFixed(2)} </span></li> */}
                      </ul>
                      <ul className="sub-total" style={{ borderBottom: '0px' }}>
                        <li>Subtotal <span className="count">RM {subTotal.toFixed(2)}</span></li>
                        <li>Discount <span className="count">RM {productsDiscount.toFixed(2)}</span></li>
                        <li>Delivery Cost <span className="count">RM {deliveryCost.toFixed(2)}</span></li>
                        {(promoApplied) ? this.renderPromoCode(promoDiscount) : null}
                        {this.renderPromoInput(promoApplied)}
                      </ul>
                      <ul className="total title-box">
                      <li>Total <span className="count">RM {(total+deliveryCost).toFixed(2)}</span></li>
                      </ul>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div
                          onClick={this.goToPaymentMaythedsScreen}
                          className="btn btn-solid">Proceed To Pay</div>
                        <div
                          onClick={() =>  this.cancelCheckout(_id)}
                          className="btn btn-solid">Cancel Checkout</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CustomAlert
                error
                onClose={() => this.setState({ showModal: false })}
                open={this.state.showModal}
                title={'Error'}
                message={this.state.errorMessage}
            />
            </div>
          </section>
        }
      </div>
    )
  }
}