const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 25,
    border: 'red'
  }
}

export default styles