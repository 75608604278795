import React, { Component } from "react";
import AccountVerificationComponent from "../components/Login/AccountVerificationComponent";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import { Manager } from "../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import NegativeAlert from "../components/CustomAlert/CustomAlert";
import RegisterContext from "../components/common/headers/common/CountContext";
import { isMobileSafari } from "react-device-detect";

class ForgetVerification extends Component {
    static contextType = RegisterContext;
    state = {
        otp: "",
        token: "",
        hash: "",
        msisdn: "",
        loading: "",
        password: "",
        confirmpassword: "",
        showPositiveAlert: false,
        showNegativeAlert: false,
    };

    componentDidMount() {
        var a, dec, b;
        a = this.props.location.search.split("?id=");
        b = a[1].split("&");
        dec = b[0];
        this.setState({
            hash: this.props.location.state.hash,
            msisdn: dec,
            token: this.props.location.state.dataToken,
            loginVerification: this.props.location.state.isLogin,
        });
    }

    changedPasswordSuccess = (value) => {
        this.setState({
            loading: true,
        });

        let body = {
            hash: this.state.hash,
            otp: value.otp,
            password: value.password,
        };
        this.props.apiManager.makeCall("changePassword", body, (res) => {
            if (res.message == 7037) {
                // call B2c API
                this.setState({
                    loading: false,
                    showPositiveAlert: true,
                    errorMessage: "Password Successfully Changed!",
                });
            } else if (res.message === 7000) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Wrong OTP, Please retry",
                });
            }else if (res.message === 7039) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Wrong OTP, Please retry",
                });
            }
            else if (res.message === 6115) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Hash not found",
                });
            }
            else if (res.message === 6113) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "OTP Expired",
                });
            } 
            else {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: res.message,
                });
            }
        });
    };

    gotoLoginScreen = () => {
        this.props.history.push(`/login`);
        this.setState({ showNegativeAlert: false });
    };

    render() {
        var regex = /^.*$/i;
        return (
            <div>
                <Breadcrumb title={"Reset Password"} />
                <Formik
                    initialValues={{
                        otp: "",
                        password: this.props.password,
                        confirmpassword: this.props.password,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.otp) {
                            errors.otp = "OTP Field cannot be empty";
                        }
                        if (!values.password) {
                            errors.password = "Please enter your new password";
                        }
                        if (!regex.test(values.password)) {
                            errors.password =
                                "Should have 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character";
                        }
                        if (!values.confirmpassword) {
                            errors.confirmpassword =
                                "Please match your confirm password";
                        }
                        if (values.password !== values.confirmpassword) {
                            errors.confirmpassword = "Password does not match";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        // lastName: Yup.string().required("OTP is required"),
                        otp: Yup.string()
                            .required("OTP is required")
                            .min(6, "OTP must be 6 Numbers"),
                        password: Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .required("Password is required")
                            .matches(
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                            ),

                        confirmpassword: Yup.string().oneOf(
                            [Yup.ref("password"), null],
                            "Passwords must match"
                        ),
                        // .required("Confirm Password is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.changedPasswordSuccess(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Change Password
                                        </span>

                                        <div className="col-xl-4 col-sx-12">
                                            <div className="wrap-input100 validate-input">
                                                <Field
                                                    type="text"
                                                    name="otp"
                                                    id="defaultRegisterFormotp"
                                                    className="input100"
                                                    placeholder="Enter OTP"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-user"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="otp"
                                                component="p"
                                            />
                                            <div className="wrap-input100 validate-input">
                                                <Field
                                                    type="password"
                                                    name="password"
                                                    id="defaultRegisterFormPassword"
                                                    className="input100"
                                                    placeholder="Enter Password"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-lock passwordError"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="password"
                                                component="p"
                                            />
                                            <div className="wrap-input100 validate-input">
                                                <Field
                                                    type="password"
                                                    name="confirmpassword"
                                                    id="defaultRegisterFormPassword"
                                                    className="input100"
                                                    placeholder="Enter Confirm password"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-lock"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="confirmpassword"
                                                component="div"
                                            />
                                            <div
                                                className="wrap-input100 validate-input"
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "30px",
                                                }}
                                            ></div>
                                            <div
                                                className="container-register100-form-btn"
                                                style={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    alignSelf: "center",
                                                }}
                                            >
                                                {this.state.loading ? (
                                                    <div
                                                        style={{
                                                            // left: 100,
                                                            // top: 100,
                                                            // bottom: 50,
                                                            zIndex: "100",
                                                            // position: "absolute",
                                                        }}
                                                    >
                                                        <LoadingLoader
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="login100-form-btn"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        Submit
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
                <NegativeAlert
                    error
                    title={"error"}
                    message={this.state.errorMessage}
                    open={this.state.showNegativeAlert}
                    onClose={() =>
                        this.setState({
                            showNegativeAlert: false,
                        })
                    }
                />
                <PositiveAlert
                    title={"error"}
                    message={this.state.errorMessage}
                    open={this.state.showPositiveAlert}
                    onClose={() => this.gotoLoginScreen()}
                />
            </div>
        );
    }
}
export default SuperHOC(ForgetVerification);
