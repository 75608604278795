import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';

class OrderDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
    }
  }

  componentDidMount = () => {
    let body = {
      orderId: this.props.location.state.id
    }
    this.props.apiManager.makeCall('get_single_order_details', body, (res) => {
      if (res.message === '1729') {
        this.setState({ items: res.order })
      }
      else {
      }
    })
  };

  render() {
    return (
      <div className="col-lg-12">
        <Breadcrumb title={'Order detail'} />
        <div className="top-banner-content small-section" style={{ marginTop: 70 }}>
          <div class="single-product-tables">
            <table class="table table-striped mb-0 col-lg-12" style={{ border: "1px solid #dadada" }} >
              {
                this.state.items.map((item, index) => {
                  let timeValues = new Date(item.createdAt);
                  let date = timeValues.getUTCDate()
                  let year = timeValues.getFullYear()
                  let hours = timeValues.getHours()
                  let hourSet;
                  var ampm = hours >= 12 ? 'PM' : 'AM';
                  hours = hours % 12;
                  hours = hours ? hours : 12;
                  var hourValueSet;

                  if (hours < 10) {
                    hourSet = hours
                    hourValueSet = "0"
                  }
                  else {
                    hourSet = hours
                  }

                  let minSet = timeValues.getUTCMinutes();
                  var minValueSet;
                  let min;
                  
                  if (minSet < 10) {
                    min = minSet
                    minValueSet = "0"
                  }
                  else {
                    min = minSet
                  }

                  var Month = new Array(12);
                  Month[0] = "Jan";
                  Month[1] = "Feb";
                  Month[2] = "Mar";
                  Month[3] = "Apr";
                  Month[4] = "May";
                  Month[5] = "Jun";
                  Month[6] = "Jul";
                  Month[7] = "Aug";
                  Month[8] = "Sep";
                  Month[9] = "Oct";
                  Month[10] = "Nov";
                  Month[11] = "Dec";
                  let month = Month[timeValues.getMonth()];
                  var weekday = new Array(7);
                  weekday[0] = "Sun";
                  weekday[1] = "Mon";
                  weekday[2] = "Tue";
                  weekday[3] = "Wed";
                  weekday[4] = "Thu";
                  weekday[5] = "Fri";
                  weekday[6] = "Sat";
                  var day = weekday[timeValues.getDay()];

                  let status;
                  if (item.shippingStatus === 0) {
                    status = 'new'
                  }
                  else if (item.shippingStatus === 1) {
                    status = 'cancelled'
                  }
                  else if (item.shippingStatus === 2 || item.shippingStatus === 4) {
                    status = 'shipping'
                  }
                  else if (item.shippingStatus === 3) {
                    status = 'completed'
                  }

                  return (
                    <tbody key={index}>
                      <tr>
                        <td colSpan={4}>Order Detail</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid white" }}>
                        <td>Product Name:</td>
                        <td>{item.productName}</td>
                        <td style={{ borderLeft: "1px solid #dadada", borderTop: "1px solid white" }}>Order Status</td>
                        <td style={{ border: "none" }}>{status}</td>
                      </tr>
                      <tr style={{ backgroundColor: 'white', border: 'white' }}>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>Order ID:</td>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>{item.orderId}</td>
                        {/* <td style={{ borderLeft: "1px solid #dadada", borderTop: "1px solid white" }}>Payment Method:</td>
                        <td style={{ border: "none" }}>Pindal wallet</td> */}
                      </tr>
                      <tr style={{ borderBottom: "1px solid white" }}>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>Total item:</td>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>{item.quantity}</td>
                        <td style={{ borderLeft: "1px solid #dadada", borderTop: "1px solid white" }}>Shipping cost:</td>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>{item.deliveryCost}</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid white", backgroundColor: 'white', border: 'none' }}>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>Total price:</td>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>RM{item.totalItemPrice}</td>
                        <td style={{ borderLeft: "1px solid #dadada", borderTop: "1px solid white" }}>Sold by:</td>
                        <td style={{ backgroundColor: 'white', border: 'white' }}>{item.merchantName}</td>
                      </tr>
                      <tr style={{ backgroundColor: "#ffffff" }}>
                        <td style={{ borderTop: "1px solid white" }}>Date Added:</td>
                        <td style={{ borderTop: "1px solid white" }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year}</td>
                        {/* <td style={{ borderLeft: "1px solid #dadada", borderTop: "1px solid white" }}>Shipping Method:</td>
                        <td style={{ borderTop: "1px solid white" }}>Tcs</td> */}
                      </tr>
                    </tbody>
                  )
                })
              }
            </table>
          </div>
        </div>
        <div className="top-banner-content small-section">
        {
          this.state.items.map((item, index) => {
            return (
            <div className="card shadow p-4 justify-content-between w-50 m-auto">
              <div className="row">
              <div className="col-lg-4 col-6">
                <label>City:</label>
                <h6>{item.deliveryAddress.cityName}</h6>
              </div>
              <div className="col-lg-4 col-6">
                <label>State:</label>
                <h6>{item.deliveryAddress.provinceName}</h6>
              </div>
              <div className="col-lg-4 col-6">
                <label>Post Code:</label>
                <h6>{item.deliveryAddress.postalCode}</h6>
              </div>
              </div>
              <div className="d-flex mt-2">
                <label>Address:</label>
                <h6>{item.deliveryAddress.address}</h6>
              </div>
            </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}


export default OrderDetail;