import React, { Component } from 'react'
import Product from '../components/products/column'
import MerchantProducts from '../components/products/merchantProducts'
import { SuperHOC } from '../HOC'
import LoadingLoader from '../components/Loader/Loader'

class ProductScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      product: {},
      loading: true,
      slug: '',
      merchant: {}
    }
  }

  getProductDetail = () => {
    let body = {
      slug: this.state.slug
    }

    this.props.apiManager.makeCall('get_product_detail', body, (res) => {
      if (res.message) {
        this.setState({ product: res.product, loading: false }, () => { this.clickToTop() })
        if (res.product) {
          this.props.apiManager.makeCall('get_merchant_detail', { email: res.product.parent }, (res) => {
            if (res.message === '1083') {
              this.setState({ merchant: res.merchant })
            }
          })
        }
      }
      else {
        console.error('Error: ', res)
      }
    })
  }
  call = (item) => {
    this.setState({ slug: item }, () => this.getProductDetail())
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      let a = this.props.location.search.split('?id=')
      let b = a[1].split('&')
      let dec = window.atob(b[0])
      this.call(dec)
    }
  }
  clickToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  componentDidMount() {
    var a, dec, b
    a = this.props.location.search.split('?id=')
    b = a[1].split('&')
    dec = window.atob(b[0])
    this.setState({
      slug: dec
    }, () => this.getProductDetail())
  }

  gotoSellerProduct = () => {
    let abc=window.btoa(this.state.product.parent)
    let removePerc = this.state.merchant.name ? this.state.merchant.name.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    // const gotoProductDetail = {
    //   pathname: `/seller-product/${convertNameIntoDashes}/?id=${abc}`,
    //   state: {
    //     email: this.state.product.parent
    //   }
    // }
    // await localStorage.setItem('seller-email', JSON.stringify(this.state.product.parent))
    this.props.history.push(`/seller-product/${convertNameIntoDashes}/?id=${abc}`)
  }

  render() {
    const product = this.state.product
    if (this.state.loading) return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', }}>
      <LoadingLoader visibel={this.state.loading} />
    </div>
    return (
      <div style={{ minHeight: '100vh' }}>
        <Product
          product={product}
          merchantName={this.state.merchant.name}
          {...this.props}
        />
        {
          Object.keys(product).length > 0 ?
            <MerchantProducts
              title={'More Products'}
              // showHeader
              {...this.props}
              email={product.parent}
              numOfRecords={8}
              numOfColumns={8}
              api={'get_merchant_products'}
              viewMoreProducts={this.gotoSellerProduct}
            /> : null
        }
      </div>
    )
  }
}
export default SuperHOC(ProductScreen)