import React, { Component } from 'react';
import { SuperHOC } from '../../../../HOC';
import SuccessAlert from '../../../CustomAlert/CustomAlert'
import ErrorAlert from '../../../CustomAlert/CustomAlert'
import CountContext from '../../../common/headers/common/CountContext'
import { toast, ToastContainer, } from 'react-toastify';
import Modal from 'react-responsive-modal';
import LoadingLoader from '../../../Loader/Loader'
import AddAddress from '../../../../screens/AddAddress'

class Price extends Component {
  static contextType = CountContext
  constructor(props) {
    super(props)
    this.state = {
      quantity: 1,
      maxQuantity: '',
      selectedPrice: null,
      stock: 'InStock',
      nav3: null,
      completeSelectedArray: [],
      selectedVariantIndex: 0,
      selectedColorId: '',
      current: new Map(),
      currentQty: '',
      price: '',
      initialPrice: '',
      selectedColorSlug: '',
      currentId: '',
      initialQuantity: '',
      showSuccess: false,
      showError: false,
      errorMessage: '',
      count: 0,
      merchant: {},
      showModal: false,
      loading : false,
      initialQuantityPrice : 0,
      quantityTotal : 0,
      activeClrId : 0
    }
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: 'InStock', quantity: this.state.quantity - 1 })
    }
  }

  plusQty = () => {
    if (this.state.maxQuantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity < 5 ? this.state.quantity + 1 : this.state.quantity })
      this.setState({quantityTotal : this.state.initialQuantityPrice * this.state.quantity})
    } else {
      this.setState({ stock: 'Out of Stock !' })
    }
  }

  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({ price: '', currentId: '', selectedColorSlug: '' })
    }
  }

  componentDidMount = () => {

    this.getTrendingProducts()
    const { colors, fixedServiceSchedule } = this.props.product
    if (this.props.product.requiresInstallation === true && this.props.product.fixedServiceSchedule !== undefined) {
      let serviceQty = fixedServiceSchedule && fixedServiceSchedule.totalQuantity
      this.setState({ maxQuantity: serviceQty })
    }
    else if (this.props.product.requiresInstallation === false) {
      let abc = colors && colors[0].specQuantity && colors[0].specQuantity[0].quantity
      this.setState({ maxQuantity: abc })
    }
    this.getMerchant();
  }

  specs(item, i) {
    let data = this.props.product
    const map = new Map();
    item.specQuantity.forEach(element => {
      element.price = (data.specifications[0].price[0])
      map.set(element.specificationId, element)
    })
    let price = [...map.values()]
    let specId = [...map.values()]
    if (this.props.product.requiresInstallation === false) {
      this.setState({
        image: item.icon.assets[0].src,
        selectedColorSlug: item.slug,
        maxQuantity: item.specQuantity[0].quantity,
        activeClrId : i,
        // availableVariants: avail,
        quantity: 1,
        current: map,
        currentId: specId[0].specificationId,
        price: price[0].price,
        // stock: ''
      })
    }
  }
  getTrendingProducts = () => {
    const map = new Map();
    let i = 0
    this.props.product.colors[0].specQuantity.forEach(element => {
      element.price = (this.props.product.specifications[i].price[0])
      map.set(element.specificationId, element)
    })
    let price = [...map.values()]
    let specificationIds = new Set()
    let specificationIdsObject = new Set()
    let colorIds = new Set()
    if (this.props.product.requiresInstallation === false) {
      this.setState({
        data: this.props.product,
        specifications: this.props.product.specifications,
        colorId: this.props.product.colors[0].slug,
        specificationIds: specificationIds,
        colorIds: colorIds,
        specificationIdsObject: specificationIdsObject,
        current: map,
        price: price[0].price,
        currentId: this.props.product.specifications[0].id,
        stockQuantity: this.props.product.colors[0].specQuantity[0].quantity ? this.props.product.colors[0].specQuantity[0].quantity : 1,
        stock: this.props.product.colors[0].specQuantity[0].quantity && this.props.product.colors[0].specQuantity[0].quantity > 0 ? 'InStock' : 'OutOfStock',

      })
    }
  }


  getMerchant = () => {
    let body = {
      email: this.props.product.parent
    }
    this.props.apiManager.makeCall('get_merchant_detail', body, (res) => {
      if (res.message === '1083') {
        this.setState({ merchant: res.merchant })
      } else {
        console.error('Error Getting Merchant: ', res.decodedMessage)
      }
    })
  }

  getCartCount = () => {
    this.props.apiManager.makeCall('get_cart_count', {}, (res) => {
      if (res.message === '1426') {
        this.context.setCartCount(res.count)
      }
    })
  }

  addProductToCart = () => {
    this.setState({loading: true})
    const { slug, specifications, colors, requiresInstallation } = this.props.product
    let specId = this.state.currentId ? this.state.currentId : specifications && specifications[0].id;
    let colorSlg = this.state.selectedColorSlug ? this.state.selectedColorSlug : colors && colors[0].slug;
    let body = {
      slug,
      currency: specifications && specifications[0].price[0].unit,
      quantity: this.state.quantity,
      specificationId: specId,
      colorSlug: colorSlg
    }
    let addProduct = 'add_product_to_cart';
    let addService = 'add_service_to_cart';
    let addFixService = 'add_fixed_service_to_cart'
    this.props.apiManager.makeCall(
      (requiresInstallation === true && this.props.product.fixedServiceSchedule !== undefined) ? addFixService : (
        requiresInstallation === true ? addService : addProduct),
      body, (res) => {
        this.setState({loading: false})
        this.getCartCount()
        if (res.message === '1420') {
          // this.setState({ showSuccess: true, errorMessage: res.decodedMessage })
          toast.success(res.decodedMessage);
        }
        else if (res.message === '1933') {
          // this.setState({ showSuccess: true, errorMessage: res.decodedMessage })
          toast.success(res.decodedMessage);
        }
        else if (res.message === '1934') {
          // this.setState({ showSuccess: true, errorMessage: res.decodedMessage })
          toast.success(res.decodedMessage);
        } else if (res.message === '1411') {
          this.setState({
            showModal: true

            // showErrorLocation: true, errorMessage: res.decodedMessage +
            //   ' Please Add Your Delivery Address / Location'
          })
        }
        else if (res.message === '1003') {
          console.info('no user')
        }
        else
          toast.error(res.decodedMessage);
      })
  }

  goToMerchantPage = () => {
    let removePerc = this.props.merchantName ? this.props.merchantName.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    const { parent } = this.props.product
    const gotoMerchantDetail = {
      pathname: `/merchant/${convertNameIntoDashes}`,
      state: {
        email: parent
      }
    }
    this.props.history.push(gotoMerchantDetail)
  }

  setPrice(item, i) {
    let stockQuantity = this.state.current.get(item.id)
    this.setState({
      price: item.price[0],
      currentId: item.id,
      quantity: 1,
      maxQuantity: stockQuantity.quantity,
      selectedVariantIndex: i,
      // stock: ''
    });
  }

  goToNegotiation = async (slug) => {
    const { specifications } = this.props.product
    let totalPrice = (this.state.price.figure === undefined) ? specifications && specifications[0].price[0].figure : this.state.price.figure
    let priceUnit = "RM"; // (this.state.price.unit === undefined) ? specifications && specifications[0].price[0].unit : this.state.price.unit
    await localStorage.setItem('price-unit', JSON.stringify(this.state.price.unit ? this.state.price.unit : priceUnit))
    await localStorage.setItem('price', JSON.stringify(this.state.price.figure ? this.state.price.figure : totalPrice))
    this.props.history.push(`/NegotiationChat/${slug}`)
  }



  renderAddressModal = () => {
    return (
      <Modal
        style={{ display: 'flex' }}
        // open={true}
        open={this.state.showModal}
        onClose={() => this.setState({ showModal: false })}
      >
        <AddAddress />

      </Modal>
    )
  }


  render() {
    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', backgroundColor: 'white' }}>
      <LoadingLoader visible={this.state.loading} />
    </div>
    const { specifications, parent } = this.props.product
    let totalPrice = (this.state.price.figure === undefined) ? specifications && specifications[0].price[0].figure : this.state.price.figure
    let discountPercent = (this.state.price.discountPercent === undefined) ? specifications && specifications[0].price[0].discountPercent : this.state.price.discountPercent
    // totalPrice = totalPrice - totalPrice * discountPercent / 100;
    let priceUnit = "RM"; //(this.state.price.unit === undefined) ? specifications && specifications[0].price[0].unit : this.state.price.unit
    var enc = window.btoa(parent);
    let removePerc = this.props.merchantName ? this.props.merchantName.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    return (
      <div className="col-lg-4">
        <div className="product-right product-form-box">
          {/* <h4>
            {discountPercent === 0 ? <div />
              :
              <div>
                <del>{priceUnit}  {totalPrice.toFixed(2)}</del>
                <span>  {discountPercent.toFixed(2)} % off</span>
              </div>}
          </h4> */}
          <h3 className="text-left">
            {priceUnit + ' '}
            {totalPrice.toFixed(2)}
          </h3>
          <h6 className="text-left">
           Merchant: { this.state.merchant.name }
          </h6>
          {/* <div className="product-description border-product"
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6 className="product-title">Sold By : </h6>
            <div onClick={() => this.props.history.push('/merchant/' + convertNameIntoDashes + '?id=' + enc)}
              style={{ cursor: 'pointer' }}
            >
              <h6 className="product-title" style={{ color: 'red' }}>{this.props.merchantName}</h6>
            </div>
          </div> */}
          <div className="product-description border-product">
            <h6 className="product-title size-text">select variant</h6>
            <div className="d-flex justify-content-between">
              <div className="">
              {this.props.product.colors ?
                <ul className="color-variant">
                  {this.props.product.colors.map((vari, i) => {
                    return (
                      <>
                      <li className={(this.state.activeClrId == i ? 'active-clr' : '')} key={i} style={{ backgroundColor: vari.code, border: '1px solid grey' }}
                        onClick={() => this.specs(vari, i)}>
                      </li>
                      {/* <span>{vari.name}</span> */}
                      <span style={{paddingRight:'5px'}}>{vari.name}</span>
                      </>)
                  })}
                </ul> : ''}
              </div>
              </div>
              <div className="d-flex justify-content-between">
              <ul>
                {this.props.product.specifications ?
                  <div className="size-box" >
                    <ul>
                      {this.props.product.specifications.map((vari, i) => {
                        if (this.state.current.has(vari.id) && this.state.current.get(vari.id).quantity > 0) {
                          if (this.state.current.get(vari.id).specificationId === this.state.currentId)
                            return <button className="btn" style={{ fontSize: 12, marginRight: 10, borderColor: 'rgb(224, 106, 66)', marginBottom: 5, backgroundColor: 'white', height: '16', width: '25', padding: 2, borderWidth: '0.5px' }} key={i}
                              onClick={() => this.setPrice(vari)}
                            >{vari.name.substring(0, 10)}
                            </button>
                          else return <button className="btn" style={{ fontSize: 12, marginRight: 10, borderColor: 'lightgray', marginBottom: 5, backgroundColor: 'white', height: '16', width: '25', padding: 2, borderWidth: '0.5px' }} key={i}
                            onClick={() => this.setPrice(vari)}
                          >{vari.name.substring(0, 10)}
                          </button>
                        }
                        else {
                          return <button disabled={true} style={{ fontSize: 12, marginRight: 10, borderColor: 'lightgray', marginBottom: 5, backgroundColor: 'lightgrey', height: '16', width: '25', padding: 2, borderWidth: '0.5px', cursor: 'context-menu' }} key={i}
                            onClick={() => this.setPrice(vari)}
                          >{vari.name.substring(0, 10)}
                          </button>
                        }
                      })}
                    </ul>
                  </div> : ''}
              </ul>
              <div className="">
                <span>{this.state.stock}</span>
              </div>
            </div>
            <div className="qty-box">
            <h6 className="product-title">quantity</h6>
              <div className="input-group">
                <span className="input-group-prepend">
                  <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                    <i className="fa fa-angle-left"></i>
                  </button>
                </span>
                <input type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty} disabled={true} className="form-control input-number" />
                <span className="input-group-prepend">
                  <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                    <i className="fa fa-angle-right"></i>
                  </button>
                </span>
              </div>
            </div>
            <div className="border-product mt-2 d-flex justify-content-between">
              <h5><i>Total</i></h5>
              <h5 className="text-left">
              {priceUnit + ' '}
              {(((totalPrice) - (totalPrice * discountPercent / 100)) * this.state.quantity).toFixed(2)}
            </h5>
            </div>
          </div>
          <div className="product-buttons">
            <div className="btn btn-solid1 btn-dark" style={{ margin: 5 }} onClick={() => this.addProductToCart()}>add to cart</div>
            {/* {
              isNegotiable ?
                <div style={{ margin: 5 }} onClick={() => this.goToNegotiation(slug)} className="btn btn-solid" >
                  Negotiate
                </div>
                : null
            } */}
          </div>
        </div>
        <SuccessAlert
          onClose={() => this.setState({ showSuccess: false })}
          open={this.state.showSuccess}
          message={this.state.errorMessage}
        />
        <ErrorAlert
          error
          onClose={() => this.setState({ showError: false })}
          open={this.state.showError}
          message={this.state.errorMessage}
        />
        {
          this.renderAddressModal()
        }

        <ToastContainer />
      </div>
    )
  }
}
export default SuperHOC(Price);