import React, { Component } from "react";
import Register from "../components/Login/registerComponent";
import { SuperHOC } from "../HOC";
import { uploadImage } from "../ApiManager/ImageUploader";
import LoadingLoader from "../components/Loader/Loader";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import RegisterContext from "../components/common/headers/common/CountContext";
// import RegisterContext from "../common/headers/common/CountContext";
//import   from "../components/CustomAlert/CustomAlert";
class RegisterScreen extends Component {
    static contextType = RegisterContext;
    state = {
        takenEmail: null,
        takenPhone: null,
        showPositiveAlert: false,
        showAlert: false,
        first_name: "",
        last_name: "",
        email: "",
        msisdn: "60",
        password: "",
        loading: true,
        token: "",
        otp: "",
    };

    componentDidMount() {
        this.props.apiManager.createSession()
        const registData = this.props.location.state;

        if (registData !== null || registData !== undefined) {
            this.setState({
                first_name: registData.first_name,
                last_name: registData.last_name,
                email: registData.email,
                msisdn: registData.msisdn,
                password: registData.password,
                loading: false,
            });
        } else {
            this.setState({ loading: false });
        }
    }

    checkEmail = (email, _callback) => {
        let body = {
            email: email,
        };

        this.props.apiManager.makeCall("check_email", body, (res) => {
            if (res.message === "1865") {
                this.setState(
                    {
                        takenEmail: null,
                    },
                    () => _callback(res)
                );
            } else if (res.message === "1868") {
                console.warn("taken email set");
                this.setState({ takenEmail: true });
            }
        });
    };

    checkphone = (phone, _callback) => {
        let body = {
            phone: phone,
        };
        this.props.apiManager.makeCall("check_phone", body, (res) => {
            if (res.message === "1867") _callback(res);
            else if (res.message === "1869")
                this.setState({ takenPhone: true });
        });
    };

    checkFile = (file, _callback) => {
        if (file) {
            uploadImage(file)
                .then((res) => {
                    _callback(res);
                })
                .catch((err) => console.error("Check File catched error: ", err));
        }
    };

    registerApi = (body) => {
        this.props.apiManager.makeCall("register", body, (res) => {
            if (res.message === "1008") {
                this.setState({
                    showPositiveAlert: true,
                    errorMessage:
                        "Registration Successfull, Please Login To Your Email For Verification!",
                });
            } else if (res.message === "2351") {
                this.setState({
                    showAlert: true,
                    errorMessage:
                        "We discover something fishing on the server.",
                });
            } else if (res.message === "2350") {
                this.setState({
                    showAlert: true,
                    errorMessage: "Invalid data.",
                });
            } else {
                this.setState({
                    showAlert: true,
                    errorMessage:
                        "Profile exists. Please use another Mobile Number, or proceed to Login",
                });
            }
        });
    };

    xoxRegistrationApi = (body) => {
        this.setState({ loading: true });
        this.props.apiManager.makeCall("sendRegisterOtp", body, (res) => {
            if (res.message === 7031) {
                this.context.setData(body);
                this.goToVerifyScreen({
                    MSISDN: body.MSISDN,
                    token: res.token,
                    notResend: true,
                });

                this.setState({
                    loading: false,
                    token: res.token,
                    otp: res.otp,
                });
            } else if (res.code === "18023" && !res.status) {
                this.setState({
                    loading: false,
                    showAlert: true,
                    errorMessage: res.message,
                });
            } else {
                this.setState({
                    loading: false,
                    showAlert: true,
                    errorMessage:
                        "Profile exists. Please use another Mobile Number, or proceed to Login",
                });
            }
        });
    };

    register = async (
        firstName,
        lastName,
        country,
        phone,
        email,
        password,
        file
    ) => {
        let body = {
            MSISDN: phone,
        };
        this.xoxRegistrationApi(body);
    };

    goToVerificationScreen = ({ MSISDN, token }) => {
        this.props.history.push({
            pathname: `/verification`,
            search: `id=${MSISDN}`,
            state: { isLogin: false, dataToken: token },
        });
    };

    goToVerifyScreen = ({ MSISDN, token, notResend }) => {
        this.props.history.push({
            pathname: `/VerifyOTP`,
            search: `id=${MSISDN}`,
            state: { isLogin: false, dataToken: token, notResend },
        });
    };

    render() {
        if (this.state.loading)
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "70vh",
                        backgroundColor: "white",
                    }}
                >
                    <LoadingLoader visible={this.state.loading} />
                </div>
            );
        return (
            <div>
                <Register
                    {...this.props}
                    first_name={this.state.first_name}
                    last_name={this.state.last_name}
                    email={this.state.email}
                    msisdn={this.state.msisdn}
                    password={this.state.password}
                    takenEmail={this.state.takenEmail}
                    takenPhone={this.state.takenPhone}
                    onSubmit={({
                        firstName,
                        lastName,
                        country,
                        phone,
                        email,
                        password,
                        file,
                    }) =>
                        this.register(
                            firstName,
                            lastName,
                            country,
                            phone,
                            email,
                            password,
                            file
                        )
                    }
                />
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showAlert: false })}
                    open={this.state.showAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(RegisterScreen);
