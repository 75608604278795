import React, { Component } from 'react'
// import AddDeliveryAddress from '../components/AddDeliveryAddress/AddDeliveryAddress'
import AddDeliveryAddressSimple from '../components/AddDeliveryAddress/AddDeliveryAddressSimple'
import { SuperHOC } from "../HOC";

class AddAddress extends Component {

  render() {
    return (
      <div>
        <AddDeliveryAddressSimple
          {...this.props}
        />
        {/* <AddDeliveryAddress
          {...this.props}
        /> */}
      </div>
    )
  }
}

export default SuperHOC(AddAddress);