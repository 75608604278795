import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faSmile, faPaperclip, faComments } from '@fortawesome/free-solid-svg-icons'
import { SuperHOC } from "../../HOC";
import FlashMassage from 'react-flash-message';
import LoadingLoader from '../Loader/Loader'
import moment from "moment";

class Chat extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      selectedChat: null,
      getBuyerChat: [],
      getBuyerChatItem: [],
      image: [],  // for Chat image show from Get buyer chat sider
      inputData: '',
      addMessage: '',
      slugSet: '',
      chatIdSent: '',
      topName: '',
      getBuyerChatItemId: '',
      idShowChatSet: '',
      emailUser: '',
      limitSet: '',
      value: '',
      defaultid: '',
      defaultChat: [],
      defaultChatReverse:[],
      defaultImage:'',
      defaultName:'',
      defaultslug:''

    }
  }
  //API's

  componentDidMount() {
    this.props.apiManager.makeCall('get_buyer_chats', {}, (res) => {
      if (res) {
        this.setState({
          loading: false,
          getBuyerChat: res.result.docs,
          image: res.result.docs,
        })
      }
    })
  }

  userDetails = () => {
    this.props.apiManager.makeCall('get_user_details', {}, res => {
      if (res.user) {
        this.setState({ emailUser: res.user.email });
      }
    }
    )
  }


  componentWillMount() {
    let chat = this.state.getBuyerChatItemId;
    this.props.apiManager.makeCall('get_buyer_chats', { chatId: chat }, (res) => {
      if (res) {
        res.result.docs.map((value, index) => {
          if (this.props.location.state.slug === value.productId){
            this.setState({
              defaultid: value.slug,
              defaultName: value.productName,
              defaultslug: value.productId,
              defaultImage: value.imageProduct[0].src

            }, () => { this.listdefaultChat() })
          }
          else {
            let slug =this.props.location.state.slug
            return (
              this.setState({
                slugSet: slug,
              }, () => { this.submit() })
            ) 
          }  
        })
      }
    })
  }

  listdefaultChat = () => {
    let iddefaultChat;
    iddefaultChat = this.state.defaultid;
    this.props.apiManager.makeCall('get_chat_items', { chatId: iddefaultChat }, (res) => {
      if (res.message === '2113') {
        this.setState({
          loading: false,
          defaultChat: res.result.docs,
        },() => {this.messageSubmit()})
      }
    })
  }


  listChat = (getBuyerChat) => {
    let idChat;
    idChat = getBuyerChat.id;
    this.props.apiManager.makeCall('get_chat_items', { chatId: idChat }, (res) => {
      if (res.message === '2113') {
        this.setState({
          loading: false,
          selectedChat: res.result.docs.chatId,
          getBuyerChatItem: res.result.docs.reverse(),
          slugSet: getBuyerChat.productId,
          chatIdSent: getBuyerChat.id,
          image: getBuyerChat.imageProduct[0].src,
          topName: getBuyerChat.productName

        })
      }
    })
    this.forceUpdate();
  }


  submit = () => {
    { this.userDetails() }
    let body = {
      slug: this.state.slugSet,
      emailBuyer: this.state.emailUser,
      message: this.state.addMessage
    }

    this.props.apiManager.makeCall('add_chat_and_chat_item', body, (res) => {
      if (res.message === '2107') {
        this.setState({
          loading:true,
          addMessage:''
        })
        let chat = this.state.getBuyerChatItemId;
        this.props.apiManager.makeCall('get_buyer_chats', { chatId: chat }, (res) => {
          if (res.message === '2103') {
            let slug = this.state.slugSet ? this.state.slugSet : this.props.location.state.slug
            this.setState({
              getBuyerChat: res.result.docs,
              idsChat: res.result.docs.map((getBuyerChat, index) => {
                if (getBuyerChat.productId === slug)
                  this.setState({
                    idShowChatSet: getBuyerChat.id
                  }, () => { this.listChat(getBuyerChat) })
              })
             }
            )
          }
        })
      }
      else {
        // this.setState({
        //   limitSet: 'Please wait for reply....'
        // })
      }
    })
  }

  messageSubmit = () => {
    let slug;
    if(this.state.defaultid !== ''){
      slug= this.state.defaultslug;
    }
    else{
      slug = this.state.slugSet ? this.state.slugSet : this.props.location.state.slug
    }
    return (
      this.setState({
        slugSet: slug,
      }, () => { this.submit() })
    )
  }

  handleOnChange = (e) => {
    e.preventDefault()
    this.setState({ addMessage: e.target.value })
  }

  renderSideBar = () => {
    if(this.state.getBuyerChat !== ''){
      return (
        <div className="sidebar" id="sidebar">
          <div className="container">
            <div className="col-md-12">
              <div className="tab-content">
                <div id="discussions" className="tab-pane fade active show">
                  <div className="discussions">
                    <h1>Discussions</h1>
                    <LoadingLoader visible={this.state.loading} />
                    <div className="list-group" id="chats" role="tablist">
                      {
                        this.state.getBuyerChat.map((getBuyerChat, index) => {
                          let timeZone = new Date(getBuyerChat.createdAt);
                          var weekday = new Array(7);
                          weekday[0] = "Sunday";
                          weekday[1] = "Monday";
                          weekday[2] = "Tuesday";
                          weekday[3] = "Wednesday";
                          weekday[4] = "Thursday";
                          weekday[5] = "Friday";
                          weekday[6] = "Saturday";
                          var day = weekday[timeZone.getDay()];
                          
                          return (
                            <div onClick={() => this.listChat(getBuyerChat)} style={{ cursor: 'pointer' }} className="filterDiscussions all unread single active" id="list-chat-list"
                              data-toggle="list" role="tab">
                              <img className="avatar-md" src={getBuyerChat.imageProduct[0].src} data-toggle="tooltip"
                                data-placement="top" title="Janette" alt="avatar" />
                              {/* <div className="status">
                                  <i className="material-icons online"></i>
                                </div>
                                <div className="new bg-yellow">
                                  <span>+7</span>
                                </div> 
                               */}
                              <div className="data">
                                &nbsp;
                                &nbsp;
                                <h5>{getBuyerChat.productName.substring(0,25)}</h5>
                                <span>{day}</span>
                                {/* <p>{merchantSidebar.message.substr(0, 56)}</p> */}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else{
      return (
        <div className="sidebar" id="sidebar">
          <div className="container">
            <div className="col-md-12">
              <div className="tab-content">
                <div id="discussions" className="tab-pane fade active show">
                  <div className="discussions">
                    <h1>Discussions</h1>
                    <div className="list-group" id="chats" role="tablist">
                      <p style={{  position: 'absolute',top: '100px', left: '65px',fontSize: '26px',
                                  color: '#777777', lineHeight: '1'}}>No conversation</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    
  }

  renderTop = () => {
    if (this.state.selectedChat !== null) {
      return (
        // this.state.getBuyerChat.map((item, index)=>{
        //   return(
        <div className="top">
          <div className="container">
            <div className="col-md-12">
              <div className="inside">
                <div><img className="avatar-md" src={this.state.image}
                  data-toggle="tooltip" data-placement="top" title={this.state.topName} alt="avatar" /></div>
                {/* <div className="status">
                  <i className="material-icons online"></i>
                </div> */}
                <div className="data">
                  <h5>{this.state.topName}</h5>
                  {/* <span>Active now</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        //  )
        // })
      )
    }
    else {
      if(this.state.defaultName !== ''){
        return(
          <div className="top">
          <div className="container">
            <div className="col-md-12">
              <div className="inside">
                <div><img className="avatar-md" src={this.state.defaultImage}
                  data-toggle="tooltip" data-placement="top" title={this.state.defaultName} alt="avatar" /></div>
                {/* <div className="status">
                  <i className="material-icons online"></i>
                </div> */}
                <div className="data">
                  <h5>{this.state.defaultName}</h5>
                  {/* <span>Active now</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }
      else{
        return (
          <div className="top">
          <div className="container">
            <div className="col-md-12">
              <div className="inside">
                <div style={{padding: '20px'}}></div>
                
              </div>
            </div>
          </div>
        </div>
        )
      }
      
    }
    // else if(this.props.location.state.slug !== null){
    //   return (
    //     <h1>no</h1>
    //   )
    // }
  }

  messages = () => {
    if (this.state.selectedChat !== null) {
      let messages = this.state.getBuyerChatItem;
      return (
        messages.map((getBuyerChatItem, index) => {
          let timeZone = new Date(getBuyerChatItem.createdAt);
          let hours = timeZone.getUTCHours();
          let min = timeZone.getUTCMinutes();
          if (getBuyerChatItem.senderRole === 2) {
            return <div className="message">             
              <img className="avatar-md" src={this.state.image} data-toggle="tooltip"
                data-placement="top" title="Keith" alt="avatar" />         
              <div className="text-main">
                <div className="text-group">
                  <div className="text">
                    <p>{getBuyerChatItem.message}</p>
                  </div>
                </div>
                <span>{moment(timeZone).format('LLL')}</span>
              </div>
            </div>
          }
          else {
            return (
              <div className="message me">
                <div className="text-main">
                  <div className="text-group me">
                    <div className="text me">
                      <p>{getBuyerChatItem.message}</p>
                    </div>
                  </div>
                  <span>{moment(timeZone).format('LLL')}</span>
                </div>
              </div>
            )
          }
        })
      )
    }
    else {
      if (this.state.defaultChat === ''){
        let reverseMsg = this.state.defaultChat;
        return (
          reverseMsg.map((defaultSelectChat, index) => {
            let timeZone = new Date(defaultSelectChat.createdAt);
            let hours = timeZone.getUTCHours();
            let min = timeZone.getUTCMinutes();
            if (defaultSelectChat.senderRole === 2) {
              return <div className="message">
                <img className="avatar-md" src={this.state.defaultImage} data-toggle="tooltip"
                  data-placement="top" title="Keith" alt="avatar" />
                <div className="text-main">
                  <div className="text-group">
                    <div className="text">
                      <p>{defaultSelectChat.message}</p>
                    </div>
                  </div>
                  <span>{moment(timeZone).format('LT')}</span>
                </div>
              </div>
            }
            else {
              return (
                <div className="message me">
                  <div className="text-main">
                    <div className="text-group me">
                      <div className="text me">
                        <p>{defaultSelectChat.message}</p>
                      </div>
                    </div>
                    <span>{moment(timeZone).format('LLL')}</span>
                  </div>
                </div>
              )
            }
          })
        )
      } else{
        return (
          <div id="emptyChat" style={{ marginBottom: '130px' }} >
              <button className="btn emoticons noChat">
            <FontAwesomeIcon icon={faComments} size='4x' color='grey' />
            <h3> Get Started Chat.... </h3>
          </button></div>
        )
      }
    }
  }

  emptyContent = () => {
    return (
      <div className="content empty">
        <div className="container">
          <div className="col-md-12">
            <div className="no-messages">
              <i className="material-icons md-48">forum</i>
              <p>Seems people are shy to start the chat. Break the ice send the first message.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderInput = () => {
      // if (this.state.selectedChat !== null ){
    return (
      <div className="container">
        <div className="col-md-12 p-0">
          <div className="bottom mt-0 p-2">
            <form className="position-relative w-100">
              <textarea className="form-control ml-0"
                onChange={(e) => this.handleOnChange(e)}
                name="addMessage" placeholder="Start typing for reply..." rows="1" value={this.state.addMessage}>         
              </textarea>
              {/* <button className="btn emoticons">
                <FontAwesomeIcon icon={faSmile} size='2x' />
              </button> */}
              {/* <div className="btn send" >
                <FontAwesomeIcon onClick={this.messageSubmit} icon={faPaperPlane} size='2x' />
              </div> */}
            </form>
            <LoadingLoader visible={this.state.loading} />
            <label style={{backgroundColor:'#f5f5f5'}}>
              <span className="btn attach">
                <FontAwesomeIcon onClick={this.messageSubmit} icon={faPaperPlane} size='2x' />
              </span>
            </label>
          </div>
        </div>
      </div>
      
    )
     
    
      // }
    // else if (this.props.location.state.slug === null){
    //     return(
    //        <h1>No</h1>
    //     )
    // }

  //   else {
  //     return (
  //      <h1>hi</h1>
  //     )
  // }
  }

  emptyChatContainer = () => {
    return (
      <div className="babble tab-pane fade" id="list-empty" role="tabpanel" aria-labelledby="list-empty-list">
        <div className="chat" id="chat2">
          {this.renderTop()}
          {this.emptyContent()}
          {this.renderInput()}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', backgroundColor: 'white' }}>
      <LoadingLoader visible={this.state.loading} />
    </div>
    
    return (
      <div className='chat-setion'>
        <div className='row'>
          <div className="col-lg-3 col-xl-3 col-12">
          {this.renderSideBar()}
          </div>
         <div className="col-lg-9 col-xl-9 col-12">
          <div className="main">
            <div className="tab-content" id="nav-tabContent">
              <div className="babble tab-pane fade active show" id="list-chat" role="tabpanel" aria-labelledby="list-chat-list">
                <div className="chat" id="chat1">
                  {this.renderTop()}
                        <FlashMassage duration={8000} persistOnHover={true}>
                          <div style={{ color: 'red' }}>{this.state.limitSet}</div>
                        </FlashMassage>
                  <div className="content" id="content" id='list-chat'>
                    <div className="container">
                      <div className="col-md-12">
                        {this.messages()}
                        </div>
                    </div>
                  </div>
                  {this.renderInput()}
                </div>
              </div>
              {this.emptyChatContainer()}
            </div>
          </div>
         </div>
        </div>
      </div>
    )
  }
}

export default SuperHOC(Chat);
