import React, { Component } from 'react';
import Breadcrumb from "../../components/common/breadcrumb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast, ToastContainer, } from 'react-toastify';
import CryptoJS from "crypto-js";
import 'react-toastify/dist/ReactToastify.min.css';
import image from '../../assets/demo.jpg';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from 'react-share';
import ShareButton from 'react-social-share-buttons'


import user from '../../assets/merchant-no-cover.jpg'
class ElementBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      limit: 12,
      hasMoreItems: true,
      error: false,
      email: ""
    };
  }

  fetchMoreItems = () => {
    if (this.state.limit >= this.props.product.length) {
      this.props.loadMore()
      if (this.props.end === true) {
        this.setState({ hasMoreItems: false });
      }
    }
    setTimeout(() => {
      this.setState({
        limit: this.state.limit + 8
      });
    }, 1000);
  }

  isHearted = (item) => {
    return this.props.user ?
      this.props.user.favoritesMerchants.indexOf(item.email) > -1 :
      false
  }

  handleHeartPress = (item) => {
    let body = { email: item.email };
    if (this.isHearted(item)) {
      this.props.apiManager.makeCall('remove_favorite_merchant', body, (result) => {
        if (result.message) {
          if (result.message === '1078') {
            this.props.saveUser(result.buyer);
            toast.success("Merchant removed from favorite");
          } else {
            this.props.alert(result.message)
          }
        }
      })
    } else {
      this.props.apiManager.makeCall('add_favorite_merchant', body, (result) => {
        if (result.message) {
          if (result.message === '1075') {
            this.props.saveUser(result.buyer);
            toast.success("Merchant added to favorite");
          } else {
            this.props.alert(result.message)
          }
        }
      })
    }
  }

  gotoSellerProduct = (item) => {
    let removePerc = item.name ? item.name.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    let email = item.email
    var enc = window.btoa(email);
    const gotoSellerDetail = {
      pathname: `/merchant/${convertNameIntoDashes}`,
      state: {
        email: item.email
      }
    }
    // await localStorage.setItem('seller-email', JSON.stringify(this.state.product.parent))
    this.props.history.push(gotoSellerDetail)
  }

  render() {
    const { product } = this.props
    return (
      <div>
        <Breadcrumb title={'merchants'} />
        <section className="banner-furniture absolute_banner ratio3_2">
          <div className="container">
            {product.length > 0 ?
              <InfiniteScroll
                dataLength={this.state.limit} //This is important field to render the next data
                next={this.fetchMoreItems}
                hasMore={this.state.hasMoreItems}
                loader={<div className="loading-cls"></div>}
                endMessage={
                  <p className="seen-cls seen-it-cls">
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div className="row partition3 merchant">
                  <ToastContainer />
                  {
                    product.slice(0, this.state.limit).map((item, index) => {
                      let src = item.asset && item.asset.src;
                      var enc = window.btoa(item.email);
                      let removePerc = item.name ? item.name.split('%').join(' ') : ' ';
                      let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
                      let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
                      let url = 'https://pindal.pk/merchant/' + convertNameIntoDashes + '?id=' + enc;
                      return (
                        <div className="col-xl-4 col-md-6 column-merchant" key={index}>
                          <div>
                            <div className="collection-banner p-left text-left" style={{ border: '1px solid #deaf2b' }}>
                              {
                                item.trusted ? <div style={{ position: 'absolute', top: 10, right: 10 }}>
                                  <svg height="30" viewBox="0 0 48 48" width="30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0h48v48h-48z" fill="none" />
                                    <path d="M24 2l-18 8v12c0 11.11 7.67 21.47 18 24 10.33-2.53 18-12.89 18-24v-12l-18-8zm-4 32l-8-8 2.83-2.83 5.17 5.17 13.17-13.17 2.83 2.83-16 16z" fill="green" />
                                  </svg>
                                </div> : null
                              }
                              <div style={{ width: '100%', height: '250px', backgroundColor: 'white', cursor: 'pointer' }}
                                onClick={() => this.props.history.push('/merchant/' + convertNameIntoDashes + '?id=' + enc)}
                              >
                                <img
                                  src={src !== undefined ? src : user} alt=""
                                  style={{ width: '100%', height: '250px', }}
                                />
                              </div>
                              <div className="absolute-contain" >
                                <h3>{item.name}</h3>
                                <div
                                  style={{ position: 'absolute', top: '10px', right: '5px' }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleHeartPress(item)
                                  }}
                                >
                                  <FontAwesomeIcon icon={faHeart} size="lg" color={this.isHearted(item) ? 'red' : '#bbbbbb'} />
                                </div>
                                <div
                                  className='icon-wrapper'
                                >

                                  <ul className="product-social" style={{ display: 'flex', alignItems: 'center' }}>
                                    <li
                                      className="network"
                                    >
                                      <FacebookShareButton
                                        className="network__share-button"
                                        url={url}
                                      >
                                        <i
                                          style={{ fontSize: 18, color: '#333' }}
                                          className="fa fa-facebook"></i>
                                      </FacebookShareButton>
                                    </li>
                                    <li
                                      className="network"
                                    >
                                      <ShareButton
                                        style={{ fontSize: 18, background: 'yellow' }}
                                        className="network__share-button"
                                        compact
                                        socialMedia={'google-plus'}
                                        url={url}
                                      >
                                      </ShareButton>
                                    </li>
                                    <li
                                      className="network"
                                    >
                                      <TwitterShareButton
                                        style={{ fontSize: 18 }}
                                        className="network__share-button"
                                        url={url}
                                      >
                                        <i
                                          style={{ fontSize: 18, color: '#333' }}
                                          className="fa fa-twitter"></i>
                                      </TwitterShareButton>
                                    </li>
                                    <li
                                      className="network"
                                    >
                                      <LinkedinShareButton
                                        style={{ fontSize: 18 }}
                                        className="network__share-button"
                                        url={url}
                                      >
                                        <i
                                          style={{ fontSize: 18, color: '#333' }}
                                          className="fa fa-linkedin"></i>
                                      </LinkedinShareButton>
                                    </li>
                                  </ul>
                                  {/* <Link to={"https://plus.google.com/discover"}><i size={'6x'} className="fa fa-facebook" aria-hidden="true"></i></Link>
                                  <Link to="https://plus.google.com/discover" target="_blank"><i className="fa fa-google-plus" aria-hidden="true"></i></Link>
                                  <Link to="https://www.linkedin.com/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                                  <Link to="https://www.instagram.com/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></Link> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </InfiniteScroll> : null
            }
          </div>
        </section>
      </div>
    )
  }
}
export default ElementBanner;