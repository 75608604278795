import React, { Component } from 'react'
import AccountComponent from '../components/Account/AccountComponent'
import { SuperHOC } from '../HOC';

 
class AccountScreen extends Component {
    render() {
        return (

            <div>
                {
                    this.props.apiManager.token ?
                        <AccountComponent />
                        :
                        null
                }

            </div>
        )
    }
}

 export default SuperHOC(AccountScreen);
 