import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadingLoader from '../Loader/Loader'
export default class componentName extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cancelledSchedule: [],
      loading: true
    }
  }

  componentDidMount() {
    this.getCancelledSchedule()
  }

  getCancelledSchedule = () => {
    let body = {
      timeStart: "1534330562000",
      state: "4"
    }
    this.props.apiManager.makeCall('get_schedules', body, res => {
      if (res) {
        this.setState({
          cancelledSchedule: res.result.docs,
          loading: false
        })
      }
      else {
      }
    })
  }

  emptyCart = () => {
    return (
      <div style={{ minHeight: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <section className="cart-section section-b-space" style={{ marginTop: 50, }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div >
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                    <h3>
                      <strong>you have no schedule yet</strong>
                    </h3>
                    <h4>Explore more shortlist some items.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  render() {
    if (this.state.loading) return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '60vh', }}>
      <LoadingLoader visibel={this.state.loading} />
    </div>
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 order-sec">
        {
          this.state.cancelledSchedule.length === 0 ? this.emptyCart() :
            this.state.cancelledSchedule.map((item, i) => {
              return (
                <div className="row blog-media" style={{ marginBottom: 20, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '10px 10px 10px 0', backgroundColor: 'rgba(221,221,221,0.5)' }}>
                  <div className="col-xl-5">
                    <div className="blog-left">
                      <Link to={`${process.env.PUBLIC_URL}/product/${item.productId}`} >
                        <img src={item.productImage} style={{ maxHeight: '200px' }} alt="" height='160px' width='100%' />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-7" style={{ marginTop: 5 }}>
                    <div className="blog-right">
                      <div>
                        <h4>{item.productName}</h4>
                        <p>{item.description}</p>
                      </div>
                      <ul className="post-social" style={{ display: 'flex', flexDirection: 'column' }}>
                        <ul className="post-social" style={{ display: 'flex', flexDirection: 'column' }}>
                          <li><i style={{ margin: 5, fontWeight: '700' }}>Total Amount:</i>RM {item.priceAfterDiscount}</li>
                          <li><i style={{ margin: 5, fontWeight: '700' }}>Paid Amount: </i>RM {item.paidAmount}</li>
                          <li><i style={{ margin: 6, fontWeight: '700' }}> Amount Due:  </i> RM {item.amountDueAtVenue}</li>
                        </ul>
                        <ul className="post-social" style={{ display: 'flex', flexDirection: 'column' }}>
                          <li><i style={{ margin: 5 }} className="fa fa-map-marker"></i>{item.originAddress.address},{item.originAddress.cityName},{item.originAddress.provinceName}</li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              )
            })}
      </div>
    );
  }
}
