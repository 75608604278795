const constants = {
    favorite: [
        'add_favorite_product',
        'remove_favorite_product',
        'add_favorite_merchant',
        'remove_favorite_merchant'
    ]
};

export {
    constants
};