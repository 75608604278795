import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faSmile, faPaperclip, faComments } from '@fortawesome/free-solid-svg-icons'
import { SuperHOC } from "../../HOC";
import LoadingLoader from '../Loader/Loader'
import FlashMassage from 'react-flash-message';


class ChatMobile extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      slugValue: '',
      selectedChat: null,
      getBuyerChat: [],
      getBuyerChatItem: [],
      image: [],  // for Chat image show from Get buyer chat sider
      inputData: '',
      addMessage: '',
      slugSet: '',
      chatIdSent: '',
      topName: '',
      getBuyerChatItemId: '',
      idShowChatSet: '',
      newChatlist: [],
      topNameSet: [],
      topNameSlug: [],
      slugData: '',
      newChatlistid: [],
      emailSet: '',
      productSet: '',
      emailUser: '',
      emailData: '',
      chatIds: [],
      value: [],
      productChatId:'',
      limitSet:'',
      defaultid: '',
      defaultChat: [],
      defaultChatReverse:[],
      defaultImage:'',
      defaultName:'',
      defaultslug:''

    }
  }
  //API's

  componentDidMount() {
    this.userDetails()
    this.listChat()
    this.listDataBuyer()

  }

  componentWillMount() {
    let chat = this.state.getBuyerChatItemId;
    this.props.apiManager.makeCall('get_buyer_chats', { chatId: chat }, (res) => {
      if (res) {
          res.result.docs.map((value, index) => {
            if (this.props.location.state.slug === value.productId){
              this.setState({
               defaultid: value.slug,
               defaultName: value.productName,
               defaultslug: value.productId,
               defaultImage: value.imageProduct[0].src

              }, () => { this.listdefaultChat() })
            }

            else{
               let slug =this.props.location.state.slug
               let emailData = this.state.emailSet ? this.state.emailSet : this.state.emailUser

                return (
                  this.setState({
                    slugSet: slug,
                    emailBuyer: emailData,
                  }, () => { this.submit() })
                )
            }  
         })
       }
    })
  }

  listdefaultChat = () => {
    let iddefaultChat;
    iddefaultChat = this.state.defaultid;
    this.props.apiManager.makeCall('get_chat_items', { chatId: iddefaultChat }, (res) => {
      if (res.message === '2113') {
        this.setState({
          loading: false,
          defaultChat: res.result.docs,
         
        },() => {this.messageSubmit()})
      }
    })
  }


  userDetails = () => {
    this.props.apiManager.makeCall('get_user_details', {}, res => {
      if (res.user) {
        this.setState({ emailUser: res.user.email });

      }
    }
    )
  }
  listChat = () => {
    const { params } = this.props.match
    let body = {
      chatId: params.id?params.id:this.state.productChatId
    }
    this.props.apiManager.makeCall('get_chat_items', body, (res) => {
      if (res.message === '2113') {
        this.setState({
          loading:false,
          newChatlist: res.result.docs,
          newChatlistid: res.result.docs._id,
        })
      }
    })
  }

  listDataBuyer = () => {
    const { params } = this.props.match
    this.setState({
      slugValue: params.slug
    })
    this.props.apiManager.makeCall('get_buyer_chats', {}, (res) => {
      if (res.message === '2103') {
        res.result.docs.forEach(element => {
          if (element.slug === this.props.match.params.id) {
            this.setState({
              loading:false,
              topNameSet: element,
              emailSet: element.emailBuyer,
              productSet: element.productId,
            },() => {this.listChat()})
          }
          else {
          }
        })
      }
    })
  }
  submit = () => {
    let body = {
      slug: this.state.slugData,
      emailBuyer: this.state.emailSet ? this.state.emailSet : this.state.emailUser,
      message: this.state.addMessage
    }
    this.props.apiManager.makeCall('add_chat_and_chat_item', body, (res) => {
      if (res.message === '2107') {
        this.setState({
          loading:false, 
          addMessage: ''    
        },()=>{
          this.props.apiManager.makeCall('get_buyer_chats',{},(res)=>{
               this.setState({
                productChatId:res.result.docs[0].slug,
                topNameSet: res.result.docs[0]
               },()=>{
                let chat = this.state.productChatId ? this.state.productChatId : this.state.slugData;
                this.props.apiManager.makeCall('get_chat_items', { chatId: chat }, (res) => {
                  if (res) {
                    this.setState({
                      // loading:false, 
                      newChatlist: res.result.docs,
                      // defaultChat: res.result.docs,
                    }, () => { this.listDataBuyer() })
                  }
                })
               })
           })
        })        
      }
    })
  }


  messageSubmit = () => {
    let slug;

    if(this.state.defaultid !== ''){
      slug= this.state.defaultslug;
    }
    else{
      slug = this.state.productSet ? this.state.productSet : this.props.location.state.slug
    }

    let emailData = this.state.emailSet ? this.state.emailSet : this.state.emailUser
    
    return (
      this.setState({
        slugData: slug,
        emailBuyer: emailData,
      }, () =>this.submit())
    )
  }

  handleOnChange = (e) => {
    e.preventDefault()
    this.setState({ addMessage: e.target.value })
  }



  renderTop = () => {
    if(this.state.defaultImage === ''){
      return (
        <div className="top">
          <div className="container">
            <div className="col-xs-12">
              <div className="inside">
                <div><img className="avatar-md" src={this.state.topNameSet && this.state.topNameSet.imageProduct !== undefined ? (this.state.topNameSet.imageProduct[0].src) : null}
                  data-toggle="tooltip" data-placement="top" title={this.state.topNameSet.productName !== '' ? this.state.topNameSet.productName: null } alt="" /></div>
                {/* <div className="status">
                   <i className="material-icons online"></i>
                 </div> */}
                <div className="data">
                  <h5>{this.state.topNameSet.productName !== undefined ? this.state.topNameSet.productName.substring(0,14): null }</h5>
                  {/* <span>Active now</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else{
      if(this.state.defaultImage !== ''){
        return(
          <div className="top">
          <div className="container">
            <div className="col-md-12">
              <div className="inside">
                <div><img className="avatar-md" src={this.state.defaultImage}
                  data-toggle="tooltip" data-placement="top" title={this.state.defaultName} alt="avatar" /></div>
                {/* <div className="status">
                  <i className="material-icons online"></i>
                </div> */}
                <div className="data">
                  <h5>{this.state.defaultName}</h5>
                  {/* <span>Active now</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }
      else{
        return null;
      }
      
    }
   
  }

  messages = () => {
    if (this.state.newChatlist.length !== 0) {
      return (
        this.state.newChatlist.map((newChatlist, index) => {
          let timeZone = new Date(newChatlist.createdAt);
          let hours = timeZone.getUTCHours();
          let min = timeZone.getUTCMinutes();
          if (newChatlist.senderRole === 2) {
            return <div className="message">
              <img className="avatar-md" src={this.state.topNameSet && this.state.topNameSet.imageProduct !== undefined ? (this.state.topNameSet.imageProduct[0].src) : null} data-toggle="tooltip"
                data-placement="top" title="Keith" alt="avatar" />
              <div className="text-main">
                <div className="text-group">
                  <div className="text">
                    <p>{newChatlist.message}</p>
                  </div>
                </div>
                <span>{hours}:{min} AM</span>
              </div>
            </div>
          }
          else {
            return (
              <div className="message me">
                <div className="text-main">
                  <div className="text-group me">
                    <div className="text me">
                      <p>{newChatlist.message}</p>
                      <LoadingLoader visible={this.state.loading} />
                    </div>
                  </div>
                  <span>{hours}:{min} AM</span>
                </div>
              </div>
            )
          }
        })

      )
    }
    else {
        if(this.state.defaultChat === ''){
          return (
            this.state.defaultChat.map((defaultSelectChat, index) => {
              let timeZone = new Date(defaultSelectChat.createdAt);
              let hours = timeZone.getUTCHours();
              let min = timeZone.getUTCMinutes();
              if (defaultSelectChat.senderRole === 2) {
                return <div className="message">
                  <img className="avatar-md" src={this.state.defaultImage} data-toggle="tooltip"
                    data-placement="top" title="Keith" alt="avatar" />
                  <div className="text-main">
                    <div className="text-group">
                      <div className="text">
                        <p>{defaultSelectChat.message}</p>
                      </div>
                    </div>
                    <span>{hours}:{min} AM</span>
                  </div>
                </div>
              }
              else {
                return (
                  <div className="message me">
                    <div className="text-main">
                      <div className="text-group me">
                        <div className="text me">
                          <p>{defaultSelectChat.message}</p>
                        </div>
                      </div>
                      <span>{hours}:{min} AM</span>
                    </div>
                  </div>
                )
              }
            })
          )
        }
        else{
          return (
            <div  style={{ marginBottom: '130px' }} >
                <button className="btn emoticons noChat">
              <FontAwesomeIcon icon={faComments} size='4x' color='grey' />
              <h3 style={{marginTop:'-5px'}}> Get Started Chat.... </h3>
            </button></div>
          )
        }
        
      }
  }




  emptyContent = () => {
    return (
      <div className="content empty">
        <div className="container">
          <div className="col-md-12">
            <div className="no-messages">
              <i className="material-icons md-48">forum</i>
              <p>Seems people are shy to start the chat. Break the ice send the first message.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderInput = () => {
    return (
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea className="form-control"
                onChange={(e) => this.handleOnChange(e)}
                name="addMessage" placeholder="Start typing for reply..." rows="1" value={this.state.addMessage}>
              </textarea>
              <button className="btn emoticons">
                <FontAwesomeIcon icon={faSmile} size='2x' />
              </button>
              <div className="btn send">
                <FontAwesomeIcon onClick={this.messageSubmit} icon={faPaperPlane} size='2x' />
              </div>
            </form>
           
            <LoadingLoader visible={this.state.loading} />
             
          </div>
        </div>
      </div>
    )
  }

  emptyChatContainer = () => {
    return (
      <div className="babble tab-pane fade" id="list-empty" role="tabpanel" aria-labelledby="list-empty-list">
        <div className="chat" id="chat2">
          {this.renderTop()}
          {this.emptyContent()}
          {this.renderInput()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='chat-setion'>
        <div className='layout'>
          <div className="main">
            <div className="tab-content" id="nav-tabContent">
              <div className="babble tab-pane fade active show" id="list-chat" role="tabpanel" aria-labelledby="list-chat-list">
                <div className="chat" id="chat1">
                  {this.renderTop()}
                  <FlashMassage duration={8000} persistOnHover={true}>
                   <div style={{ color: 'red'}}>{this.state.limitSet}</div>
                   </FlashMassage>
                  <div className="content" id="content" id='list-chat'>
                    <div className="container">
                      <div className="col-md-12">
                        {this.messages()}
                      </div>
                    </div>
                  </div>
                  {this.renderInput()}
                </div>
              </div>
              {this.emptyChatContainer()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SuperHOC(ChatMobile);

