import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NewScheduleItem from './ScheduleItem'
import BookedSchedultItem from './BookedScheduleItem'
import CompletedScheduleItem from './CompletedSchedule'
import CancelledScheduleItem from './CancelledScheduleItem'
import RemovedScheduleItem from './RemovedScheduleItem'
import BreadCrumb from "../common/breadcrumb";

export default class componentName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: ''
    }
  }

  componentDidMount = () => {
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')
    if (previous[3] !== same[2]) {
      this.setState({ previous: previous[3] })
    }
  }
  render() {
    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <BreadCrumb title={'Schedules'} parent={this.state.previous} />
        </div>
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="collection-content col">
                <div className="page-main-content ">
                  <div className="">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="collection-product-wrapper">
                          <Tabs className="theme-tab" >
                            <TabList className="tab-title" >
                              <Tab>New</Tab>
                              <Tab>Booked</Tab>
                              <Tab>Cancelled</Tab>
                              <Tab>Completed</Tab>
                              <Tab>Removed</Tab>
                            </TabList>
                            <div className="tab-content-cls">
                              <TabPanel className="tab-content">
                                <div className="row product-tab">
                                  <NewScheduleItem
                                    {...this.props}
                                  />
                                </div>
                              </TabPanel>
                              <TabPanel className="tab-content">
                                <div className="row product-tab">
                                  <BookedSchedultItem
                                    {...this.props}
                                  />
                                </div>
                              </TabPanel>
                              <TabPanel className="tab-content">
                                <div className="row product-tab">
                                  <CancelledScheduleItem
                                    {...this.props}
                                  />
                                </div>
                              </TabPanel>
                              <TabPanel className="tab-content">
                                <div className="row product-tab">
                                  <CompletedScheduleItem
                                    {...this.props}
                                  />
                                </div>
                              </TabPanel>
                              <TabPanel className="tab-content">
                                <div className="row product-tab">
                                  <RemovedScheduleItem
                                    {...this.props}
                                  />
                                </div>
                              </TabPanel>
                            </div>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
