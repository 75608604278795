import React, { Component } from 'react';
import Slider from 'react-slick';
import classes from './style.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MerchantProducts from '../products/merchantProducts';
import ReviewContainer from './ReviewContainer';
import user from '../../assets/user.png'
export default class ImageCaraousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      merchant: {},
    };
  }


  getMerchantReview = () => {
    let body = {
      merchant: this.props.email
    }
    this.props.apiManager.makeCall('get_merchant_review', body, (res) => {
      if (res.message) {
        this.setState({ reviews: res.result.docs, loading: false })
      }
      else {
        console.error('Error')
      }
    })
  }

  componentDidMount = () => {
    this.getMerchantReview();
  }

  isMobile = () => {
    if (window.innerWidth <= 576) {
      return true
    }
    else {
      return false
    }
  }

  gotoSellerProduct = () => {
    let { name, email } = this.props.item

    let removePerc = name ? name.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';

    // const gotoProductDetail = {
    //   pathname: '/seller-product/' + convertNameIntoDashes + '?id=' + this.props.id,
    //   // state: {
    //   //   email: email
    //   // },
    //   search:
    // }
    // await localStorage.setItem('seller-email', JSON.stringify(this.state.product.parent))
    this.props.history.push('/seller-product/' + convertNameIntoDashes + '?id=' + this.props.id)
  }

  // gotoSellerProduct = async (id) => {
  //   let { email } = this.props.item
  //   await localStorage.setItem('seller-email', JSON.stringify(email))
  //   this.props.history.push(`${process.env.PUBLIC_URL}/seller-product/${id}`)
  // }

  render() {
    let { name, description, email, image, assets, locations, _id } = this.props.item
    return (
      <div>
        <section className="padding-bottom-cls">
          <Slider className="slide-1 home-slider">
            <div>
              <div>
                <div >
                  <div className="row">
                    <div className="col">
                      <div>
                        <div>
                          <img src={image} className='merchant-profile-image' style={{ width: '100%', height: this.isMobile() ? '240px' : '450px' }} alt="" />
                          <div style={{ bottom: this.isMobile() ? '-15px' : '-30px', position: 'absolute' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: this.isMobile() ? '30px' : '60px' }}>
                              <div style={{ borderRadius: '50%', border: '2px solid white' }}>
                                <img
                                  src={assets ? assets[0].src : user} alt="Nothing" style={{ borderRadius: '50%', width: this.isMobile() ? '80px' : '150px', border: '1px solid #e3e3e3', height: this.isMobile() ? '80px' : '150px' }} />
                              </div>

                              <div style={{ borderRadius: '1px 15px 15px 1px', boxShadow: '1px 3px 3px #000000a3', border: '1px solid rgb(227,227,227)', marginLeft: '-1px', height: '30px', alignSelf: 'center', backgroundColor: '#FFF', padding: '5px' }}>
                                <h4>
                                  {name}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Products Listing Component*/}
              <Tabs className="theme-tab">
                <TabList className="tab-title" style={{ borderTop: "0" }} >
                  <Tab>Description</Tab>
                  <Tab>Products</Tab>
                  <Tab>Services</Tab>
                  <Tab>Reviews</Tab>
                </TabList>
                <div className="tab-content-cls">
                  <TabPanel className="tab-content">
                    <div className="container">
                      <div className="top-banner-content small-section">
                        <div className="single-product-tables">
                          <table className="table table-striped mb-0" style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th>Description:</th>
                                <td>
                                  {
                                    description ? description[0].summary : 'nothing'
                                  }
                                  {/* {this.props.item && description !== undefined && description.length > 1 ? parser(description[0].description) : 'nothing'} */}
                                </td>
                              </tr>
                              {/* <tr>
                                <th>Email:</th>
                                <td>{email}</td>
                              </tr> */}
                              <tr>
                                <th>Address:</th>
                                <td>
                                  {
                                    locations && locations[0] ? locations[0].address : 'nothing'
                                  }
                                  {/* //{this.props.item && description !== undefined && description.length > 1 ? parser(description[0].summary) : 'nothing'} */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tab-content">
                    <div className="top-banner-content small-section">
                      <MerchantProducts
                        {...this.props}
                        email={email}
                        numOfRecords={8}
                        api={'get_merchant_products'}
                        viewMoreProducts={this.gotoSellerProduct}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel className="tab-content">
                    <div className="top-banner-content small-section">
                      <MerchantProducts
                        {...this.props}
                        email={email}
                        numOfRecords={8}
                        api={'get_merchant_services'}
                        viewMoreProducts={this.gotoSellerProduct}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel className="tab-content">
                    <div className="container">
                      <div className="top-banner-content small-section">
                        <div className={classes.reviewContainer} style={{ marginTop: 10, padding: '20px' }}>
                          <div >
                            <h1 className={classes.ratingReview}>Seller Ratings and Reviews ({this.state.reviews && this.state.reviews.length})</h1>
                          </div>
                          <ReviewContainer {...this.props}
                            data={this.state.reviews}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </Slider>
        </section>
      </div>
    );
  }
}
