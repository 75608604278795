import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { SuperHOC } from '../../HOC';
import Loader from '../Loader/Loader'
// import { toast, ToastContainer, } from 'react-toastify';
import { formatDate } from '../../config/HelperFunction'

class RefundedOrders extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: '',
      pages: '',
      refunds: [],
      loading: true,
      previous: ''
    }
  }

  componentDidMount = () => {
    this.getRefunds();
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')
    if (previous[3] !== same[2]) {
      this.setState({ previous: previous[3] })
    }
  }


  getRefunds = () => {
    this.props.apiManager.makeCall('get_refunded_complains', {}, res => {
      if (res.result) {
        this.setState({ refunds: res.result.docs, pages: res.result.pages })
        let { page, pages, docs } = res.result;
        this.setState({ page: page, pages: pages })
        if (page <= pages) {
          this.setState({
            refunds: this.state.refunds.concat(docs),
            page: page + 1,
            // pages,
            loading: false
          }, () => (page === pages) ? this.setState({ end: true }) : null);
        } else {
          this.setState({
            end: true,
            loading: false
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }

  render() {
    if (this.state.loading)
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '70vh' }}>
          <Loader
            visible={this.state.loading}
          />
        </div>
      )
    return (
      this.state.refunds.length !== 0
        ?
        <div class="row">
          <div className="col-sm-12 pre-scrollable">
            <Breadcrumb title={'Refunds'} parent={this.state.previous} />
            <table className="table table-responsive-xs">
              <thead style={{ textAlign: "center" }}>
                <tr className="table-head">
                  <th scope="col">Refund Date</th>
                  {/* <th scope="col">Sender</th> */}
                  <th scope="col">Refund Amount</th>
                  <th scope="col">Refund Type</th>
                  <th scope="col">Merchant</th>
                </tr>
              </thead>
              {this.state.refunds.map((item, index) => {
                return (
                  <tbody style={{ textAlign: "center" }}>
                    <tr>
                      <td>{formatDate(new Date(item.createdAt))}</td>
                      <td>{item.refundAmount}</td>
                      <td>{item.isCancelled === true ? "Canceled Order" : 'Complained'}</td>
                      <td>{item.merchantEmail}</td>

                    </tr>
                  </tbody>
                )
              }
              )}
            </table>
            {
              (this.state.page <= this.state.pages) ?
                <div
                  className="btn btn-solid"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 50, width: 150 }}
                  onClick={() => this.getRefunds()}>
                  Show More
                                </div>
                :
                <div />
            }
          </div>
          <br />
        </div>
        :
        <section className="cart-section section-b-space">
          <Breadcrumb title={'Refunds'} parent={this.state.previous} />
          <div style={{ minHeight: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div >
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/orderhistory.png`} style={{ color: "grey" }} className="img-fluid mb-4" alt="" />
                      <h3>
                        <strong> You don't have any Refunds</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

export default SuperHOC(RefundedOrders);