import React, { Component } from 'react';
import classes from './style.module.css'
import Breadcrumb from "../common/breadcrumb";
export default class wallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      walletHistory: [],
      page: '',
      pages: '',
    }
  }

  componentDidMount() {
    this.getWalletHistories()
  }

  getWalletHistories = (pageNum = 1) => {
    let body = {
      page: pageNum,
      startTime: 1,
      endTime: new Date().valueOf(),
      numRecords: 10,
    }
    this.props.apiManager.makeCall(this.props.api, body, res => {
      if (res) {
        let { page, pages, docs } = res.result;
        if (page <= pages) {
          this.setState({
            walletHistory: this.state.walletHistory.concat(docs),
            page,
            pages,
          }, () => (page === pages) ? this.setState({ end: true }) : null);
        }
        else {
          this.setState({
            end: true,
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
        });
      }
    })
  }

  recheckPending = (item) => {
    let body = { orderId: item._id, type: '1' }
    this.props.apiManager.makeCall('payment_gateway_reterive_order', body, res => {
      if (res) {
        window.location.reload()
      }
    });
  }


  render() {
    const { walletHistory } = this.state
    return (
      <div>
        <Breadcrumb title={'Wallet History '} />
        <section className="register-page section-b-space" >
          <div className="container">
            <h2 style={{ paddingTop: 30, paddingBottom: 30, textAlign: 'center', textDecoration: 'underline' }}>All Wallet History</h2>
            <table className={classes.table} >
              <thead>
                <tr className='table-row' align="center">
                  <th>Date</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Detail</th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: 'white' }}>
                {walletHistory.map((item, index) => {
                  let d = new Date(item.createdAt)
                  let month = '' + (d.getMonth() + 1)
                  let day = '' + d.getDate()
                  let year = d.getFullYear()
                  if (month.length < 2)
                    month = '0' + month;
                  if (day.length < 2)
                    day = '0' + day;
                  let date = [day, month, year].join('-')
                  let status = item.status && item.status > 0 ? item.status > 1 ? "Un-Approved" : "Pending" : "Approved"
                  let type = item.type && item.type > 0 ? item.type > 1 ? item.type > 2 ? "Purchase" : "Un-Approved" : "Top-Up" : "Checkout"
                  return (
                    <tr key={index} align="center">
                      <td>{date}</td>
                      <td>{type}</td>
                      <td>{item.approvedAmount.toFixed(2)}</td>
                      <td>{status}</td>
                      {item.status === 1 ?
                        <td style={{ paddingBottom: 1, paddingTop: 1, paddingRight: 0, paddingLeft: 0, verticalAlign: 'middle' }}><button onClick={() => this.recheckPending(item)} className="btn btn-secondary" style={{ marginBottom: 10, marginTop: 10, marginRight: 0, marginLeft: 0, paddingBottom: 1, paddingTop: 1, paddingLeft: 6, paddingRight: 5, fontSize: 10, }}>Recheck</button></td>
                        :
                        item.status === 0 ?
                          <td style={{ paddingBottom: 10, paddingTop: 10, paddingRight: 0, paddingLeft: 0, verticalAlign: 'middle' }}><a href={item.receipt} target="_blank" rel="noopener noreferrer"><img src={item.receipt !== undefined ? item.receipt : `${process.env.PUBLIC_URL}/assets/tick.png`} height='20px' width='20px' alt=''></img></a></td>
                          :
                          <td style={{ paddingBottom: 10, paddingTop: 10, paddingRight: 0, paddingLeft: 0, verticalAlign: 'middle' }}><a href={item.receipt} target="_blank" rel="noopener noreferrer"><img src={item.receipt !== undefined ? item.receipt : `${process.env.PUBLIC_URL}/assets/cross.png`} height='20px' width='20px' alt=''></img></a></td>
                      }
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 0, }} onClick={() => this.getWalletHistories(this.state.page + 1)}>
              <h6 style={{ alignSelf: 'center', alignItems: 'center', paddingTop: 7, marginRight: 5, cursor: 'pointer' }}>
                Load More
                            </h6>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
