export const login = () => {
  const hasExistingToken = window.localStorage.getItem('x-token');

  if (hasExistingToken) {
    console.info('Has existing token, aborting...')
    return Promise.resolve();
  }

  if (!localStorage.getItem('token')) {
    console.info('No token set, aborting...')
    return Promise.reject();
  }

  return fetch(`${process.env.REACT_APP_LIFESTYLE_HOSTNAME}/v1/checkpoint`, {
    method: "GET",
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  }).then(response => {
    if (response.headers.has('x-token')) {
      window.localStorage.setItem('x-token', response.headers.get('x-token'));
    }
    return response.json();
  }).then(result => {
    console.info(result)
  })
}

export const logout = () => {
  return fetch(`${process.env.REACT_APP_LIFESTYLE_HOSTNAME}/v1/account/signout`, {
    method: "DELETE",
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('x-token')}`
    }
  }).then(response => {
    window.localStorage.removeItem('x-token');
    return response.json()
  }).then(result => {

    console.info(result)
  })
}
