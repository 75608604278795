import React, { Component } from "react";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import "../components/Login/style.css";
import { Manager } from "../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
const prefix = "60";
class ChangeMSISDN extends Component {
    state = {
        token: "",
        prefix: "60",
        phone: "60",
        loading: false,
        errorMessage: "",
        inputPhoneNumber: "",
        showPositiveAlert: false,
        showNegativeAlert: false,
    };

    sendTacNumber = (msisdn) => {
        this.setState({ loading: true });
        this.setState({
            inputPhoneNumber: this.state.phone,
        });

        let body = {
            newMSISDN: this.state.phone,
        };

        let payload = {
            newMSISDN: this.state.phone,
        };

        this.props.apiManager.makeCall("changeMSISDN", payload, (res) => {
            if (res.errors != undefined) {
                let erro;
                erro = res.errors.map((err) => {
                    return err;
                });

                if (!erro[0].msg) {
                    this.setState({
                        loading: false,
                        showPositiveAlert: true,
                        errorMessage: erro[0] ? erro[0].msg : "Invalid Number",
                    });
                } else if (!erro[1]) {
                    this.setState({
                        loading: false,
                        showPositiveAlert: true,
                        errorMessage: erro[1] ? erro[1] : "Invalid Number",
                    });
                } else {
                    this.setState({
                        loading: false,
                        showPositiveAlert: true,
                        errorMessage: "Invalid Number",
                    });
                }
            }

            if (res.message === 7100) {
                this.setState({
                    loading: false,
                    showNegativeAlert: false,
                    errorMessage:
                        "Sorry! The number you have entered is not a registered XOX Mobile Number. If you would like to continue, please ensure that the number entered is a registered XOX Mobile Number!",
                });
            }

            if (res.message === 7097) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage:
                        "This Number Belongs to another User, in the case of dispute please contact our support team!",
                });
            }
            if (res.message === 7060) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Msisdn is already taken!",
                    // access_token: res.data.data.accessToken,
                });
            }
            if (res.message == 7061) {
                this.setState({
                    loading: false,
                    token: res.token,
                    showPositiveAlert: true,
                    errorMessage: `OTP sent to ${this.state.phone}`,
                });
                setTimeout(() => {
                    this.props.history.push({
                        pathname: "/VerifyChangeMsisdn",
                        hash: res.otp.token,
                        state: {
                            removeVerifyPassword: true,
                            msis: this.state.phone,
                        },
                    });
                }, 1000);
            }
        });
    };

    handleChange = (e) => {
        // e.preventDefault()
        let temp = [];
        let newTemp = [];
        const input = e.target.value;
        const name = e.target.name;
        let number = (e.target.value = prefix + input.substr(prefix.length));
        temp.push(input);

        if (temp[0][2] !== "0") {
            newTemp.push(...temp);
            this.setState({
                phone: number ? number : newTemp,
            });
        }
    };

    render() {
        let phone = this.state.phone;
        return (
            <div className="limiter">
                <Breadcrumb title={"Change MSISDN"} />
                <Formik
                    initialValues={{
                        // changeMSISDN: this.state.prefix
                        //     ? this.state.prefix
                        //     : 60,
                        phone: phone,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!phone === "60") {
                            errors.phone = "Enter Msisdn with 60";
                            // errors.confirmPassword = 'Please enter your confirm password';
                        }
                        if (phone === "60") {
                            errors.phone = "Field cannot be empty";
                        }
                        if (!/\60/i.test(phone)) {
                            errors.phone =
                                "Invalid MSISDN address! Must start with 60";
                        }
                        if (phone.length <= 10) {
                            errors.phone =
                                "Msisdn must be at least 11 characters";
                        }
                        if (phone.length >= 14) {
                            errors.phone =
                                "Msisdn must be less then 14 characters";
                        }
                        return errors;
                    }}
                    // validationSchema={Yup.object().shape({
                    //     [{ phone: phone}] : Yup.string()
                    //         .min(11, "Msisdn must be at least 11 characters")
                    //         .required("Msisdn is required")
                    //         .max(14, "Numbers must be less then 14 "),
                    //     // .matches(
                    //     //     /60/,
                    //     //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    //     // ),
                    // })}
                    onSubmit={(values, { setSubmitting }) => {
                        values.phone = this.state.phone;
                        this.sendTacNumber(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        Enter New MSISDN
                                    </span>
                                    <div className="wrap-input100 validate-input">
                                        <input
                                            type="text"
                                            className="input100"
                                            name="phone"
                                            placeholder="Change Msisdn"
                                            value={this.state.phone}
                                            onke
                                            // onKeyPress={(e)=>this.keyPress(e)}
                                            // ref={(target) => {
                                            //     // target.value = prefix;
                                            // }}
                                            id="defaultRegisterFormPassword"
                                            onChange={(e) =>
                                                this.handleChange(e)
                                            }
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-mobile"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="phone"
                                            component="div"
                                        />
                                    </div>

                                    <div className="container-login100-form-btn">
                                        {this.state.loading ? (
                                            <div
                                                style={{
                                                    // left: 100,
                                                    // top: 100,
                                                    // bottom: 50,
                                                    zIndex: "100",
                                                    // position: "absolute",
                                                }}
                                            >
                                                <LoadingLoader visible={true} />
                                            </div>
                                        ) : (
                                            <button
                                                className="login100-form-btn"
                                                type="submit"
                                            >
                                                Change MSISDN
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(ChangeMSISDN);
