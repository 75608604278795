import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import InfiniteList from '../components/List/InfiniteList'
import { SuperHOC } from "../HOC";
import Breadcrumb from "../components/common/breadcrumb";
import ThemeSettings from "../components/common/theme-settings"
class LatestProductListing extends Component {

  componentDidMount() {
    const { type } = this.props.match.params;
    var newArrivalAnchor = document.getElementById("newArrivalAnchor");
    if (newArrivalAnchor && type != "all"){
      newArrivalAnchor.classList.add("underlineAnchor2");
    }
  }

  componentWillUnmount(){
    var newArrivalAnchor = document.getElementById("newArrivalAnchor");
    if (newArrivalAnchor){
      newArrivalAnchor.classList.remove("underlineAnchor2");
    }
  }

  render() {
    const { type } = this.props.match.params;
    return (
      <div>
        <Breadcrumb
          title={type == "all" ? "All Products" : "New Arrivals"}
        />
        <div className="container" style={{ paddingTop: '30px' }}>
          <InfiniteList
            {...this.props}
            api={"get_latest_products"}
            title={type == "all" ? "All Products" : "New Arrivals"}
            colSize={3}
          />
          <ThemeSettings />
        </div>
      </div >
    )
  }
}
export default SuperHOC(LatestProductListing)