import React, { useContext, useEffect } from 'react';
import NotificationBadge, { Effect } from 'react-notification-badge';
import { Link } from 'react-router-dom';
import CountContext from "../components/common/headers/common/CountContext";
import { login } from '../utils/session';

const CartContainer = () => {
  const context = useContext(CountContext);

  const mobile = () => {
    if (window.innerWidth > 576) {
      return false;
    } else {
      return true;
    }
  };

  let newCount = context.count;
  let blackmarketURL = process.env.REACT_APP_BLACKMARKET_URL;

  if (blackmarketURL.endsWith('/')) {
    blackmarketURL = blackmarketURL.substring(0, blackmarketURL.length - 1);
  }

  const obtainCookies = async () => {
    try {
      await login();
    } catch (error) {
      console.error('Something went wrong! Error: ', error)
    }
  }


  useEffect(() => {
    if (!window.localStorage.getItem('x-token')) {
      obtainCookies();
    }
  }, [])

  const onLinkClicked = (url) => {
    const token = localStorage.getItem('x-token');
    const cartToken = localStorage.getItem('token');

    const lifestyleURL = new URL('shopping-cart', url);

    lifestyleURL.searchParams.set('token', encodeURIComponent(token));
    lifestyleURL.searchParams.set('cartToken', encodeURIComponent(cartToken));

    window.location.href = lifestyleURL.href;
  }

  return (
    <>
      <li className="onhover-div mobile-cart">
        <a
          href={`${blackmarketURL}/shopping-cart`}
          onClick={(event) => {
            event.preventDefault();
            onLinkClicked(blackmarketURL)
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: mobile() ? 0 : 93,
              left: mobile() ? 110 : 52
            }}
          >
            <NotificationBadge count={newCount} effect={Effect.SCALE} />
            <i className="fa fa-shopping-cart text-white"></i>
          </div>
        </a>
        <a
          href={`${blackmarketURL}/shopping-cart`}
          onClick={(event) => {
            event.preventDefault();
            onLinkClicked(blackmarketURL)
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/shopping-cart.png`}
            className="img-fluid"
            style={{ marginBottom: '15px' }}
            width={'20px'}
            height={'20px'}
            alt=""
          />
        </a>
        <Link to={`${process.env.PUBLIC_URL}/chatScreen`}>
          <i className="fa fa-envelope"></i>
        </Link>
      </li>
    </>
  );
};

export default CartContainer;
