import React, { Component } from 'react';
import ChatMobile from '../components/Chat/ChatMobile'
import { SuperHOC } from "../HOC";
class ChatMobileScreen extends Component {

  render() {
    return (
      <ChatMobile {...this.props}/>
    );
  }
}

export default SuperHOC(ChatMobileScreen);