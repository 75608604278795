import React, { Component } from "react";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import "../components/Login/style.css";
import { Tab, TabList } from "react-tabs";
import { Manager } from "../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";

class VerifyChangeMsisdn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            number: "",
            loading: false,
            errorMessage: "",
            currentNumber: "",
            changingCheck: false,
            showPositiveAlert: false,
            showNegativeAlert: false,
        };
    }

    componentDidMount() {
        if (
            this.props.history.location.state != undefined &&
            this.props.history.location.state.msis != undefined
        ) {
            let currentNumb = this.props.history.location.state.msis;
            let removeVerifyPassword = this.props.location.state
                .removeVerifyPassword;
            this.setState({
                currentNumber: currentNumb,
                changingCheck: removeVerifyPassword,
            });
        }

        if (
            this.props.history.location.state != undefined &&
            this.props.history.location.state.changeMsi != undefined
        ) {
            let number = this.props.history.location.state.data;
            let flag = this.props.history.location.state.changeMsi;
            if (flag === true) {
                this.sendTransactionFunction(number);
                this.setState({ number: number });
            }
        }

        let tempToken = this.props.history.location.hash;
    }

    sendTransactionFunction = (numb) => {
        this.setState({
            loading: true,
        });

        let payload = {};
        this.props.apiManager.makeCall("sendTransactionTac", payload, (res) => {
            if (res.message === 6113) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Wrong OTP!",
                });
            }
            if (res.message == 7031) {
                this.setState({
                    loading: false,
                    token: res.token,
                    showPositiveAlert: true,
                    errorMessage: `OTP sent to ${numb}`,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: `Slow Internet Connection`,
                });
            }
        });
    };

    sendTacNumber = (otp) => {
        this.setState({
            loading: true,
        });
        let token = "";
        if (
            this.props.history.location.state != undefined &&
            this.props.history.location.state.changeMsi != undefined
        ) {
            let flag = this.props.history.location.state.changeMsi;
            if (flag === true) {
                token = this.state.token;

                let body = {
                    newMSISDN: otp,
                };

                let payload = {
                    otp: body.newMSISDN.otp,
                    hash: token,
                };

                this.props.apiManager.makeCall(
                    "verifyTransactionTac",
                    payload,
                    (res) => {
                        if (res.message === 7039) {
                            this.setState({
                                loading: false,
                                showNegativeAlert: true,
                                errorMessage: "OTP Wrong!",
                            });
                        }
                        if (res.message === 6112) {
                            this.setState({
                                loading: false,
                                showNegativeAlert: true,
                                errorMessage: "Hash code is not found!",
                            });
                        }
                        if (res.message === 6113) {
                            this.setState({
                                loading: false,
                                showNegativeAlert: true,
                                errorMessage: "OTP is expired!",
                            });
                        }
                        if (res.message == 7072) {
                            this.setState({
                                loading: false,
                                token: res.token,
                                showPositiveAlert: true,
                                errorMessage: `OTP successfully verified, You can change the MSISDN now!`,
                            });
                            setTimeout(() => {
                                this.props.history.push("/change-MSISDN");
                            }, 3000);
                        }
                    }
                );
            }
        } else {
            token = this.props.history.location.hash;
            token = token.substring(1);

            let body = {
                newMSISDN: otp,
            };

            let payload = {};
            if (this.state.changingCheck === true) {
                payload = {
                    otp: body.newMSISDN.otp,
                    hash: token,
                };
            } else {
                payload = {
                    otp: body.newMSISDN.otp,
                    hash: this.state.token,
                };
            }

            this.props.apiManager.makeCall(
                "verifyChangeMSISDN",
                payload,
                (res) => {
                    if (res.errors != undefined) {
                        let erro;
                        erro = res.errors.map((err) => {
                            return err;
                        });

                        if (!erro[0].msg) {
                            this.setState({
                                loading: false,
                                showPositiveAlert: true,
                                errorMessage: erro[0]
                                    ? erro[0].msg
                                    : "Invalid Number",
                            });
                        } else if (!erro[1]) {
                            this.setState({
                                loading: false,
                                showPositiveAlert: true,
                                errorMessage: erro[1]
                                    ? erro[1]
                                    : "Invalid Number",
                            });
                        } else {
                            this.setState({
                                loading: false,
                                showPositiveAlert: true,
                                errorMessage: "Invalid Number",
                            });
                        }
                    }

                    if (res.message === 7060) {
                        this.setState({
                            loading: false,
                            showNegativeAlert: true,
                            errorMessage: "Msisdn is already taken!",
                        });
                    }
                    if (res.message === 7098) {
                        this.setState({
                            loading: false,
                            showNegativeAlert: true,
                            errorMessage: res.detail.Message
                                ? res.detail.Message
                                : "Change Msisdn failed!",
                        });
                    }
                    if (res.message === 7096) {
                        this.setState({
                            loading: false,
                            showNegativeAlert: true,
                            errorMessage: res.detail.Message
                                ? res.detail.Message
                                : "Xox-users are not allowed to switch on non Xox-user!",
                        });
                    }
                    if (res.message === 6113) {
                        this.setState({
                            loading: false,
                            showNegativeAlert: true,
                            errorMessage: "Wrong OTP!",
                        });
                    }
                    if (res.message === 7039) {
                        this.setState({
                            loading: false,
                            showNegativeAlert: true,
                            errorMessage: "Wrong OTP!",
                        });
                    }
                    if (res.message == 7062) {
                        this.setState({
                            loading: false,
                            token: res.token,
                            showPositiveAlert: true,
                            // errorMessage: `OTP sent to ${body.newMSISDN.changeMSISDN}`,
                            errorMessage: `OTP successfully verified`,
                        });
                        setTimeout(() => {
                            this.props.history.push("/account");
                            // this.window.location.reload();
                        }, 3000);
                    }
                }
            );
        }
    };

    resendMSISDN = (msisdn) => {
        this.setState({ loading: true, changingCheck: false });
        this.setState({
            inputPhoneNumber: this.state.phone,
        });

        let payload = {
            newMSISDN: msisdn,
        };

        this.props.apiManager.makeCall("changeMSISDN", payload, (res) => {
            if (res.errors != undefined) {
                let erro;

                erro = res.errors.map((err) => {
                    return err;
                });

                if (!erro[0].msg) {
                    this.setState({
                        loading: false,
                        showPositiveAlert: true,
                        errorMessage: erro[0] ? erro[0].msg : "Invalid Number",
                    });
                } else if (!erro[1]) {
                    this.setState({
                        loading: false,
                        showPositiveAlert: true,
                        errorMessage: erro[1] ? erro[1] : "Invalid Number",
                    });
                } else {
                    this.setState({
                        loading: false,
                        showPositiveAlert: true,
                        errorMessage: "Invalid Number",
                    });
                }
            }

            if (res.message === 7100) {
                this.setState({
                    loading: false,
                    showNegativeAlert: false,
                    errorMessage:
                        "Sorry! The number you have entered is not registered as XOX Mobile Number. If you want to continue, please ensure that the number you have entered should registered as XOX Mobile Number!",
                });
            }

            if (res.message === 7097) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage:
                        "This Number Belongs to another User, in the case of dispute please contact our support team!",
                });
            }

            if (res.message === 7060) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Msisdn is already taken!",
                    // access_token: res.data.data.accessToken,
                });
            }
            if (res.message == 7061) {
                this.setState({
                    loading: false,
                    token: res.otp.token,
                    showPositiveAlert: true,
                    errorMessage: `OTP sent to ${msisdn}`,
                });
            }
        });
    };

    render() {
        let userID = Manager.getItem("profileIdMdlwr");
        let removeVerifyPassword = this.props.location.state
            .removeVerifyPassword;
        return (
            <div className="limiter">
                <Breadcrumb title={"OTP Verification"} />
                <Formik
                    initialValues={{
                        otp: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.otp) {
                            errors.otp = "Enter OTP";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        otp: Yup.string()
                            .min(6, "OTP must be at least 6 characters")
                            .required("OTP is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.sendTacNumber(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                    Change MSISDN OTP Verification
                                    </span>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="text"
                                            className="input100"
                                            name="otp"
                                            placeholder="OTP"
                                            id="defaultRegisterFormPassword"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="otp"
                                            component="div"
                                        />
                                    </div>
                                    <div>
                                        <TabList
                                            className="tab-title"
                                            style={{
                                                flex: 1,
                                                flexDirection: "column",
                                            }}
                                        >
                                            {removeVerifyPassword ===
                                            true ? null : (
                                                <Tab
                                                    direction={"column"}
                                                    style={{
                                                        paddingRight: 10,
                                                        alignItems: "stretch",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <u
                                                        className="text-primary"
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                {
                                                                    pathname:
                                                                        "/ChangeEmail",
                                                                    search: `id=${this.state.number}`,
                                                                    state: {
                                                                        verifyMsisdnThruPassword: true,
                                                                        data: this
                                                                            .state
                                                                            .number,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Verify through Password
                                                        ?
                                                    </u>
                                                </Tab>
                                            )}

                                            <Tab
                                                direction={"column"}
                                                style={{
                                                    paddingRight: 10,
                                                    alignItems: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <u
                                                    className="text-primary"
                                                    onClick={() => {
                                                        removeVerifyPassword ===
                                                        true
                                                            ? this.resendMSISDN(
                                                                  this.state
                                                                      .currentNumber
                                                              )
                                                            : this.sendTransactionFunction(
                                                                  this.state
                                                                      .number
                                                              );
                                                    }}
                                                >
                                                    Resend OTP ?
                                                </u>
                                            </Tab>
                                        </TabList>
                                    </div>
                                    <div className="container-login100-form-btn">
                                        {this.state.loading ? (
                                            <div
                                                style={{
                                                    zIndex: "100",
                                                }}
                                            >
                                                <LoadingLoader visible={true} />
                                            </div>
                                        ) : (
                                            <button
                                                className="login100-form-btn"
                                                type="submit"
                                            >
                                                Verify
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(VerifyChangeMsisdn);
