import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import axios from "axios"
import { SuperHOC } from "../HOC";
import { Manager } from "../StorageManager/Storage";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import NegativeAlert from "../components/CustomAlert/CustomAlert";

class VerifyChangeEmail extends Component {
    state = {
        token: "",
        errBool: false,
        currentError: "",
        currentEmail: "",
        emailVerified: "",
        errorMessage: "",
        showPositiveAlert: false,
        showNegativeAlert: false,
    };

    componentDidMount() {
        let token = Manager.getItem("token", false).then((res) => {
            this.setState({
                token: res,
            });
        });
    }

    verifyEmailApprovedFunction = (dataField) => {
        this.setState({ loading: true });
        let codeEmail = dataField.email;
        let options = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Basic " + this.state.token,
                "x-client-Id": "bs3et1r4rb2qy9han6wg.pindal.pk",
                "x-client-Key": "bs3et1r4rb2qy9han6x0",
            },
        };

        fetch(
            "http://18.136.106.202/api/verifyChangeEmail/?code=" + codeEmail,
            options
        )
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.message == 7065) {
                    this.setState({
                        loading: false,
                        successLogin: true,
                        showPositiveAlert: true,
                        errorMessage: "Email to Verified",
                    });
                    setTimeout(() => {
                        this.props.history.push("/account");
                    }, 2000);
                    return false;
                }

                if (res.message === 7099) {
                    this.setState({
                        loading: false,
                        faildRegistration: true,
                        showNegativeAlert: true,
                        errorMessage: "Change Email Failed",
                    });
                    return false;
                }

                if (res.message === 7000) {
                    this.setState({
                        loading: false,
                        faildRegistration: true,
                        showNegativeAlert: true,
                        errorMessage: "User not found Try different Email",
                    });
                    return false;
                }
                if (res.message === 7057) {
                    this.setState({
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: "Error in verify email!",
                    });
                    return false;
                }
                if (res.message === 6113) {
                    this.setState({
                        loading: false,
                        faildRegistration: true,
                        showNegativeAlert: true,
                        errorMessage: "Token Expired",
                    });
                    return false;

                } else {
                    this.setState({
                        loading: false,
                        faildRegistration: true,
                        showNegativeAlert: true,
                        errorMessage: res.message,
                    });
                    return false;
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <div>
                <Breadcrumb title={"email approvel"} />
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values) {
                            errors.email = "Please enter code";
                        }
                        if (values.email === "") {
                            errors.email = "Field cannot be empty";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().required(),
                        email: Yup.string().required("Code is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.verifyEmailApprovedFunction(values);

                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Verify Your Email
                                        </span>

                                        <div
                                            className="wrap-input100 validate-input"
                                            style={{
                                                width: "60%",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Field
                                                // type="email"
                                                name="email"
                                                // id="email"
                                                className="input100"
                                                placeholder="Enter Code"
                                                id="defaultRegisterFormEmail"
                                                // value={this.state.currentEmail}
                                                // disabled
                                            />
                                            <span className="focus-input100"></span>
                                            <span className="symbol-input100">
                                                <i className="fa fa-envelope"></i>
                                            </span>

                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="email"
                                                component="p"
                                            />
                                        </div>

                                        <div
                                            className="container-register100-form-btn"
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                alignSelf: "center",
                                                width: "50%",
                                            }}
                                        >
                                            {this.state.loading ? (
                                                <div
                                                    style={{
                                                        // left: 100,
                                                        // top: 100,
                                                        // bottom: 50,
                                                        zIndex: "100",
                                                        // position: "absolute",
                                                    }}
                                                >
                                                    <LoadingLoader
                                                        visible={true}
                                                    />
                                                </div>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className="login100-form-btn"
                                                >
                                                    Verify my Email
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Success"}
                    message={this.state.errorMessage}
                />
                <NegativeAlert
                    error
                    title={"error"}
                    open={this.state.showNegativeAlert}
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(VerifyChangeEmail);
