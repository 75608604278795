import React, { Component } from 'react'
export default class ServicesCart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: false,
      loading: true,
      items: this.props.item
    }
  }

  componentDidMount() {
    this.getSelected()
  }


  getSelected = () => {
    const { itemsJson } = this.state.items
    let filteredItem = itemsJson.filter(item => item.selected === true)
    if (filteredItem.length === itemsJson.length) {
      let items = this.state.items;
      let merchant = items.merchant;
      merchant.selected = true
      items.merchant = merchant;
      this.setState({ items: items, loading: false })
    }
    else {
      let items = this.state.items;
      let merchant = items.merchant;
      merchant.selected = false
      items.merchant = merchant;
      this.setState({ items: items, loading: false })
    }
  }

  handleSelectingSingleItems = (data) => {
    this.props.selectSingleItem({ ...data, itemIndex: data.itemIndex, merchantSelected: this.state.selected }, () => {
      this.getSelected();
    })
  }

  render() {
    const { merchant } = this.props.item
    let itemsJson = this.props.itemJson

    return (
      <tbody>
        <tr style={{ backgroundColor: '#f9f9f9f9' }}>
          <th colSpan={1}>
            <input type="checkbox" size='large'
              onClick={() => this.props.selectCartMerchantServices(this.props.item)}
              // defaultChecked={this.state.selected}
              checked={merchant.selected}
              disabled={this.props.disabled}
            />
          </th>
          <th colSpan={8}>{merchant.merchantName} ({merchant.originAddress.cityName})</th>
        </tr>
        {
          itemsJson.map((item, index) => {
            var enc = window.btoa(item.productId);
            let removePerc = item.productName ? item.productName.split('%').join(' ') : ' ';
            let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
            let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';

            return (
              <tr key={index}>
                <td className='table-check-box'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderWidth: 3, borderColor: 'black' }}>
                    <input type="checkbox" size='large'
                      onClick={() => this.handleSelectingSingleItems({ ...item, itemIndex: index })}
                      checked={item.selected}
                      defaultChecked={item.selected}
                    />
                  </div>
                </td>
                <td>
                  <img
                    onClick={() => this.props.history.push('/product/' + convertNameIntoDashes + '?id=' + enc)}
                    src={item.productImage} alt="hello" style={{ height: '100px', width: '200px', margin: '5px' }} />
                </td>
                <td>{item.productName ? item.productName.length > 20 ? item.productName.substring(0, 20) + '...' : item.productName.substring(0, 20) : ''}</td >
                <td>
                  {item.priceAfterDiscount}
                </td>
                <td>
                  <div className="qty-box quantity-box-cart">
                    <div className="input-group">
                      <span className="input-group-prepend">
                        <button type="button" className="btn quantity-left-minus"
                          disabled={(item.quantity === 1) ? true : false}
                          onClick={() => this.props.serviceDecrementQty({ ...item, itemIndex: index })} data-type="minus" data-field="">
                          <i className="fa fa-angle-left"></i>
                        </button >
                      </span >
                      <input type=" text" name=" quantity" value={item.quantity} readOnly={true} className=" form-control input-number" />
                      <span className=" input-group-prepend">
                        <button className="btn quantity-right-plus" onClick={() => this.props.serviceIncrementQty({ ...item, itemIndex: index })} data-type="plus" disabled={(item.quantity >= item.singleCartEntry) ? true : false}>
                          <i className="fa fa-angle-right"></i>
                        </button >
                      </span >
                    </div >
                  </div >
                  {(item.quantity >= item.singleCartEntry) ? 'limit exceeds' : ''}
                </td >
                <td>
                  <div className="icon" onClick={() => this.props.removeServiceItem({ ...item, itemIndex: index })}>
                    <i className="fa fa-times"></i>
                  </div>
                </td>
                <td>RM {item.totalItemPrice}</td >
              </tr>
            )
          })
        }
      </tbody >
    )
  }
}
