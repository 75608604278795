import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SuccessAlert from "../CustomAlert/CustomAlert";
import ErrorAlert from "../CustomAlert/CustomAlert";
const Link = require("react-router-dom").Link;

class AddDeliveryAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            postalCode: "",
            allStates: [],
            allCities: [],
            selectedCityId: "",
            SelectedStateId: "",
            SelectedStateName: "",
            selectedProvince: "",
            main: "",
            showSuccess: false,
            showError: false,
            errorMessage: "",
            loading: true,
            phoneNum: "60",
        };
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.changePostalCode = this.changePostalCode.bind(this);
        this.changeAddressType = this.changeAddressType.bind(this);
    }

    getProvince = () => {
        this.props.apiManager.makeCall("get_all_provinces", {}, (res) => {
            if (res.provinces !== undefined) {
                this.setState({ allStates: res.provinces });
            }
            const arrayProvinces = res.provinces;
            let provinces = [];
            arrayProvinces.forEach((object, index) => {
                var randomData = {};
                // randomData.key = object.provinceName.toString();
                randomData.label = object.provinceName
                    .toLowerCase()
                    .replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {
                        return letter.toUpperCase();
                    });
                randomData.value = JSON.stringify(object);
                randomData.index = object.key;
                provinces.push(randomData);
            });
            this.setState({ allStates: provinces });
        });
    };

    getCity = (id) => {
        if (id !== null) {
            this.props.apiManager.makeCall(
                "get_province_cities",
                { provinceId: id },
                (res) => {
                    if (res.cities !== undefined) {
                        const arrayCities = res.cities;
                        let cities = [];
                        arrayCities.forEach((object, index) => {
                            var data = {};
                            data.label = object.cityName
                                .toLowerCase()
                                .replace(/\b[a-z](?=[a-z]{2})/g, function(
                                    letter
                                ) {
                                    return letter.toUpperCase();
                                });
                            data.value = object.cityId;
                            data.index = object.key;
                            cities.push(data);
                        });
                        this.setState({
                            allCities: cities,
                            selectedCityId: 0,
                        });
                    }
                }
            );
        }
    };

    addAdress = () => {
        if (this.state.phoneNum.length > 9) {
            if (this.state.phoneNum[0] === "6") {
                let body = {
                    address: this.state.address,
                    postalCode: this.state.postalCode,
                    cityName: this.state.selectedCityId,
                    provinceName: this.state.SelectedStateName,
                    provinceId: this.state.SelectedStateId,
                    main: this.state.main,
                    lat: "2",
                    lng: "2",
                    phone: parseInt(this.state.phoneNum),
                    countryCode: "60",
                };
                
                this.props.apiManager.makeCall(
                    "add_user_address",
                    body,
                    (res) => {
                        
                        if (res.message === "1024") {
                            this.setState({
                                showSuccess: true,
                                errorMessage: res.decodedMessage,
                            });
                        } else {
                            this.setState({
                                showError: true,
                                errorMessage: res.decodedMessage,
                            });
                        }
                    }
                );
            } else {
                this.setState({
                    showError: true,
                    errorMessage:
                        "Please add country code in your mobile number",
                });
            }
        } else {
            this.setState({
                showError: true,
                errorMessage: "Please Enter Complete number (60xxxxxxxxx)",
            });
        }
    };

    handleChangeAddress(event) {
        this.setState({ address: event.target.value });
    }
    changePostalCode(event) {
        if (event.target.value.length <= 5)
            this.setState({ postalCode: event.target.value });
    }

    changeAddressType(event) {
        this.setState({ main: event.target.value });
    }

    componentDidMount() {
        this.getProvince();
    }
    changePhoneNum = (event) => {
        this.setState({ phoneNum: event.target.value });
    };
    thirdMethod(e) {
        const re = /[0-9]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }

    render() {
        return (
            <div>
                <Formik
                    initialValues={{
                        address: "",
                        state: "",
                        city: "",
                        postalCode: "",
                        addressType: "",
                        phoneNum: "60",
                    }}
                    validate={(values) => {
                        let errors = {};
                        Object.keys(values).map((key) => {
                            if (!values[key]) errors[key] = "Required";
                        });
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        this.props.onSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form
                            class="row justify-content-md-center"
                            style={{ margin: 5 }}
                        >
                            <div className="col-lg-13">
                                <div
                                    class="row justify-content-md-center"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <h3
                                        style={{
                                            marginTop: 0,
                                            color: "black",
                                            marginBottom:0
                                        }}
                                    >
                                        Add Delivery 
                                        <br />
                                        <br />
                                    </h3>
                                </div>
                                <div
                                    class="form-row mb-4"
                                    style={{ height: "11%" }}
                                >
                                    {/* <label>Address</label> */}
                                    <Field
                                        style={{ height: "100%" }}
                                        type="text"
                                        name="address"
                                        id="defaultRegisterFormFirstName"
                                        class="form-control"
                                        placeholder="Enter Your Delivery Address"
                                        value={this.state.address}
                                        onChange={this.handleChangeAddress}
                                    />
                                    <ErrorMessage
                                        id="error"
                                        name="address"
                                        component="div"
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        marginTop:-10,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {/* <label style={{ marginRight: 10 }}> <b>Select Your Province </b></label> */}

                                    <select
                                        id="state"
                                        name="state"
                                        class="form-control"
                                        onChange={(obj) => {
                                            let selectedProvince = JSON.parse(
                                                obj.target.value
                                            );
                                            
                                            this.setState(
                                                {
                                                    SelectedStateId:
                                                        selectedProvince.provinceId,
                                                    SelectedStateName:
                                                        selectedProvince.provinceName,
                                                },
                                                () => {
                                                    if (obj != null) {
                                                        this.getCity(
                                                            this.state
                                                                .SelectedStateId
                                                        );
                                                    }
                                                }
                                            );
                                        }}
                                    >
                                        {
                                            <option value="0" abc="lahore">
                                                Choose State
                                            </option>
                                        }
                                        {this.state.allStates.map(
                                            (state, index) => {
                                                return (
                                                    <option
                                                        id="state"
                                                        key={index}
                                                        abc={state.label}
                                                        value={state.value}
                                                    >
                                                        {state.label}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                    <ErrorMessage
                                        id="error"
                                        name="state"
                                        component="div"
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        marginTop:5,
                                        marginBottom: 5,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {/* <label style={{ marginRight: 10 }}> <b>Select Your City </b></label> */}
                                    <select
                                        class="form-control"
                                        id="city"
                                        name="city"
                                        //value={this.state.allCities.filter( city => city.value === this.state.selectedCityId)}
                                        // value={1}
                                        onChange={(obj) => {
                                            setFieldValue(
                                                "city",
                                                obj.target.value
                                            );
                                            this.setState({
                                                selectedCityId:
                                                    obj.target.value,
                                            });
                                        }}
                                    >
                                        {
                                            <option
                                                value=""
                                                label="Choose City"
                                                selected
                                            />
                                        }
                                        {this.state.allCities.map(
                                            (state, index) => {
                                                return (
                                                    <option
                                                        classname="capitalize"
                                                        id={state.value}
                                                        value={state.label}
                                                    >
                                                        {state.label}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                    <ErrorMessage
                                        id="error"
                                        name="city"
                                        component="div"
                                    />
                                </div>

                                <div
                                    class="form-row mb-4 "
                                    style={{ marginBottom: 0, height: "11%" }}
                                >
                                    {/* <label>Postal Code</label> */}
                                    <input
                                        style={{ height: "100%" }}
                                        // type="number"
                                        name="postalCode"
                                        id="postalCode"
                                        class="form-control mb-4"
                                        placeholder="Enter Your Postal Code"
                                        value={this.state.postalCode}
                                        onChange={this.changePostalCode}
                                    />

                                    <ErrorMessage
                                        id="error"
                                        name="postalCode"
                                        component="div"
                                    />
                                </div>
                                <div
                                    class="form-row mb-4"
                                    style={{ height: "11%" ,marginTop:-10}}
                                >
                                    {/* <label>Postal Code</label> */}
                                    <input
                                        style={{ height: "100%" }}
                                        name="phoneNum"
                                        id="phoneNum"
                                        class="form-control mb-4"
                                        placeholder="Enter Your Phone Number(60xxxxxxxxxx)"
                                        value={this.state.phoneNum}
                                        onChange={this.changePhoneNum}
                                        maxLength="12"
                                        // max='12'
                                        onKeyPress={(e) => this.thirdMethod(e)}
                                        type="tel"
                                        pattern="[0-9]*"
                                        required
                                    />
                                    <ErrorMessage
                                        id="error"
                                        name="phoneNum"
                                        component="div"
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: 2,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {/* <label style={{ marginRight: 10 }}> <b>Select Address Type </b></label> */}
                                    <select
                                        class="form-control"
                                        value={this.state.main}
                                        onChange={this.changeAddressType}
                                        id="addressType"
                                        name="addressType"
                                    >
                                        {
                                            <option
                                                label="Select Type"
                                                selected
                                            />
                                        }
                                        <option id="addressType" value="true">
                                            {" "}
                                            Primary{" "}
                                        </option>
                                        <option id="addressType" value="false">
                                            {" "}
                                            Secondary{" "}
                                        </option>
                                    </select>
                                    <ErrorMessage
                                        id="error"
                                        name="addressType"
                                        component="div"
                                    />
                                </div>
                                <br />

                                <div
                                    className="col-md-12"
                                    style={{
                                      marginTop:-10,
                                      marginBottom:10,
                                        display: "flex",
                                        alignContent: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <button
                                        className="btn btn-solid"
                                        type="button"
                                        onClick={this.addAdress}
                                    >
                                        Add Delivery Address
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <SuccessAlert
                    onClose={() =>
                        this.setState(
                            { showSuccess: false },
                            () => (window.location.href = "/account")
                        )
                    }
                    open={this.state.showSuccess}
                    message={this.state.errorMessage}
                />
                <ErrorAlert
                    error
                    onClose={() => this.setState({ showError: false })}
                    open={this.state.showError}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default AddDeliveryAddress;