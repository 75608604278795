import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import classes from './style.module.css'
import SuccessAlert from '../CustomAlert/CustomAlert'
import ErrorAlert from '../CustomAlert/CustomAlert'

export default class NegotiationRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 1,
      product: {},
      isVisile: false,
      negotiation_status: null,
      slected: false,
      options: [],
      index: null,
      showSuccess: false,
      showError: false,
      errorMessage: '',
      info: {
        maxNego: 0,
        price: 0,
        unit: ''
      }
    }
  }


  getProductDetail = () => {
    let slug = this.props.match.params.product
    let productPrice = JSON.parse(localStorage.getItem('price'))
    let body = {
      slug
    }

    this.props.apiManager.makeCall('get_product_detail', body, (res) => {
      if (res.message === '1171') {
        this.setState({
          product: res.product,

          info: {
            maxNego: res.product.maximumNegotiation,
            price: productPrice ? productPrice : res.product.specifications[0].price[0].figure,
            unit: res.product.specifications[0].price[0].unit
          }
        }, () => {
          //setting array for price negotiation items
          let list = [];
          for (let i = this.state.info.maxNego; i > 0; i = i - 0.5) {
            let value = (i / 100) * this.state.info.price;
            list.push({
              percent: i,
              unit: this.state.info.unit,
              price: this.state.info.price - value.toFixed(2)
            });
          }
          this.setState({ options: list })
        });
      }
    })
  }

  negotiatePrice = () => {
    let { slug } = this.state.product
    let body = {
      slug: slug,
      negotiationPrice: this.state.options[this.state.index].percent,
      quantity: this.state.quantity
    };
    this.props.apiManager.makeCall('negotiate_price', body, (res) => {
      if (res.message === '1343') {
        this.setState({ showSuccess: true, errorMessage: res.decodedMessage })
      }
      else {
        this.setState({ showError: true, errorMessage: res.decodedMessage })
      }
    })
  }

  componentDidMount() {
    this.getProductDetail();
  }


  renderTop = () => {
    let { name, colors, specifications } = this.state.product
    let asset = colors && colors[0].icon && colors && colors[0].icon.assets && colors[0].icon.assets[0].src
    let priceUnit = specifications && specifications[0].price && specifications[0].price[0].unit
    let productPrice = JSON.parse(localStorage.getItem('price'))
    let amount = productPrice ? productPrice : specifications && specifications[0].price && specifications[0].price[0].figure

    return (
      <div className={classes.topContainer}>
        <div className={classes.productContainer}>
          <div className={classes.productImage}>
            <img src={asset} alt="Nothing Found" className={classes.image} />
          </div>
          <div className={classes.productTitle}>
            <h1 className={classes.title}>{name}</h1>
            <h1 className={classes.title}>{priceUnit} {amount}</h1>
          </div>
        </div>
      </div>
    )
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({ quantity: e.target.value })
  }

  renderActionContainer = () => {
    return (
      <div className={classes.actionContainer}>
        <div className={classes.acceptButton}>
          <button>
            Accept
          </button>
        </div>
        <div className={classes.declineButton}>
          <button>
            Decline
          </button>
        </div>
        <div className={classes.quantityContainer}>
          <p>Quantity:</p>
          <input
            className={classes.input}
            type="text"
            placeholder="add quantity"
            size='large'
            defaultValue={this.state.quantity}
            onChange={(text) => this.handleChange(text)}
          />
        </div>
      </div>
    )
  }



  renderPercentageContainer = () => {
    return (
      <div className={classes.percentageContainer}>
        <div className={classes.heading}>
          <div>
            <h1>NEGOTIATE YOUR PRICE</h1>
          </div>
          <div className={classes.quantityContainer}>
            <p>Quantity:</p>
            <input
              className={classes.input}
              type='number'
              placeholder="Add Quantity"
              size='large'
              min="1"
              defaultValue={this.state.quantity}
              onChange={(text) => this.handleChange(text)}
            />
          </div>
        </div>
        {
          this.state.options.map((item, index) => {
            return <div key={index} onClick={() => this.setState({ index: index })} style={{ display: 'flex', cursor: 'pointer' }} >
              <p className={!(this.state.index === index) ? classes.unselect : classes.select}>
                {item.percent}% {item.unit} : {item.price}
              </p>
            </div>
          })
        }
      </div>
    )
  }

  renderFooter = () => {
    if (this.state.index === null) return;
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="btn btn-solid" style={{ width: '18%', justifyContent: 'center', margin: 10 }}
          // className={classes.footer}
          onClick={this.negotiatePrice}
        >
          Send
      </div>
      </div>
    )
  }


  render() {

    return (
      <div style={{ display: 'flex', minHeight: '600px', backgroundColor: '#f5f5f5' }}>
        <Container style={{ margin: 50 }}>
          <div style={{ marginBottom: '30px', paddingTop: '30px' }}>
            {this.renderTop()}
            {/* {this.renderActionContainer()} */}
            {this.renderPercentageContainer()}
            {this.renderFooter()}
          </div>
        </Container>
        <SuccessAlert
          onClose={() => this.setState({ showSuccess: false })}
          open={this.state.showSuccess}
          message={this.state.errorMessage}
        />
        <ErrorAlert
          error
          onClose={() => this.setState({ showError: false })}
          open={this.state.showError}
          message={this.state.errorMessage}
        />
      </div>
    )
  }
}

