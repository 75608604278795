import React, { Component } from 'react'
import { SuperHOC } from '../HOC';
import Breadcrumb from '../components/common/breadcrumb';
import { formatDate } from '../config/HelperFunction'
import Loader from './../components/Loader/Loader'

class Complains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: '',
      pages: '',
      complains: [],
      selectedComplain: {},
      loading: true,
      previous: ''
    }
  }

  componentDidMount = () => {
    this.getComplains();
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')
    if (previous[3] !== same[2]) {
      this.setState({ previous: previous[3] })
    }
  }

  getComplains = () => {
    let today = Math.round((new Date()).getTime() / 1000);
    let date = new Date();
    date.setDate(date.getDate() - 10);
    let lastDate = date / 1000 | 0

    let body = {
      seller: false,
    }
    this.props.apiManager.makeCall('get_complains', body, res => {
      if (res.result) {
        this.setState({ pages: res.result.pages})
        let { page, pages, docs } = res.result;
        if (page <= pages) {
          this.setState({
            complains: this.state.complains.concat(docs),
            page: page + 1,
            loading: false
          }, () => (page === pages) ? this.setState({ end: true }) : null);
        } else {
          this.setState({
            end: true,
            loading: false
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }

  render() {
    const CMPScreen = {
      pathname: `/ComplainSingle/${this.state.selectedComplain.transactionSlug}`,
      state: {
        complainId: this.state.selectedComplain.id,
      }
    }
    if (this.state.loading)
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '70vh' }}>
          <Loader
            visible={this.state.loading}
          />
        </div>
      )

    return (
      this.state.complains.length !== 0
        ?
        <div class="row">
          <div className="col-sm-12">
            <Breadcrumb title={'Complains'} parent={this.state.previous} />
            <table className="table table-responsive-xs">
              <thead style={{ textAlign: "center" }}>
                <tr className="table-head">
                  <th scope="col">Complain Topic</th>
                  {/* <th scope="col">Sender</th> */}
                  <th scope="col">Created At</th>
                  <th scope="col">Merchant</th>
                  <th scope="col">Status</th>
                  <th scope="col">Complain Details</th>
                </tr>
              </thead>
              {this.state.complains.map((item, index) => {
                return (
                  <tbody style={{ textAlign: "center" }}>
                    <tr>
                      <td>{item.complainTopic}</td>
                      {/* <td>{item.userEmail}</td> */}
                      <td>{formatDate(new Date(item.createdAt))}</td>
                      <td>{item.merchantEmail}</td>
                      <td>
                        {item.state === 0 ? 'New Complain'
                          : item.state === 1 ? 'In Converstaion'
                            : item.state === 2 ? 'Refund'
                              : item.state === 3 ? 'Resolved'
                                : null}
                      </td>
                      <td>
                        {(item.state === 3) || (item.state === 2) ?
                          <button className="btn btn-solid"> NO ACTION NEEDED </button>
                          :
                          <button className="btn btn-solid"
                            onMouseOver={() => this.setState({ selectedComplain: item })}
                            onClick={() => this.props.history.push(CMPScreen)}
                          >
                            Check Details
                          </button>
                        }
                      </td>
                    </tr>
                  </tbody>
                )
              }
              )}
            </table>
            {
              (this.state.page <= this.state.pages) ?
                <div
                  className="btn btn-solid"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 50, width: 150 }}
                  onClick={() => this.getComplains()}
                >
                  Show More
            </div> : <div />}
          </div>
          <br />
        </div> :

        <section className="cart-section section-b-space">
          <Breadcrumb title={'Complains'} parent={this.state.previous} />
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div >
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/orderhistory.png`} style={{ color: "grey" }} className="img-fluid mb-4" alt="" />
                    <h3>
                      <strong>No Complain is Present at the Moment</strong>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

export default SuperHOC(Complains);