import React, { Component } from 'react';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import LoadingLoader from '../Loader/Loader'
import SideImageItem from "../layouts/common/side-image-item"

class MerchantProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      end: false,
      loading: true,
    }
  }

  componentDidMount = () => {
    this.getProducts();
  }

  onApiResponse = (res) => {
  }

  getProducts = () => {
    let body = {
      merchant: this.props.email
    }

    this.props.apiManager.makeCall(this.props.api, body, res => {
      console.warn('merchant product -', res)
      if (res.result) {
        let { page, pages, docs } = res.result;
        if (page <= pages) {
          this.setState({
            data: this.state.data.concat(docs.slice(0, 8)),
            page,
            pages,
            loading: false
          }, () => (page === pages) ? this.setState({ end: true }) : null);
        } else {
          this.setState({
            end: true,
            loading: false
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }

  renderEmptyProduct = () => {
    return (
      <div style={{ width: '100%', display: 'flex', alignSelf: 'center', minHeight: '60vh' }}>
        <div className="col-sm-12 text-center section-b-space mt-5 no-found"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt='' />
          <h3>Sorry! Couldn't Find The Item You Were Looking For!!!    </h3>
          <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
        </div>
      </div >
    )
  }

  render() {
    const { symbol } = this.props;
    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}><LoadingLoader visible={this.state.loading} /></div>
    if (this.state.data.length === 0) return this.renderEmptyProduct()
    return (
      <div>
        <section className="p-0">
          <div className="tab-bg">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  {
                    this.props.title ? <div className="title4">
                      <h2 className="title-inner4">{this.props.title}</h2>
                      <div className="line"><span></span></div>
                    </div> : null
                  }
                  <Tabs className="theme-tab">
                    <Tab></Tab>
                    <div className="tab-content-cls">
                      <TabPanel className="tab-content">
                        <div className="row product-tab">
                          {this.state.data.map((item, i) =>
                            <div className="tab-box" key={i}>
                              <SideImageItem product={item} symbol={symbol}
                                {...this.props}
                              />
                            </div>
                          )}
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
            <div onClick={this.props.viewMoreProducts}>
              <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 0, cursor: 'pointer' }}>
                <h6 style={{ alignSelf: 'center', alignItems: 'center', paddingTop: 9, marginRight: 5 }}>
                  SHOW MORE
                </h6>
                <FontAwesomeIcon icon={faChevronRight} size="1x" color='grey' />
              </div>
            </div>
          </div>
        </section>
      </div >
    )
  }
}
export default MerchantProducts;