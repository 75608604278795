import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Breadcrumb from "../components/common/breadcrumb";
import LoadingLoader from "../components/Loader/Loader";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import { SuperHOC } from "../HOC";

class VerifySignupMsisdn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hash: "",
            msisdn: "",
            previous: "",
            showPositiveAlert: false,
            showAlert: false,
            counter: 120,
            access_token: "",
            loginVerification: false,
            sendTac: true,
            resend_hash: "",
            isLogin: true,
            apiName: "",
        };
    }

    componentDidMount() {
        let number = this.props.location.state.msisdn;
        let hash = this.props.location.state.token;
        this.setState({
            hash: hash,
            msisdn: number,
        });
    }

    sendRegisterOTP = (values) => {
        this.setState({ loading: true });

        let body = {
            MSISDN: values,
        };

        this.props.apiManager.makeCall("sendRegisterOtp", body, (res) => {
            if (res.message === 6107) {
                this.setState({
                    showAlert: true,
                    loading: false,
                    errorMessage:
                        "OTP Limit Exceeded! Please wait for 10 minutes",
                });
            }
            if (res.message === 7056) {
                this.setState({
                    showErrorAlert: true,
                    loading: false,
                    errorMessage: "MSISDN is Already Taken",
                });
            }

            if (res.message === 7031) {
                // this.gotToVerify(res.token, body.MSISDN);
                this.setState({
                    loading: false,
                    counter:120,
                    apiName: res.apiName,
                    resend_hash: res.token,
                    showPositiveAlert: true,
                    errorMessage: `OTP Resend Successfully on ${body.MSISDN}.`,
                });
                setTimeout(() => {
                    this.setState({
                        showPositiveAlert: false,
                    });
                }, 2000);
            } else if (res.message === 7026) {
                this.setState({
                    loading: false,
                    showModal: true,
                    errorMessage: "MSISDN NUMBER REQUIRED",
                });
            }
        });
    };

    verifyRegisterOTP = (body) => {
        let navigateFromSignup = this.props.location.state.navigateFromSignup;

        this.setState({ loading: true });

        if (navigateFromSignup === true) {
            let payload = {
                hash: this.props.location.state.token,
                otp: body.tac,
            };

            let pBody = {
                hash: payload.hash,
                otp: payload.otp,
            };

            this.props.apiManager.makeCall(
                "verifyRegisterOTP",
                payload,
                (res) => {
                    if (res.message === 7058) {
                        this.setState({
                            showPositiveAlert: true,
                            loading: false,
                            errorMessage: "Continue...",
                        });
                        setTimeout(() => {
                            this.props.history.push({
                                pathname: `/UserDetailsForRegister`,
                                state: {
                                    payload: payload,
                                    msisdn: this.state.msisdn,
                                },
                            });
                        }, 1000);
                    } else if (res.message === 7039) {
                        this.setState({
                            showAlert: true,
                            loading: false,
                            errorMessage: "Invalid OTP Please Try again",
                        });
                    } else if (res.message === 6113) {
                        this.setState({
                            showAlert: true,
                            loading: false,
                            errorMessage: "Expired OTP",
                        });
                    }
                    //  else {
                    //     this.setState({
                    //         showPositiveAlert: true,
                    //         loading: false,
                    //         errorMessage: "Continue...",
                    //     });
                    // }
                }
            );
        }
        if (this.state.apiName === "sendRegisterOtp") {
            let payload = {
                hash: this.state.resend_hash,
                otp: body.tac,
            };

            let pBody = {
                hash: payload.hash,
                otp: payload.otp,
            };
            
            this.props.apiManager.makeCall(
                "verifyRegisterOTP",
                payload,
                (res) => {
                    if (res.message === 7058) {
                        this.setState({
                            showPositiveAlert: true,
                            loading: false,
                            errorMessage: "Continue...",
                        });
                        setTimeout(() => {
                            this.props.history.push({
                                pathname: `/UserDetailsForRegister`,
                                state: {
                                    payload: payload,
                                    msisdn: this.state.msisdn,
                                },
                            });
                        }, 1000);
                    } else if (res.message === 7039) {
                        this.setState({
                            showAlert: true,
                            loading: false,
                            errorMessage: "Invalid OTP Please Try again",
                        });
                    } else if (res.message === 6113) {
                        this.setState({
                            showAlert: true,
                            loading: false,
                            errorMessage: "Expired OTP",
                        });
                    }
                    //  else {
                    //     this.setState({
                    //         showPositiveAlert: true,
                    //         loading: false,
                    //         errorMessage: "Continue...",
                    //     });
                    // }
                }
            );
        }
    };

    counter = () => {
        var x = this;
        var { counter } = this.state;
        setTimeout(function() {
            if (counter > 0) {
                x.setState({ counter: counter - 1 });
            }
        }, 1000);
        if (counter > 0) {
            return (
                <div style={{ color: "red" }}>
                    TAC Will Expire in: {counter}
                </div>
            );
        } else {
            return (
                <div>
                    <u
                        className="text-primary cursor-p"
                        onClick={() =>
                            this.sendRegisterOTP(
                                this.props.location.state.msisdn
                            )
                        }
                    >
                        Resend?
                    </u>
                </div>
            );
        }
    };

    render() {
        return (
            <div>
                <Breadcrumb title={"Verify Account"} />
                <Formik
                    initialValues={{ tac: "" }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.tac) {
                            errors.oldPassword = "Please enter TAC";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        // phone: Yup.string()
                        //             .required('Phone Number is required')
                        tac: Yup.string()
                            .min(6, "Add Atleast 6 number")
                            .max(
                                6,
                                "Not valid number, you can add 6 number at max"
                            )
                            .matches(
                                /^(\+?\d{1,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                                "Phone number is not valid"
                            )
                            .required("TAC field required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.verifyRegisterOTP(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue, handleChange }) => (
                        <div className="container-login100">
                            <div className="wrap-login100 modal-content quick-view-modal">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        Verify Signup Msisdn
                                    </span>
                                    <p>
                                        <i>
                                            TAC sent to your Mobile Number.
                                            Please key-in to verify account.
                                        </i>
                                    </p>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="text"
                                            name="msisdn"
                                            id="defaultRegisterFormMsisdn"
                                            className="input100"
                                            placeholder="TAC"
                                            value={
                                                this.props.location.state.msisdn
                                            }
                                            disabled
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-phone"></i>
                                        </span>
                                    </div>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="password"
                                            name="tac"
                                            id="defaultRegisterFormTac"
                                            className="input100"
                                            placeholder="TAC"
                                            onChange={handleChange}
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="tac"
                                            component="div"
                                        />
                                    </div>

                                    <div className="container-login99-form-btn pt-2 w-50 ml-auto">
                                        {this.counter()}
                                    </div>
                                    <div className="container-login100-form-btn pt-2 w-50 ml-auto">
                                        {this.state.loading ? (
                                            <div
                                                style={{
                                                    // left: 100,
                                                    // top: 100,
                                                    // bottom: 50,
                                                    zIndex: "100",
                                                    // position: "absolute",
                                                }}
                                            >
                                                <LoadingLoader visible={true} />
                                            </div>
                                        ) : (
                                            <button
                                                className="login100-form-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Verify Now
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>

                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showAlert: false })}
                    open={this.state.showAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(VerifySignupMsisdn);
