import React, { Component } from "react";
import ChangePassword from "../components/Login/ChangePassword";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import NegativeAlert from "../components/CustomAlert/CustomAlert";
import { SuperHOC } from "../HOC";
import Breadcrumb from "../components/common/breadcrumb";

class ChangePasswordScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previous: "",
            showPositiveAlert: false,
            showNegativeAlert: false,
        };
    }

    componentDidMount = () => {
        let previous = document.referrer.split("/");
        let same = this.props.location.pathname.split("/");
        if (previous[3] !== same[2]) {
            this.setState({ previous: previous[3] });
        }
    };

    changepassword = (newPassword, oldPassword) => {
        let body = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        this.props.apiManager.makeCall("resetPassword", body, (res) => {
            var erro, tempError;
            if (res.errors !== undefined) {
                erro = res.errors.map((err) => {
                    tempError = err.msg;
                    return err.msg;
                });
                if (erro) {
                    this.setState({
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: tempError,
                    });
                }
            }

            if (res.message === 7037) {
                this.setState({
                    showPositiveAlert: true,
                    errorMessage: "Successfully Password Updated",
                });
                setTimeout(() => {
                    this.onComplete();
                }, 2000);
            } else if (res.message === 7013) {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: "Old Password is incorrect",
                });
            } else if (res.message === 7044) {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: "Old and new Passwords Cannot be same",
                });
            } else {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: erro ? erro[0] : "Error in updated Password",
                });
            }
        });
    };

    onComplete = () => {
        this.props.history.push("/account");
        window.location.reload(true);
        // Manager.removeItem("token");
        // Manager.removeItem("user");
        // this.setState({ showPositiveAlert: false });
        // window.location.href = "/login";
    };

    render() {
        return (
            <div
                style={{
                    width: "100%",
                    minHeight: "600px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Breadcrumb
                    title={"Change Password"}
                    parent={this.state.previous}
                />
                <ChangePassword
                    onSubmit={({ newPassword, oldPassword }) =>
                        this.changepassword(newPassword, oldPassword)
                    }
                />
                <PositiveAlert
                    open={this.state.showPositiveAlert}
                    // onClose={this.onComplete}
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    message={this.state.errorMessage}
                />
                <NegativeAlert
                    error
                    title={"error"}
                    open={this.state.showNegativeAlert}
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default SuperHOC(ChangePasswordScreen);
