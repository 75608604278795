import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

class NavBar2 extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      navClose: { right: '0px' },
      menu: [],
      showCategoryMenu :true
    }
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: '-410px' } })
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: '-300px' } })
    }
  }
  componentDidMount() {
    this.initializeMenu();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  initializeMenu = () => {
    this.props.apiManager.makeCall('get_all_categories', {}, res => {
      if (res.message) {
        this.setState({ menu: res.categories })
      }
    })
  }
  
  handleMegaSubmenu = (category) => {
    console.log('handleMegaSubmenu', category)

    let t = 'get_latest_products/' + category;
    let tp = window.btoa(t)
    let decodedPath = window.btoa('get_latest_products')
    let path = {
      pathname: `/product-listing/${'3' + tp+'?path='+decodedPath+'?key='+category}`,
      state: {
        apiName: 'get_latest_products',
        title: 'Products',
        category: category,
      }
    }
    this.closeNav();
    if (this.props.location.pathname.split( '/' )[1] === 'product-listing') {
      this.props.history.replace(path);
      window.location.reload();
    } else
      this.props.history.push(path);
  }

  openCategory = (item, name) => {
    // console.log('aaa bawa', name)
    this.closeNav();
    var replaced = item.name[0].value.split(' ').join('-');
    const CategoryProduct = {
      pathname: name === 'Products' ? `/CategoryProduct/${replaced}` : `/CategoryService/${replaced}`,
      state: {
        slug: item.slug,
        name: item.name[0].value,
      }
    }
    this.props.history.push(CategoryProduct)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ navClose: { right: '-410px' } })
    }
  }

  openNav() {
    this.setState({ navClose: { right: '0px' } })
  }
  closeNav() {
    this.setState({ navClose: { right: '-410px' } })
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className='hellofrom2'>
        <div className="main-navbar">
          <div id="mainnav" >
            <div className="toggle-nav" style={{padding:'0'}} onClick={this.openNav.bind(this)} >
              <i className="fa fa-bars sidebar-bar"></i>
            </div>
            <ul className="nav-menu smallScreenNavPadding" style={this.state.navClose}>
              <li className="back-btn" onClick={this.closeNav.bind(this)} >
                <div className="mobile-back text-right">
                  <span >Back</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              <li onClick={this.closeNav.bind(this)}>
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li onClick={this.closeNav.bind(this)}>
                <Link to={`${process.env.PUBLIC_URL}/wishList`} className="nav-link">Wishlist</Link>
              </li>
              <li onClick={this.closeNav.bind(this)}>
                { this.props.apiManager.token ?
                    <Link to={`${process.env.PUBLIC_URL}/account`}>My Account</Link>
                  :
                  <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login</Link>
                }
              </li>
              <li onClick={this.closeNav.bind(this)}>
                <Link to={`${process.env.PUBLIC_URL}/chatScreen`} className="nav-link">Chat</Link>
              </li>
              <li onClick={this.closeNav.bind(this)}>
                <Link to={`${process.env.PUBLIC_URL}/latest-product-listing`} className="nav-link">New Arrivals</Link>
              </li>
              <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} onClick={()=>{this.setState({showCategoryMenu : !this.state.showCategoryMenu})}}>
                <a className="nav-link" style={{width:'fit-content',paddingRight:'0'}}>Shop</a>
                {this.state.showCategoryMenu ? <MdKeyboardArrowDown size={25}/> : <MdKeyboardArrowUp size={25} /> }
              </li>
              
            {this.state.menu && this.state.showCategoryMenu ?
              this.state.menu.map((item, index) => {
                // console.log("menuu", item)
                return (
                  <li key={index}>
                    <div style={{ padding: '10px 50px 20px 40px', color:"#222222", textTransform: 'uppercase', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.handleMegaSubmenu(item.slug)}>
                      {item.name[0].value}
                    </div>
                  </li>
                )
              }) : ''}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}


export default NavBar2;