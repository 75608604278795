import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Manager } from '../../../../StorageManager/Storage';
class TopBarWhite extends Component {

  handleLogOut = () => {
    Manager.removeItem('token')
    Manager.removeItem('user')
    window.location.reload()
  }


  componentDidMount = () => {
    // this.mobile()
  };

  mobile = () => {
    if (window.innerWidth > 576) {
      document.getElementById('merchant-list').style.opacity = 1;
    }
    else {
      document.getElementById('merchant-list').style.opacity = 0;
    }
  }


  showTextOnMobile = () => {
    if (window.innerWidth > 576) {
      return false
    }
    else {
      return true
    }
  }


  render() {
    return (
      <div>
        <div className="top-header bg-dark border-bottom-grey">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="header-contact">
                  <ul>
                    <li style={{}}>Black Market</li>
                    <li><i className="fa fa-envelope-o fa-md text-white" aria-hidden="true"></i>Email us:  enq_blackmarket@xox.com.my</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 text-right header-right">
                <ul className="header-dropdown">
                  {/* <li id='merchant-list'><Link to={`${process.env.PUBLIC_URL}/MerchantList`}><i aria-hidden="true"></i>merchants</Link></li> */}
                  <li id='show-mobile-wish-button' className="mobile-wishlist">
                    <Link to={`${process.env.PUBLIC_URL}/wishList`}>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li id='not-show-mobile-wish-button' className="mobile-wishlist">
                    <a href={`/wishList`}>
                      <i className="fa fa-heart" aria-hidden="true"> wishlist </i>
                    </a>
                  </li>
                  {
                    this.props.apiManager.token ?
                      <li className="onhover-dropdown mobile-account">
                        <Link to={`${process.env.PUBLIC_URL}/account`}>
                          {
                            this.showTextOnMobile() ? <i className="fa fa-user" aria-hidden="true"></i> : <i className="fa fa-user" aria-hidden="true"> My Account </i>
                          }
                        </Link>
                      </li>
                      :
                      <li id='not-show-mobile-wish-button'>
                        <i className="fa fa-user" aria-hidden="true"></i>
                        <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login</Link>
                      </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TopBarWhite
