import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import FreeScrollBar from 'react-free-scrollbar';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class componentName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unseenArray: [],
            seenArray: [],
            listToWatch: [],
            promotionsArray: [],
            index: 0
        };
    }

    componentDidMount() {
        this.props.apiManager.makeCall('get_all_notifications', { seen: false }, (res) => {
            if (res.message) {
                this.setState({
                    unseenArray: res.result.docs
                })
            }
        })
        this.props.apiManager.makeCall('get_all_notifications', { seen: true }, (res) => {
            if (res.message) {
                this.setState({
                    seenArray: res.result.docs,
                    listToWatch: res.result.docs
                })
            }
        })
        this.props.apiManager.makeCall('get_promotions', {}, (res) => {
            if (res.message) {
                this.setState({
                    promotionsArray: res.promotion.docs
                })
            }
        })
    }

    buttonCalled = (e, id) => {
        if (id === 0) {
            this.setState({ listToWatch: this.state.seenArray, index: 0 })
        } else if (id === 1) {
            this.setState({ listToWatch: this.state.unseenArray, index: 1 })
        } else if (id === 2) {
            this.setState({ listToWatch: this.state.promotionsArray, index: 2 })
        }
    }

    handelNotifications = (item) => {
        let body = {
            notificationId: item.id
        }
        this.props.apiManager.makeCall('mark_notification_seen', body, (res) => {
            if (res.message === '1840') {
                switch (item.type) {
                    case 0:
                        return (
                            this.props.history.push(`/NegotiationChat`)
                        )
                    case 1:
                        return (
                            this.props.history.push(`/chatScreen`)
                        )
                    case 3:
                        return (
                            this.props.history.push(`/OrderHistoryScreen`)
                        )
                    case 7:
                        return (
                            this.props.history.push(`/schedule`)
                        )
                    case 8:
                        return (
                            this.props.history.push(`/Complains`)
                        )
                    case 10:
                        return (
                            this.props.history.push(`/walletHistory`)
                        )
                        default : return  null;
                }
            }
        })
    }

    renderNoti = (item) => {
        let timeValues = new Date(item.createdAt);
        let hourSet = timeValues.getUTCHours();
        if (item.promoDiscount) {
            return (
                <div>
                    <div className="channel">
                        <span className="iconsPromo"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                        <p className="textPromo">{item.title} {item.promoDiscount}% Off</p>
                        <br />
                        <p className="textPromo" style={{ color: 'red' }}>{hourSet} hour ago</p>
                    </div>
                    <hr />
                </div>
            )
        }
        else {
            switch (item.type) {
                case 0:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 1:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 2:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 3:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )
                case 4:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 5:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 6:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 7:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 8:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 9:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    {/* {item.userToName.substring(0, 2)} */}
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 10:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    {/* {item.userToName.substring(0, 2)} */}
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )

                case 11:
                    return (
                        <div onClick={() => this.handelNotifications(item)} >
                                <div className="channel">
                                    <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
                                    {/* {item.userToName.substring(0, 2)} */}
                                    <div className="flex-grow-1">
                                        <p className="text"> {item.message}</p>
                                        <p className="text" style={{ color: 'red' }}>{hourSet} hour ago</p>
                                    </div>
                                </div>
                            <hr />
                        </div>
                    )
                    default : return  null;
            }
        }
    }

    render() {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100vh' }}>
                <FreeScrollBar>
                    <Tabs className="theme-tab" >
                        <TabList className="tab-title" >
                            <Tab
                                key={0}
                                onClick={(e) => {
                                    this.buttonCalled(e, 0)
                                }
                                }><h5 style={{ color: this.state.index === 0 ? 'red' : 'black' }}>Seen</h5></Tab>
                            <Tab
                                onClick={(e) => {
                                    this.buttonCalled(e, 1)
                                }

                                }><h5 style={{ color: this.state.index === 1 ? 'red' : 'black' }}>UnSeen</h5></Tab>
                            <Tab
                                onClick={(e) => {
                                    this.buttonCalled(e, 2)
                                }}><h5 style={{ color: this.state.index === 2 ? 'red' : 'black' }}>Promotions</h5></Tab>
                        </TabList>

                        <TabPanel className="tab-content">
                            {this.state.listToWatch.map((item) => {
                                return (
                                    this.renderNoti(item)
                                )
                            })}
                        </TabPanel>
                        <TabPanel className="tab-content">
                            {this.state.listToWatch.map((item) => {
                                return (
                                    this.renderNoti(item)
                                )
                            })}
                        </TabPanel>
                        <TabPanel className="tab-content">
                            {this.state.listToWatch.map((item) => {
                                return (
                                    this.renderNoti(item)
                                )
                            })}
                        </TabPanel>
                    </Tabs>
                </FreeScrollBar>
            </div>
        );
    }
}
