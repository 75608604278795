import React,{useState} from 'react';
import AllNotification from '../Notifications/AllNotification';
import { withRouter } from 'react-router-dom'
//  import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { faEnvelope,faBellSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const List = ({ motion, isFetching, notifications, onChange, ...props }) => {
    const [index, setIndex] = useState(0);
    if (isFetching) {
        return null;
    }
    const content = notifications.map((notification, index) => {
        let timeValues = new Date(notification.createdAt);
            let hourSet = timeValues.getUTCHours();
        if (notification.promoDiscount) {
            return (
                <div >
                    <div className="channel">
                    <span className="iconsPromo"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x"/></span>
                     <p className="textPromo">{notification.title} {notification.promoDiscount}% Off</p>
                     <br/>
                     <p className="textPromo" style={{ color:'red'}}>{hourSet} hour ago</p>                    
                    </div>
                    <hr/>
                </div>
            )
        }
        return (
            <AllNotification
                {...props}
                item={notification} />
        )
    });
        
    const handle=(e,key)=>{
        e.preventDefault();
        e.stopPropagation();
        onChange(key);
        setIndex(key)
    }
    
    return (
        <div className="notification-container">
            <p id="head">Notifications</p>
            <hr/>
            
                <Tabs className="theme-tabs" >
                    <TabList className="tab-titles" >
                        <Tab
                        key={0}
                        onClick={(e) => {
                            handle(e,0)                           
                        }
                        }><h5 style={{color:index===0?'red':'black'}}>Unseen</h5></Tab>
                        <Tab
                            onClick={(e) => {
                                handle(e,1   )                                
                            }
                        }><h5 style={{color:index===1?'red':'black'}}>Seen</h5></Tab>
                        <Tab 
                        onClick={(e) => {
                            handle(e,2)                         
                        }}><h5 style={{color:index===2?'red':'black'}}>Promotions</h5></Tab>
                    </TabList>
                    <hr/>
                    <TabPanel className="tab-content">
                        {content.length > 0 ? content : <div className="nofound">
                                                          <FontAwesomeIcon icon={faBellSlash} color='grey' className="nofoundicon" />
                                                          <p className="nofoundtext">Notification not found</p>
                                                       </div>
                        }
                    </TabPanel>
                    <TabPanel className="tab-content">
                    {content.length > 0 ? content : <div className="nofound">
                                                          <FontAwesomeIcon icon={faBellSlash} color='grey' className="nofoundicon" />
                                                          <p className="nofoundtext">Notification not found</p>
                                                       </div>
                        }                    </TabPanel>
                    <TabPanel className="tab-content">
                    {content.length > 0 ? content : <div className="nofound">
                                                          <FontAwesomeIcon icon={faBellSlash} color='grey' className="nofoundicon" />
                                                          <p className="nofoundtext">Notification not found</p>
                                                       </div>
                        }                    </TabPanel>
                </Tabs>
            
        </div>
    );
}

export default withRouter(List)