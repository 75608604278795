import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import StarRatings from 'react-star-ratings'
import RatingContainer from './RatingContainer'
import ReviewContainer from './ReviewContainer'
import parser from 'html-react-parser'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';


class DetailsTopTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            slug: '',
            rating: 4,
            comment: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    changeRating(newRating, name) {
        this.setState({
            rating: newRating
        });
    }

    handleChange(event) {
        this.setState({ comment: event.target.value });
    }

    render() {
        const { slug, productDetails } = this.props.product
        let product = this.props.product
        let productDetail = productDetails && productDetails[0].details
        let productDetail1 = productDetail.replace('<div>', '') 
        let productDetail2 = productDetail1.replace('Features', '')
        let productDetailFiltered = productDetail2.replace('</div>', '') 
        
        let desc = productDetails && productDetails[0].description && productDetails[0].description.desc

        return (
            <section className="tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material">
                            <TabList className="nav nav-tabs nav-material">
                                <Tab className="nav-item">
                                    <span className="nav-link active">
                                        <i className="icofont icofont-ui-home"></i>Description</span>
                                    <div className="material-border"></div>
                                </Tab>
                                <Tab className="nav-item">
                                    <span className="nav-link" ><i className="icofont icofont-man-in-glasses"></i>Details</span>
                                    <div className="material-border"></div>
                                </Tab>
                                {/* <Tab className="nav-item">
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Video</span>
                                    <div className="material-border"></div>
                                </Tab> */}
                                <Tab className="nav-item">
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Write Review</span>
                                    <div className="material-border"></div>
                                </Tab>
                                {/* <Tab className="nav-item">
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Rating & Reviews</span>
                                    <div className="material-border"></div>
                                </Tab> */}
                            </TabList>
                            <TabPanel className="tab-pane fade mt-4 show active">
                                {desc !== undefined ? parser(desc) : ''}

                                {/* <table class="table table-striped mb-0">
                                    <tbody>
                                        <tr>
                                            <td>Material</td>
                                            <td>Baboo</td>
                                        </tr>
                                        <tr>
                                            <td>Net weight</td>
                                            <td>Approx 0.336 kg</td>
                                        </tr>
                                        <tr>
                                            <td>Dimensions</td>
                                            <td>10 x 1.6 x 10 cm</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>Wood Brown</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h3>Package Includes</h3>
                                
                                <table class="table table-striped mb-0">
                                    <tbody>
                                        <tr>6 x High Quality Antibacterial bamboo Saucers for Coffee & Tea, Bambum Silva Wood Brown - 2454</tr>
                                    </tbody>
                                </table> */}
                            </TabPanel>

                            <TabPanel>
                                <textarea rows="10" disabled style={{ width: '100%', border: 'none', marginLeft: 10 }}
                                    defaultValue={productDetailFiltered} >
                                </textarea>
                                {/* {productDetail} */}
                            </TabPanel>

                            <TabPanel>
                                <form className="theme-form mt-4">
                                    <div className="form-row">
                                        <div className="col-md-12 ">
                                            <div className="media m-0">
                                                <label>Rating</label>
                                                <div className="media-body ml-3">
                                                    <StarRatings
                                                        rating={this.state.rating}
                                                        starRatedColor="Gold"
                                                        changeRating={(newRating) => this.changeRating(newRating)}
                                                        numberOfStars={5}
                                                        // name='rating'
                                                        starDimension="15px"
                                                        starSpacing="3px"
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="review">Review Message</label>
                                            <textarea className="form-control"
                                                placeholder="Write Your Testimonial Here"
                                                onChange={this.handleChange}
                                                value={this.state.comment}
                                                id="exampleFormControlTextarea1"
                                                rows="6"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button
                                                className="btn btn-solid"
                                                {...this.props}
                                                type="button"
                                                // onClick={this.addReview}

                                                onClick={() => this.props.onAddReview(
                                                    slug,
                                                    this.state.rating,
                                                    this.state.comment
                                                )}
                                            >
                                                Submit Your Review</button>
                                        </div>
                                    </div>
                                </form>
                            </TabPanel>

                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-6">
                                        <RatingContainer
                                            product={product}
                                        />
                                    </div>

                                    <div className="col-md-6 ">
                                        <ReviewContainer
                                            product={product}
                                        />
                                    </div>
                                </div>
                            </TabPanel>




                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}

export default DetailsTopTabs;