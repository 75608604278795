import {
  faEye,
  faEyeSlash,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { SlideToggle } from 'react-slide-toggle';
import ToggleButton from 'react-toggle-button';
import { uploadImage } from '../../ApiManager/ImageUploader';
import { SuperHOC } from '../../HOC';
import { decrypt, Manager } from '../../StorageManager/Storage';
import { login, logout } from '../../utils/session';
import Breadcrumb from '../common/breadcrumb';
import CountContext from '../common/headers/common/CountContext';
import PositiveAlert from '../CustomAlert/CustomAlert';
import ChangeAlert from '../CustomAlert/NegativeAlertWithTwoBtn';
import LoadingLoader from '../Loader/Loader';

let verifyy = '';
// Information//
class AccountComponent extends Component {
  static contextType = CountContext;
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      user: {},
      file: null,
      selectedFile: null,
      hidden: true,
      loading: false,
      setMessage: '',
      save: 'save',
      emailToggle: false,
      chatToggle: false,
      pushToggle: false,
      verificationModal: false,
      oldVerificationCode: '',
      newVerificationCode: '',
      showPositiveAlert: false,
      showNegativeAlert: false,
      prefrence: [],
      sameCode: false,
      emptyCode: false,
      showAddressModal: false,
      addressId: null,
      address: '',
      postalCode: '',
      allStates: [],
      allCities: [],
      selectedCityId: '',
      SelectedStateId: '',
      SelectedStateName: '',
      main: '',
      showSuccess: false,
      showError: false,
      errorMessage: '',
      phoneNum: '60',
      token: '',
      currentEmail: '',
      currentNumber: '',
      currentName: 'No',
      currentLastname: 'Name',
      userVerify: '',
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleChangeImage(event) {
    if (event.target.files.length > 0) {
      this.setState({
        file: URL.createObjectURL(event.target.files[0]),
        selectedFile: event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  UpdateImage = (file, _callback) => {
    if (file) {
      uploadImage(file)
        .then((res) => {
          _callback(res);
        })
        .catch((err) => console.error('Upload image catched error: ', err));
    }
  };

  cancelImage = () => {
    this.setState({
      file: this.state.user.image,
      selectedFile: null,
    });
  };

  newGetUserDetails = () => {
    this.props.apiManager.get('getUser', {}, (res) => {      
      if (res.message == 7079) {
        this.setState({
          currentEmail: res.result[0].email,
          currentNumber: res.result[0].MSISDN,
          currentName: res.result[0].firstName,
          currentLastname: res.result[0].lastName,
          userVerify: res.result[0].emailVerified,
        });

        if (res.result[0].emailVerified === 0) {
          this.setState({ showNegativeAlert: false });
        }
        if (res.result[0].emailVerified === 1) {
          this.setState({
            showNegativeAlert: true,
            errorMessage:
              'Your Email is not Verified, \nBefore Performing a Transaction on the Black Market, Please verify your email.',
          });
        }
        verifyy = res.result[0].emailVerified;
      } else {
        this.setState({
          errorMessage: 'User not found Try different Email',
        });
      }
    });
  };

  getToken = () => {
    let token = Manager.getItem('token', false).then((res) => {
      this.setState({
        token: res,
      });
      this.newGetUserDetails();
    });
  };

  getUserDetail = () => {
    let body = {
      email: this.props.email,
    };
    this.props.apiManager.makeCall('get_user_details', body, (res) => {
      if (res.user) {
        this.setState({ user: res.user, file: res.user.image });
      } else {
        console.error('Error while fetching merchant detail ', res.merchant);
      }
    });
  };

  getUserPrefrence = () => {
    this.props.apiManager.makeCall('get_user_prefrence', {}, (res) => {
      if (res.message === '2225') {
        this.setState({ prefrence: res.prefrence.notifications });
      } else {
        console.error('Error while fetching merchant detail ', res.merchant);
      }
    });
  };

  setUserSubPrefrence = (value, subtype, item) => {
    let body = {
      type: item.type,
      enabled: !value,
      subType: subtype,
    };
    this.props.apiManager.makeCall('set_user_prefrence', body, (res) => {
      if (res.message === '2221') {
        this.getUserPrefrence();
      } else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage,
        });
      }
    });
  };
  setUserMainPrefrence = (value, subtype, item) => {
    let body = {
      type: item.type,
      enabled: !value,
    };
    this.props.apiManager.makeCall('set_user_prefrence', body, (res) => {
      if (res.message === '2221') {
        this.getUserPrefrence();
      } else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage,
        });
      }
    });
  };

  deleteAddress = (itemId) => {
    let body = {
      locationSlug: itemId,
    };
    this.props.apiManager.makeCall('remove_user_address', body, (res) => {
      if (res.message === '1024') {
        this.setState(
          {
            showPositiveAlert: true,
            errorMessage: 'Deleted Successfully',
          },
          () => {
            this.getUserDetail();
          }
        );
      } else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage,
        });
      }
    });
  };

  changeCartDeliveryAddress = (item) => {
    let mainObject = this.state.user.locations.find((add) => {
      return add.main === true;
    });
    let body = {
      oldDefault: mainObject._id,
      newDefault: item._id,
    };
    this.props.apiManager.makeCall('change_default_address', body, (res) => {
      if (res.message === '1024') {
        this.setState(
          {
            showPositiveAlert: true,
            errorMessage: 'Delivery Address Changed Successfully',
          },
          () => this.getUserDetail()
        );
      } else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.message,
        });
      }
    });
  };

  getProvince = () => {
    this.props.apiManager.makeCall('get_all_provinces', {}, (res) => {
      if (res.provinces !== undefined) {
        this.setState({ allStates: res.provinces });
      }
      const arrayProvinces = res.provinces;
      let provinces = [];
      arrayProvinces.forEach((object, index) => {
        var randomData = {};
        // randomData.key = object.provinceName.toString();
        randomData.label = object.provinceName
          .toLowerCase()
          .replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {
            return letter.toUpperCase();
          });
        randomData.value = JSON.stringify(object);
        randomData.index = object.key;
        provinces.push(randomData);
      });
      this.setState({ allStates: provinces });
    });
  };

  getCity = (id) => {
    if (id !== null) {
      this.props.apiManager.makeCall(
        'get_province_cities',
        { provinceId: id },
        (res) => {
          if (res.cities !== undefined) {
            const arrayCities = res.cities;
            let cities = [];
            arrayCities.forEach((object, index) => {
              var data = {};
              data.label = object.cityName
                .toLowerCase()
                .replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {
                  return letter.toUpperCase();
                });
              data.value = object.cityId;
              data.index = object.key;
              cities.push(data);
            });
            this.setState({
              allCities: cities,
              selectedCityId: 0,
            });
          }
        }
      );
    }
  };

  componentDidMount = () => {
    this.getProvince();
    this.getToken();
    this.getUserDetail();
    this.getUserPrefrence();
    
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  isMobile = () => {
    if (window.innerWidth < 576) {
      return true;
    } else {
      return false;
    }
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      document.querySelector('.collection-filter').style = 'left: -365px';
    }
  }
  closeFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -365px';
  };

  handleLogOut = async (event) => {
    event.preventDefault();
    Manager.removeItem('token');
    Manager.removeItem('user');
    Manager.removeItem('userStatus');
    Manager.removeItem('userEmail');
    Manager.removeItem('profileIdMdlwr');
    Manager.removeItem('accessTokenMdlwr');

    try {
      await logout();
    } catch (error) {
      console.error('Something went wrong! Error: ', error);
    } finally {
      window.location.href = '/';
    }
  };

  openFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -15px';
  };
  myFunction = () => {
    this.setState({ hidden: false });
  };
  checkCode = (_callback) => {
    if (
      this.state.newVerificationCode === '' &&
      this.state.oldVerificationCode === ''
    ) {
      this.setState({ emptyCode: true });
    } else if (this.state.oldVerificationCode === '') {
      this.setState({ emptyCode: true });
    } else if (this.state.newVerificationCode === '') {
      this.setState({ emptyCode: true });
    } else {
      this.setState({ sameCode: false, emptyCode: false });
      _callback(true);
    }
  };

  changeVerificationCode = () => {
    this.checkCode((res) => {
      let body = {
        verificationCode: this.state.newVerificationCode,
        oldVerificationCode: this.state.oldVerificationCode,
      };
      this.props.apiManager.makeCall(
        'change_verification_code',
        body,
        (res) => {
          if (res.message === '1914') {
            this.setState(
              {
                verificationModal: false,
                oldVerificationCode: '',
                newVerificationCode: '',
                showPositiveAlert: true,
                errorMessage: 'Verification Code Changed',
              },
              () => {
                this.getUserDetail();
              }
            );
          } else {
            this.setState({
              oldVerificationCode: '',
              newVerificationCode: '',
              showNegativeAlert: true,
              errorMessage: res.decodedMessage,
            });
          }
        }
      );
    });
  };

  changePhoneNum = (event) => {
    this.setState({ phoneNum: event.target.value });
  };
  thirdMethod(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  handleChangeAddress = (event) => {
    this.setState({ address: event.target.value });
  };
  changePostalCode = (event) => {
    this.setState({ postalCode: event.target.value });
  };

  changeAddressType = (event) => {
    this.setState({ main: event.target.value });
  };

  showAddressModal = (item) => {
    this.setState({
      addressId: item._id,
      address: item.address,
      phoneNum: item.phone,
      postalCode: item.postalCode,
      SelectedStateName: item.provinceName,
      selectedCityId: item.cityName,
      showAddressModal: true,
    });
  };

  updateAdress = () => {
    let body = {
      addressId: this.state.addressId,
      address: this.state.address,
      postalCode: this.state.postalCode,
      cityName: this.state.selectedCityId,
      provinceName: this.state.SelectedStateName,
      lat: '2',
      lng: '2',
      phone: this.state.phoneNum,
      countryCode: '60',
    };

    this.props.apiManager.makeCall('update_address', body, (res) => {
      if (res.message === '1024') {
        this.setState(
          {
            showPositiveAlert: true,
            errorMessage: res.decodedMessage,
            showAddressModal: false,
          },
          () => this.getUserDetail()
        );
      } else
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage,
          showAddressModal: false,
        });
    });
  };

  verifyEmail = (registerData) => {
    this.setState({
      showNegativeAlert: false,
    });

    let payload = {
      email: registerData,
    };

    let body = {
      email: payload.email,
    };
    
    this.props.apiManager.makeCall('emailVerificationRequest', body, (res) => {
      if (res.message == 7073) {
        this.setState({
          loading: false,
          showPositiveAlert: true,
          errorMessage: 'Email has been sent to your account, Please verify!',
        });
        setTimeout(() => {
          this.setState({
            showPositiveAlert: false,
          });
        }, 3000);
      } else if (res.message === 7000) {
        this.setState({
          showPositiveAlert: false,
          loading: false,
          errorMessage: 'Email already Verified',
        });

      } else {
        this.setState({
          loading: false,
          showPositiveAlert: false,
          errorMessage: 'Email already Verified',
        });
      }
    });
  };

  render() {
    let count = this.context.count;
    const color = {
      activeThumb: {
        base: 'rgb(62,130,247)',
      },
      inactiveThumb: {
        base: 'rgb(250,250,250)',
      },
      active: {
        base: 'rgb(65,66,68)',
        hover: 'rgb(95,96,98)',
      },
      inactive: {
        base: 'rgb(207,221,245)',
        hover: 'rgb(177, 191, 215)',
      },
    };
    const { displayName, email, phone, locations } = this.state.user;
    Manager.setItem('currentNumber', phone);
    Manager.setItem('currentEmail', this.state.currentEmail);

    return (
      <div>
        <Breadcrumb title={'Dashboard'} />
        <br />
        <br />
        <br />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="account-sidebar" onClick={this.openFilter}>
                  <span
                    onClick={this.openFilter}
                    className="filter-btn btn btn-theme"
                  >
                    <i className="fa fa-filter" aria-hidden="true"></i>
                    Account
                  </span>
                </div>
                <div className="dashboard-left">
                  <div className="collection-mobile-back">
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true"></i>{' '}
                      back
                    </span>
                  </div>
                  <div className="collection-filter">
                    <div>
                      <div
                        className="collection-filter-block"
                        style={{
                          padding: '10px 30px 10px 5px',
                        }}
                      >
                        {/*brand filter start*/}
                        <div
                          ref={this.setWrapperRef}
                          className="collection-mobile-back"
                        >
                          <span
                            className="filter-back"
                            onClick={(e) => this.closeFilter(e)}
                          >
                            <i
                              className="fa fa-angle-left"
                              aria-hidden="true"
                            ></i>{' '}
                            back
                          </span>
                        </div>
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li className="active">
                                      <a href="/account">Account Info</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          const token = localStorage.getItem('x-token');
                                          const cartToken = localStorage.getItem('token');
                                          const url = `${process.env.REACT_APP_BLACKMARKET_URL}/shopping-cart/checkout`;
                                          window.location.href = `${url}?token=${encodeURIComponent(token)}&cartToken=${encodeURIComponent(cartToken)}`;
                                        }}
                                      >
                                        Checkout
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a
                                        href={`${process.env.PUBLIC_URL}/toPay`}
                                      >
                                        To Pay
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a href="/payments">Payments</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a href="/order-history">My Orders</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          const token = localStorage.getItem('x-token');
                                          const cartToken = localStorage.getItem('token');
                                          const url = `${process.env.REACT_APP_BLACKMARKET_URL}/lifestyle`;
                                          window.location.href = `${url}?token=${encodeURIComponent(token)}&cartToken=${encodeURIComponent(cartToken)}`;
                                        }}
                                      >
                                        Lifestyle
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a href="/wishList">My Wishlist</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                        {/* <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                <div className="block-content" style={{ border: 0, padding: 5 }}>
                                  <ul>
                                    <li><a href="/wallet">Wallet</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle> */}
                        {/* <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                <div className="block-content" style={{ border: 0, padding: 5 }}>
                                  <ul>
                                    <li><a href="/Refund">Refunds</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle> */}
                        {/* <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                <div className="block-content" style={{ border: 0, padding: 5 }}>
                                  <ul>
                                    <li><a href="/Schedule">Schedule History</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle> */}
                        {/* <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                <div className="block-content" style={{ border: 0, padding: 5 }}>
                                  <ul>
                                    <li><a href="/Complains"> Complain </a> </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle> */}
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a href="/Edit-Profile">Edit Profile</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                        {/* <SlideToggle>
                                                    {({
                                                        onToggle,
                                                        setCollapsibleElement,
                                                    }) => (
                                                        <div className="collection-collapse-block">
                                                            <div
                                                                className="collection-collapse-block-content"
                                                                ref={
                                                                    setCollapsibleElement
                                                                }
                                                            >
                                                                <div
                                                                    className="block-content"
                                                                    style={{
                                                                        border: 0,
                                                                        padding: 5,
                                                                    }}
                                                                >
                                                                    <ul>
                                                                        <li>
                                                                            <a href="/VerifyEmail">
                                                                                Verify
                                                                                Email
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </SlideToggle> */}
                        {/* <SlideToggle>
                                                    {({
                                                        onToggle,
                                                        setCollapsibleElement,
                                                    }) => (
                                                        <div className="collection-collapse-block">
                                                            <div
                                                                className="collection-collapse-block-content"
                                                                ref={
                                                                    setCollapsibleElement
                                                                }
                                                            >
                                                                <div
                                                                    className="block-content"
                                                                    style={{
                                                                        border: 0,
                                                                        padding: 5,
                                                                    }}
                                                                >
                                                                    <ul>
                                                                        <li>
                                                                            <a href="/change-MSISDN">
                                                                                Change
                                                                                MSISDN
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </SlideToggle> */}
                        {/* <SlideToggle>
                                                    {({
                                                        onToggle,
                                                        setCollapsibleElement,
                                                    }) => (
                                                        <div className="collection-collapse-block">
                                                            <div
                                                                className="collection-collapse-block-content"
                                                                ref={
                                                                    setCollapsibleElement
                                                                }
                                                            >
                                                                <div
                                                                    className="block-content"
                                                                    style={{
                                                                        border: 0,
                                                                        padding: 5,
                                                                    }}
                                                                >
                                                                    <ul>
                                                                        <li>
                                                                            <a href="/change-password">
                                                                                Change
                                                                                Password
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </SlideToggle> */}
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                              >
                                <div
                                  className="block-content"
                                  style={{
                                    border: 0,
                                    padding: 5,
                                  }}
                                >
                                  <ul>
                                    <li
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      className="last"
                                      onClick={this.handleLogOut}
                                    >
                                      Log Out
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>My Dashboard</h2>
                    </div>
                    <div className="welcome-msg">
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{
                            borderRadius: ' 200px',
                            width: '130px',
                            height: '120px',
                          }}
                          src={this.state.file}
                          alt=""
                        ></img>
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: '100',
                            left: 50,
                            top: 50,
                            bottom: 50,
                          }}
                        >
                          <LoadingLoader visible={this.state.loading} />
                        </div>
                      </div>
                      <br />
                      <br />
                      <form>
                        <input
                          type="file"
                          id="file"
                          name="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={this.handleChangeImage}
                        />
                        <label
                          style={{
                            color: 'rgb(224, 106, 66)',
                            cursor: 'pointer',
                          }}
                          className="profileImg"
                          htmlFor="file"
                        >
                          Edit Image
                        </label>
                        {this.state.selectedFile !== null ? (
                          <div
                            className="imgUpload"
                            onClick={(event) => {
                              this.UpdateImage(
                                this.state.selectedFile,
                                (res) => {
                                  console.warn('--- done', res.location);
                                  let body = {
                                    image: res.location,
                                  };
                                  if (res) {
                                    this.setState(
                                      {
                                        loading: true,
                                      },
                                      () => {
                                        this.props.apiManager.makeCall(
                                          'update_avatar',
                                          body,
                                          (res) => {
                                            let body = {
                                              image: res.image,
                                            };
                                            this.props.apiManager.makeCall(
                                              'get_user_details',
                                              body,
                                              (res) => {
                                                if (res.user) {
                                                  this.setState({
                                                    user: res.user,
                                                    setMessage:
                                                      'Image update successfully',
                                                    loading: false,
                                                    selectedFile: null,
                                                  });
                                                } else {
                                                  console.error(
                                                    'Error while fetching merchant detail ',
                                                    res.merchant
                                                  );
                                                }
                                              }
                                            );
                                          }
                                        );
                                      }
                                    );
                                  }
                                }
                              );
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              <div
                                id="text"
                                style={{
                                  marginRight: 10,
                                  backgroundColor: 'rgb(91,149,255)',
                                  width: '60px',
                                  cursor: 'pointer',
                                  fontSize: '12px',
                                  color: 'white',
                                  textAlign: 'center',
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  borderRadius: 10,
                                }}
                              >
                                Save
                              </div>
                              {this.state.selectedFile !== null ? (
                                <div
                                  id="cancelImg"
                                  style={{
                                    backgroundColor: 'rgb(211,211,211)',
                                    width: '60px',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    color: 'white',
                                    textAlign: 'center',
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRadius: 10,
                                  }}
                                  onClick={this.cancelImage}
                                >
                                  Remove
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                        {/* {this.state.selectedFile !== null ?
                                                    <div id="cancelImg" style={{ marginTop: 10, backgroundColor: 'rgb(211,211,211)', width: '10%', cursor: 'pointer', fontSize: '12px', color: 'white', textAlign: 'center', paddingTop: 5, paddingBottom: 5, borderRadius: 10 }} onClick={this.cancelImage} >Remove</div>
                                                    : null
                                                } */}
                      </form>
                      {/* {this.state.selectedFile !== null ?
                                                <div id="cancelImg" style={{ marginTop: 10, backgroundColor: 'rgb(211,211,211)', width: '10%', cursor: 'pointer', fontSize: '12px', color: 'white', textAlign: 'center', paddingTop: 5, paddingBottom: 5, borderRadius: 10 }} onClick={this.cancelImage} >Remove</div>
                                                : null
                                            } */}
                      <p>Hello, {this.state.currentName}!</p>
                      <p>
                        From your My Account Dashboard you have the ability to
                        view a snapshot of your recent account activity and
                        update your account information. Select a link below to
                        view or edit information.
                      </p>
                    </div>
                    <div className="box-account box-info">
                      <div className="box-head">
                        <h2>Account Information</h2>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="box">
                            <div className="box-title">
                              <h3>Contact Information</h3>
                            </div>
                            <div className="box-content">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <h6>
                                  {this.state.currentName
                                    ? this.state.currentName
                                    : 'No'}{' '}
                                  {this.state.currentLastname
                                    ? this.state.currentLastname
                                    : 'name'}
                                </h6>
                              </div>
                              <h6>
                                {this.state.currentEmail}
                                {!this.state.userVerify ? (
                                  <>
                                    <i
                                      className="fa fa-check-circle fa-1x"
                                      style={{
                                        marginLeft: 3,
                                        // boxShadow:"0.5px 0.2px 0.5px 0.2px #00FF00",
                                        color: 'black',
                                      }}
                                    ></i>
                                  </>
                                ) : null}
                              </h6>
                              <h6>{this.state.currentNumber}</h6>
                              {/* <h6><a href="/change-password">Change Password</a></h6> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          {this.state.user &&
                          this.state.user.verificationCode ? (
                            <div className="box">
                              <div className="box-title">
                                <h3>Verification Code</h3>
                                <a
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      verificationModal: true,
                                    })
                                  }
                                >
                                  Edit
                                </a>
                              </div>
                              <div className="box-content">
                                <input
                                  id="myInput"
                                  type={this.state.hidden ? 'password' : 'text'}
                                  value={this.state.user.verificationCode}
                                  inputMode="none"
                                  size="8"
                                  style={{
                                    outline: 'none',
                                    border: 0,
                                  }}
                                />
                                <FontAwesomeIcon
                                  onClick={() =>
                                    this.setState({
                                      hidden: !this.state.hidden,
                                    })
                                  }
                                  icon={this.state.hidden ? faEyeSlash : faEye}
                                  size="1x"
                                  color="grey"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="box">
                              <div className="box-title">
                                <h3>Verification Code</h3>
                                <a>{''}</a>
                              </div>
                              <div className="box-content">
                                <p> Will Be Added While Purchasing </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="box">
                          <div className="box-title">
                            <h3>Address Book</h3>
                            <Link to={`/AddAddress`}> Add New Address </Link>
                          </div>
                          {locations ? (
                            <div className="row">
                              {this.state.user.locations.map((item, index) => {
                                return (
                                  <div key={index} className="col-sm-6">
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <h6>
                                        {item.main === true
                                          ? ' Main Address '
                                          : ' Secondary Address '}
                                      </h6>
                                      {item.main !== true ? (
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          onClick={() =>
                                            this.changeCartDeliveryAddress(item)
                                          }
                                        >
                                          Make Delivery Address
                                        </div>
                                      ) : (
                                        <div />
                                      )}
                                    </div>
                                    <address>
                                      {item.address +
                                        ', ' +
                                        item.cityName +
                                        ', ' +
                                        item.provinceName}
                                      <br />
                                      <div
                                        style={{
                                          cursor: 'pointer',
                                          color: 'red',
                                          marginTop: 5,
                                          display: 'flex',
                                          flexDirection: 'row',
                                        }}
                                      >
                                        <div
                                          onClick={() =>
                                            this.deleteAddress(item._id)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            size={'1x'}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            color: 'red',
                                            marginLeft: 5,
                                          }}
                                          onClick={() =>
                                            this.showAddressModal(item)
                                          }
                                        >
                                          Edit
                                        </div>
                                      </div>
                                      <br />
                                      <br />
                                    </address>
                                  </div>
                                );
                              }, this)}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <div className="box">
                          <div className="box-title">
                            <h3>Preferences</h3>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <Accordion defaultActiveKey="0">
                                {this.state.prefrence.map((item, index) => {
                                  let subType = [
                                    {
                                      name: 'Negotiation',
                                      value:
                                        item.notificationTypes.types
                                          .negotiation,
                                    },
                                    {
                                      name: 'Chat',
                                      value: item.notificationTypes.types.chat,
                                    },
                                    {
                                      name: 'Promo',
                                      value: item.notificationTypes.types.promo,
                                    },
                                    {
                                      name: 'Order',
                                      value: item.notificationTypes.types.order,
                                    },
                                    {
                                      name: 'Reminder',
                                      value:
                                        item.notificationTypes.types.reminder,
                                    },
                                    {
                                      name: 'Delivered',
                                      value:
                                        item.notificationTypes.types.delivered,
                                    },
                                    {
                                      name: 'General',
                                      value:
                                        item.notificationTypes.types.general,
                                    },
                                    {
                                      name: 'Schedule',
                                      value:
                                        item.notificationTypes.types.schedule,
                                    },
                                    {
                                      name: 'Complain',
                                      value:
                                        item.notificationTypes.types.complain,
                                    },
                                    {
                                      name: 'Refund',
                                      value:
                                        item.notificationTypes.types.refund,
                                    },
                                    {
                                      name: 'Wallet',
                                      value:
                                        item.notificationTypes.types.wallet,
                                    },
                                    {
                                      name: 'Service Canceled',
                                      value:
                                        item.notificationTypes.types
                                          .serviceCanceled,
                                    },
                                  ];
                                  let notificationName =
                                    item.type > 0
                                      ? item.type > 1
                                        ? 'Message Notifications'
                                        : 'Push Notifications'
                                      : 'Email Notifications';
                                  return (
                                    <Card
                                      style={{
                                        border: 0,
                                      }}
                                      key={index}
                                    >
                                      <Card.Header
                                        style={{
                                          backgroundColor: 'white',
                                          paddingLeft: 0,
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <Accordion.Toggle
                                          as={Card.Header}
                                          variant="link"
                                          eventKey={index}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: 0,
                                            padding: 0,
                                            alignItems: 'center',
                                            border: 0,
                                            backgroundColor: 'white',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <h5
                                            style={{
                                              marginRight: 12,
                                              paddingTop: 6,
                                            }}
                                          >
                                            {notificationName}
                                          </h5>
                                          <ToggleButton
                                            inactiveLabel={'Off'}
                                            activeLabel={'On'}
                                            colors={color}
                                            value={item.enabled || false}
                                            onToggle={(value) => {
                                              this.setUserMainPrefrence(
                                                value,
                                                0,
                                                item
                                              );
                                            }}
                                          />
                                        </Accordion.Toggle>
                                      </Card.Header>
                                      {item.enabled === true
                                        ? subType.map((subtype, indexx) => {
                                            return (
                                              <Accordion.Collapse
                                                eventKey={index}
                                                key={indexx}
                                              >
                                                <Card.Body
                                                  style={{
                                                    backgroundColor: 'white',
                                                    paddingBottom: 1,
                                                    paddingTop: 1,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                        'space-between',
                                                    }}
                                                  >
                                                    <h6
                                                      style={{
                                                        marginRight: 12,
                                                        paddingTop: 6,
                                                      }}
                                                    >
                                                      {subtype.name}
                                                    </h6>
                                                    <ToggleButton
                                                      inactiveLabel={'Off'}
                                                      activeLabel={'On'}
                                                      colors={color}
                                                      value={
                                                        subtype.value || false
                                                      }
                                                      onToggle={(value) => {
                                                        subType.value = false;
                                                        this.setUserSubPrefrence(
                                                          value,
                                                          indexx,
                                                          item
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            );
                                          })
                                        : ''}
                                    </Card>
                                  );
                                })}
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          closeOnOverlayClick={true}
          open={this.state.verificationModal}
          onClose={() => this.setState({ verificationModal: false })}
          center
          styles={{
            modal: {
              padding: '10px 5px 10px 5px',
              borderRadius: 5,
            },
          }}
          showCloseIcon={false}
        >
          <div
            className="modal-content quick-view-modal"
            style={{
              border: 0,
              width: this.isMobile() ? 300 : 400,
            }}
          >
            <div className="modal-body" style={{ border: 0 }}>
              <h4
                style={{
                  textAlign: 'center',
                  paddingBottom: 10,
                }}
              >
                Verification Code{' '}
              </h4>
              <p style={{ textAlign: 'center', lineHeight: 1.4 }}>
                Never reveal your code to others.
              </p>
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-description border-product">
                    <form className="form-header">
                      <div
                        className="input-group"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingTop: 10,
                          paddingBottom: 5,
                        }}
                      >
                        <div id="PaymentForm">
                          <form
                            style={{
                              marginTop: 20,
                            }}
                          >
                            <div className="form-group">
                              <input
                                size="30"
                                type="text"
                                name="oldVerificationCode"
                                className="form-control"
                                placeholder="Old Verification Code"
                                value={this.state.oldVerificationCode}
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                size="30"
                                type="text"
                                name="newVerificationCode"
                                className="form-control"
                                placeholder="New Verification Code"
                                value={this.state.newVerificationCode}
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                              />
                              <p
                                style={{
                                  padding: '5px 0px 0px 0px',
                                  margin: 0,
                                  color: 'red',
                                }}
                              >
                                {this.state.emptyCode
                                  ? 'Verification Code Can Not Be Empty'
                                  : this.state.sameCode
                                  ? 'Verification Code Not Same'
                                  : ''}
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingTop: 10,
                          paddingBottom: 5,
                        }}
                      >
                        <div
                          onClick={this.changeVerificationCode}
                          style={{
                            backgroundColor: 'rgb(91,149,255)',
                            width: '80%',
                            cursor: 'pointer',
                            fontSize: '16px',
                            color: 'white',
                            textAlign: 'center',
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderRadius: 10,
                          }}
                        >
                          Submit
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          closeOnOverlayClick={true}
          open={this.state.showAddressModal}
          onClose={() => this.setState({ showAddressModal: false })}
        >
          <Formik
            initialValues={{
              address: '',
              state: '',
              city: '',
              postalCode: '',
              addressType: '',
              phoneNum: '60',
            }}
            validate={(values) => {
              let errors = {};
              Object.keys(values).map((key) => {
                if (!values[key]) errors[key] = 'Required';
              });
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              this.props.onSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form
                class="row justify-content-lg-center"
                style={{ margin: 10 }}
              >
                <div className="col-lg-15">
                  <div
                    class="row justify-content-md-center"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: 10,
                        color: 'black',
                      }}
                    >
                      Add Delivery Address
                      <br />
                      <br />
                    </h3>
                  </div>
                  <div class="form-row mb-4" style={{ height: '11%' }}>
                    <Field
                      style={{ height: '100%' }}
                      type="text"
                      name="address"
                      id="defaultRegisterFormFirstName"
                      class="form-control"
                      placeholder="Enter Your Delivery Address"
                      value={this.state.address}
                      onChange={this.handleChangeAddress}
                    />
                    <ErrorMessage id="error" name="address" component="div" />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      marginBottom: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {/* <label style={{ marginRight: 10 }}> <b>Select Your Province </b></label> */}

                    <select
                      class="form-control"
                      id="state"
                      name="state"
                      // placeholder={'Select Province'}
                      onChange={(obj) => {
                        let selectedProvince = JSON.parse(obj.target.value);
                        
                        this.setState(
                          {
                            SelectedStateId: selectedProvince.provinceId,
                            SelectedStateName: selectedProvince.provinceName,
                          },
                          () => {
                            if (obj != null) {
                              this.getCity(this.state.SelectedStateId);
                            }
                          }
                        );
                      }}
                    >
                      {
                        <option value="0" abc="lahore">
                          {this.state.SelectedStateName
                            ? this.state.SelectedStateName
                            : 'Choose State'}
                        </option>
                      }
                      {this.state.allStates.map((state, index) => {
                        return (
                          <option
                            id="state"
                            key={index}
                            abc={state.label}
                            value={state.value}
                          >
                            {state.label}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage id="error" name="state" component="div" />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      marginBottom: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {/* <label style={{ marginRight: 10 }}> <b>Select Your City </b></label> */}
                    <select
                      class="form-control"
                      id="city"
                      name="city"
                      //value={this.state.allCities.filter( city => city.value === this.state.selectedCityId)}
                      // value={1}
                      onChange={(obj) => {
                        setFieldValue('city', obj.target.value);
                        this.setState({
                          selectedCityId: obj.target.value,
                        });
                      }}
                    >
                      {
                        <option
                          value=""
                          label={
                            this.state.selectedCityId
                              ? this.state.selectedCityId
                              : 'Choose City'
                          }
                          selected
                        />
                      }
                      {this.state.allCities.map((state, index) => {
                        return (
                          <option id={state.value} value={state.label}>
                            {state.label}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage id="error" name="city" component="div" />
                  </div>

                  <div
                    class="form-row mb-4 "
                    style={{
                      height: '11%',
                      marginBottom: 0,
                    }}
                  >
                    {/* <label>Postal Code</label> */}
                    <Field
                      style={{ height: '100%' }}
                      type="number"
                      name="postalCode"
                      id="postalCode"
                      class="form-control mb-4"
                      placeholder="Enter Your Postal Code"
                      value={this.state.postalCode}
                      onChange={this.changePostalCode}
                    />
                    <ErrorMessage
                      id="error"
                      name="postalCode"
                      component="div"
                    />
                  </div>
                  <div class="form-row mb-4 " style={{ height: '11%' }}>
                    {/* <label>Postal Code</label> */}
                    <input
                      style={{ height: '100%' }}
                      name="phoneNum"
                      id="phoneNum"
                      class="form-control mb-4"
                      placeholder="Enter Your Phone Number(60xxxxxxxxxx)"
                      value={this.state.phoneNum}
                      onChange={this.changePhoneNum}
                      maxLength="12"
                      max="12"
                      onKeyPress={(e) => this.thirdMethod(e)}
                      type="tel"
                      pattern="[0-9]*"
                      required
                    />
                    <ErrorMessage id="error" name="phoneNum" component="div" />
                  </div>
                  <div
                    className="col-md-12"
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      marginTop: 10,
                    }}
                  >
                    <button
                      className="btn btn-solid"
                      type="button"
                      onClick={this.updateAdress}
                    >
                      Add Delivery Address
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <PositiveAlert
          onClose={() => this.setState({ showPositiveAlert: false })}
          open={this.state.showPositiveAlert}
          title={'Success'}
          message={this.state.errorMessage}
        />
        {/* <NegativeAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                /> */}

        <ChangeAlert
          error
          changeName={true}
          goto={() => this.verifyEmail(this.state.currentEmail)}
          onClose={() => this.setState({ showNegativeAlert: false })}
          open={this.state.showNegativeAlert}
          title={'error'}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default SuperHOC(AccountComponent);
