import React, { Component } from 'react';
import Slider from '../assets/terms-&-conditions.jpg';
import Breadcrumb from "../components/common/breadcrumb";
// import { Slider2, Team4 } from "../../services/script"

class Terms extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div style={{ minHeight: '100vh' }}>
        <Breadcrumb title={'Terms & Condition'} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{ marginTop: 44 }}>
                <div className="banner-section">
                  <img src={Slider} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-sm-12">
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <br />
                  <h2>Black Market Terms and Conditions</h2>
                  <br />
                </div>
                
                <h3>Introduction</h3>
                <p>1. XOX ("XOX" or "we" or "us" or “our”) welcomes you, the registered users, either Buyer or Seller (“user(s)” or “you” or “your”) to Black Market (“Platform”) and its services (“Services”), which allows users to access, place, accept, close, manage, and fulfil orders for the sale and purchase of products and/or services online through the Platform.</p>
                <p>2. The Platform is accessible via the Black App</p>
                <p>3. The following terms and conditions ("Terms and Conditions") govern your use of the Platform.</p>
                <p>4. Please read these Terms and Conditions before browsing, accessing, and/or using the Platform. By browsing, using or continuing to use the Platform and/or its Services, you are agreeing to be bound by these Terms and Conditions the Policies and the guidelines we have put in place for our Platform.  If you do not agree to any of the Terms and Conditions the Policies and/or the guidelines, you must not access or use our Services or the Platform.</p>
                <p>5. The Platform and its Services are offered to you subject to your acceptance of these Terms and Conditions and all other applicable terms and conditions, rules, policies, regulations and law that may or may not be communicated from time to time.</p>
                <p>6. The BLACK App Terms of Use, Seller Policy, Buyer Policy, Privacy Policy, Cancellation, Exchange, Returns and Refunds Policy, and all other policies as published on the Platform from time to time form an integral part of these Terms and Conditions.</p>
                <p>7. The Policies' terms are incorporated into these Terms and Conditions by reference, and together they constitute a legally binding agreement between the User and XOX. In the event of any inconsistency, the terms and conditions in this Terms and Conditions shall take precedence.</p>
                <p>8. These Terms and Conditions are our copyrighted intellectual property. Any unconsented use even of extracts of the Terms and Conditions, for any purpose is not permitted. Infringements may be subject to legal action.</p>
                <br />


                <h3>Entire Agreement</h3>
                <p>9. These Terms and Conditions shall apply to your use of the Platform and all Orders and made or to be made by us for the sale and supply of Products. These Terms and Condition constitute the entire agreement between you and XOX and supersede any and all preceding and contemporaneous agreements between us.</p>

                <h3>Definitions</h3>
                <p>10. In these Terms and Conditions:</p>

                <table style={{ margin: '20px auto', padding: '10px', width: '80%', border: '1px black solid' }}>
                  <tbody>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Account</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means the Black App Account that you have registered on our Platform;</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Black App</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>refers to the mobile application known as “BLACK App” which is owned and operated by XOX;</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Black Market</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>refers to e-commerce marketplace owned, operated and managed by XOX;</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Business Days</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means a day from 9.00 a.m. to 6.00 p.m. in Malaysia, excluding Saturdays, Sundays, and gazetted public holidays</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Buyer</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means a user who purchases the Products through the Platform, subject to these Terms and Conditions;</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Intellectual Property Rights</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means, whether registered or unregistered, rights in, and in relation to, any patents, registered designs, design rights, trademarks, trade and business names (including all goodwill associated with any trademark or trade business names), copyright and related rights, moral rights, databases, domain names, semi-conductor and other topography rights and utility models, and including the benefit of all registrations of, applications to register and the right to apply for registration of any of the foregoing items and all rights in the nature of any of the foregoing items, each for their full term (including any extensions or renewals thereof);</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Order</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means the Order submitted by you to the Platform to purchase a Product</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Platform</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>refers to the online marketplace owned and operated by the XOX, and known as “Black Market;</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Product</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means a Seller’s products and/or services listed for sale on the Platform;</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Seller</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means a User who sells the Products through the Platform, subject to the Terms and Conditions as herein contained as well as the Seller Agreement and Policies;</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>Transaction</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>means a sale and purchase transaction of the Products between a Buyer and a Seller which is conducted online via the Platform</td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>XOX</td>
                      <td style={{ padding: '10px', border: '1px black solid', color: '#777777' }}>refers to XOX Media Sdn Bhd (Registration No. 200801011962 (813250-K)) and shall include the Parent Company and all of their subsidiaries and affiliated entities under their control</td>
                    </tr>
                  </tbody>
                </table>


                <h3>Representations and Warranties</h3>
                <p>14. You represent and warrant that:</p>
                <p style={{ marginLeft: '25px'}}>(a) you possess the legal capacity, right and ability to enter into these Terms and Conditions;</p>
                <p style={{ marginLeft: '25px'}}>(b) in the event of a minor, you possess valid parent or legal guardian consent to enter into these Terms and Conditions;</p>
                <p style={{ marginLeft: '25px'}}>(c) you comply with its terms; and</p>
                <p style={{ marginLeft: '25px'}}>(d) you will use the Services and the Platform for lawful purposes only and in accordance with these Terms and Conditions and all applicable laws, rules, codes, directives, guidelines, policies and regulations.</p>

                <h3>Registration</h3>
                <p>15. Our Platform requires Users to create an Account in order to access the Platform and/or use the Services.</p>
                <p>16. You details submitted for registration must be accurate, true and uptodate.</p>
                <p>17. It shall be your responsibility to update your Account in the event of any changes.</p>
                <p>18. XOX shall not be held responsible nor accountable for any loss/losses, in connection with your details maintained in the Platform’s record not being current and/or correct.</p>

                <h3>Use of Service and Platform</h3>
                <p>19. The Platform allows Users to sell and purchase Products.</p>
                <p>20. You acknowledge that you are using the Platform at your own risk. We XOX accept no responsibility nor agree to indemnify you for losses incurred as a result of such use, and you irrevocably agree to hold us harmless and indemnify us for any losses incurred as a result of such use.</p>
                <p>21. In using our Platform and its Services, you agree to:</p>
                <p style={{ marginLeft: '25px'}}>(a) follow and adhere to all applicable laws and regulations; and</p>
                <p style={{ marginLeft: '25px'}}>(b) follow and adhere to these Terms and Conditions as well as our Policies</p>
                <p>22. In using our Platform and its Services, you agree NOT to:</p>
                <p style={{ marginLeft: '25px'}}>(a) not engage in any illegal activities, including but not limited to:</p>
                <p style={{ marginLeft: '50px'}}>(i) commit fraud, such as making purchases on the Platform using another person's identity, credit card, or bank account;</p>
                <p style={{ marginLeft: '50px'}}>(ii) distribute, send, or facilitate the sending of any unsolicited electronic commercial messages, or engaging in any form of spamming activities; or</p>
                <p style={{ marginLeft: '25px'}}>(b) create multiple User Accounts;</p>
                <p style={{ marginLeft: '25px'}}>(c) use the Services to promote a product or service</p>
                <p style={{ marginLeft: '25px'}}>(d) upload, post, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another&#39;s privacy, hateful, or racially, ethnically or otherwise objectionable;</p>
                <p style={{ marginLeft: '25px'}}>(e) upload, post, transmit or otherwise make available any Content featuring an unsupervised minor or use the Services to harm minors in any way;</p>
                <p style={{ marginLeft: '25px'}}>(f) use the Services or upload Content to impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity;</p>
                <p style={{ marginLeft: '25px'}}>(g) forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Services;</p>
                <p style={{ marginLeft: '25px'}}>(h) remove any proprietary notices from the Platform;</p>
                <p style={{ marginLeft: '25px'}}>(i) cause, permit or authorize the modification, creation of derivative works, or translation of the Services without the express permission of XOX;</p>
                <p style={{ marginLeft: '25px'}}>(j) use the Services for the benefit of any third party or any manner not permitted by the licenses granted herein;</p>
                <p style={{ marginLeft: '25px'}}>(k) use the Services or upload Content in a manner that is fraudulent, unconscionable, false, misleading or deceptive;</p>
                <p style={{ marginLeft: '25px'}}>(l) manipulate the price of any item or interfere with other User's listings, feedback or ratings systems;</p>
                <p style={{ marginLeft: '25px'}}>(m) attempt to decompile, reverse engineer, disassemble or hack the Services (or any portion thereof), or to defeat or overcome any encryption technology or security measures implemented by XOX with respect to the Services and/or data transmitted, processed or stored by XOX;</p>
                <p style={{ marginLeft: '25px'}}>(n) harvest or collect any information about or regarding other Account holders, including, without limitation, any personal data or information;</p>
                <p style={{ marginLeft: '25px'}}>(o) upload, email, post, transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</p>
                <p style={{ marginLeft: '25px'}}>(p) upload, email, post, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;</p>
                <p style={{ marginLeft: '25px'}}>(q) upload, email, post, transmit or otherwise make available any unsolicited or unauthorised advertising, promotional materials, "junk mail", "spam", "chain letters", "pyramid schemes", or any other unauthorised form of solicitation;</p>
                <p style={{ marginLeft: '25px'}}>(r) upload, email, post, transmit or otherwise make available any material that contains software viruses, worms, Trojan-horses or any other computer code, routines, files or programs designed to directly or indirectly interfere with, manipulate, interrupt, destroy or limit the functionality or integrity of any computer software or hardware or data or telecommunications equipment;</p>
                <p style={{ marginLeft: '25px'}}>(s) disrupt the normal flow of dialogue, cause a screen to "scroll" faster than other Users of the Services are able to type, or otherwise act in a manner that negatively affects other Users'; ability to engage in real time exchanges;</p>
                <p style={{ marginLeft: '25px'}}>(t) interfere with, manipulate or disrupt the Services or servers or networks connected to the Services or any other User's use and enjoyment of the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Platform;</p>
                <p style={{ marginLeft: '25px'}}>(u) take any action or engage in any conduct that could directly or indirectly damage, disable, overburden, or impair the Services or the servers or networks connected to the Services;</p>
                <p style={{ marginLeft: '25px'}}>(v) use the Services to intentionally or unintentionally violate any applicable local, state, national or international law, rule, code, directive, guideline, policy or regulation including, without limitation, laws and requirements (whether or not having the force of law) relating to anti-money laundering or counter-terrorism;</p>
                <p style={{ marginLeft: '25px'}}>(w) use the Services to violate the privacy of others or to "stalk: or otherwise harass another;</p>
                <p style={{ marginLeft: '25px'}}>(x) infringe the rights of XOX, including any intellectual property rights and any passing off of the same thereof;</p>
                <p style={{ marginLeft: '25px'}}>(y) list items which infringe upon the copyright, trademark or other intellectual property rights of third parties or use the Services in a manner which will infringe the intellectual property rights of others.</p>
                <p style={{ marginLeft: '25px'}}>(z) direct or encourage another user to conduct a transaction other than on the Platform.</p>
                <p style={{ marginLeft: '25px'}}>(aa) misuse the Platform.</p>
                <p>23. Any breach of the above provision may constitute an offence under Section 233 of the Communications and Multimedia Act 1998. In the event such breach occurs, XOX will report the breach to the relevant law enforcement authorities and appropriate legal action will be taken.</p>
                
                <h3>Use of Service and Platform</h3>
                <p>24. XOX reserves the rights to the following actions in the event of any violations to this Terms and Conditions, which includes but not limited to:</p>
                <p style={{ marginLeft: '25px'}}>(a) prohibited conduct in violation</p>
                <p style={{ marginLeft: '25px'}}>(b) any wrongdoing/illegal act; or</p>
                <p style={{ marginLeft: '25px'}}>(c) any other action that XOX deems necessary, XOX reserves the right to restrict your access to the Services and/or temporarily or permanently suspend the Services and/or your User without prior notice.</p>

                <h3>Suspension and/or Termination</h3>
                <p>25. XOX may proceed with actions in accordance with the Termination Article with or without notice to you, if:</p>
                <p style={{ marginLeft: '25px'}}>(a) you commit any prohibited conduct in violation of the use of Service and Platform;</p>
                <p style={{ marginLeft: '25px'}}>(b) you have committed or are suspected to commit any wrongdoing/unlawful act or where you have committed or are suspected of breaching or are anticipated to breach any of these Terms and Conditions; and/or</p>
                <p style={{ marginLeft: '25px'}}>(c) there are any other reasonable grounds as may be determined by XOX at its sole and absolute discretion</p>
                <p>26. You have the option to delete your account and/or discontinue use of the Platform at any time. If you want to delete Black Market account, send an email to enq_blackmarket@xox.com.my.</p>
                <p>27. For the avoidance of doubt, Users agree that any and all consequences arising from termination under paragraph 26 above shall be borne by you.</p>

                <h3>Intellectual Property Rights</h3>
                <p>28. All content included in or made available through our Platform, such as text, graphics, logos, button icons, images, audio clips, digital downloads, campaign titles and data compilations is the property of XOX or its content suppliers and is protected by copyright laws and treaties around the world. All such rights are reserved by XOX and its licensors.</p>
                <p>29. You are not permitted to publish, manipulate, distribute or otherwise reproduce, in any format, any of the content or copies of the content supplied to you or which appears on this Platform nor may you use any such content in connection with any business or commercial enterprise.</p>
                <p>30. XOX's trademarks may not be used in connection with any product or service that is not provided by XOX, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits XOX. All other trademarks not owned by XOX that appear on the Platform are the property of their respective owners.</p>
                <p>31. You must not use any part of the content on our site for commercial purposes without obtaining a license to do so from us or our licensors. In the event XOX becomes aware of any infringement of its intellectual property rights, it shall not refrain from taking any relevant legal action.</p>
                <p>32. If you print, copy or download any part of our Platform in breach of these Terms and Conditions, your right to use our Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You shall not modify, translate, reverse engineer, decompile, disassemble or create derivative works based on any software or accompanying documentation supplied by XOX or its licensors.</p>

                <h3>Order and Payment</h3>
                <p>33. Please see our Buyer’s Policy on Order and Payment which better explains our practices.</p>

                <h3>Shipping and Delivery</h3>
                <p>34. Please see our Buyer’s Policy on Shipping and Delivery which better explains our practices.</p>

                <h3>Third Party Links</h3>
                <p>35. Third party links provided throughout the Platform will let you leave this Platform. These links are not under the control of XOX in any manner whatsoever and you therefore access them at your own risk. XOX is in no manner responsible for the contents of any such linked site or any link contained within a linked site, including any changes or updates to such sites. The inclusion of any link does not in any way imply or express affiliation, endorsement or sponsorship by XOX of any linked site and/or any of its content therein.</p> 
                <p>36. XOX shall have no liability whatsoever in the event the XOX posts any information provided by its partners, or provides reference information or contents provided by a third party at the Platform or links on the Platform for your convenience.</p>


                <h3>Cancellations, Refunds and Returns</h3>
                <p>37. Please see our Buyer’s Policy on Cancellations, Refunds and Returns Policy which better explains our practices.</p>

                <h3>Disputes</h3>
                <p>38. In the event of an issue arise during a Transaction, Buyer and Seller should attempt to communicate with each other first to attempt to resolve such dispute by mutual discussions.</p>
                <p>39. In the event the issue cannot be resolved by mutual discussions, Users may approach the claims tribunal of their local jurisdiction to resolve any dispute arising from a Transaction.</p>
                <p>40. At all times, Buyer and Seller covenants and agrees that it will not bring suit or otherwise assert any claim against XOX in relation to any transaction made on the Platform or any dispute related to such transaction.</p>

                <h3>Feedback</h3>
                <p>41. XOX welcomes information and feedback from our Users which will enable us to improve the quality of service provided.</p>
                <p>42. You may submit your feedback through the Platform via online form</p>

                <h3>Force Majeure</h3>
                <p>43. Under no circumstances shall XOX  be held liable for any delay or failure or disruption of the content or services delivered through the Platform resulting directly or indirectly from acts of nature, forces or causes beyond our reasonable control, including without limitation, Impossibility of the use of public or private telecommunications networks, Internet failures, computer, telecommunications or any other equipment failures, electrical power failures, accidents, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, flood, storms, tempest, explosions, acts of God, war, governmental actions, health epidemics declared by authorities, orders of domestic or foreign courts or tribunals or non-performance of third parties. In such event, XOX’s obligations shall be suspended for so long as such force majeure event continues.</p>

                <h3>Indemnity</h3>
                <p>44. You hereby indemnify and hold XOX, and our officers, directors, agents, subsidiaries, joint ventures and employees, harmless from any claim or demand or damages, including all attorneys' fees incurred or to be incurred, as a result of any claims, demands or proceedings, by any third party due to or arising out of your breach of this Terms and Conditions, the terms and conditions of this Terms and Conditions and/or the Seller Agreement (as the case may be), or your violation of any law or the rights of a third party.</p> 

                <h3>Disclaimer</h3>
                <p>45. THE SERVICES ARE PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY XOX OF ANY KIND EITHER EXPRESSED, IMPLIED OR STATUTORY WITH RESPECT TO THE SERVICES, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE OR TRADE USAGE. WITHOUT LIMITING THE FOREGOING AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, XOX DOES NOT WARRANT THAT THE SERVICES, THE PLATFORM OR THE FUNCTIONS CONTAINED THEREIN WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE OR ERROR-FREE, THAT DEFECTS, IF ANY, WILL BE CORRECTED, OR THAT THE SERVICES AND/OR THE SERVER THAT MAKES THE SAME AVAILABLE ARE FREE OF VIRUSES, CLOCKS, TIMERS, COUNTERS, WORMS, SOFTWARE LOCKS, DROP DEAD DEVICES, TROJAN-HORSES, ROUTINGS, TRAP DOORS, TIME BOMBS OR ANY OTHER HARMFUL CODES, INSTRUCTIONS, PROGRAMS OR COMPONENTS.</p>
                <p>46. YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE PLATFORM AND/OR THE SERVICES REMAINS WITH YOU TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</p>

                <h3>Exclusion and Limitation of Liability</h3>
                <p>47. XOX  HAS NO CONTROL OVER AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DOES NOT GUARANTEE OR ACCEPT ANY RESPONSIBILITY FOR: (A) THE FITNESS FOR PURPOSE, EXISTENCE, QUALITY, SAFETY OR LEGALITY OF ITEMS AVAILABLE VIA THE SERVICES; OR (B) THE ABILITY OF SELLERS TO SELL ITEMS OR OF BUYERS TO PAY FOR ITEMS.IF THERE IS A DISPUTE INVOLVING ONE OR MORE USERS, SUCH USERS AGREE TO RESOLVE SUCH DISPUTE BETWEEN THEMSELVES DIRECTLY AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RELEASE XOX AND ITS AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES ARISING OUT OF OR IN CONNECTION WITH ANY SUCH DISPUTE.</p>
                <p>48. XOX CANNOT AND DOES NOT GUARANTEE CONTINUOUS OR SECURED ACCESS TO THE PLATFORM AND ITS SERVICES, AND OPERATION OF THE PLATFORM MAY BE AFFECTED BY NUMEROUS FACTORS OUTSIDE OF XOX’S CONTROL. AS SUCH, XOX SHALL NOT BE RESPONSIBLE FOR UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, ANY MATERIAL OR DATA SENT OR RECEIVED OR NOT SENT OR RECEIVED, OR ANY TRANSACTIONS ENTERED INTO THROUGH THIS WEBSITE. SPECIFICALLY, YOU AGREE THAT THE COMPANY IS NOT LIABLE OR RESPONSIBLE FOR ANY THREATENING, DEFAMATORY, OBSCENE, OFFENSIVE OR ILLEGAL CONTENT OR CONDUCT OF ANY OTHER PARTY OR ANY INFRINGEMENT OF ANOTHER'S RIGHTS, INCLUDING INTELLECTUAL PROPERTY RIGHTS. SPECIFICALLY, YOU ALSO AGREE THAT XOX IS NOT RESPONSIBLE FOR ANY CONTENT SENT USING THE COMMUNICATION SERVICES AND / OR INCLUDED IN THIS WEBSITE BY ANY THIRD PARTY. IN NO EVENT SHALL XOX BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, WHETHER IN CONTRACT, TORT, STRICT 
                LIABILITY OR OTHERWISE, INCLUDING WITHOUT LIMITATION, THOSE RESULTING FROM: (1) RELIANCE ON THE MATERIALS PRESENTED, (2) COSTS OF REPLACEMENT PRODUCTS, (3) LOSS OF USE, DATA OR PROFITS, (4) DELAYS OR BUSINESS INTERRUPTIONS, (5) NEGLIGENCE, (6) AND ANY THEORY OF LIABILITY, OUT OF OR IN ARISING CONNECTION WITH THE USE OF, INABILITY OR TO USE THIS WEBSITE, WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                <p>49. YOU UNDERSTAND AND ACCEPT THAT THE CONTENT DISPLAYED ON THIS PLATFORM IS PROVIDED WITHOUT ANY GUARANTEES, CONDITIONS OR WARRANTIES AS TO ITS ACCURACY. UNLESS EXPRESSLY STATED TO THE CONTRARY AND TO THE FULLEST EXTENT PERMITTED BY LAW, XOX AND ITS SUPPLIERS, CONTENT PROVIDERS AND ADVERTISERS HEREBY EXPRESSLY EXCLUDE ALL CONDITIONS, WARRANTIES AND OTHER TERMS WHICH MIGHT OTHERWISE BE IMPLIED BY STATUTE, COMMON LAW OR THE LAW OF EQUITY AND SHALL NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING BUT WITHOUT LIMITATION TO ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE OR INCIDENTAL DAMAGES, OR DAMAGES FOR LOSS OF USE, PROFITS, DATA OR OTHER INTANGIBLES, DAMAGE TO GOODWILL OR REPUTATION, OR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES, ARISING OUT OF OR RELATED TO THE USE, INABILITY TO USE, PERFORMANCE OR FAILURES OF THIS PLATFORM AND ANY MATERIALS POSTED THEREON, IRRESPECTIVE OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR ARISE IN CONTRACT, TORT, EQUITY, RESTITUTION, BY STATUTE, AT COMMON LAW OR OTHERWISE. IT SHALL BE YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS AND USEFULNESS OF ANY INFORMATION AND PROVIDED, AND USE OF THE PLATFORM IS SOLELY AT YOUR OWN RISK. XOX SHALL NOT BE LIABLE TO YOU EITHER IN TORT, CONTRACT, NEGLIGENCE OR OTHERWISE FOR ANY LOSS, DAMAGE, INJURY OR EXPENSE, HOWSOEVER ARISING, OUT OF OR IN CONNECTION WITH THE SUPPLY OR USE OF THE PRODUCTS. UNDER NO CIRCUMSTANCES WILL XOX BE LIABLE FOR ANY LOSS OF PROFITS, LOSS OF DATA OR FOR ANY OTHER SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE, BASED ON CLAIMS OF A USER OR WHETHER IN ACTION FOR CONTRACT, BREACH OF WARRANTY, TORT OR OTHERWISE.</p>
                <p>50. YOU ACKNOWLEDGE AND AGREE THAT YOUR ONLY RIGHT WITH RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO REQUEST FOR TERMINATION OF YOUR ACCOUNT AND/OR DISCONTINUE ANY USE OF THE SERVICES.</p>

                <h3>Amendments</h3>
                <p>51. XOX shall be entitled at any time and from time to time, to modify, amend or change the Terms and Conditions as appropriate and at our sole and absolute discretion. In such instance, XOX shall notify you of such amendments or changes (including the effective date for the same) via an announcement to be published on the main page of the Platform, and you shall be bound by such modified Terms and Conditions. As such, it is your responsibility to regularly visit the Platform, view the Terms and Conditions and Policies, and keep updated on any changes made to the Terms and Conditions. Please cease to use the Services if you are not agreeable to any amendments or modifications of the Terms and Conditions. You agree to use the Platform at your own risk, and your use of the Platform following any amendment or modification of the Terms and Conditions constitutes your agreement and acceptance to be bound by the same.</p> 
                <p>52. XOX do not take any responsibility or agree to indemnify you for losses you suffer arising from the modified Terms and Conditions or your own failure to keep yourself updated on the modifications to the Terms and Conditions, and you irrevocably agree to hold us harmless or indemnify us from any losses that you may suffer therefrom.</p>
                <p>53. For the avoidance of doubt, newer versions of the Terms and Conditions and/or Policies supersede older versions, unless otherwise stated by XOX.</p>

                <h3>Notices</h3>
                <p>54. All notices or communications shall be given or made in writing, and shall be delivered personally, or sent by courier/registered mail, addressed to XOX at Lot 8.1, 8th Floor, Menara Lien Hoe, No.8, Persiaran Tropicana, Tropicana Golf & Country Resort, 47410 Petaling Jaya, Selangor Darul Ehsan, Malaysia.</p>

                <h3>Governing Law</h3>
                <p>55. These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia. Both XOX and you hereby agree to submit to the non-exclusive jurisdiction of the courts of Malaysia.</p>

                <h3>Relationship</h3>
                <p>56. Nothing in these Terms and Conditions shall create or be deemed to create a partnership, an agency or a relationship of employer and employee between you and XOX.</p>

                <h3>Severability</h3>
                <p>57. If any provision of this Agreement shall be held by a court of competent jurisdiction to be illegal, invalid or unenforceable, such provision or a part thereof shall be severed to the extent affected and the remaining provisions shall remain in full force and effect.</p>

                <h3>Assignment</h3>
                <p>58. Neither party shall not assign or otherwise transfer any or all of its rights arising out of this Terms and Conditions without written notice to other party.</p>

                <h3>Waiver</h3>
                <p>59.Failure by XOX to insist upon or enforce strict performance of any of these Terms and Conditions shall not be construed as a waiver of any provision or right.</p>

                <p style={{ marginTop: '20px' }}>Version 1.2 as at 3.11.2021</p>

              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}
export default Terms