import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Breadcrumb from "../common/breadcrumb";
class ForgotComponent extends Component {
  render() {
    return (
      <div className="limiter">
        <Breadcrumb title={'Forget Password'} />
        <Formik
          initialValues={{ email: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.props.onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <div className="container-login100">
              <div className="wrap-login100">
                <Form className="login100-form validate-form">
                  <span className="login100-form-title">
                    Forgot Password
                </span>
                  <div className="wrap-input100 validate-input">
                    <Field type="email" name="email" id="defaultRegisterFormEmail" className="input100" placeholder="Enter Email" />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <ErrorMessage id="error" name="email" component="div" />
                  </div>
                  <div className="container-login100-form-btn">
                    <button className="login100-form-btn" type="submit" disabled={isSubmitting}>
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    )
  }
}

export default ForgotComponent;