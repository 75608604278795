import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import OrderList from "./OrderList";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class OrderHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      previous: ''
    }
  }
  componentDidMount() {
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')
    if (previous[3] !== same[2]) {
      this.setState({ previous: previous[3] })
    }
  }
  render() {
    return (
      <div>
        <Breadcrumb title={'Order History'} parent={this.state.previous}/>
        <section className="new-cart-section-wishlist section-b-space">
          <Tabs className="theme-tab" >
            <TabList className="tab-title" >
              <Tab>New</Tab>
              <Tab>Shipping</Tab>
              <Tab>Completed</Tab>
              <Tab>Cancelled</Tab>
            </TabList>
            <div className="tab-content-cls" >
              <TabPanel className="tab-content">
                {/* New Orders */}
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <div className="top-banner-content small-section">
                    <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                      <OrderList
                        {...this.props}
                        api='get_order_history'
                        status={0}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
            <TabPanel className="tab-content">
              {/* Orders in Shipping */}
              <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                <div className="top-banner-content small-section">
                  <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                    <OrderList
                      {...this.props}
                      api='get_order_history'
                      status={2}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel className="tab-content">
              {/* Completed Orders */}
              <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                <div className="top-banner-content small-section">
                  <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                    <OrderList
                      {...this.props}
                      api='get_order_history'
                      status={3}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel className="tab-content">
              {/* Cancelled Orders */}
              <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                <div className="top-banner-content small-section">
                  <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                    <OrderList
                      {...this.props}
                      api='get_order_history'
                      status={1}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </section>
      </div>
    )
  }
}

export default OrderHistory
