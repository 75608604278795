import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Breadcrumb from "../common/breadcrumb";

const prefix = "60";

class Register extends Component {
    state = {
        registerComponentFlag: true,
        phone: "60",
        inputPhoneNumber: "",
    };

    handleChange = (e) => {
        // e.preventDefault()
        const input = e.target.value;
        const name = e.target.name;
        let number = (e.target.value = prefix + input.substr(prefix.length));

        this.setState({
            phone: number,
        });
    };

    render() {
        const MyInput = ({ field, form, ...props }) => {
            return <input {...field} {...props} />;
        };

        let phone = this.state.phone;
        return (
            <div>
                <Breadcrumb title={"create account"} />
                <Formik
                    initialValues={{ phone: phone }}
                    validate={(values) => {
                        values.phone = this.state.phone;
                        const errors = {};
                        if (!phone === "60") {
                            errors.phone = "Enter Msisdn with 60";
                            // errors.confirmPassword = 'Please enter your confirm password';
                        }
                        if (phone === "60") {
                            errors.phone = "Field cannot be empty";
                        }
                        if (!/\60/i.test(phone)) {
                            errors.phone =
                                "Invalid MSISDN address! Must start with 60";
                        }
                        
                        if (phone.length <= 10) {
                            errors.phone =
                                "Msisdn must be at least 11 characters";
                        }
                        // if(!/\d*/.test(value)) return
                        if (!/\d*/.test(phone)) {
                            errors.phone =
                                "Msisdn must be at least 11 characters";
                        }
                        if (phone.length >= 14) {
                            errors.phone =
                                "Msisdn must be less then 14 characters";
                        }
                        return errors;
                    }}
                    
                    onSubmit={(values, { setSubmitting }) => {
                        values.registerComponentFlag = this.state.registerComponentFlag;
                        values.phone = this.state.phone;
                        this.props.onSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Create Your Account
                                        </span>

                                        <div className="col-xl-4 col-sx-12">
                                            <div className="wrap-input100 validate-input">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    className="input100"
                                                    component={MyInput}
                                                    // onkeypress={(event)=>validate(event)}
                                                    value={this.state.phone}
                                                    // setFieldValue={this.state.phone}
                                                    placeholder="Enter Phone Number"
                                                    // ref={(target) => {
                                                    //     // target.value = prefix;
                                                    // }}
                                                    id="defaultRegisterFormPassword"
                                                    onChange={(e) =>
                                                        this.handleChange(e)
                                                    }
                                                />
                                                {/* <Field
                                                    type="text"
                                                    name="phone"
                                                    className="input100"
                                                    component={MyInput}
                                                    placeholder="Enter Phone Number"
                                                    id="defaultRegisterPhonePassword"
                                                /> */}
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-phone"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                id="error"
                                                name="phone"
                                                component="p"
                                                style={{ color: "red" }}
                                            />

                                            <div className="container-register100-form-btn">
                                                <button
                                                    className="login100-form-btn"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Register
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
            </div>
        );
    }
}
export default Register;
