import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import InfiniteList from '../components/List/InfiniteList'
import { SuperHOC } from "../HOC";
import Breadcrumb from "../components/common/breadcrumb";
import ThemeSettings from "../components/common/theme-settings"
class ProductListing extends Component {
  render() {
    let title;
    let apiName;
    let category = null;
    let isCategory = false;
    if(this.props.location.state === undefined || this.props.location.state == null){
      title = 'Products';
      let path = this.props.location.search.split('?path=')[1]
      let key = path.split('?key=');

      path = window.atob(key[0])
      if(key.length >= 2){
        category = key[1];
        isCategory = true;
      }
      apiName = path;
      
    }else{
      title = this.props.location.state.title;
      apiName = this.props.location.state.apiName;
      category = this.props.location.state.category;
      isCategory = category != undefined ? true : false;
    }
    let routeName = this.props.location.pathname.split( '/' )[1];
    return (
      <div>
        <Breadcrumb
          title={title}
        />
        <div className="container" style={{ paddingTop: '30px' }}>
          <InfiniteList
            {...this.props}
            isCategory={isCategory}
            api={apiName}
            title={title}
            routeName={routeName}
            slug={category}
            colSize={title === 'Products' ? 3 : 4}
          />
          <ThemeSettings />
        </div>
      </div >
    )
  }
}
export default SuperHOC(ProductListing)