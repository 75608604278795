import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import 'react-credit-cards/es/styles-compiled.css';
import Breadcrumb from "../common/breadcrumb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import classes from './style.module.css'
import PositiveAlert from "../CustomAlert/CustomAlert";
import NegativeAlert from "../CustomAlert/CustomAlert";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from './utils';

export default class wallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wallet: '',
      amount: '',
      openModal: false,
      fedXModal: false,
      paymentModal: false,
      code: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
      bankName: '',
      bankPNR: '',
      bankAdress: '',
      checkoutAmount: '',
      walletHistory: [],
      orderId: null,
      FedX: '',
      paymentMethods: [],
      bankCode: '',
      index: '',
      selectedColor: '',
      errorMessage: '',
      showPositiveAlert: false,
      showNegativeAlert: false,
      bankImage: '',
      previous: ''
    }
  }

  componentDidMount() {
    this.getWallet()
    this.getWalletHistories()
    this.renderApi()
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')
    if (previous[3] !== same[2]) {
      this.setState({ previous: previous[3] })
    }
  }

  getWallet = () => {
    this.props.apiManager.makeCall(this.props.api, {}, res => {
      if (res) {
        this.setState({
          wallet: res.wallet,
          amount: res.wallet.amount
        })
      }
      else {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }
  getPaymentFedX = () => {
    let body = {
      checkoutAmount: this.state.checkoutAmount
    }
    this.props.apiManager.makeCall("check_payment_fedx", body, res => {
      if (res.message === '1767') {
        this.setState({
          fedXModal: false,
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
      if (res) {
        this.setState({
          FedX: res
        })
      }
      else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }
  getPaymentMethods = () => {
    let body = {
      checkoutAmount: this.state.checkoutAmount
    }
    this.props.apiManager.makeCall("get_wallet_payment_methods", body, res => {
      if (res) {
        this.setState({ paymentMethods: res.result, loading: false })
      }
      else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }

  getWalletHistories = () => {
    var startTime = new Date()
    startTime.setMonth(startTime.getMonth() - 1)
    let body = {
      startTime: startTime.valueOf(),
      endTime: new Date().valueOf(),
      numRecords: 10
    }
    this.props.apiManager.makeCall('get_wallet_histories', body, res => {
      if (res) {
        this.setState({
          walletHistory: res.result.docs,
        })
      }
      else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }

  checkoutWallet = () => {
    let body = {
      checkoutAmount: this.state.checkoutAmount
    }
    this.props.apiManager.makeCall('checkout_wallet', body, (res) => {
      if (res.message === '1757') {
        this.setState({ openModal: true, fedXModal: false })
      }
      if (res.message === '1765') {
        this.setState({
          fedXModal: false,
          showNegativeAlert: true,
          errorMessage: 'You Already Have A Transaction In Pending'
        })
      }
      else if (res.message === '1769') {
        this.setState({
          fedXModal: false,
          showPositiveAlert: true,
          errorMessage: res.decodedMessage
        })
      }
      else {
        this.setState({
          fedXModal: false,
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }
  handleCheckout = () => {
    if (this.state.checkoutAmount > 1) {
      if (this.state.amount > 1) {
        this.getPaymentFedX();
        this.setState({ fedXModal: true })
      }
      else this.setState({
        showNegativeAlert: true,
        errorMessage: 'You dont have this much amount'
      })
    }
    else this.setState({
      showNegativeAlert: true,
      errorMessage: 'Please Type Amount'
    })
  }

  handleTopup = () => {
    if (this.state.checkoutAmount > 1) {
      this.getPaymentMethods()
      this.setState({ paymentModal: true })
    }
    else this.setState({
      showNegativeAlert: true,
      errorMessage: 'Please Type Amount'
    })
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }
    this.setState({ [target.name]: target.value });
  };

  addBankDetails = () => {
    const { name, code, number, bankName, bankAdress, bankPNR } = this.state
    let body = {
      nameOfAccount: name,
      bankCode: code,
      accountNumber: number,
      bankName: bankName,
      bankAddress: bankAdress,
      bankPnr: bankPNR
    }
    this.props.apiManager.makeCall('add_bank_details', body, (res) => {
      if (res.message === '1745') {
        this.setState({ openModal: false, fedXModal: true })
      }
    })
  }

  saveToLocal() {
    const local = this.state.orderId;
    localStorage.setItem("walletOrderId", local);
  }

  topupWallet = () => {
    let body = {
      amount: parseInt(this.state.checkoutAmount),
      code: this.state.bankCode
    }
    this.props.apiManager.makeCall('topup_wallet', body, (res) => {
      if (res.message === '2100') {
        (window).Checkout.configure({
          merchant: 'AzureIn',
          order: {
            amount: function () {
              return 500;
            },
            currency: 'PKR',
            description: 'description',
            id: '34569690968968jhk',
          },
          interaction: {
            operation: 'PURCHASE',
            merchant: {
              name: 'nadeem',
              address: {
                line1: 'one hundeerr',
                line2: 'two line',
              }
            }
          },
          session: {
            id: res.session
          }
        });
        (window).Checkout.showPaymentPage();
        this.setState(
          { orderId: JSON.stringify(res.orderId) },
          this.saveToLocal
        )
      }
      else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }

  onPaymentMehtodClick = (item, index) => {
    this.setState({ selectedColor: '1px solid red', bankCode: item, index: index })
  }

  renderApi = async () => {
    let orderId = await JSON.parse(localStorage.getItem("walletOrderId"))
    if (orderId !== null) {
      let body = { orderId: JSON.parse(localStorage.getItem("walletOrderId")), type: '1' }
      this.props.apiManager.makeCall('payment_gateway_reterive_order', body, res => {
        if (res.message) {
          if (res.message === '2100') {
            this.setState({
              showPositiveAlert: true,
              errorMessage: 'Top Up Successful'
            })
          }
        }
      });
    }
    else {
      console.info('retrieve order data')
    }
  }

  recheckPending = (item) => {
    let body = { orderId: item._id, type: '1' }
    this.props.apiManager.makeCall('payment_gateway_reterive_order', body, res => {
      if (res) {
        window.location.reload()
      }
    })
  }
  handleCheck = (item) => {
    this.setState({ bankImage: item.asset.src, bankCode: item.code })
  }
  onCompleteOrder = () => {
    this.setState({ PositiveAlert: false })
    localStorage.removeItem("walletOrderId")
  }

  onCancelOrder = () => {
    localStorage.removeItem("walletOrderId")
    this.setState({ NegativeAlert: false })
  }

  render() {
    let wallet = this.state.wallet
    const { walletHistory } = this.state
    var amount = parseFloat(wallet.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    if (window.location && window.location.completeCallback) { this.renderApi() }
    return (
      <div>
        <Breadcrumb title={'wallet'} parent={this.state.previous} />
        <section className="register-page section-b-space" >
          <div className="container">
            <div className="row" >
              <div className="col-lg-9" style={{ marginTop: 20 }}>
                <h3>Wallet</h3>
                <div className="theme-card" style={{ backgroundColor: 'white', }}>
                  <form className="theme-form">
                    <div className="form-row">
                      <div className="col-md-6">
                        <h6 htmlFor="email">My Wallet</h6>
                        <h4 style={{ fontSize: 30 }}>{amount}</h4>
                      </div>
                    </div>
                    <hr />
                    <div className="form-row">
                      <div className="col-md-3">
                        <h6 htmlFor="email">Checkout Amount</h6>
                        <input
                          style={{ padding: 1, border: 0 }}
                          autoFocus={true}
                          type="tel"
                          name="checkoutAmount"
                          placeholder="Enter Amount"
                          min="500"
                          max={wallet.amount}
                          required
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <h6 htmlFor="email">Last Checkout Amount</h6>
                        <h5>{wallet.lastCheckoutAmount}</h5>
                      </div>
                      <div className="col-md-4">
                        <h6 htmlFor="email">FedX Amount</h6>
                        <h5>{wallet.fedxAmount ? wallet.fedxAmount.toFixed(2) : 0}</h5>
                      </div>
                    </div>
                    <div style={{ justifyContent: 'flex-end', }}
                      onClick={this.handleCheckout}
                      className="btn btn-solid"
                    >
                      Checkout
                                    </div>
                    <div style={{ justifyContent: 'flex-end', marginLeft: 20 }}
                      onClick={this.handleTopup}
                      className="btn btn-solid"
                    >
                      Top Up
                                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3" style={{ marginTop: 20 }}>
                <h3> <br /> </h3>
                <div className="theme-card" >
                  <form className="theme-form">
                    <div className="form-row">
                      <div className="col-md-12">
                        <h6 htmlFor="email">Whenever user has requested for refund due to any issue
                            with transaction and the payment is overcharged.
                            After administrative Check with the event, if the statment is true,
                            the refund ammount will send to wallet.
                                            </h6>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <h3 style={{ marginTop: 30, textAlign: 'center' }}>Credit History</h3>
            <table className={classes.table} >
              <thead>
                <tr className='table-row' align="center">
                  <th>Date</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Detail</th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: 'white' }}>
                {walletHistory.map((item, index) => {
                  let d = new Date(item.createdAt)
                  let month = '' + (d.getMonth() + 1)
                  let day = '' + d.getDate()
                  let year = d.getFullYear()
                  if (month.length < 2)
                    month = '0' + month;
                  if (day.length < 2)
                    day = '0' + day;
                  let date = [day, month, year].join('-')
                  let status = item.status && item.status > 0 ? item.status > 1 ? "Un-Approved" : "Pending" : "Approved"
                  let type = item.type && item.type > 0 ? item.type > 1 ? item.type > 2 ? "Purchase" : "Refund" : "Top-Up" : "Checkout"
                  return (
                    <tr key={index} align="center">
                      <td className={classes.tableRow}>{date}</td>
                      <td className={classes.tableRow}>{type}</td>
                      <td className={classes.tableRow}>{item.approvedAmount.toFixed(2)}</td>
                      <td className={classes.tableRow}>{status}</td>
                      {item.status === 1 ?
                        <td style={{ paddingBottom: 1, paddingTop: 1, paddingRight: 0, paddingLeft: 0, verticalAlign: 'middle' }}><button onClick={() => this.recheckPending(item)} className="btn btn-secondary" style={{ marginBottom: 10, marginTop: 10, marginRight: 0, marginLeft: 0, paddingBottom: 1, paddingTop: 1, paddingLeft: 6, paddingRight: 5, fontSize: 10, }}>Recheck</button></td>
                        :
                        item.status === 0 ?
                          <td style={{ paddingBottom: 10, paddingTop: 10, paddingRight: 0, paddingLeft: 0, verticalAlign: 'middle' }}><a href={item.receipt} target="_blank" rel="noopener noreferrer"><img src={item.receipt !== undefined ? item.receipt : `${process.env.PUBLIC_URL}/assets/tick.png`} height='20px' width='20px' alt=''></img></a></td>
                          :
                          <td style={{ paddingBottom: 10, paddingTop: 10, paddingRight: 0, paddingLeft: 0, verticalAlign: 'middle' }}><a href={item.receipt} target="_blank" rel="noopener noreferrer"><img src={item.receipt !== undefined ? item.receipt : `${process.env.PUBLIC_URL}/assets/cross.png`} height='20px' width='20px' alt=''></img></a></td>
                      }
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Link to={`${process.env.PUBLIC_URL}/WalletHistory`}>
              <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 0 }}>
                <h6 style={{ alignSelf: 'center', alignItems: 'center', paddingTop: 7, marginRight: 5 }}>
                  See All
                            </h6>
                <FontAwesomeIcon icon={faChevronRight} size="1x" color='grey' />
              </div>
            </Link>
          </div>
          <Modal open={this.state.openModal} onClose={() => this.setState({ openModal: false, fedXModal: false })} center styles={{ modal: { padding: '10px 5px 10px 5px', borderRadius: 5 } }} showCloseIcon={false}>
            <div className="modal-content quick-view-modal" style={{ border: 0 }}>
              <div className="modal-body" style={{ border: 0 }}>
                <div className="row">
                  <div className="col-lg-12">
                    <h3 style={{ textAlign: 'center', paddingBottom: 10 }}> Payment Type </h3>
                    <div id="PaymentForm">
                      <form style={{ marginTop: 20 }}>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-3" style={{ paddingRight: 0 }}>
                              <input
                                type="tel"
                                name="code"
                                className="form-control"
                                placeholder="Code"
                                pattern="\d{3,4}"
                                maxLength='4'
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                              />
                            </div>
                            <div className="col-9">
                              <input
                                type="tel"
                                name="number"
                                className="form-control"
                                placeholder="Account Number"
                                pattern="[\d| ]{16,22}"
                                maxLength='19'
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Name Of Account"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="bankName"
                            className="form-control"
                            placeholder="Bank Name"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="bankAdress"
                            className="form-control"
                            placeholder="Bank Adress"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="bankPNR"
                            className="form-control"
                            placeholder="Bank PNR"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                        </div>
                        <div onClick={this.addBankDetails} className="btn btn-primary btn-block">Add Details</div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal open={this.state.fedXModal} onClose={() => this.setState({ fedXModal: false })} center styles={{ modal: { padding: '0px 0px 0px 0px', borderRadius: 5 } }} showCloseIcon={false}>
            <div className="modal-content quick-view-modal" style={{ border: 0, backgroundColor: 'rgb(211,211,211,0.3)' }}>
              <div className="modal-body" style={{ border: 0 }}>
                <div className="row" style={{ padding: 0 }}>
                  <div className="col-lg-12" style={{ padding: 0 }}>
                    <h3 style={{ textAlign: 'center', paddingBottom: 10, textDecoration: 'underline' }}> FedX Deduction </h3>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: 10 }}>
                      <h5 >FedX Amount: </h5>
                      <h6 style={{ color: 'red' }}>{this.state.FedX.fedxAmount ? this.state.FedX.fedxAmount.toFixed(2) : ''}</h6>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: 10 }}>
                      <h5 >Checkout Amount: </h5>
                      <h6 style={{ color: 'red' }}>{this.state.FedX.checkoutAmount ? this.state.FedX.checkoutAmount.toFixed(2) : ''}</h6>
                    </div>
                  </div>
                  <div style={{ marginTop: 20, marginRight: 'auto', marginLeft: 10, backgroundColor: 'rgb(211,211,211)', width: '30%', cursor: 'pointer', fontSize: '16px', color: 'white', textAlign: 'center', paddingTop: 10, paddingBottom: 10, borderRadius: 10 }} onClick={() => this.setState({ fedXModal: false })} >Close</div>
                  <div style={{ marginTop: 20, marginLeft: 'auto', marginRight: 10, backgroundColor: 'rgb(91,149,255)', width: '30%', cursor: 'pointer', fontSize: '16px', color: 'white', textAlign: 'center', paddingTop: 10, paddingBottom: 10, borderRadius: 10 }} onClick={this.checkoutWallet} >Checkout</div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal open={this.state.paymentModal} onClose={() => this.setState({ paymentModal: false })} center styles={{ modal: { padding: '0px 0px 0px 0px', borderRadius: 5 } }} showCloseIcon={false}>
            <div className="modal-content quick-view-modal" style={{ border: 0, backgroundColor: 'rgb(211,211,211,0.3)' }}>
              <div className="modal-body" style={{ border: 0 }}>
                <div className="row">
                  <div className="col-lg-12" style={{ padding: 0 }}>
                    <h3 style={{ textAlign: 'center', paddingBottom: 10 }}>Top-Up Methods</h3>
                    <div className="payment-box">
                      <div className="upper-box">
                        <div className="payment-options">
                          <ul>
                            {
                              this.state.paymentMethods.map((item, index) => {
                                return (
                                  <li onClick={() => this.onPaymentMehtodClick(item.code, index)} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="radio-option" style={{ display: 'flex', padding: '5px 10px 0px 10px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                      <span className="image">
                                        <img src={item.asset.src} width="100vh" height="80vh" style={{ backgroundColor: 'white', border: index === this.state.index ? '1px solid #cd3232' : '1px solid lightgrey' }} alt="" />
                                      </span>
                                      <p style={{ textAlign: 'center', padding: 0, margin: 0, paddingTop: 5 }}>{item.name}</p>
                                    </div>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 20, marginLeft: 'auto', marginRight: 10, backgroundColor: 'rgb(91,149,255)', width: '40%', cursor: 'pointer', fontSize: '16px', color: 'white', textAlign: 'center', paddingTop: 10, paddingBottom: 10, borderRadius: 10 }} onClick={this.topupWallet}>Top Up</div>
                </div>
              </div>
            </div>
          </Modal>
          <PositiveAlert
            onClose={this.onCompleteOrder}
            open={this.state.showPositiveAlert}
            title={'Success'}
            message={this.state.errorMessage}
          />
          <NegativeAlert
            error
            onClose={this.onCancelOrder}
            open={this.state.showNegativeAlert}
            title={'Error'}
            message={this.state.errorMessage}
          />
        </section>
      </div>
    );
  }
}
