import React, { Component } from 'react'
import MerchanList from '../components/MerchantList/MerchantInfiniteList'
import { SuperHOC } from "../HOC";
import ThemeSettings from "../components/common/theme-settings"
class MerchantListScreen extends Component {


  render() {
    return (
      <div>
        <MerchanList  {...this.props} />
        <ThemeSettings />
      </div>
    )
  }
}

export default SuperHOC(MerchantListScreen)