import React, { Component } from 'react'
import StarRatings from 'react-star-ratings';
import classes from './style.module.css'
import { RatingBar } from '../Review'

export default class RatingContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reviews: [],
    }
    this.slug = this.props.slug
  }

  calculateRating(productRating) {
    const numberOfOneStar = productRating.rating1 !== undefined ? productRating.rating1 : 0
    const numberOfTwoStar = productRating.rating2 !== undefined ? productRating.rating2 * 2 : 0
    const numberOfThreeStar = productRating.rating3 !== undefined ? productRating.rating3 * 3 : 0
    const numberOfFourStar = productRating.rating4 !== undefined ? productRating.rating4 * 4 : 0
    const numberOfFiveStar = productRating.rating5 !== undefined ? productRating.rating5 * 5 : 0
    const totalRating = numberOfOneStar + numberOfTwoStar + numberOfThreeStar + numberOfFourStar + numberOfFiveStar
    return totalRating
  }

  renderRating = (obtainRating) => {
    return (
      <StarRatings
        rating={obtainRating !== undefined ? Number(obtainRating) : '0'}
        starRatedColor={"#ffb508"}
        changeRating={this.changeRating}
        numberOfStars={5}
        name='rating'
        starDimension="20px"
        starSpacing="2px"
      />
    )
  }

  render() {

    const { productRating, totalRatings } = this.props.product

    return (
      <div className={classes.mainRatingContainer}>
        <div className={classes.leftRatingContainer}>
          <div className={classes.totalRating}>
            <div className={classes.ratingRow}>
              <h1 style={{ fontSize: 25, }}>
                {(this.calculateRating
                  (productRating !== undefined ? productRating : 0)
                  / (totalRatings === 0 ? 1 : totalRatings) * 5).toFixed(2)}
              </h1>
              <h1 style={{ fontSize: 25, color: 'rgb(158,158,158)' }}>/ 5</h1>
            </div>
            <div>
              {this.renderRating(
                (this.calculateRating(productRating !== undefined ? productRating : 0) /
                  (totalRatings === 0 ? 1 : totalRatings) * 5).toFixed(2))
              }
            </div>
          </div>
          <div className={classes.ratingBarContainer} >
            <RatingBar
              RatedStars={5}
              value={productRating !== undefined ?
                (productRating.rating5 !== undefined ? productRating.rating5 : 0) : 0}
              barValue={productRating !== undefined ?
                (productRating.rating5 !== undefined ? productRating.rating5 : 0) : 0}
              maxBarValue={totalRatings !== undefined ? totalRatings / 5 : 0}
            />
            <RatingBar
              RatedStars={4}
              value={productRating !== undefined ?
                (productRating.rating4 !== undefined ? productRating.rating4 : 0) : 0}
              barValue={productRating !== undefined ?
                (productRating.rating4 !== undefined ? productRating.rating4 : 0) : 0}
              maxBarValue={totalRatings !== undefined ? totalRatings / 5 : 0}
            />
            <RatingBar
              RatedStars={3}
              value={productRating !== undefined ?
                (productRating.rating3 !== undefined ? productRating.rating3 : 0) : 0}
              barValue={productRating !== undefined ?
                (productRating.rating3 !== undefined ? productRating.rating3 : 0) : 0}
              maxBarValue={totalRatings !== undefined ? totalRatings / 5 : 0}

            />
            <RatingBar
              RatedStars={2}
              value={productRating !== undefined ?
                (productRating.rating2 !== undefined ? productRating.rating2 : 0) : 0}
              barValue={productRating !== undefined ?
                (productRating.rating2 !== undefined ? productRating.rating2 : 0) : 0}
              maxBarValue={totalRatings !== undefined ? totalRatings / 5 : 0}

            />
            <RatingBar
              RatedStars={1}
              value={productRating !== undefined ?
                (productRating.rating1 !== undefined ? productRating.rating1 : 0) : 0}
              barValue={productRating !== undefined ?
                (productRating.rating1 !== undefined ? productRating.rating1 : 0) : 0}
              maxBarValue={totalRatings !== undefined ? totalRatings / 5 : 0}

            />
          </div>
        </div>
      </div>
    )
  }
}
