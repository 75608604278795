import React, { Component } from 'react'
import RefundedOrders from '../components/Complain/RefundedOrders'
import { SuperHOC } from '../HOC';


class Refund extends Component {
  render() {
    return (

      <div style={{ minHeight: '70vh' }}>
        <RefundedOrders
          {...this.props}
        />
      </div>
    )
  }
}

export default SuperHOC(Refund);
