import React, { Component } from "react";
import { SuperHOC } from "../HOC";
import { Manager } from "../StorageManager/Storage";
import LoadingLoader from "../components/Loader/Loader";
import CustomAlert from "../components/CustomAlert/CustomAlert";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import NegativeAlert from "../components/CustomAlert/CustomAlert";
import RegisterContext from "../components/common/headers/common/CountContext";
import AccountVerificationComponent from "../components/Login/AccountVerificationComponent";
// import { isMobileSafari } from "react-device-detect";

class AccountVerificationScreen extends Component {
    static contextType = RegisterContext;
    state = {
        otp: "",
        token: null,
        msisdn: "",
        counter: 0,
        hash: "",
        fcmToken: "",
        loading: true,
        takenEmail: null,
        access_token: "",
        showAlert: false,
        errorMessage: "",
        takenPhone: null,
        successLogin: false,
        loginVerification: false,
        faildRegistration: false,
        showPositiveAlert: false,
        conformationAlert: false,
        errorMessageLimit: "",
        showWrongOtpAlert: false,
        currentNumber: "",
        currentEmail: "",
    };

    componentDidMount() {
        var a, dec, b;
        a = this.props.location.search.split("?id=");
        b = a[1].split("&");
        dec = b[0];
        var isLoginVerification = this.props.location.state;
        this.setState({
            msisdn: dec,
            loginVerification: isLoginVerification.isLogin,
        });
        this.props.apiManager.createSession();
        this.sendTacNumber(dec);
    }

    sendTacNumber = (msisdn) => {
        let body = { MSISDN: msisdn };
        this.props.apiManager.makeCall("sendLoginOtp", body, (res) => {
            if (res.errors !== undefined) {
                let erro;

                erro = res.errors.map((err) => {
                    return err;
                });

                if (!erro[0].msg) {
                    this.setState({
                        showPositiveAlert: true,
                        errorMessage: erro[0] ? erro[0].msg : "Invalid Number",
                    });
                } else if (!erro[1]) {
                    this.setState({
                        showPositiveAlert: true,
                        errorMessage: erro[1] ? erro[1] : "Invalid Number",
                    });
                } else {
                    this.setState({
                        showPositiveAlert: true,
                        errorMessage: "Invalid Number",
                    });
                }
            }
            if (res.message === 6107) {
                this.setState({
                    loading: false,
                    showPositiveAlert: false,
                    showAlert: true,
                    errorMessage: "OTP number exceeded!",
                    // access_token: res.data.data.accessToken,
                });
                // setTimeout(() => {
                //     this.props.history.push("/login");
                // }, 3000);
            }
            if (res.message === 7000) {
                this.setState({
                    loading: false,
                    showAlert: true,
                    showPositiveAlert: false,
                    errorMessage: "User Not Found!",
                    // access_token: res.data.data.accessToken,
                });
                // setTimeout(() => {
                //     this.props.history.push("/login");
                // }, 3000);
            }
            if (res.message === 7101) {
                this.setState({
                    loading: false,
                    currentEmail: res.user.email,
                    currentNumber: res.user.phone,
                    conformationAlert: true,
                    errorMessageLimit:
                        "Welcome back! We have upgraded our Black App Security to ensure you receive the best privacy experience while using our App.\n Please set a new password for your account.",
                });
                setTimeout(() => {
                    this.forgetPassword(res.user.email, res.user.phone);
                }, 3000);
            }
            if (res.message === 7031) {
                this.setState({
                    token: res.token,
                    otp: res.otp,
                    counter: 120,
                    loading: false,
                    showPositiveAlert: true,
                    errorMessage: `OTP has been sent!`,
                    // access_token: res.data.data.accessToken,
                });
            }
        });
    };

    forgetPassword = (email, number) => {
        let body = {
            email: email,
        };
        this.props.apiManager.makeCall("forgotPassword", body, (res) => {
            if (res.message === 7066) {
                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: `OTP sent to ${number}`,
                });
                setTimeout(() => {
                    this.props.history.push({
                        pathname: `/ForgetVerification`,
                        search: `id=${number}`,
                        state: { isLogin: false },
                    });
                }, 2000);
            } else if (res.message === 7031) {
                this.setState({
                    conformationAlert: false,
                    loading: false,
                    showPositiveAlert: true,
                    showWrongOtpAlert: false,
                    // showWrongOtpAlert:false,
                    errorMessage: `OTP sent to ${number}`,
                });
                setTimeout(() => {
                    this.props.history.push({
                        pathname: `/ForgetVerification`,
                        search: `id=${number}`,
                        state: { isLogin: false, hash: res.token },
                    });
                }, 2000);
            } else if (res.message === 7000) {
                this.setState({
                    showModal: true,
                    positiveResponse: false,
                    errorMessage: `Email not found ${number}`,
                });
            } else if (res.message === 6107) {
                this.setState({
                    conformationAlert: false,
                    // conformationAlert: true,
                    showWrongOtpAlert: true,
                    // showModal: true,
                    // positiveResponse: true,

                    errorMessage: `OTP Limit Exceeded of ${number} Please wait for 10 minutes`,
                });
                setTimeout(() => {
                    this.props.history.push("/login");
                    // this.setState({
                    //     conformationAlert: false,
                    //     showWrongOtpAlert: false,

                    // });
                }, 3000);
            }
            // else {
            //     this.setState({
            //         showModal: true,
            //         positiveResponse: false,
            //         errorMessage: `Email not found ${email}`,
            //     });
            // }
            //this.props.history.replace('/login');
        });
    };

    goToLoginScreen = () => {
        this.props.history.push("/login");
    };

    goToRegisterScreen = () => {
        let registerData = this.context.data;
        this.props.history.push("/register");
        this.props.history.push({
            pathname: `/register`,
            search: ``,
            state: {
                first_name: registerData.first_name,
                last_name: registerData.last_name,
                password: registerData.password,
                msisdn: registerData.msisdn,
                email: registerData.email,
                hash: this.state.token,
            },
        });
    };

    middlewareRegistration = (body) => {
        this.setState({ loading: true });
        this.props.apiManager.makeCall(
            "v3/black_app/users/register_mobile",
            body,
            (res) => {
                if (res.status && res.code === 24359) {
                    this.setState({
                        successLogin: true,
                        loading: false,
                        errorMessage:
                            "Registration Successful. Kindly proceed to Login",
                    });
                } else {
                    this.setState({
                        faildRegistration: true,
                        loading: false,
                        errorMessage: res.message,
                    });
                }
            }
        );
    };

    byPassb2cLogin = (body) => {
        this.props.apiManager.makeCall("xox_by_pass_login", body, (res) => {
            if (res.token) {
                this.setState({ loading: false });
                this.props.history.replace("/");
                window.location.href = "/";
            } else if (res.message === 7000) {
                this.setState({
                    showWrongOtpAlert: true,
                    loading: false,
                    errorMessage: "User not found!",
                });
            } else if (
                res.message === "1001" ||
                res.message === "1002" ||
                res.message === "1003"
            ) {
                this.setState({
                    showWrongOtpAlert: true,
                    loading: false,
                    errorMessage: "Email Or Password Incorrect",
                });
            } else {
                this.setState({
                    showWrongOtpAlert: true,
                    loading: false,
                    errorMessage: res.decodedMessage,
                });
            }
        });
    };

    middlewareMobileLogin = (body) => {
        this.setState({ loading: true });
        Manager.getItem("fcmToken", true).then((result) => {
            this.setState({ fcmToken: result });
        });
        let notificationKey =
            this.state.fcmToken !== "" ? this.state.fcmToken : "1";
        this.props.apiManager.makeCall("loginWithTac", body, (res) => {
            if (res.message === 7051) {
                Manager.setItem("userEmail", res.user.userId);
                Manager.setItem("accessTokenMdlwr", res.token, false);

                // call B2c API
                let body = {
                    role: 1,
                    userId: res.user.userId,
                    accessToken: res.token,
                    notificationStatus: 1,
                    notificationKey: notificationKey,
                };
                this.byPassb2cLogin(body);
            } else if (res.message === 7039) {
                this.setState({
                    showWrongOtpAlert: true,
                    loading: false,
                    errorMessage: "Wrong OTP. Please Try Again!",
                });
            } else if (res.message === 6113) {
                this.setState({
                    showWrongOtpAlert: true,
                    loading: false,
                    errorMessage: "OTP Expired. Please Resend OTP!",
                });
            } else if (res.message === 6112) {
                this.setState({
                    showWrongOtpAlert: true,
                    loading: false,
                    token: body.token,
                    errorMessage: "OTP Expired. Please Resend OTP!",
                });
            } else {
                this.setState({
                    showWrongOtpAlert: true,
                    loading: false,
                    errorMessage: res.message,
                });
            }
        });
    };

    verifyTac = (body) => {
        this.setState({ loading: true });
        this.props.apiManager.makeCall(
            "v3/black_app/self_care/verify_tac",
            body,
            (res) => {
                if (res.status && res.code === 24185) {
                    let registerData = this.context.data;
                    if (registerData === undefined || registerData === null) {
                        this.setState({
                            faildRegistration: true,
                            loading: false,
                            errorMessage:
                                "Please enter correct data and try again. Thanks!",
                        });
                    }

                    let regsiterBody = {
                        access_token: body.access_token,
                        first_name: registerData.first_name,
                        last_name: registerData.last_name,
                        password: registerData.password,
                        msisdn: registerData.msisdn,
                        email: registerData.email,
                    };

                    this.middlewareRegistration(regsiterBody);

                } else if (res.code === 24352) {
                    this.setState({
                        showAlert: true,
                        loading: false,
                        errorMessage: res.data.message,
                    });
                } else {
                    this.setState({
                        showAlert: true,
                        loading: false,
                        errorMessage: res.message,
                    });
                }
            }
        );
    };

    goToBackScreen = () => {
        this.props.history.push("/login");
    };

    render() {
        if (this.state.loading)
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "70vh",
                        backgroundColor: "white",
                    }}
                >
                    <LoadingLoader visible={this.state.loading} />
                </div>
            );

        return (
            <div>
                <AccountVerificationComponent
                    {...this.props}
                    msisdn={this.state.msisdn}
                    onSubmit={(data) => {
                        let body = {};

                        if (data.resend_hash) {
                            this.setState({ token: data.resend_hash });
                        }

                        body = {
                            msisdn: this.state.msisdn,
                            hash: data.resend_hash
                                ? data.resend_hash
                                : this.state.token,
                            otp: data.tac,
                        };

                        if (this.state.loginVerification)
                            this.middlewareMobileLogin(body);
                        else this.verifyTac(body);
                    }}
                />
                <PositiveAlert
                    title={"Error"}
                    message={this.state.errorMessage}
                    open={this.state.showPositiveAlert}
                    onClose={() => this.setState({ showPositiveAlert: false })}
                />
                <NegativeAlert
                    error
                    title={"Error"}
                    open={this.state.showAlert}
                    message={this.state.errorMessage}
                    onClose={() => this.goToBackScreen()}
                />
                {/* <PositiveAlert
                    error
                    title={"Error"}
                    open={this.state.faildRegistration}
                    message={this.state.errorMessage}
                    onClose={() => this.goToRegisterScreen()}
                /> */}
                {/* <PositiveAlert
                    title={"Error"}
                    open={this.state.successLogin}
                    message={this.state.errorMessage}
                    onClose={() => this.goToLoginScreen()}
                /> */}
                <PositiveAlert
                    error
                    title={"Error"}
                    message={this.state.errorMessage}
                    open={this.state.showWrongOtpAlert}
                    onClose={() => this.setState({ showWrongOtpAlert: false })}
                />
                {/* <ConformationAlert
                    error
                    title={"Error"}
                    message={this.state.errorMessage}
                    open={this.state.conformationAlert}
                    onClose={() => this.setState({ conformationAlert: false })}
                /> */}
                <CustomAlert
                    title={"Error"}
                    message={this.state.errorMessageLimit}
                    open={this.state.conformationAlert}
                    onClose={
                        () => console.info("close is off")
                    }
                />
            </div>
        );
    }
}
export default SuperHOC(AccountVerificationScreen);
