import React, { Component } from 'react';
import ImageCarousel from "./ImageCaraousel";
import Breadcrumb from '../common/breadcrumb';
import LoadingLoader from "../Loader/Loader";
class MerchantProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant: {},
      loading: true,
      email: ''
    };
  }


  getMerchantDetail = () => {
    // // let parentEmail = this.props.match.params.product;
    // let azab = await JSON.parse(localStorage.getItem('azab'))
    // let email = this.props.match.params.product
    // let body;
    // if (email.includes('@')) {
    //   body = {
    //     email: email
    //   }
    // }
    // else {
    //   body = {
    //     email: azab
    //   }
    // }

    let body = {
      email: this.state.email
    }

    this.props.apiManager.makeCall('get_merchant_detail', body, res => {
      if (res.merchant) {
        this.setState({
          merchant: res.merchant,
          loading: false
        });
      } else {
        console.error('Error while fetching merchant detail: ', res);
      }
    }
    )
  }

  componentDidMount = () => {
    var a
    var dec
    // if (this.props.location.state && this.props.location.state.email && this.props.location.state.email !== '') {
    //   this.setState({
    //     email: this.props.location.state.email
    //   }, () => this.getMerchantDetail()
    //   )
    // }
    // else {
    a = this.props.location.search.split('?id=')
    let b = a[1].split('&')
    dec = window.atob(b[0])
    this.setState({
      email: dec
    }, () => this.getMerchantDetail())
  }
  // }

  render() {
    let id = this.props.location.search.split('?id=')

    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}><LoadingLoader visible={this.state.loading} /></div>
    return (
      <div>
        {/* <div style={{ marginBottom: '40px' }}>
          <Breadcrumb title={'Merchant Profile'} />
        </div> */}
        <ImageCarousel
          {...this.props}
          item={this.state.merchant}
          id={id[1]}
        />
      </div>
    )
  }
}

export default MerchantProfile;