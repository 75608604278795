import React, { Component } from 'react'
import ToPay from '../components/toPay/MainContainer'
import { SuperHOC } from "../HOC";
class CheckoutScreen extends Component {


  render() {

    return (
      <div>
        <ToPay
          {...this.props}
        />
      </div>
    )
  }
}
export default SuperHOC(CheckoutScreen)