import React, { Component } from 'react'
import { SuperHOC } from "../../HOC";
import {Link} from 'react-router-dom'
class ChatMobileSidebar extends Component {
  constructor() {
    super()
    this.state = {
      selectedChat: null,
      getBuyerChat: [],
      getBuyerChatItem: [],
      image: [],  // for Chat image show from Get buyer chat sider
      inputData: '',
      addMessage: '',
      slugSet: '',
      chatIdSent: '',
      topName: '',
      getBuyerChatItemId: '',
      idShowChatSet: ''
    }
  }
  //API's

  componentDidMount() {
    this.props.apiManager.makeCall('get_buyer_chats', {}, (res) => {
      if (res) {
        this.setState({
          getBuyerChat: res.result.docs,
          image: res.result.docs,
        })
      }
    })
  }

  renderSideBar = () => {
    if(this.state.getBuyerChat !== ''){
    return (
      <div className="sidebar" id="sidebar">
        <div className="container">
          <div className="col-md-12">
            <div className="tab-content">
              <div id="discussions" className="tab-pane fade active show">
                <div className="discussions">
                  <h1>Discussions</h1>
                  <div className="list-group" id="chats" role="tablist">
                    {
                      this.state.getBuyerChat.map((getBuyerChat, index) => {
                        let timeZone = new Date(getBuyerChat.createdAt);
                        // let hours = timeZone.getUTCHours();
                        // let min = timeZone.getUTCMinutes();
                        var weekday = new Array(7);
                        weekday[0] = "Sunday";
                        weekday[1] = "Monday";
                        weekday[2] = "Tuesday";
                        weekday[3] = "Wednesday";
                        weekday[4] = "Thursday";
                        weekday[5] = "Friday";
                        weekday[6] = "Saturday";
                        var day = weekday[timeZone.getDay()];
                        return (
                          <Link to={`/mobileChat/${getBuyerChat.id}`} style={{ cursor: 'pointer' }} className="filterDiscussions all unread single active" id="list-chat-list"
                            data-toggle="list" role="tab">
                            <img className="avatar-md" src={getBuyerChat.imageProduct[0].src} data-toggle="tooltip"
                              data-placement="top" title="Janette" alt="avatar" />
                            <div className="data">
                              &nbsp;
                              &nbsp;
                              <h5>{getBuyerChat.productName.substring(0,25)}</h5>
                              <span>{day}</span>
                              {/* <p>{merchantSidebar.message.substr(0, 56)}</p> */}
                            </div>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
   }
   else{
    return (
      <div className="sidebar" id="sidebar">
        <div className="container">
          <div className="col-md-12">
            <div className="tab-content">
              <div id="discussions" className="tab-pane fade active show">
                <div className="discussions">
                  <h1>Discussions</h1>
                  <div className="list-group" id="chats" role="tablist">
                  <p style={{ display: 'flex',marginTop: '50px', fontSize: '20px', justifyContent:'center', marginBottom:'70px'}}>No conversation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
   }
  }

  render() {
    return (
      <div className='chat-setion'>
        <div className='layout'>
        {this.renderSideBar()}
        </div>
      </div>
    )
  }
}

export default SuperHOC(ChatMobileSidebar);
