import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";

class Contact extends Component {
  render() {
    return (
      <div>
        <Breadcrumb title={'Contact Us'} />
        <div style={{ paddingTop: 40 }}>
          <section className="contact-page section-b-space">
            <div className="container">
              <div className="row section-b-space">
                <div className="col-lg-7 map">
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.864266763426!2d101.59269681532706!3d3.1305558977204604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc495dce7ee263%3A0x44a890304364e093!2sXOX%20Com%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1639450802735!5m2!1sen!2smy" 
                    width="600" 
                    height="450" 
                    allowfullscreen="" 
                    loading="lazy" 
                  />
                </div>
                <div className="col-lg-5">
                  <div className="contact-right">
                    <ul>
                      {/* <li>
                        <div className="contact-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`} alt="Generic placeholder" style={{ width: '15px', height: '15px' }} />
                          <h6>Contact Us</h6>
                        </div>
                        <div className="media-body">
                          <p>From Malaysia: 1300 888 010</p>
                          <p>From Your XOX Mobile: 12273</p>
                          <p>From Abroad: +603-7800 0033</p>
                        </div>
                      </li> */}
                      <li>
                        <div className="contact-icon">
                          <i className="fa fa-map-marker" aria-hidden="true"></i>
                          <h6>Address</h6>
                        </div>
                        <div className="media-body">
                          <h6 style={{ fontWeight: 'bold', color: '#000000' }}>XOX Com Sdn Bhd 200501027788 (709922-X)</h6>
                          <h6 style={{ fontWeight: 'bold', color: '#000000' }}>XOX Mobile Sdn Bhd 200801011962 (813250-K)</h6>
                          <p>Lot 17.1, 17th Floor, Menara Lien Hoe,</p>
                          <p>No. 8, Persiaran Tropicana,</p>
                          <p>Tropicana Golf & Country Resort,</p>
                          <p>47410 Petaling Jaya, Selangor Darul Ehsan.</p>
                        </div>
                      </li>
                      <li>
                        <div className="contact-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`} alt="Generic placeholder" style={{ width: '18px'}} />
                          <h6>Email</h6>
                        </div>
                        <div className="media-body">
                          <p>Buyer Related Enquiries : enq_blackmarket@xox.com.my</p>
                          <p>Seller Related Enquiries : seller_blackmarket@xox.com.my</p>
                        </div>
                      </li>
                      {/* <li>
                        <div className="contact-icon">
                          <i className="fa fa-fax" aria-hidden="true"></i>
                          <h6>Fax</h6>
                        </div>
                        <div className="media-body">
                          <p>Support@pindal.pk</p>
                          <p>info@pindal.pk</p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-sm-12">
                  <form className="theme-form">
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="name">First Name</label>
                        <input type="text" className="form-control" id="name"
                          placeholder="Enter First Name" required="" />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Last Name</label>
                        <input type="text" className="form-control" id="last-name"
                          placeholder="Enter Last Name" required="" />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="review">Phone number</label>
                        <input type="text" className="form-control" id="review"
                          placeholder="Enter your number" required="" />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Email</label>
                        <input type="text" className="form-control" id="email" placeholder="Enter Your Email"
                          required="" />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Inquiries</label>
                        <select className="form-control" id="inquiry" placeholder="Select one" required="" style={{ marginBottom: '30px', height: '50px', fontSize: '12px', padding: '10px 25px' }}>
                          <option value="enq_blackmarket@xox.com.my">I am Buyer</option>
                          <option value="seller_blackmarket@xox.com.my">I am Seller</option>
                          // <option value="enq_epartner@xox.com.my">I am ePartner</option>
                          // <option value="elite_epartner@xox.com.my">I am Elite ePartner</option>
                        </select>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="review">Write Your Message</label>
                        <textarea className="form-control" placeholder="Write Your Message"
                          id="exampleFormControlTextarea1" rows="6"></textarea>
                      </div>
                      <div className="col-md-12">
                        <button className="btn btn-solid" type="submit">Send Your Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default Contact