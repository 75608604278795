import React, { Component } from 'react'
import Schedule from '../components/Schedule/Schedule'
import {SuperHOC} from '../HOC'


class ScheduleScreen extends Component {
   
   
    render(){
        return(
            <div>
                <Schedule
                {...this.props}
                />
            </div>
        )
    }
}
export default SuperHOC(ScheduleScreen)