import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import SimpleReactValidator from 'simple-react-validator';
import Breadcrumb from "../common/breadcrumb";
import { removeFromWishlist } from '../../actions'
import { getCartTotal } from "../../services";

class checkout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            payment: 'stripe',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            country: '',
            address: '',
            city: '',
            state: '',
            pincode: '',
            create_account: ''
        }
        this.validator = new SimpleReactValidator();
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);
        if (!this.validator.fieldValid(event.target.name)) {
            this.validator.showMessages();
        }
    }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }

    StripeClick = () => {

        if (this.validator.allValid()) {
            alert('You submitted the form and stuff!');
            var handler = (window).StripeCheckout.configure({
                key: 'pk_test_glxk17KhP7poKIawsaSgKtsL',
                locale: 'auto',
                token: (token: any) => {
                    this.props.history.push({
                        pathname: '/order-success',
                        state: { payment: token, items: this.props.cartItems, orderTotal: this.props.total, symbol: this.props.symbol }
                    })
                }
            });
            handler.open({
                name: 'Multikart',
                description: 'Online Fashion Store',
                amount: this.amount * 100
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { cartItems, symbol, total } = this.props;
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>CheckOut</title>
                    <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
                </Helmet>
                {/*SEO Support End */}
                <Breadcrumb title={'Checkout'} />
                <section className="section-b-space">
                    <div className="padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <form>
                                    <div className="checkout row m-2">
                                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                            <section className="cart-section section-b-space">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${'item.id'}`}>
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/landing-page/icon/1.png`} alt="" />
                                                                </Link>
                                                                <h6 className="descriptioncheckout"> Ipone X 64 gb jet black color full lush push </h6>
                                                                <div className='column'>
                                                                    <h6 style={{ color: 'black' }}>
                                                                        RM 240,000
                                                                        </h6>
                                                                    <del><span className='money' > RM 250,000</span></del>
                                                                </div>
                                                                <h6 > Qty:1 </h6>
                                                            </div>
                                                            <table className=" table cart-table table-responsive-md">
                                                                <tfoot>
                                                                    <tr>
                                                                        <td>total price :</td>
                                                                        <td><h2>{symbol} {total} </h2></td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div >
                                                    </div >
                                                    <div className=" row cart-buttons">
                                                        <div className=" col-12">
                                                            <Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">check out</Link>
                                                        </div >
                                                    </div >
                                                </div >
                                            </section >
                                        </div >
                                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 cart-side-bar">
                                            <div className="checkout-details">
                                                <div className="order-box">
                                                    <div className="title-box">
                                                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>Shipping & Billing</div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '5px' }}>
                                                            <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />
                                                            <h4 style={{ fontSize: '16px', marginLeft: '14px', marginTop: '2px' }}>address</h4>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '5px' }}>
                                                            <FontAwesomeIcon icon={faPhoneAlt} size="sm" />
                                                            <h4 style={{ fontSize: '16px', marginLeft: '10px', marginTop: '2px' }}>Phone</h4>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '5px' }}>
                                                            <FontAwesomeIcon icon={faEnvelope} size="sm" />
                                                            <h4 style={{ fontSize: '16px', marginLeft: '10px', marginTop: '2px' }}>Email</h4>
                                                        </div>
                                                    </div>
                                                    <ul className="qty">
                                                        <li>Product Total <span>RM </span></li>
                                                        <li>Services Total <span>RM </span></li>
                                                    </ul>
                                                    <ul className="sub-total">
                                                        <li>Shipping <span className="count">RM 150</span></li>
                                                        <li>Subtotal <span className="count">RM 500</span></li>
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5, height: '40px' }}>
                                                            <input type="text" className="form-control" style={{ display: 'flex', width: '65%', backgroundColor: '#f5f2f2', height: 'auto' }} id="exampleFormControlInput"
                                                                placeholder="Promo Code" />
                                                            <button style={{ padding: '5px 15px 5px 15px' }} type="submit" className="btn btn-solid">Apply</button>
                                                        </div>
                                                        <li style={{ fontSize: 14 }}>Promo Code <span style={{ fontSize: 14 }} className="count">RM 50</span></li>
                                                    </ul>
                                                    <ul className="total">
                                                        <li>Total <span className="count">RM 700</span></li>
                                                    </ul>
                                                    <div >
                                                        <div>
                                                            <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} className="btn btn-solid">Proceed To Pay</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </form >
                            </div >
                        </div >
                    </div >
                </section >
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    { removeFromWishlist }
)(checkout)