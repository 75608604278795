import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      navClose: { right: '0px' }
    }
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: '-410px' } })
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: '-300px' } })
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ navClose: { right: '-410px' } })
    }
  }

  openNav() {
    this.setState({ navClose: { right: '0px' } })
  }
  closeNav() {
    this.setState({ navClose: { right: '-410px' } })
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className='hellofrom'>
        <div className="main-navbar">
          <div id="mainnav" >
            <div className="toggle-nav" onClick={this.openNav.bind(this)} >
              <i className="fa fa-bars sidebar-bar"></i>
            </div>
            <ul className="nav-menu" style={this.state.navClose}>
              <li className="back-btn" onClick={this.closeNav.bind(this)} >
                <div className="mobile-back text-right">
                  <span >Back</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              <li onClick={this.closeNav.bind(this)}>
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li onClick={this.closeNav.bind(this)}>
                <Link to={`${process.env.PUBLIC_URL}/wishList`} className="nav-link">Wishlist</Link>
              </li>
              <li onClick={this.closeNav.bind(this)}>
                { this.props.apiManager.token ?
                    <Link to={`${process.env.PUBLIC_URL}/account`}>My Account</Link>
                  :
                  <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login</Link>
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}


export default NavBar;