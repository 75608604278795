import { Manager } from "../StorageManager/Storage";
import ErrMsg from "./string-error-code-en";
import CryptoJS from "crypto-js";
import config from "./config";
import { constants } from "../config/constants";
import * as Fingerprint2 from "fingerprintjs2";
import { login } from "../utils/session";

const publicIp = require("react-public-ip");

const URL = (apiName) => {
    if (apiName in middlewareApis) {
        return `${config.xox_url}${apiName}`;

    } else if (apiName in ssoApis) {
        return `${process.env.REACT_APP_LIVE_PRODUCTION_URL_SSO}/api/${apiName}`;

    } else {
        return (
            `${process.env.REACT_APP_LIVE_PRODUCTION_URL_ECOMMERENCE}/api/${apiName}`
        ); // ecommerence
    }
};

const clientId = process.env.REACT_APP_DEFAULT_CLIENT_ID;
const clientSecret = process.env.REACT_APP_DEFAULT_CLIENT_SECRET;

const TIMEOUT = 15000; // ten seconds

// list of Middleware APIs
let middlewareApis = { "payment/createOrder": 1 };
let ssoApis = {
    login: 1,
    getUser: 1,
    createSession: 1,
    changeEmail: 1,
    registerData: 1,
    loginWithTac: 1,
    sendLoginOtp: 1,
    resetPassword: 1,
    changeMSISDN: 1,
    verifyPassword: 1,
    editUserProfile: 1,
    forgotPassword: 1,
    changePassword: 1,
    sendRegisterOtp: 1,
    emailVerification: 1,
    verifyRegisterOTP: 1,
    sendTransactionTac: 1,
    verifyTransactionTac: 1,
    verifyChangeMSISDN: 1,
    emailVerificationRequest: 1,
};

export default class ApiManager {
    static GET = "GET";
    token = null;
    parent = null;
    sessionId = null;
    oneTimeFlag = 1;

    constructor(parent) {
        this.parent = parent;
    }

    // this function must call before calling makeCall otherwide its not get token from local if its saved
    async Initialize() {
        await this.assignToken();
    }

    postServer = (apiName, options) => {
        return Promise.race([
            fetch(URL(apiName), options),
            new Promise((_, reject) =>
                setTimeout(
                    () =>
                        reject({
                            error: -1,
                            message: "Timeout with " + TIMEOUT,
                        }),
                    TIMEOUT
                )
            ),
        ]);
    };

    getParams = (apiName) => {
        let apiUrl = URL(apiName);
        return {
            apiUrl,
        };
    };

    translateResponse = (response) => {
        /**
         * statuses
         *  0 -> success
         *  1 ->
         *  2 -> token need
         */
        let status = 0;
        let res = null;

        if (response.token || response.status != undefined || response.errors) {
            return response;
        }

        if (response.message) {
            response.decodedMessage = ErrMsg(response.message);
            if (response.message === "1003" || response.message === "1004") {
                // show login modal here
                this.parent.redirect();
                status = 2;
                res = response;
            } else res = response;
        }
        return res;
    };

    temperedBody = (body) => {
        let tempBody = {};
        let secretData = this.encode(body);
        tempBody.data = secretData;
        return body;
    };

    createLoginOptions = (body) => {
        let tempBody = this.temperedBody(body);
        return {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: "Basic " + this.token,
                "session-id": this.sessionId,
                "x-client-Id": process.env.REACT_APP_X_CLIENT_ID,
                "x-client-Key": process.env.REACT_APP_REACT_APP_X_CLIENT_KEY,
            },
            credentials: "include",
            body: JSON.stringify(tempBody),
        };
    };

    createLoginOptionsGet = () => {
        return {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "session-id": this.sessionId,
                Authorization: "Basic " + this.token,
                "x-client-Id": process.env.REACT_APP_X_CLIENT_ID,
                "x-client-Key": process.env.REACT_APP_REACT_APP_X_CLIENT_KEY,
            },
            credentials: "include",
        };
    };

    // create headers for middleware.
    createMiddlewarePaymentOrder = (body) => {
        let tempBody = this.temperedBody(body);
        return {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "session-id": this.sessionId,
                Authorization: "Basic " + this.token,
            },
            body: JSON.stringify(tempBody),
        };
    };

    createMiddlewarePaymentOrderGet = () => {
        return {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "session-id": this.sessionId,
                Authorization: "Basic " + this.token,
            },
        };
    };

    createSession = () => {
        Manager.getItem("session-id", false).then((session) => {
            if (session.length === 0) {
                return fetch(config.session, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Basic " +
                            CryptoJS.enc.Base64.stringify(
                                CryptoJS.enc.Utf8.parse(
                                    clientId + ":" + clientSecret
                                )
                            ),
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        Manager.setItem(
                            "session-id",
                            data.data.session_id,
                            false
                        );
                        this.sessionId = data.data.session_id;
                    });
            } else {
                this.sessionId = session;
            }
        });
    };

    createWithOutLoginOptions = (body) => {
        let tempBody = this.temperedBody(body);
        return {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "session-id": this.sessionId,
                "Content-Type": "application/json",
                "x-client-Id": process.env.REACT_APP_X_CLIENT_ID,
                "x-client-Key": process.env.REACT_APP_REACT_APP_X_CLIENT_KEY,
            },
            credentials: "include",
            body: JSON.stringify(tempBody),
        };
    };

    createWithOutLoginOptionsGet = () => {
        return {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "session-id": this.sessionId,
                "Content-Type": "application/json",
                "x-client-Id": process.env.REACT_APP_X_CLIENT_ID,
                "x-client-Key": process.env.REACT_APP_REACT_APP_X_CLIENT_KEY,
            },
            credentials: "include",
        };
    };

    assignToken = async () => {
        let token = await Manager.getItem("token", false);
        this.token = token;
    };

    saveToken = (email, token) => {
        let rawStr = email + ":" + token;
        let wordArray = CryptoJS.enc.Utf8.parse(rawStr);
        let base64 = CryptoJS.enc.Base64.stringify(wordArray);
        this.token = base64;
        Manager.setItem("token", base64, false);
        this.saveUser();
    };

    saveUser = () => {
        this.post("get_user_details", {}, (result) => {
            if (result.message) {
                if (result.message === "1397") {
                    Manager.setItem("user", result.user);
                }
            }
        });
    };

    setQueryParams = (apiUrl, body) => {
        let tempValue = apiUrl;
        Object.keys(body).map((value, index) => {
            tempValue += "/?" + value + "=" + body[value];
        });
        return tempValue;
    };

    getFetch = (apiName, options) => {
        return Promise.race([
            fetch(apiName, options),
            new Promise((_, reject) =>
                setTimeout(
                    () =>
                        reject({
                            error: -1,
                            message: "Timeout with " + TIMEOUT,
                        }),
                    TIMEOUT
                )
            ),
        ]);
    };

    get = (apiName, body, callback) => {
        let options = null;

        if (this.token) {
            if (apiName in middlewareApis) {
                options = this.createMiddlewarePaymentOrderGet();

            } else if (apiName in ssoApis) {
                options = this.createLoginOptionsGet();
            }
        } else {
            if (!apiName in middlewareApis) {
                options = this.createWithOutLoginOptionsGet();

            } else if (apiName in ssoApis) {
                options = this.createLoginOptionsGet();

            } else {
                options = this.createMiddlewarePaymentOrderGet();
            }
        }

        // set query params
        this.getFetch(this.setQueryParams(URL(apiName), body), options)
            .then(async (res) => {
                res = await res.json();
                res.apiName = apiName;
                callback(res);
            })
            .catch((e) => {
                console.error("Error: ", e);
                // this.parent.showTimeoutError && this.parent.showTimeoutError(e);
                // show timeout modal here
            });
    };

    post = (apiName, body, callback) => {
        let options = null;

        if (this.token) {
            if (apiName in middlewareApis) {
                options = this.createMiddlewarePaymentOrder(body);
            } else if (apiName in ssoApis) {
                options = this.createLoginOptions(body);
            } else {
                options = this.createLoginOptions(body);
            }
        } else {
            if (apiName in middlewareApis) {
                options = this.createWithOutLoginOptions(body);
            } else if (apiName in ssoApis) {
                options = this.createLoginOptions(body);
            } else {
                options = this.createMiddlewarePaymentOrder(body);
            }
        }

        this.postServer(apiName, options)
            .then(async (res) => {
                res = await res.json();
                res.apiName = apiName;
                if (constants.favorite.indexOf(apiName !== -1)) {
                    if (res.buyer) res.buyer.role = 1;
                }
                let response = this.translateResponse(res);

                if (apiName === "xox_by_pass_login" && res.token) {
                    this.saveToken(res.userId, res.token);

                    try {
                        await login();
                    } catch (error) {
                        console.error('Something went wrong', error)
                    }
                }

                if (apiName === "add_favorite_merchant") {
                    console.info("api mngr");
                }

                callback(response);
            })
            .catch((e) => {
                console.error("Error: ", e);
                // this.parent.showTimeoutError && this.parent.showTimeoutError(e);
                // show timeout modal here
            });
    };

    getIp = async () => {
        let ipv4 = (await publicIp.v4()) || "";
        return ipv4;
    };

    getDeviceId = () => {
        return new Promise((resolve, reject) => {
            if (window.requestIdleCallback) {
                requestIdleCallback(() => {
                    Fingerprint2.get((components) => {
                        let values = components.map((com) => {
                            return com.value;
                        });
                        let device_id = Fingerprint2.x64hash128(
                            values.join(""),
                            31
                        );
                        resolve(device_id);
                    });
                });
            } else {
                setTimeout(() => {
                    Fingerprint2.get((components) => {
                        let values = components.map((com) => {
                            return com.value;
                        });
                        let device_id = Fingerprint2.x64hash128(
                            values.join(""),
                            31
                        );
                        resolve(device_id);
                    });
                }, 500);
            }
        });
    };

    getMergedWithTime = (str, milli) => {
        let strMerged = "";
        for (let i = 0; i < milli.length; i++) strMerged += str[i] + milli[i];
        strMerged += str.substr(milli.length);
        return strMerged;
    };

    encode = (body) => {
        let msg = JSON.stringify(body);
        let key = Date.now().toString();
        let decrptedMsg = CryptoJS.AES.encrypt(msg, key).toString();
        let getMergedWithTime = this.getMergedWithTime(decrptedMsg, key);
        return getMergedWithTime;
    };

    async makeCall(apiName, body, callback) {
        body.ip = "IP";
        body.mac = "ID";
        this.post(apiName, body, (res) => {
            if (this.oneTimeFlag == 1) {
                this.oneTimeFlag++;
                this.createSession();
            }
            callback(res);
        });
    }
}
