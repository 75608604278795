import React, { Component } from 'react';
// import image from '../assets/terms-&-conditions.jpg';
import Breadcrumb from "../components/common/breadcrumb";

class WarrantyPolicy extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div style={{ minHeight: '100vh' }}>
        <Breadcrumb title={'Warranty Policy'} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{ marginTop: 44 }}>
                <div className="banner-section">
                  {/* <img src={image} className="img-fluid" alt="" /> */}
                </div>
              </div>
              <div className="col-sm-12">
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h2>Warranty Policy</h2>
                </div>
                <br />

                <p>
                  Pindal.pk provides you with a 7days replacement/checking warranty on every purchase with us.
                </p>
                <br />
                <h5>How would I know whether my item has a warranty?</h5>
                <p>
                  •	Only those item(s) have a warranty which has been determined by merchant/seller.
                <br />
                  •	You can check warranty through product page or by a chat with seller.
                </p>

                <br />
                <br />

                <h5>What type of warranty does Pindal.pk have?</h5>
                <p>
                  •   <b>Marchant Warranty:</b> The warranty service is extended by the Seller/merchant on Pindal.pk. You should directly contact the seller or the authorized service center through the details provided by the Seller.
                <br />
                  •    <b>Manufacturer Warranty:</b> The warranty service is offered by a local manufacturer. Warranty can be claimed at any authorized Service Center inside Pakistan.
                </p>
                <br />

                <b>
                <h4>Note: </h4>Please note if any part of the item/product needs to be replaced for repair under this warranty policy, the customer will have to pay that part. Furthermore, customer induced damage, such as broken display, scratch, dent, burn, self-repair, soaked or liquid damage, manual update failure to comply with product manual and so on is considered as a violation of warranty policy.
                </b>
                <br />

              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}
export default WarrantyPolicy