import React, { Component } from 'react'
import { NegotiationChat } from "../components/Negotiation";

import { SuperHOC } from "../HOC";

class NegotiationChatScreen extends Component {
  render() {
    return (
      <div>
        <NegotiationChat
          {...this.props}
        />
      </div>
    )
  }
}

export default SuperHOC(NegotiationChatScreen)