import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SlideUpDown } from "../../../services/script"
import LogoImage from '../headers/common/logo';

class FooterFour extends Component {

  componentDidMount() {
    var contentwidth = window.innerWidth;
    if ((contentwidth) < 750) {
      SlideUpDown('footer-title');
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function (elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }


  render() {

    return (
      <footer id='footer' style={{marginBottom:0}}>
        <section className="section-b-space darken-layout" style={{backgroundColor:'#000000'}}>
          <div className="container" style={{ paddingTop: "50px" }}>
            <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4>about</h4>
                </div>
                <div className="footer-contant">
                  <div className="footer-logo" style={{ padding: 1 }}>
                      <LogoImage logo={`${process.env.PUBLIC_URL}/assets/Black_Market_Logo_Header_White.png`} style={{ minWidth: '120px', maxWidth: '40%', width: '40%', height: 'auto'}} />
                    </div>
                  {/* <p>XOX Black Market is an emerging online marketplace with distinctive features that are introduced for the first time in Pakistan on the e-commerce industry.
                    We likewise provide an online service solution for different service providers on an e-commerce platform.
                  </p> */}
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/theXOXBlackMarket" target="_blank" rel="noopener noreferrer"> <i className="fa fa-facebook" aria-hidden="true"></i></a>
                      </li>
                      {/* <li>
                        <a href="https://twitter.com/xox_malaysia?lang=en" target="_blank" rel="noopener noreferrer"> <i className="fa fa-twitter" aria-hidden="true"></i></a>
                      </li> */}
                      <li>
                        <a href="https://www.instagram.com/xoxblackmarket/" target="_blank" rel="noopener noreferrer"> <i className="fa fa-instagram" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/xox-bhd" target="_blank" rel="noopener noreferrer"> <i className="fa fa-linkedin" aria-hidden="true"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col offset-xl-1">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>my account</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li><a href={`${process.env.PUBLIC_URL}/WishList`} >WishList</a></li>
                      {/* <li><a href={`${process.env.PUBLIC_URL}/Schedule`} >Schedule</a></li> */}
                      {/* <li><a href={`${process.env.PUBLIC_URL}/AddAddress`} >Delivery Address</a></li> */}
                      <li><a href={`${process.env.PUBLIC_URL}/OrderHistoryScreen`} >My Order</a></li>
                      {/* <li><a href={`${process.env.PUBLIC_URL}/wallet`} >Wallet</a></li> */}
                      {/* <li><a href={`${process.env.PUBLIC_URL}/complains`} >Complain</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>Help</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      {/* <li><a href="/about">About Us</a></li> */}
                      {/* <li><a href="/Terms">Terms & Conditions</a></li>
                      <li><a href="/RefundPolicy">Black Market Buyer Policy</a></li> */}
                      <li><a href="/Faq">FAQs</a></li>
                      <li><a href="/contact">Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>store information</h4>
                  </div>
                  <div className="footer-contant">
                    <ul className="contact-list">
                      <li>
                          <i className="fa fa-map-marker fa-lg"></i>
                          Our Main Office: <br />
                          Lot 17.1, 17th Floor, Menara Lien Hoe, 
                          No. 8, Persiaran Tropicana, 
                          Tropicana Golf & Country Resort, 
                          47410 Petaling Jaya, Selangor Darul Ehsan.
                      </li>
                      {/* <li style={{ textTransform: 'none' }}>
                        <i className="fa fa-phone fa-md"></i>
                        Call us: <br />
                        From Malaysia - 1300 888 010 <br />
                        From Your XOX Mobile - 12273 <br />
                        From Abroad - +603-7800 0033
                      </li> */}
                      <li style={{ textTransform: 'none' }}>
                        <i className="fa fa-envelope-o fa-md"></i>
                        enq_blackmarket@xox.com.my
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer dark-subfooter" style={{backgroundColor:'#000000',borderTop:'0.1px solid rgba(255,255,255,0.3)'}}>
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="footer-end" style={{ paddingTop: 20 }}>
                  <p>Copyright © 2024 XOX Media Sdn Bhd 200701005355 (763354-U). All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="payment-card-bottom" style={{textAlign:'center'}}>
                  <ul>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.xoxblackapp&hl=en" target="_blank" rel="noopener noreferrer">
                        {/* <i className="fa fa-play"></i> */}
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/google-play-badge.png`}
                          width='150px' height='40px'
                          style={{margin:'3px 3px 3px 0'}}
                          // style={{ borderRadius: '10px' }} 
                          alt="Nothing" />
                      </a>
                    </li>
                    <li>
                      <a href="https://apps.apple.com/my/app/xox-black/id1458845942?l=EN" target="_blank" rel="noopener noreferrer">
                        {/* <i className="fa fa-apple"></i> */}
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/app-store.png`}
                          width='150px' height='40px'
                          style={{margin:'3px 3px 3px 0'}}
                          // style={{ borderRadius: '10px' }} 
                          alt="Nothing" />
                      </a>
                    </li>
                    <li>
                      <a href="https://appgallery.huawei.com/app/C101429711" target="_blank" rel="noopener noreferrer">
                        {/* <i className="fa fa-apple"></i> */}
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/huawei-Badge-Black 2.png`}
                          width='150px' height='40px'
                          style={{margin:'3px 3px 3px 0'}}
                          // style={{ borderRadius: '10px' }} 
                          alt="Nothing" />
                      </a>
                    </li>

                    {/* <li>
                      <Link to={`${process.env.PUBLIC_URL}/payments`}><img src={`${process.env.PUBLIC_URL}/assets/images/wallet.jpeg`} width='30px' height='30px' style={{ borderRadius: '50px' }} alt="" /></Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/payments`}><img src={`${process.env.PUBLIC_URL}/assets/images/cod.jpeg`} width='30px' height='30px' style={{ borderRadius: '50px' }} alt="" /></Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/payments`}><img src={`${process.env.PUBLIC_URL}/assets/images/bankAlfalah.jpeg`} width='30px' height='30px' style={{ borderRadius: '50px' }} alt="" /></Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default FooterFour;