import React, { Component } from 'react'
import LoadingLoader from '../Loader/Loader'
import { styles } from './styles'
export default class ProductCart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: false,
      loading: true,
      items: this.props.item
    }
  }


  componentDidMount() {
    this.getSelected()
  }


  getSelected = () => {
    const { itemsJson } = this.state.items
    let filteredItem = itemsJson.filter(item => item.selected === true)
    if (filteredItem.length === itemsJson.length) {
      let items = this.state.items;
      let merchant = items.merchant;
      merchant.selected = true
      items.merchant = merchant;
      this.setState({ items: items, loading: false })
    }
    else {
      let items = this.state.items;
      let merchant = items.merchant;
      merchant.selected = false
      items.merchant = merchant;
      this.setState({ items: items, loading: false })
    }
  }

  handleSelectingSingleItems = (data) => {
    this.props.selectSingleItem({ ...data, itemIndex: data.itemIndex, merchantSelected: this.state.selected }, () => {
      this.getSelected();
    })
  }

  render() {

    const { merchant } = this.props.item
    let itemsJson = this.props.itemJson
    // if (this.state.loading) return <LoadingLoader visible={this.state.loading} />
    return (
      <tbody>
        <tr style={{ backgroundColor: '#f9f9f9f9' }}>
          <th colSpan={1}>
            <input type="checkbox" size='large'
              id='merchant-checkbox'
              onClick={() => this.props.selectCartMerchantItems(this.props.item)}
              // defaultChecked={merchant.selected}
              checked={merchant.selected}
              disabled={this.props.disabled}
            />
          </th>
          <th colSpan={8}>{merchant.merchantName} ({merchant.originAddress.cityName.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {return letter.toUpperCase(); })})</th>
        </tr>
        {
          itemsJson.map((item, index) => {
            var enc = window.btoa(item.productId);
            let removePerc = item.productName ? item.productName.split('%').join(' ') : ' ';
            let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
            let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
            
            return (
              <tr key={index}>
                <td className='table-check-box'>
                  <div style={styles.checkBox}>
                    <input type="checkbox" size='large'
                      onClick={() => this.handleSelectingSingleItems({ ...item, itemIndex: index })}
                      //  defaultChecked={item.selected}
                      checked={item.selected}
                      disabled={this.props.singleDisabled}
                    />
                  </div>
                </td>
                <td >
                  <img
                    onClick={() => this.props.history.push('/product/' + convertNameIntoDashes + '?id=' + enc)}
                    src={item.productImage} alt="hello" style={styles.productImage} />
                </td>
                <td>{item.productName ? item.productName.length > 20 ? item.productName.substring(0, 20) + '...' : item.productName.substring(0, 20) : ''}</td >
                <td>{item.specificationName ? item.specificationName.length > 20 ? item.specificationName.substring(0, 20) + '...' : item.specificationName : ''}</td>
                <td>
                  <p>{item.colorName}</p>
                  {/* <div style={{ backgroundColor: item.colorCode, width: '30px', height: '30px', borderRadius: '15px', border: '0.3px solid black', }}> */}
                  <p style={{marginBottom:'5px'}}>{item.colorName}</p>
                  <div style={{marginLeft:'13px', backgroundColor: item.colorCode, width: '30px', height: '30px', borderRadius: '15px', border: '0.3px solid black', }}>
                  </div>
                </td>
                <td>
                  {(item.priceBeforeDiscount > item.priceAfterDiscount) ? <del><span className='money' > RM{item.priceBeforeDiscount.toFixed(2)}</span></del> : null} <br></br>
                  RM {item.priceAfterDiscount.toFixed(2)}
                </td>
                <td>
                  <div className="qty-box quantity-box-cart">
                    <div className="input-group">
                      <span className="input-group-prepend">
                        <button type="button" className="btn quantity-left-minus"
                          onClick={() => this.props.decrementQty({ ...item, itemIndex: index })} disabled={(item.quantity === 1) ? true : false} data-type="minus" data-field="">
                          <i className="fa fa-angle-left"></i>
                        </button >
                      </span >
                      <input type=" text" name=" quantity" value={item.quantity} readOnly={true} className=" form-control input-number" />
                      <span className=" input-group-prepend">
                        <button className="btn quantity-right-plus"
                          onClick={() => this.props.incrementQty({ ...item, itemIndex: index })}
                          data-type="plus" disabled={(item.quantity >= item.singleCartEntry) ? true : false}>
                          <i className="fa fa-angle-right"></i>
                        </button >
                      </span >
                    </div >
                  </div >
                  {(item.quantity >= item.singleCartEntry) ? 'limit exceeds' : ''}
                </td >
                <td>
                  <div className="icon" onClick={() => this.props.removeItem({ ...item, itemIndex: index })}>
                    <i className="fa fa-times"></i>
                  </div>
                </td >
                <td>RM {item.totalItemPrice.toFixed(2)}</td >
              </tr>
            )
          })
        }
      </tbody>
    )
  }
}
