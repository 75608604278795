import React, { Component } from 'react'
import Wallet from '../components/Wallet/wallet'
import {SuperHOC} from '../HOC'


class WalletScreen extends Component {
   
   
    render(){
        return(
            <div>
                <Wallet
                api='get_wallet'
                {...this.props}
                />
            </div>
        )
    }
}
export default SuperHOC(WalletScreen)