import React, { useContext, useEffect } from 'react';
import NotificationBadge, { Effect } from 'react-notification-badge';
import { Link } from 'react-router-dom';
import CountContext from "../components/common/headers/common/CountContext";
import { login } from '../utils/session';
import { HiOutlineShoppingBag } from "react-icons/hi2";

const CartContainer2 = () => {
  const context = useContext(CountContext);

  const mobile = () => {
    if (window.innerWidth > 576) {
      return false;
    } else {
      return true;
    }
  };

  let newCount = context.count;
  let blackmarketURL = process.env.REACT_APP_BLACKMARKET_URL;

  if (blackmarketURL.endsWith('/')) {
    blackmarketURL = blackmarketURL.substring(0, blackmarketURL.length - 1);
  }

  const obtainCookies = async () => {
    try {
      await login();
    } catch (error) {
      console.error('Something went wrong', error)
    }
  }


  useEffect(() => {
    if (!window.localStorage.getItem('x-token')) {
      obtainCookies();
    }
  }, [])

  const onLinkClicked = (url) => {
    const token = localStorage.getItem('x-token');
    const cartToken = localStorage.getItem('token');

    const lifestyleURL = new URL('shopping-cart', url);

    lifestyleURL.searchParams.set('token', encodeURIComponent(token));
    lifestyleURL.searchParams.set('cartToken', encodeURIComponent(cartToken));

    window.location.href = lifestyleURL.href;
  }

  return (
    <a
    href={`${blackmarketURL}/shopping-cart`}
    className='noHoverBlackText blackText'
    style={{position:'relative'}}
    onClick={(event) => {
      event.preventDefault();
      onLinkClicked(blackmarketURL)
    }}
  >
    <img src={`${process.env.PUBLIC_URL}/assets/Icons/Top navigation bar/icon_shopping cart.svg`}  />
    <div style={{position: 'absolute',top:'-10%',left:'100%'}}>
      <NotificationBadge count={newCount} effect={Effect.SCALE} />
    </div>
  </a>
  );
};

export default CartContainer2;
