import React, { Component } from 'react'
import Search from '../components/Search/Main'
import { SuperHOC } from '../HOC'


class SearchScreen extends Component {
    render() {
        return (
            <div>
                <Search
                    {...this.props}
                    api={'search_products'}
                    searchPhrase='cam'
                />
            </div>
        )
    }
}
export default SuperHOC(SearchScreen)