import React, { Component } from "react";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import "../components/Login/style.css";
import { Tab, TabList } from "react-tabs";
import { Manager } from "../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";

class VerifyEmailThroughOTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            number: "",
            loading: false,
            currentEmail: "",
            errorMessage: "",
            showPositiveAlert: false,
            showNegativeAlert: false,
        };
    }

    componentDidMount() {
        let email = "";
        if (this.props.location.state.verifyEmailThruOTP === true) {
            email = this.props.location.state.data;
            this.setState({
                currentEmail: this.props.location.state.data,
            });
            this.sendTransactionFunction(email);
        }
    }

    sendTransactionFunction = (email) => {
        this.setState({ loading: true });
        let payload = {
            otpType: email,
        };
        this.props.apiManager.makeCall("sendTransactionTac", payload, (res) => {
            if (res.message === 6113) {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: "Wrong OTP!",
                    loading: false,
                });
            }
            if (res.message === 7085) {
                this.setState({
                    loading: false,
                    token: res.token,
                    showPositiveAlert: true,
                    token: res.otpHash,
                    errorMessage: `OTP sent to ${email}`,
                });
            } else {
                this.setState({ loading: false });
            }
        });
    };

    sendTacNumber = (otp) => {
        this.setState({ loading: true });
        let token = "";
        token = this.state.token;

        let body = {
            newMSISDN: otp,
        };

        let payload = {
            hash: this.state.token,
            otp: body.newMSISDN.otp,
        };

        this.props.apiManager.makeCall(
            "verifyTransactionTac",
            payload,
            (res) => {
                if (res.errors !== undefined) {
                    let erro = res.errors.map((err) => {
                        return err;
                    });

                    this.setState({
                        loading: false,
                        showPositiveAlert: true,
                        positiveResponse: true,
                        errorMessage: erro ? erro : "Something went wrong",
                    });
                }

                if (res.message === 7039) {
                    this.setState({
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: "OTP Wrong!",
                    });
                }
                if (res.message === 6112) {
                    this.setState({
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: "Hash code is not found!",
                    });
                }
                if (res.message === 6113) {
                    this.setState({
                        loading: false,
                        showNegativeAlert: true,
                        errorMessage: "OTP is expired!",
                    });
                }
                if (res.message === 7072) {
                    this.setState({
                        loading: false,
                        token: res.token,
                        showPositiveAlert: true,
                        errorMessage: `OTP successfully verified, You can change the Email now!`,
                    });
                    setTimeout(() => {
                        this.props.history.push("/ChangingEmail");
                    }, 3000);
                }
            }
        );
    };

    render() {
        let userID = Manager.getItem("profileIdMdlwr");
        
        return (
            <div className="limiter">
                <Breadcrumb title={"OTP Verification"} />
                <Formik
                    initialValues={{
                        otp: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.otp) {
                            errors.otp = "Enter OTP";
                            // errors.confirmPassword = 'Please enter your confirm password';
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        otp: Yup.string()
                            .min(6, "OTP must be at least 6 characters")
                            .required("OTP is required"),
                        // .matches(
                        //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{8,}$/,
                        //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        // ),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        // this.props.onSubmit(values);
                        this.sendTacNumber(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        Change Email OTP Verification
                                    </span>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="text"
                                            className="input100"
                                            name="otp"
                                            placeholder="Otp"
                                            id="defaultRegisterFormPassword"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="otp"
                                            component="div"
                                        />
                                    </div>

                                    <div>
                                        <TabList
                                            className="tab-title"
                                            style={{
                                                flex: 1,
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Tab
                                                direction={"column"}
                                                style={{
                                                    paddingRight: 10,
                                                    alignItems: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <u
                                                    className="text-primary"
                                                    onClick={() =>
                                                        this.sendTransactionFunction(
                                                            this.state
                                                                .currentEmail
                                                        )
                                                    }
                                                >
                                                    Resend OTP ?
                                                </u>
                                            </Tab>
                                        </TabList>
                                    </div>
                                    {this.state.loading ? (
                                        <div
                                            style={{
                                                // left: 100,
                                                // top: 100,
                                                // bottom: 50,
                                                zIndex: "100",
                                                // position: "absolute",
                                            }}
                                        >
                                            <LoadingLoader visible={true} />
                                        </div>
                                    ) : (
                                        <div className="container-login100-form-btn">
                                            <button
                                                className="login100-form-btn"
                                                type="submit"
                                            >
                                                Verify
                                            </button>
                                        </div>
                                    )}
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(VerifyEmailThroughOTP);
