import React, { Component } from "react";
import AccountVerificationComponent from "../components/Login/AccountVerificationComponent";
import LoadingLoader from "../components/Loader/Loader";
import { SuperHOC } from "../HOC";
import { Manager } from "../StorageManager/Storage";
import RegisterContext from "../components/common/headers/common/CountContext";
import PositiveAlert from "../components/CustomAlert/CustomAlert";

class VerifyOtp extends Component {
    static contextType = RegisterContext;
    state = {
        //   loading: true,
        //   takenEmail: null,
        //   takenPhone: null,
        //   showPositiveAlert: false,
        //   showAlert: false,
        //   errorMessage: "",
        //   fcmToken: "",
        //   faildRegistration: false,
        //   loginVerification: false,
        //   successLogin: false,
        msisdn: "",
        //   access_token: "",
        //   counter: 60,
        token: "",
        otp: "",
        resendAuth: true,
    };

    componentDidMount() {
        this.setState({
            resendAuth: this.props.location.state.notResend,
        });
        var a, dec, b;
        a = this.props.location.search.split("?id=");
        b = a[1].split("&");
        dec = b[0];
        this.setState({
            msisdn: dec,
            token: this.props.location.state.dataToken,
            loginVerification: this.props.location.state.isLogin,
        });

        //   this.sendTacNumber(dec);
    }

    middlewareMobileLogin = (body) => {
        this.setState({ loading: true });
        Manager.getItem("fcmToken", true).then((result) => {
            this.setState({ fcmToken: result });
        });
    };

    verifyTac = (body) => {
        this.setState({ loading: true });
        let payload = {
            hash: body.hash,
            otp: body.otp,
        };

        let pBody = {
            hash: payload.hash,
            otp: payload.otp,
        };

        this.props.apiManager.makeCall("verifyRegisterOTP", payload, (res) => {
            if (res.message === 7058) {
                let registerData = this.context.data;
                if (registerData === undefined || registerData === null) {
                    this.setState({
                        faildRegistration: true,
                        loading: false,
                        errorMessage:
                            "Please enter correct information and try again!",
                    });
                }

                this.props.history.push({
                    pathname: `/UserDetailsForRegister`,
                    search: ``,
                    state: {
                        payload: payload,
                        msisdn: this.state.msisdn,
                    },
                });

            } else if (res.message === 7039) {
                this.setState({
                    showAlert: true,
                    loading: false,
                    errorMessage: "Invalid OTP Please Try again",
                });

            } else if (res.message === 6113) {
                this.setState({
                    showAlert: true,
                    loading: false,
                    errorMessage: "Expired OTP",
                });

            } else {
                this.setState({
                    showPositiveAlert: true,
                    loading: false,
                    errorMessage: "Continue...",
                });
            }
        });
    };

    render() {
        if (this.state.loading)
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "70vh",
                        backgroundColor: "white",
                    }}
                >
                    <LoadingLoader visible={this.state.loading} />
                </div>
            );
        return (
            <div>
                <AccountVerificationComponent
                    {...this.props}
                    msisdn={this.state.msisdn}
                    onSubmit={(data) => {
                        let body = {};
                        if (this.state.resendAuth === true) {
                            body = {
                                tac: data.tac,
                                otp: data.tac,
                                hash: data.resend_hash,
                                msisdn: this.state.msisdn,
                                access_token: data.resend_hash,
                            };

                            this.verifyTac(body);
                        }
                        if (this.props.location.state.isLogin === false) {
                            body = {
                                tac: data.tac,
                                otp: data.tac,
                                hash: this.props.location.state.dataToken,
                                msisdn: this.state.msisdn,
                                access_token: this.state.token,
                            };

                            this.verifyTac(body);
                        }
                    }}
                />
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showAlert: false })}
                    open={this.state.showAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.goToRegisterScreen()}
                    open={this.state.faildRegistration}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    onClose={() => this.goToLoginScreen()}
                    open={this.state.successLogin}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(VerifyOtp);
