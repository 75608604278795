import React, { Component } from 'react';
import '../common/index.scss';
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import Details from "./common/product/details";
import Price from "./common/product/price";
import DetailsTopTabs from "./common/details-top-tabs";
import ImageContainer from './common/ImageContainer'
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions'
import CustomAlert from '../CustomAlert/CustomAlert'
import ErrorAlert from '../CustomAlert/CustomAlert'



class Column extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      showErrorAlert: false,
      errorMessage: ''
    };
  }

  addReview = (slug, rating, comment) => {
    let body = {
      productSlug: slug,
      rating: rating,
      comment: comment
    };
    this.props.apiManager.makeCall('add_review', body, (res) => {
      if (res.message === '1054') {
        this.setState({ showAlert: true, errorMessage: res.decodedMessage })
      } else {
        this.setState({ showErrorAlert: true, errorMessage: res.decodedMessage })
      }
    })
  }

  render() {
    const { symbol, addToCart, addToCartUnsafe, addToWishlist } = this.props.product

    let product = this.props.product
    let color = product.colors

    return (
      <div>

        <Breadcrumb title={product.name.substring(0, 20) + ' ...'} />

        {/*Section Start*/}
        {
          <section >
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 product-thumbnail">
                    <ImageContainer
                      colors={color}
                    />

                  </div>
                  {/* Product Details */}
                  <Details
                    product={product}
                    addToWishlistClicked={addToWishlist}
                    {...this.props}
                  />
                  {/* Product Price Details */}
                  {
                    Object.keys(this.props.product).length > 0 ?
                      <Price
                        symbol={symbol}
                        product={product}
                        {...this.props}
                        merchantName={this.props.merchantName}
                        addToCartClicked={addToCart}
                        BuynowClicked={addToCartUnsafe}
                      /> : null
                  }
                </div>
              </div>
            </div>
          </section>
        }
        {/*Section End*/}

        <section className="tab-product m-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                <DetailsTopTabs
                  onAddReview={(slug, rating, comment) => this.addReview(slug, rating, comment)}
                  product={product}

                />
              </div>
            </div>
          </div>
        </section>
        <div style={{ height: 20 }} />
        <CustomAlert
          onClose={() => this.setState({ showAlert: false })}
          open={this.state.showAlert}
          message={this.state.errorMessage}
        />
        <ErrorAlert
          error
          onClose={() => this.setState({ showErrorAlert: false })}
          open={this.state.showErrorAlert}
          message={this.state.errorMessage}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find(el => el.id === productId),
    symbol: state.data.symbol
  }
}

export default connect(mapStateToProps, { addToCart, addToCartUnsafe, addToWishlist })(Column);