import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { SuperHOC } from '../../HOC';
import { toast, ToastContainer, } from 'react-toastify';

class AddComplains extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            id: this.props.match.params.id,
            title: "",
            complainDetails: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.enterDetails = this.enterDetails.bind(this);
    }


    componentDidMount = () => {
        // this.setState({})
        let body = {
            orderId: this.props.match.params.id
        }
        this.props.apiManager.makeCall('get_single_order_details', body, (res) => {
            if (res.message === '1729') {
                this.setState({ item: res.order })
            }
            else {
                console.error('Error: ', res.message)
            }
        })
    }

    handleChange(event) {
        this.setState({ title: event.target.value });
    }

    enterDetails(event) {
        this.setState({ complainDetails: event.target.value });
    }


    submitComplain = () => {
        let body = {
            transactionId: this.state.id,
            complainTopic: this.state.title,
            complainMessage: this.state.complainDetails
        }
        this.props.apiManager.makeCall('add_complain', body, (res) => {
            if (res.message === '1803') {
                toast.success(res.decodedMessage)
            } else {
                toast.error(res.decodedMessage)
            }
        })

        this.setState({ title: '', complainDetails: '' })
    }

    render() {
        const { productName, priceAfterDiscount, _id, merchantName, deliveryAddress } = this.state.item
        let address = (deliveryAddress && deliveryAddress.address) + ", " + (deliveryAddress && deliveryAddress.cityName)

        return (
            <div>
                <Breadcrumb title={'Complain'} />
                <ToastContainer />

                <br />
                <br />
                <br />
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                        <tr>
                                            <th>Product:</th>
                                            <td>{productName}</td>
                                        </tr>
                                        <tr>
                                            <th>Price:</th>
                                            <td>{priceAfterDiscount}</td>
                                        </tr>
                                        <tr>
                                            <th>Delivery Address:</th>
                                            <td>{address}</td>
                                        </tr>
                                        <tr>
                                            <th>Order ID</th>
                                            <td>{_id}</td>
                                        </tr>
                                        <tr>
                                            <th>Merchant</th>
                                            <td> {merchantName} </td>
                                        </tr>

                                    </tbody>
                                </table> <br />
                            </div>
                            <div className="col-lg-8">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>Add Complain</h2>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <label htmlFor="review">Complain Title</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="review"
                                                    placeholder="Enter Complain Subject"
                                                    value={this.state.title}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <label htmlFor="review">Complain Details</label>
                                                <textarea
                                                    className="form-control"
                                                    value={this.state.complainDetails}
                                                    onChange={this.enterDetails}
                                                    placeholder="Wrire Your Complain Details Here"
                                                    id="exampleFormControlTextarea1"
                                                    rows="6"
                                                    required>   </textarea>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <button
                                                    className="btn btn-solid"
                                                    type="button"
                                                    onClick={this.submitComplain}
                                                >
                                                    Submit Complain</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default SuperHOC(AddComplains);