import React, { Component } from 'react'

export default class ProductItem extends Component {



  render() {
    const { merchant } = this.props.item
    let itemsJson = this.props.itemJson
    return (
      <tbody>
        <tr style={{ backgroundColor: '#f9f9f9f9' }}>
          <th colSpan={9}>
            {merchant.merchantName} ({merchant.originAddress.cityName.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {return letter.toUpperCase(); })})
          </th>
        </tr>
        {
          itemsJson.map((item, index) => {
            return <tr key={index}>
              <td>
                <img src={item.productImage} alt="hello" style={{ height: '90px', width: '90px', margin: '5px' }} />
              </td>
              <td>
                {item.productName ? item.productName.substring(0, 20) + '...' : ''}
              </td >
              <td>
                {item.specificationName.substring(0, 20) + '...'}
              </td>
              <td>
                {/* <p>{item.colorName}</p>
                <div style={{ width: '50px', height: '50px', borderRadius: '25px', backgroundColor: item.colorCode, border: '2px solid black' }}>
                </div> */}
                 <p style={{marginBottom:'5px'}}>{item.colorName}</p>
                <div style={{marginLeft:'8px', width: '50px', height: '50px', borderRadius: '25px', backgroundColor: item.colorCode, border: '2px solid black' }}></div>
              </td>
              <td>
              {(item.priceBeforeDiscount > item.priceAfterDiscount) ? <del><span className='money' > RM{item.priceBeforeDiscount.toFixed(2)}</span></del> : null} <br></br>
                RM{item.priceAfterDiscount.toFixed(2)}
              </td>
              <td>{item.quantity}</td>
              <td><h4 className="td-color">
                RM {item.totalItemPrice.toFixed(2)}
              </h4></td >
            </tr>
          })
        }
      </tbody>
    )
  }
}
