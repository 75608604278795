import {
    SELECTED_COLOR_INDEX } from "../constants/ActionTypes";


const initialState = {
    index: 0,
};

const selectedProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_COLOR_INDEX:
            return { ...state,
                index: action.index };
        default:
            return state;
    }
};
export default selectedProductReducer;