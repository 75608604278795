import React, { Component } from 'react'
import { Review } from '../Review'
import classes from './style.module.css'
import { SuperHOC } from '../../../HOC'

class ReviewContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      reviews: [],
      loading: true
    }
    this.id = this.props.product._id
  }

  getProductReview = () => {
    let body = {
      productSlug: this.id
    }

    this.props.apiManager.makeCall('get_product_review', body, (res) => {
      if (res.message) {
        this.setState({ reviews: res.result.docs })
      }
      else {
        console.error('Error: ', res.message)
      }
    })
  }

  componentDidMount() {
    this.getProductReview()
  }


  render() {


    return (
      <div className={classes.detailContainer}>
        <div className={classes.descriptionHeading}>
          <h1>Product reviews</h1>
        </div>
        {
          this.state.reviews.map((comment, index) => {
            return (
              <Review
                key={index}
                reviewerName={comment.reviewer}
                rating={comment.rating}
                comment={comment.comment}
                timeAgo={comment.createdAt}
              />
            )
          })
        }
      </div>
    )
  }
}

export default SuperHOC(ReviewContainer)