import DataPackage from '../assets/images/lifestyle/DataPackage.png'
import Electricity from '../assets/images/lifestyle/Electricity.png'
import Entertainment from '../assets/images/lifestyle/Entertainment.png'
import Broadband from '../assets/images/lifestyle/Internet.png'
import LocalCouncil from '../assets/images/lifestyle/LocalCouncil.png'
import Postpaid from '../assets/images/lifestyle/Postpaid.png'
import TopUp from '../assets/images/lifestyle/TopUp.png'
import Water from '../assets/images/lifestyle/Water.png'

export const lifestyleItems = [{
  name: 'Postpaid',
  url: '/lifestyle/bill?tab=Postpaid',
  iconURL: Postpaid
}, {
  name: 'Broadband',
  url: '/lifestyle/bill?tab=Broadband',
  iconURL: Broadband,
}, {
  name: 'Water',
  url: '/lifestyle/bill?tab=Water',
  iconURL: Water
}, {
  name: 'Electricity',
  url: '/lifestyle/bill?tab=Electricity',
  iconURL: Electricity,
}, {
  name: 'Local Council',
  url: '/lifestyle/bill?tab=LocalCouncils',
  iconURL: LocalCouncil
}, {
  name: 'Entertainment',
  url: '/lifestyle/bill?tab=Entertainment',
  iconURL: Entertainment,
}, {
  name: 'Top Up',
  url: '/lifestyle/prepaid?tab=topup',
  iconURL: TopUp,
}, {
  name: 'Data Packages',
  url: '/lifestyle/prepaid?tab=data_package',
  iconURL: DataPackage,
}].map(lifestyle => {
  const { url }= lifestyle;

  let blackmarketURL = process.env.REACT_APP_BLACKMARKET_URL

  if (blackmarketURL.endsWith('/')) {
    blackmarketURL = blackmarketURL.substring(0, blackmarketURL.length -1)
  }

  return {
    ...lifestyle,
    url: [blackmarketURL, url].join(''),
  }
})
