import React from 'react';
import { ApiManager, ErrMsg } from '../ApiManager';
import { Manager } from '../StorageManager/Storage';
const WithAuthenticate = OriginalComponent => {
  return class WithAuthenticate extends React.Component {
    constructor(props) {
      super(props);
      this.apiManager = new ApiManager(this);
      this.state = {
        user: null,
        loaded: false,
        retrying: false,
        showModal: true
      }
    }
    componentDidMount = () => {
      this.getReady(() => {
        this.reLoadUser();
      });
    }

    redirect = () => {
      Manager.removeItem('token')
      Manager.removeItem('user')
      Manager.removeItem('userStatus')
      Manager.removeItem('userEmail')
      Manager.removeItem('profileIdMdlwr')
      Manager.removeItem('accessTokenMdlwr')
      this.props.history.push('/login');
    }

    getReady = async (whenDone) => {
      await this.apiManager.Initialize();
      let user = await Manager.getItem('user', true);
      this.setState({ user }, whenDone());
    }

    saveUser = async (user) => {
      await Manager.setItem('user', user);
      this.setState({ user, loaded: true });
    }

    // showTimeoutError = (e) => {
    //   // console.warn(e);
    //   let headerTitle = 'Timeout', msg = 'There is problem with internet';
    //   if (e.message) {
    //     headerTitle = 'Error';
    //     msg = e.message;
    //   }
    //   if (msg === 'Failed to fetch') {
    //     this.props.history.push('/network');
    //   }
    // }

    retrying = () => {
      this.setState({
        retrying: true
      }, () => {
        setTimeout(() => {
          this.setState({ retrying: false })
        }, 100);
      })
    }

    reLoadUser = () => {
      // SecureStore.getItemAsync('user')
      // .then( result => this.setState({ user: JSON.parse(result)}))
      Manager.getItem('user', true).then(
        result => {
          this.setState({ user: result, loaded: true })
        });
    }

    componentWillUnmount = () => {
      // console.warn('HOC UNMOUNT')

    }

    handleAlert = (data) => {
      if (data !== '1004') {
      }
    }

    render() {
      if (!this.state.loaded) return null;
      return (
        <OriginalComponent
          {...this.props}
          alert={(data) => this.handleAlert(data)}
          apiManager={this.apiManager}
          ifNotLogin={this.ifNotLogin}
          user={this.state.user}
          saveUser={(user) => this.saveUser(user)}
          setOnBack={(obj) => this.setOnBack(obj)}
          setDontShow={(dontShow) => this.setState({ dontShow })}
          reLoadUser={this.reLoadUser}
          customNavigate={(screen, data) => this.navigate(screen, data)}

        />


      )
    }
  }
}

export default WithAuthenticate;
