import React, { Component } from "react";
import * as Yup from "yup";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import Breadcrumb from "../common/breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PositiveAlert from "../CustomAlert/CustomAlert";
import NegativeAlert from "../CustomAlert/CustomAlert";
import ErrMsg from "../../ApiManager/string-error-code-en";
import { Manager } from "../../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { withLastLocation } from "react-router-last-location";
const prefix = "60";

export default class loginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "60",
            msisdn: "60",
            password: "",
            countryCode: "",
            displayName: "",
            facebookLogin: "",
            confirmPassword: "",
            accessToken: "",
            showModal: false,
            showPositiveAlert: false,
            showNegativeAlert: false,
            emptyPhone: false,
            takenPhone: false,
            wrongFormat: false,
            takenPass: false,
            emptyPass: false,
            emailLogin: true,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.lastLocation && nextProps.lastLocation.pathname) {
            if (this.props.lastLocation === null) {
                return this.setState({ location: "/" });
            }

            let pathname =
                    this.props.lastLocation.pathname +
                    this.props.lastLocation.search;
                this.setState({ location: pathname });
        }
    }

    handleInputChange = ({ target }) => {
        this.setState({
            [target.name]: target.value,
            takenPhone: false,
            wrongFormat: false,
            takenPass: false,
            emptyPass: false,
            emptyPhone: false,
        });
    };

    checkPass = (_callback) => {
        if (this.state.password !== "" && this.state.confirmPassword !== "") {
            if (this.state.password === this.state.confirmPassword)
                _callback(true);
            else
                this.setState({
                    takenPhone: false,
                    wrongFormat: false,
                    takenPass: true,
                    emptyPass: false,
                    emptyPhone: false,
                });
        } else
            this.setState({
                takenPhone: false,
                wrongFormat: false,
                takenPass: false,
                emptyPass: true,
                emptyPhone: false,
            });
    };

    newUserLogin = () => {
        let phone = "60" + this.state.phone;
        let body = {
            email: this.state.email,
            access_token: this.state.accessToken,
            name: this.state.name,
            displayName: this.state.name,
            phone: phone,
            password: this.state.password,
            countryCode: 60,
            notificationKey: "hjdafghjfgahjfjd",
            notificationStatus: 0,
            role: 1,
        };
        let rawStr = body.email + ":" + body.access_token;
        let encodedToken = CryptoJS.enc.Utf8.parse(rawStr);
        encodedToken = CryptoJS.enc.Base64.stringify(encodedToken);
        let apiParams = this.props.apiManager.getParams(
            "new_user_social_login"
        );

        let options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Basic " + encodedToken,
                "x-client-Id": "bs3et1r4rb2qy9han6wg.pindal.pk",
                "x-client-Key": "bs3et1r4rb2qy9han6x0",
            },
            body: JSON.stringify(body),
        };

        fetch(apiParams.apiUrl, options)
            .then((res) => {
                return res.json();
            })
            .then((mainRes) => {
                mainRes.decodedMessage = ErrMsg(mainRes.message);
                if (mainRes.message === "2012") {
                    this.props.apiManager.saveToken(body.email, mainRes.token);
                    window.location.href = "/";
                } else {
                    this.setState({
                        showNegativeAlert: true,
                        errorMessage: mainRes.decodedMessage,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: error,
                });
            });
        // });
    };

    renderLoginModal = () => {
        return (
            <Modal
                open={this.state.showModal}
                onClose={() => this.setState({ showModal: false })}
                center
                styles={{
                    modal: { padding: "10px 5px 10px 5px", borderRadius: 5 },
                }}
                showCloseIcon={false}
            >
                <div
                    className="modal-content quick-view-modal"
                    style={{ border: 0 }}
                >
                    <div
                        className="modal-body"
                        style={{ border: 0, width: "100%" }}
                    >
                        <div className="row">
                            <div className="col-lg-5">
                                <div
                                    style={{
                                        display: "flex",
                                        height: "60%",
                                        width: "100%",
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                    }}
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/Black_Market_Logo_Header_Black.png`}
                                        alt="Black Market Logo Header Black"
                                        className="img-fluid rounded"
                                    />
                                </div>
                                <div style={{ height: "40%", width: "100%" }}>
                                    <h4
                                        style={{
                                            color: "rgb(239,91,49)",
                                            textAlign: "center",
                                            paddingTop: 10,
                                        }}
                                    >
                                        Welcome to XOX Black Market
                                    </h4>
                                    <p
                                        style={{
                                            textAlign: "center",
                                            paddingTop: 10,
                                        }}
                                    >
                                        Just one smaller step more, We need this
                                        information so you can enjoy XOX Black
                                        Market uninterrupted.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                {/* <h3 style={{ textAlign: 'center', paddingBottom: 10 }}> Modal </h3> */}
                                <div id="PaymentForm">
                                    <form style={{ marginTop: 20 }}>
                                        <div className="form-group">
                                            <div>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        readOnly
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                        value={this.state.name}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Display Name"
                                                        readOnly
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                        value={this.state.email}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        name="phone"
                                                        className="form-control"
                                                        placeholder="Phone No (60XXXXXXXXXX)"
                                                        maxLength="12"
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        required
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                        value={this.state.phone}
                                                    />
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            marginTop: 5,
                                                        }}
                                                    >
                                                        {this.state.emptyPhone
                                                            ? "Phone is empty"
                                                            : this.state
                                                                  .takenPhone
                                                            ? "Phone Is Taken "
                                                            : this.state
                                                                  .wrongFormat
                                                            ? "Wrong Format "
                                                            : ""}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="Password"
                                                required
                                                onChange={
                                                    this.handleInputChange
                                                }
                                                value={this.state.password}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                required
                                                onChange={
                                                    this.handleInputChange
                                                }
                                                value={
                                                    this.state.confirmPassword
                                                }
                                            />
                                            <p
                                                style={{
                                                    color: "red",
                                                    marginTop: 5,
                                                }}
                                            >
                                                {this.state.takenPass
                                                    ? "Password does not match "
                                                    : this.state.emptyPass
                                                    ? "Password is empty"
                                                    : ""}
                                            </p>
                                        </div>
                                        <div
                                            onClick={() => this.newUserLogin()}
                                            className="btn btn-primary btn-block"
                                        >
                                            Submit
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    renderCompleteLoginScreen = () => {
        return (
            <div className="limiter">
                <Tabs className="theme-tab" style={{ marginTop: 15 }}>
                    <TabList className="tab-title">
                        <Tab style={{ paddingRight: 50, alignSelf: "center" }}>
                            <u className="text-primary">Email Login</u>
                        </Tab>
                        <Tab>
                            <u className="text-primary">Mobile Login</u>
                        </Tab>
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel className="tab-content">
                            <div className="row product-tab w-100">
                                {this.renderEmailLogin()}
                            </div>
                        </TabPanel>
                        <TabPanel className="tab-content">
                            <div className="row product-tab w-100">
                                {this.renderMobileLogin()}
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>

                {this.renderLoginModal()}
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Success"}
                    message={this.state.errorMessage}
                />
                <NegativeAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    };

    renderTacVerificationScreen = () => {
        return (
            <>
                <Breadcrumb title={"TAC Verifications"} />
                <Formik
                    initialValues={{ tac: "" }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.tac) {
                            errors.oldPassword = "Please enter TAC";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        tac: Yup.string()
                            .min(6, "Add Atleast 6 number")
                            .max(
                                6,
                                "Not valid number, you can add 6 number at max"
                            )
                            .matches(
                                /^(\+?\d{1,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                                "Phone number is not valid"
                            )
                            .required("TAC field required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.props.onSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        TAC Verifications
                                    </span>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="text"
                                            name="phone"
                                            id="defaultRegisterFormPhone"
                                            className="input100"
                                            value={this.state.phone}
                                            disabled
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-phone"></i>
                                        </span>
                                    </div>
                                    <div
                                        className="wrap-input100 validate-input"
                                        data-validate="Tac Is required"
                                    >
                                        <Field
                                            type="password"
                                            name="tac"
                                            id="defaultRegisterFormPassword"
                                            className="input100"
                                            placeholder="TAC"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="tac"
                                            component="div"
                                        />
                                    </div>
                                    <div className="container-login100-form-btn pt-1">
                                        <button
                                            className="login100-form-btn"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Mobile Login
                                        </button>
                                    </div>
                                    <div className="not-a-member">
                                        <Link
                                            to={`/login`}
                                            className="txt1 bo1 hov1"
                                        >
                                            Login
                                        </Link>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
            </>
        );
    };

    handleonChange = (evt) => {
        // evt.defaultPrevented()
        const ev = evt.target.value;
        this.setState({
            msisdn: ev,
        });
    };

    handleChange = (e) => {
        // e.preventDefault()
        let temp = [];
        let newTemp = [];
        const input = e.target.value;
        const name = e.target.name;
        let number = (e.target.value = prefix + input.substr(prefix.length));
        temp.push(input);

        if (temp[0][2] !== "0") {
            newTemp.push(...temp);
            this.setState({
                phone: number ? number : newTemp,
            });
        }
    };

    renderMobileLogin = () => {
        let phone = this.state.phone;
        return (
            <div>
                <Breadcrumb title={"MOBILE LOGIN"} />
                <Formik
                    initialValues={{
                        phone: phone,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!phone) {
                            errors.phone = "Please add your mobile number";
                        }
                        if (phone === "60") {
                            errors.phone = "Field cannot be empty";
                        }
                        if (!/\60/i.test(phone)) {
                            errors.phone =
                                "Invalid MSISDN address! Must start with 60";
                        }
                        if (phone.length <= 10) {
                            errors.phone =
                                "Msisdn must be at least 11 characters";
                        }
                        if (phone.length >= 14) {
                            errors.phone =
                                "Msisdn must be less then 14 characters";
                        }
                        return errors;
                    }}
                    // validationSchema={Yup.object().shape({
                    //     [{ phone: this.state.phone }]: Yup.string()
                    //         .min(11, "Add Atleast 11 characters")
                    //         .max(14, "Numbers must be less then 14 "),
                    //     // .matches(
                    //     //     /^(\+?\d{1,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                    //     //     "Phone number is not valid"
                    //     // )
                    //     // .required("Phone number required"),
                    // })}
                    onSubmit={(values, { setSubmitting }) => {
                        values.phone = this.state.phone;
                        this.props.onSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Mobile Login
                                        </span>

                                        <div className="col-xl-4 col-sx-12">
                                            <div className="wrap-input100 validate-input">
                                                <input
                                                    type="text"
                                                    className="input100"
                                                    name="changeMSISDN"
                                                    value={this.state.phone}
                                                    placeholder="Change Msisdn"
                                                    id="defaultRegisterFormPassword"
                                                    onChange={(e) =>
                                                        this.handleChange(e)
                                                    }
                                                />
                                                {/* <Field
                                                    type="text"
                                                    name="phone"
                                                    className="input100"
                                                    // component={MyInput}
                                                    placeholder="Enter Phone Number"
                                                    id="defaultRegisterPhonePassword"
                                                /> */}
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-phone"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                id="error"
                                                name="phone"
                                                component="p"
                                                style={{ color: "red" }}
                                            />

                                            <div className="container-register100-form-btn">
                                                <button
                                                    className="login100-form-btn"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    MOBILE LOGIN
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
            </div>
        );
    };

    renderEmailLogin = () => {
        return (
            <>
                <Breadcrumb title={"Email Login"} />
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = "Email required";
                            errors.password = "Password required";
                        } else if (!values.password) {
                            errors.password = "Password required";
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                values.email
                            )
                        ) {
                            errors.email = "Invalid email address";
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        this.props.onSubmit(values, this.state.emailLogin);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        Email Login
                                    </span>
                                    <div
                                        className="wrap-input100 validate-input"
                                        data-validate="Valid email is required: ex@abc.xyz"
                                    >
                                        <Field
                                            type="email"
                                            name="email"
                                            id="defaultRegisterFormEmail"
                                            className="input100"
                                            placeholder="info@gmail.com"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-envelope"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="email"
                                            component="div"
                                        />
                                    </div>
                                    <div
                                        className="wrap-input100 validate-input"
                                        data-validate="Password is required"
                                    >
                                        <Field
                                            type="password"
                                            name="password"
                                            id="defaultRegisterFormPassword"
                                            className="input100"
                                            placeholder="password"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="password"
                                            component="div"
                                        />
                                    </div>
                                    <div className="container-login100-form-btn pt-1">
                                        {/* <button
                                            role="button"
                                            type="submit"
                                            class="button-49"
                                            disabled={isSubmitting}
                                        >
                                            Login
                                        </button> */}

                                        <button
                                            className="login100-form-btn"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div className="not-a-member">
                                        <Link
                                            to={`/SignupMsisdn`}
                                            className="txt1 bo1 hov1 float-left border-0"
                                        >
                                            <u>Sign up</u>
                                        </Link>
                                        <div className="float-right">
                                            <h6>
                                                <u
                                                    className="cursor-p"
                                                    onClick={() =>
                                                        this.setState({
                                                            emailLogin: false,
                                                        })
                                                    }
                                                >
                                                    Login with Mobile Number
                                                </u>
                                            </h6>
                                            <Link
                                                to={`/forgotpassword`}
                                                className="txt1 bo1 hov1"
                                            >
                                                Forgot password
                                            </Link>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
            </>
        );
    };

    sendLoginTac() {
    }

    updatePhoneVal = (e) => {
        this.setState({
            phone: e.target.value,
        });
    };

    render() {
        if (this.state.emailLogin) return this.renderEmailLogin();
        else return this.renderMobileLogin();
    }
}
