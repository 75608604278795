import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './style.css'
class AllNotification extends Component {

  handelNotifications = (item) => {
    let body = {
      notificationId: item.id
    }
    this.props.apiManager.makeCall('mark_notification_seen', body, (res) => {
      if (res.message === '1840') {
        switch (item.type) {
          case 0:
            return (
              this.props.history.push(`/NegotiationChat`)
            )
          case 1:
            return (
              this.props.history.push(`/chatScreen`)
            )
          case 3:
            return (
              this.props.history.push(`/OrderHistoryScreen`)
            )
          case 7:
            return (
              this.props.history.push(`/schedule`)
            )
          case 8:
            return (
              this.props.history.push(`/Complains`)
            )
          case 10:
            return (
              this.props.history.push(`/walletHistory`)
            )
          default: return null;

        }
      }
    })
  }

  notificationFunction(item) {
    let timeValues = new Date(item.createdAt);
    let date = timeValues.getUTCDate()
    let year = timeValues.getFullYear()
    let hours = timeValues.getHours()
    let hourSet;
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var hourValueSet;
    if (hours < 10) {
      hourSet = hours
      hourValueSet = "0"
    }
    else {
      hourSet = hours
    }
    let minSet = timeValues.getUTCMinutes();
    var minValueSet;
    let min;
    if (minSet < 10) {
      min = minSet
      minValueSet = "0"
    }
    else {
      min = minSet
    }
    
    var Month = new Array(12);
    Month[0] = "Jan";
    Month[1] = "Feb";
    Month[2] = "Mar";
    Month[3] = "Apr";
    Month[4] = "May";
    Month[5] = "Jun";
    Month[6] = "Jul";
    Month[7] = "Aug";
    Month[8] = "Sep";
    Month[9] = "Oct";
    Month[10] = "Nov";
    Month[11] = "Dec";
    let month = Month[timeValues.getMonth()];
    var weekday = new Array(7);
    weekday[0] = "Sun";
    weekday[1] = "Mon";
    weekday[2] = "Tue";
    weekday[3] = "Wed";
    weekday[4] = "Thu";
    weekday[5] = "Fri";
    weekday[6] = "Sat";
    var day = weekday[timeValues.getDay()];
    //let hourSet = timeValues.getUTCHours();
    switch (item.type) {
      case 0:
        return (
          <div onClick={() => this.handelNotifications(item)} style={{ color: "#777777" }} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 1:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                {item.message === '2107' ? <p className="text">Message sent successfully, please wait for reply.</p> : <p className="text">{item.message}</p>}
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 2:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 3:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )
      case 4:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 5:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 6:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 7:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 8:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 9:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              {/* {item.userToName.substring(0, 2)} */}
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 10:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              {/* {item.userToName.substring(0, 2)} */}
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )

      case 11:
        return (
          <div onClick={() => this.handelNotifications(item)} >
            <div className="channel">
              <span className="icons"><FontAwesomeIcon icon={faEnvelope} color='grey' size="2x" /></span>
              {/* {item.userToName.substring(0, 2)} */}
              <div className="flex-grow-1">
                <p className="text"> {item.message}</p>
                <p className="text" style={{ color: 'red' }}>{hourValueSet}{hourSet}:{minValueSet}{min} {ampm}  {day} {date} {month} {year} </p>
              </div>
            </div>
            <hr />
          </div>
        )
      default: return null;
    }
  }
  render() {
    return (
      <div>
        {this.notificationFunction(this.props.item)}
      </div>
    )
  }
}
export default withRouter(AllNotification)
