import React, { Component } from 'react'
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styles from "./styles";

export default class ConfirmationAlert extends Component {
  render() {
    return (
      <div>
        <Modal
          showCloseIcon={false}
          styles={{ modal: { backgroundColor: '#f9f9f9', padding: 0, borderRadius: 15, } }}
          open={this.props.open}
          onClose={this.props.onClose}
          center>
          <div style={styles.mainContainer}>
            <div style={styles.iconContainer}>
              <div style={{ height: 60, width: 60, border: '2px solid rgb(255, 52,111)', borderRadius: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faTimes} size='2x' color='rgb(255, 52,111)' />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 25, border: 'red' }}>
              <h3 style={{ color: 'rgb(255, 52,111)' }}>{'Confirmation'}!</h3>
            </div>
            <div style={{ paddingTop: 25, paddingBottom: 15, paddingLeft: 5, paddingRight: 5, }}>
              <h5 style={{ textAlign: 'center', lineHeight: '30px' }}>{this.props.message}</h5>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 15, flexDirection: 'row', paddingBottom: 15, width: '100%', }}>
              <div
                onClick={this.props.onCancel}
                style={{ backgroundColor: 'rgb(193, 193,193)', width: '40%', cursor: 'pointer', fontSize: '16px', color: 'white', textAlign: 'center', paddingTop: 5, paddingBottom: 5, borderRadius: 5, }}>
                No
              </div>
              <div
                onClick={this.props.onAccept}
                style={{ backgroundColor: 'rgb(255, 52,111)', width: '40%', cursor: 'pointer', fontSize: '16px', color: 'white', textAlign: 'center', paddingTop: 5, paddingBottom: 5, borderRadius: 5, }}>
                Yes
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
