import React from 'react';
import { Motion, spring } from 'react-motion';
import List from '../Notifications/List';


const normalHeight = 80;
const loadingHeight = 66;

export default ({ isOpen, isFetching, notifications, onChange, ...props }) => {
    let height = isOpen ? spring(notifications.length * normalHeight) : spring(0);

    height = isFetching ? spring(loadingHeight) : height;

    const motionStyles = {
        height: isOpen ? height : spring(0),
        width: isOpen ? spring(500) : spring(0),
    };

    return (
        <div className="row">
            <Motion style={motionStyles}>
                {
                    motionStyles => (
                        <div style={Object.assign(motionStyles)}>
                            {
                                isOpen ?
                                    <List
                                    {...props}
                                        onChange={onChange}
                                        motion={motionStyles}
                                        isFetching={isFetching}
                                        notifications={notifications}
                                         /> :
                                    <div />
                            }
                        </div>
                    )
                }
            </Motion>
        </div>
    );
}