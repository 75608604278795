import React, { Component } from "react";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import "../components/Login/style.css";
import { Tab, TabList } from "react-tabs";
import { Manager } from "../StorageManager/Storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";

class ChangeEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            number: "",
            currentEmail: "",
            errorMessage: "",
            showPositiveAlert: false,
            showNegativeAlert: false,
        };
    }

    componentDidMount() {
        if (this.props.location.state.changeEmail === true) {
            this.setState({
                currentEmail: this.props.location.state.data,
            });
        }
        if (this.props.location.state.verifyMsisdnThruPassword === true) {
            this.setState({
                number: this.props.location.state.data,
            });
        }
    }

    sendTacNumber = (password) => {
        let msisdnVerify = this.props.location.state.verifyMsisdnThruPassword;
        let payload = {
            password: password.password,
        };

        this.props.apiManager.makeCall("verifyPassword", payload, (res) => {
            if (res.message === 7084) {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: "Password verified!",
                });
                if (msisdnVerify === true) {
                    this.props.history.push("/change-MSISDN");
                } else {
                    this.props.history.push("/ChangingEmail");
                }
                return;
            }
            if (res.message === 7083) {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage:
                        "Wrong password Please type correct password !",
                });
                return;
            }
            if (res.message === 5002) {
                this.setState({
                    showNegativeAlert: true,
                    errorMessage: "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
                });
                return;
            }
        });
    };

    render() {
        let msisdnVerify = this.props.location.state.verifyMsisdnThruPassword;
        let userID = Manager.getItem("profileIdMdlwr");
        
        return (
            <div className="limiter">
                <Breadcrumb title={"Verify Password"} />
                <Formik
                    initialValues={{
                        password: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.password) {
                            errors.password = "Enter password";
                            // errors.confirmPassword = 'Please enter your confirm password';
                        }
                        // if (regex.test(values.password)) {
                        //     errors.password =
                        //         "Should have 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character";
                        // }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .required("Password is required")
                            .matches(
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                            ),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        // this.props.onSubmit(values);
                        this.sendTacNumber(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        Verify Password
                                    </span>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="password"
                                            className="input100"
                                            name="password"
                                            placeholder="Enter Password"
                                            id="defaultRegisterFormPassword"
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                    </div>
                                    <ErrorMessage
                                        style={{ color: "red" }}
                                        id="error"
                                        name="password"
                                        component="div"
                                    />
                                    {msisdnVerify === true ? null : (
                                        <div>
                                            <TabList
                                                className="tab-title"
                                                style={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Tab
                                                    direction={"column"}
                                                    style={{
                                                        paddingRight: 10,
                                                        alignItems: "stretch",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <u
                                                        className="text-primary"
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                {
                                                                    pathname:
                                                                        "/VerifyEmailThroughOTP",
                                                                    search: `id=${this.state.currentEmail}`,
                                                                    state: {
                                                                        verifyEmailThruOTP: true,
                                                                        data: this
                                                                            .state
                                                                            .currentEmail,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Verify through OTP ?
                                                    </u>
                                                </Tab>
                                            </TabList>
                                        </div>
                                    )}
                                    <div className="container-login100-form-btn">
                                        <button
                                            className="login100-form-btn"
                                            type="submit"
                                        >
                                            Verify
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    open={this.state.showNegativeAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(ChangeEmail);
