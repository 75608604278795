import React, { Component } from "react";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import NegativeAlert from "../components/CustomAlert/CustomAlert";

class ChangingEmail extends Component {
    state = {
        loading: false,
        currentEmail: "",
        errorMessage: "",
        showPositiveAlert: false,
        showNegativeAlert: false,
    };

    componentDidMount() {
    }

    verifyEmailFunction = (registerData) => {
        this.setState({ loading: true });
        let body = {
            newEmail: registerData.email,
        };

        this.props.apiManager.makeCall("changeEmail", body, (res) => {
            if (res.message === 7064) {
                this.setState({
                    errorMessage:
                        "Successfully User email Updated! Please Verify email",
                    loading: false,
                    showPositiveAlert: true,
                    navigationEmail: body.email,
                });
                setTimeout(() => {
                    // this.handleLogOut();
                    this.props.history.push("/account");
                }, 2000);
            } else if (res.message === 7063) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Email already exist",
                });
            } else if (res.message === 7099) {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: "Old email not found",
                });
            } else {
                this.setState({
                    loading: false,
                    showNegativeAlert: true,
                    errorMessage: res.decodedMessage? res.decodedMessage:"Something went wrong",
                });
            }
        });
    };

    render() {
        return (
            <div>
                <Breadcrumb title={"CHANGE EMAIL"} />
                <Formik
                    initialValues={{
                        email: this.state.currentEmail,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = "Please enter your email";
                        }
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                values.email
                            )
                        ) {
                            errors.email = "Invalid email address";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({})}
                    onSubmit={(values, { setSubmitting }) => {
                        this.verifyEmailFunction(values);

                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Change Email
                                        </span>

                                        <div
                                            className="wrap-input100 validate-input"
                                            style={{
                                                width: "70%",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Field
                                                type="email"
                                                name="email"
                                                id="defaultRegisterFormEmail"
                                                className="input100"
                                                placeholder="Enter Email"
                                                // value={this.state.currentEmail}
                                            />
                                            <span className="focus-input100"></span>
                                            <span className="symbol-input100">
                                                <i className="fa fa-envelope"></i>
                                            </span>

                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="email"
                                                component="p"
                                            />
                                        </div>

                                        <div
                                            className="container-register100-form-btn"
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                alignSelf: "center",
                                                width: "50%",
                                            }}
                                        >
                                            {this.state.loading ? (
                                                <div
                                                    style={{
                                                        // left: 100,
                                                        // top: 100,
                                                        // bottom: 50,
                                                        zIndex: "100",
                                                        // position: "absolute",
                                                    }}
                                                >
                                                    <LoadingLoader
                                                        visible={true}
                                                    />
                                                </div>
                                            ) : (
                                                <button
                                                    className="login100-form-btn"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Send VERIFICATION EMAIL
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Success"}
                    message={this.state.errorMessage}
                />
                <NegativeAlert
                    error
                    title={"error"}
                    open={this.state.showNegativeAlert}
                    onClose={() => this.setState({ showNegativeAlert: false })}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(ChangingEmail);
