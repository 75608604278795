import React, { Component } from 'react';
import { SuperHOC } from '../../../../HOC'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import SuccessAlert from '../../../CustomAlert/CustomAlert'
import ErrorAlert from '../../../CustomAlert/CustomAlert'
import { toast, ToastContainer, } from 'react-toastify';
import LoadingLoader from '../../../Loader/Loader'

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import ShareButton from 'react-social-share-buttons'



class Details extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showSuccess: false,
      showError: false,
      errorMessage: '',
      loading: false
    }
  }


  isHearted = (product) => {
    return this.props.user ?
      this.props.user.favoritesProducts.indexOf(product.slug) > -1 :
      false
  }

  handleHeartpress = () => {
    let product = this.props.product
    let body = { productId: product.slug };
    if (this.isHearted(product)) {
      this.props.apiManager.makeCall('remove_favorite_product', body, (result) => {
        if (result.message) {
          if (result.message === '1077') {
            this.props.saveUser(result.buyer);
            toast.success(result.decodedMessage);
          } else {
            toast.error(result.decodedMessage);
          }
        }
      })
    } else {
      this.props.apiManager.makeCall('add_favorite_product', body, (result) => {
        if (result.message) {
          if (result.message === '1074') {
            this.props.saveUser(result.buyer);
            toast.success(result.decodedMessage);
          } else {
            toast.error(result.decodedMessage);
          }
        }
      })
    }
  }

  render() {
    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', backgroundColor: 'white' }}>
      <LoadingLoader visible={this.state.loading} />
    </div>
    const { colors, name, productBrand, productDetails, shippingFrom, parent } = this.props.product
    var enc = window.btoa(this.props.product.slug);
    let removePerc = name ? name.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    let url = 'https://blackmarket.xox.com.my/product/' + convertNameIntoDashes + '?id=' + enc;

    let brand = productBrand && productBrand.brand
    let city = shippingFrom && shippingFrom.cityName.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function(letter) {return letter.toUpperCase(); } )
    let productDetail = productDetails && productDetails[0].summary
    let discountPrice = colors && colors[0].specQuantity[0].price && colors[0].specQuantity[0].price.discountPercent

    const slugmobile = {
      pathname: `/mobileChat/${this.props.product.slug}`,
      state: {
        slug: this.props.product.slug
      }
    }

    const slugobj = {
      pathname: `/chatScreen/${this.props.product.slug}`,
      state: {
        slug: this.props.product.slug,

      }
    }


    return (
      <div className="col-lg-4">
        { discountPrice > 0 ? <span class="discount-badge">{discountPrice.toFixed(0)}% OFF</span> : null }
        <div className="product-right product-description-box">
          <h3 style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}> {name} </h3>
          <div className="border-product">
            <h6 className="product-title">Product Summary</h6>
            <pre>
              {productDetail}
            </pre>
          </div>
          <div className="single-product-tables detail-section">
            <table>
              <tbody><tr>
                <td>Brand:</td>
                <td>{brand}</td>
              </tr>
                {/* <tr>
                  <td>Color:</td>
                  <td>
                    Green
                  </td>
                </tr> */}
                <tr>
                  <td>Shipping From:</td>
                  <td>{city}</td>
                </tr>
                <tr>
                  <td style={{ minWidth: '150px' }}>Standard Shipping:</td>
                  <td style={{ minWidth: '150px' }}>{ parent == "xoxinternational@xox.com.my" ?  "7-14 working days" : "2-3 working days"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-product">
            <h6 className="product-title">share it</h6>
            <div className="product-icon">


              <ul className="product-social" style={{ display: 'flex', alignItems: 'center' }}>
                <li
                  className="network"
                >
                  <FacebookShareButton
                    className="network__share-button"
                    url={url}
                    style={{ fontSize: 18 }}
                  >
                    <i className="fa fa-facebook"></i>
                  </FacebookShareButton>
                </li>
                {/* <li
                  className="network"
                >
                  <ShareButton
                    style={{ fontSize: 18 }}
                    className="network__share-button"
                    compact
                    socialMedia={'google-plus'}
                    url={url}
                  >
                    <i className="fa fa-google-plus"></i>
                  </ShareButton>
                </li> */}
                <li
                  className="network"
                >
                  <TwitterShareButton
                    style={{ fontSize: 18 }}
                    className="network__share-button"
                    url={url}
                  >
                    <i className="fa fa-twitter"></i>
                  </TwitterShareButton>
                </li>
                <li
                  className="network"
                >
                  <WhatsappShareButton
                    style={{ fontSize: 18 }}
                    className="network__share-button"
                    url={url}
                  >
                    <i className="fa fa-whatsapp"></i>
                  </WhatsappShareButton>
                </li>
              </ul>
              <button className="wishlist-btn" onClick={this.handleHeartpress}>
                <i className="fa fa-heart" style={{ color: this.isHearted(this.props.product) ? 'red' : '#bbbbbb' }}></i>
                <small className="title-font">Add To WishList</small>
              </button>
              {window.innerWidth > 576 ?
                <div onClick={() => this.props.history.push(slugobj)} >
                  {/* <FontAwesomeIcon icon={faComments} size="2x" style={{ marginLeft: '6px', color: '#ed4637' }} /> */}
                  <div className="product-buttons">
                  <div className="btn btn-solid1 btn-dark" style={{ margin: 5 }}>Chat</div>
                </div>
                </div>
                :
                <div onClick={() => this.props.history.push(slugobj)} >
                  <FontAwesomeIcon icon={faComments} size="2x" style={{ marginLeft: '6px', color: '#ed4637' }} />
                </div>
              }


              {/* <Link to={`${process.env.PUBLIC_URL}/ChatScreen/${slug}`} >
                Chat
              </Link> */}

            </div>
          </div>
        </div>
        <SuccessAlert
          onClose={() => this.setState({ showSuccess: false })}
          open={this.state.showSuccess}
          message={this.state.errorMessage}
        />
        <ErrorAlert
          onClose={() => this.setState({ showError: false })}
          open={this.state.showError}
          message={this.state.errorMessage}
        />
        <ToastContainer />
      </div>
    )
  }
}


export default SuperHOC(Details);