import React, { Component } from 'react'
import LoadingLoader from '../components/Loader/Loader'
import { SuperHOC } from "../HOC";
import NegativeAlert from "../components/CustomAlert/CustomAlert";
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Breadcrumb from "../components/common/breadcrumb";

class VerifyPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showNegativeAlert: false,
    }
  }
  componentDidMount = () => {
  }
  resetPassword = (password) => {
    let hash = this.props.match.params.item
    let body = {
      password: password.password
    }
    this.props.apiManager.makeCall('forgot_password/' + hash, body, (res) => {
      if (res.message === '1030') {
        this.setState({ loading: false })
      }
      else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <div className="limiter">
            <Breadcrumb title={'ResetPassword'} />
            <Formik
              initialValues={{ password: '' }}
              validate={values => {
                const errors = {};
                if (!values.password) {
                  errors.password = 'Required';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.resetPassword(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <div className="container-login100">
                  <div className="wrap-login100">
                    <Form className="login100-form validate-form">
                      <span className="login100-form-title">
                        Reset Password
                </span>
                      <div className="wrap-input100 validate-input">
                        <Field type="password" name="password" id="defaultRegisterFormEmail" className="input100" placeholder="New Password" />
                        <span className="focus-input100"></span>
                        <span className="symbol-input100">
                          <i className="fa fa-envelope"></i>
                        </span>
                        <ErrorMessage id="error" name="password" component="div" />
                      </div>
                      <div className="container-login100-form-btn">
                        <button className="login100-form-btn" type="submit" disabled={isSubmitting}>
                          Submit
                    </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          </div>
          <NegativeAlert
            error
            onClose={() => this.setState({ showNegativeAlert: false })}
            open={this.state.showNegativeAlert}
            title={'Error'}
            message={this.state.errorMessage}
          />
        </div >)
    }
    else {
      return (
        <div className="modal-content quick-view-modal" style={{ border: 0, fontSize: 15, padding: '50px 30px 50px 30px', display: 'flex', alignItems: 'center', background: 'white' }}>
          <div style={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'rgb(211,211,211,0.3)', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '0px 30px' }}>
            <div className="col-xl-6 col-lg-6 col-md-6 order-sec" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 50 }}>
              <div className="row blog-media" style={{ marginBottom: 2, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', background: 'beige', padding: 20, borderRadius: '50%', height: '150px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={`${process.env.PUBLIC_URL}/assets/Black_Market_Logo_Header_Black.png`} style={{ maxHeight: '200px', cursor: 'pointer' }} alt="Black Market Logo Header Black" height='100%' width='100%' onClick={() => this.props.history.push('/')} />
              </div>
            </div>
            <h2 style={{ textAlign: 'center', color: 'rgb(224, 106, 66)', textShadow: '2px 2px #000000ab' }}>Password Reset Successfull</h2>
            <div className="row footer-theme partition-f">
              <div className="col offset-xl-1">
                <div className="sub-title">
                  <div className="footer-title">
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li style={{ textShadow: '2px 2px beige', fontSize: 16, marginTop: 5 }}><Link to={`${process.env.PUBLIC_URL}/`} >ShopNow</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default SuperHOC(VerifyPassword)
