import React, { Component } from 'react'
import { SuperHOC } from "../HOC";
import Listing from '../components/List/InfiniteList'
import BreadCrumb from "../components/common/breadcrumb";

class BrandProdutcs extends Component {
  render() {
    let { params } = this.props.match;
    return (
      <div style={{ minHeight: '100vh', paddingTop: 20 }}>
        <BreadCrumb title={params.brandName} />
        <div style={{ paddingTop: 20, }}>
          <Listing
            title={'Brands products'}
            colSize={3}
            api={'get_brand_products'}
            brandName={params.brandName}
            {...this.props}
          />
        </div>
      </div>
    )
  }
}

export default SuperHOC(BrandProdutcs)