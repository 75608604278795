import React, { Component } from 'react'
import NotificationContainer from '../Notifications/NotificationContainer';
const styles = {
    fontFamily: 'sans-serif',
    textAlign: 'center'
};

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isFetching: false,
            listToWatch: [],
            loading: false,
            seenArray: [],
            unseenArray: [],
            promotionsArray: [],
            countNotification: 0,
            emptySet: '',
            emptyState: ''
            // end: false,
            // loading: true
            
            
        };
    }
    componentDidMount() {  
    }
    _toggleList=(pageNum = 1)=> {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    buttonCalled = (id) => {
        if (id === 0) {
            this.setState({ listToWatch: this.state.unseenArray,
                            emptySet: this.state.emptyState
            })
        } else if (id === 1) {
            this.setState({ listToWatch: this.state.seenArray })
        } else if (id === 2) {
            
            this.setState({ listToWatch: this.state.promotionsArray })
        }
    }

    render() {
        return (
            <div style={styles}>
                <NotificationContainer
                    {...this.props}
                    onChange={(id) => this.buttonCalled(id)}
                    isFetching={this.state.isFetching}
                    isOpen={this.state.isOpen}
                    toggleHandler={this._toggleList}
                    items={this.state.listToWatch}
                    data={this.state.countNotification}
                />
            </div>
        );
    }
}
export default Notification;