import React, { Component } from 'react'
import ProductItem from '../checkout/ProductItems'
import Breadcrumb from "../common/breadcrumb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, } from '@fortawesome/free-solid-svg-icons'
import LoadingLoader from '../Loader/Loader'
import { formatDate } from '../../config/HelperFunction'
import moment from "moment";
export default class MainContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      cartJson: {},
      emptyCart: false,
      selectOnly: {},
      checkoutItems: {},
      loading: true,
      checked: null,
      allTrue: true,
      previous: ''
    }
  }

  componentDidMount() {
    // if session is getting called twice ? // 
    //comment this code of line below //
    this.props.apiManager.createSession();
    this.getPayout();
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')
    if (previous[3] !== same[2]) {
      this.setState({ previous: previous[3] })
    }
  }

  goToPaymentMaythedsScreen = (checkoutId) => {
    this.props.history.push(`/Payments/?id=${checkoutId}`)
  }

  emptyCart = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div >
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                    <h3>
                      <strong>There is nothing to pay</strong>
                    </h3>
                    <h4>Explore more shortlist some items.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  cancelCheckout = (checkoutId) => {
    this.props.apiManager.makeCall('cancel_topay_checkout_cart', {'checkoutId': checkoutId}, (res) => {
      if (res.message === '1970') {
        this.setState({ checkoutItems: '' })
        this.getPayout()
      }
      else {
      }
    })
  }

  getPayout = () => {
    this.props.apiManager.makeCall('get_topay_checkout', {}, (res) => {
      if (res.message === '1426') {
        this.setState({ cartJson: res.carts, loading: false })
      }
      else if (res.message === '1425') {
        this.setState({ cartJson: {}, emptyCart: true, loading: false })
      }
      else {
        this.setState({ cartJson: {}, emptyCart: true, loading: false })
      }
    })
  }

  getCheckoutItemDetail = (checkoutId) => {
    this.props.apiManager.makeCall('get_topay_checkout_items', {'checkoutId' : checkoutId}, (res) => {
      if (res.message === '1426') {
        this.setState({ checkoutItems: res.cartJson, loading: false })
      }
      else if (res.message === '1425') {
        this.setState({ checkoutItems: {}, emptyCart: true, loading: false })
      }
      else {
        this.setState({ checkoutItems: {}, emptyCart: true, loading: false })
      }
    })
  }

  getToPayDate = (date) => {
    // return new Date(date).toLocaleDateString("en-US")
    return moment(date).format('DD/MM/YYYY')
  }


  renderProductItem = () => {
    let  checkoutItems  = this.state;
    const { merchantProducts } = this.state.checkoutItems
    const newItem = Object.keys(merchantProducts).map(key => merchantProducts[key])

    if (newItem.length === 0) return null

    return newItem.map((item, index) => (
       <>
       {/* let checkoutItem = this.getPayoutCheckoutItems() */}
      <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style={{ marginTop: '50px' }}
        >
        <div style={{ backgroundColor: '#f9f9f9f9', display: 'flex', padding: 10 }}>
          <h3 style={{ margin: 0, padding: 0 }}>Products</h3>
        </div>
        <div>
          <table className="table cart-table table-responsive">
            <thead style={{ width: '100%' }}>
              <tr className='table-row' >
                <th scope='col'>Image</th>
                <th scope='col'>Name</th>
                <th scope='col'>Spec</th>
                <th scope='col'>Color</th>
                <th scope='col'>Price</th>
                <th scope='col'>Qty</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            <ProductItem
                key={index}
                item={item}
                itemJson={item}
            />
          </table>
        </div>
    </div>
        {this.renderToPayDetail(item)}
    </>
   ))
  }

  renderToPayItemsDetail = () => {
    const { merchantProducts } = this.state.checkoutItems
    if (merchantProducts == undefined || merchantProducts.length === 0) return null
    const newItem = Object.keys(merchantProducts).map(key => merchantProducts[key])
    if (newItem.length === 0) return null
    return (
      <>
      <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 cart-side-bar'>
        <div style={{ backgroundColor: '#f9f9f9f9', display: 'flex', padding: 10 }}>
          <h3 style={{ margin: 0, padding: 0 }}>Products</h3>
        </div>
        <div>
          <table className="table cart-table table-responsive">
            <thead style={{ width: '100%' }}>
              <tr className='table-row' >
                <th scope='col'>Image</th>
                <th scope='col'>Name</th>
                <th scope='col'>Spec</th>
                <th scope='col'>Color</th>
                <th scope='col'>Price</th>
                <th scope='col'>Qty</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            {
              newItem.map((item, index) => {
                let itemJson = item.itemsJson
                return (
                  <ProductItem
                    key={index}
                    item={item}
                    itemJson={itemJson}
                  />
                )
              })
            }
          </table>
        </div>
      </div>
      </>
    )
  }

  renderToPayDetail = (item) => {
    const { cartJson } = this.state
    if (cartJson.length === 0){
      this.setState({ emptyCart: true })
      return null
    } 
    return cartJson.map((item, index) => (
      <>
        <div className={ + (index%2 != 0) ? 'col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 cart-side-bar offset-1' : 'col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 cart-side-bar'}>
        
        <h3 className="text-center">To Pay Item</h3>
        <small>Date: {formatDate(new Date(item.createdAt))}</small>
        <div className="checkout-details">
        <div className="order-box">
            <div className="title-box">
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>Shipping & Billing</div>
            <div className="justify-content-between" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '5px' }}>
                <h4>City: {item.deliveryAddress.cityName}</h4>
                <h4>State: {item.deliveryAddress.provinceName}</h4>
                <h4>PostCode: {item.deliveryAddress.postalCode}</h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '5px' }}>
                <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />
                <h4 style={{ fontSize: '16px', marginLeft: '14px', marginTop: '2px' }}>{item.deliveryAddress.address + ', ' + item.deliveryAddress.postalCode + ', ' + item.deliveryAddress.cityName}</h4>
            </div>
            </div>
            <ul className="qty">
            <li>Product Total <span>RM {item.productsTotal.toFixed(2)}</span></li>
            </ul>
            <ul className="sub-total" style={{ borderBottom: '0px' }}>
            <li>Shipping <span className="count">RM {item.deliveryCost.toFixed(2)}</span></li>
            <li>Subtotal <span className="count">RM {item.subTotal.toFixed(2)}</span></li>
            </ul>
            <ul className="total">
            <li>Total <span className="count">RM {(item.subTotal+item.deliveryCost).toFixed(2)}</span></li>
            </ul>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div
                // onClick={this.goToPaymentMaythedsScreen(item._id)}
                onClick={() => this.getCheckoutItemDetail(item._id)}
                
                className="btn btn-solid">Detail</div>
            <div
                // onClick={this.goToPaymentMaythedsScreen}
                onClick={() => this.goToPaymentMaythedsScreen(item._id)}
                className="btn btn-solid">Proceed To Pay</div>
            <div
                onClick={() => this.cancelCheckout(item._id)}
                className="btn btn-solid">Cancel Topay</div>
            </div>
        </div>
        </div>
        </div>
        </>
     )
    )
  }

  renderServicesItem = () => {
    const { merchantServices } = this.state.cartJson
    const newItem = Object.keys(merchantServices).map(key => merchantServices[key])
    if (newItem.length === 0) return null
    return (
      <>
        <div style={{ marginTop: 20, padding: '10px', backgroundColor: '#f9f9f9f9', display: 'flex', flexDirection: 'row' }}>
          <h3 style={{ marginLeft: '10px' }}>Services</h3>
        </div>
        <div>
          <table className="table cart-table table-responsive">
            <thead style={{ width: '100%' }}>
              <tr className='table-row' >
                <th scope='col'>Image</th>
                <th scope='col'>Name</th>
                <th scope='col'>Spec</th>
                <th scope='col'>Color</th>
                <th scope='col'>Price</th>
                <th scope='col'>Qty</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            {
              newItem.map((item, index) => {
                let itemJson = item.itemsJson
                return (
                  <ProductItem
                    key={index}
                    item={item}
                    itemJson={itemJson}
                  />
                )
              })
            }
          </table>
        </div>
      </>
    )
  }

  render() {
    // const {
    //   cartJson  
    // } = this.state.cartJson
    if (this.state.loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}> <LoadingLoader visible={this.state.loading} /> </div>
    return (
      <div>
        <Breadcrumb title={'Topay'} parent={this.state.previous} />
        {
          this.state.emptyCart ? this.emptyCart() : <section className="cart-section section-b-space">
            <div className="container">
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', }}>
                {this.renderToPayDetail()}
                {this.renderToPayItemsDetail()}
              </div>
            </div>
          </section>
        }
      </div>
    )
  }
}