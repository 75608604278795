import React, { Component } from 'react'
import Checkout from '../components/checkout/MainContainer'
import { SuperHOC } from "../HOC";
class CheckoutScreen extends Component {


  render() {

    return (
      <div>
        <Checkout
          {...this.props}
        />
      </div>
    )
  }
}
export default SuperHOC(CheckoutScreen)