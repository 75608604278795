import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SuperHOC } from "../HOC";
import * as Yup from "yup";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";

const prefix = "60";

class SignupMsisdn extends Component {
    state = {
        msisdn: "",
        phone: "60",
        token: "",
        loading: false,
        successLogin: false,
        showErrorAlert: false,
        inputPhoneNumber: "",
        registerComponentFlag: true,
    };

    // Event Functions //
    handleChange = (e) => {
        let temp = [];
        let newTemp = [];
        const input = e.target.value;
        const name = e.target.name;
        let number = (e.target.value = prefix + input.substr(prefix.length));
        temp.push(input);

        if (temp[0][2] !== "0") {
            newTemp.push(...temp);
            this.setState({
                phone: number ? number : newTemp,
            });
        }
    };

    // Api Implementation //

    sendRegisterOTP = (values) => {
        this.setState({ loading: true });
        let body = {
            MSISDN: values.phone,
        };
        this.props.apiManager.makeCall("sendRegisterOtp", body, (res) => {
            if (res.message === 7056) {
                this.setState({
                    showErrorAlert: true,
                    loading: false,
                    errorMessage: "MSISDN is Already Taken",
                });
            }
            if (res.message === 6107) {
                this.setState({
                    showErrorAlert: true,
                    loading: false,
                    errorMessage:
                        "OTP Limit Exceed! Please wait for 10 minutes",
                });
            }
            if (res.message == 7031) {
                this.gotToVerify(res.token, body.MSISDN);
            } else if (res.message === 7026) {
                this.setState({
                    loading: false,
                    showModal: true,
                    errorMessage: "MSISDN NUMBER REQUIRED",
                });
            }
        });
    };

    gotToVerify = (token, MSISDN) => {
        this.setState({
            token: token,
            loading: false,
            msisdn: MSISDN,
            successLogin: true,
            errorMessage: `OTP Has Been sent to ${MSISDN}`,
        });

        setTimeout(() => {
            this.props.history.push({
                pathname: `/VerifySignupMsisdn`,
                search: `id=${MSISDN}`,
                state: {
                    token: token,
                    msisdn: MSISDN,
                    navigateFromSignup: true,
                },
            });
        }, 2000);
    };

    render() {
        const MyInput = ({ field, form, ...props }) => {
            return <input {...field} {...props} />;
        };

        let phone = this.state.phone;
        return (
            <div>
                <Breadcrumb title={"create account"} />
                <Formik
                    initialValues={{ phone: phone }}
                    validate={(values) => {
                        values.phone = this.state.phone;
                        const errors = {};
                        if (!phone === "60") {
                            errors.phone = "Enter Msisdn with 60";
                            // errors.confirmPassword = 'Please enter your confirm password';
                        }
                        if (phone === "60") {
                            errors.phone = "Field cannot be empty";
                        }
                        if (!/\60/i.test(phone)) {
                            errors.phone =
                                "Invalid MSISDN address! Must start with 60";
                        }

                        if (phone.length <= 10) {
                            errors.phone =
                                "Msisdn must be at least 11 characters";
                        }
                        // if(!/\d*/.test(value)) return
                        if (!/\d*/.test(phone)) {
                            errors.phone =
                                "Msisdn must be at least 11 characters";
                        }
                        if (phone.length >= 14) {
                            errors.phone =
                                "Msisdn must be less then 14 characters";
                        }
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting }) => {
                        this.sendRegisterOTP(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                        ACCOUNT REGISTRATION
                                        </span>

                                        <div className="col-xl-4 col-sx-12">
                                            <div className="wrap-input100 validate-input">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    className="input100"
                                                    component={MyInput}
                                                    value={this.state.phone}
                                                    placeholder="Enter Phone Number"
                                                    id="defaultRegisterFormPassword"
                                                    onChange={(e) =>
                                                        this.handleChange(e)
                                                    }
                                                />
                                                {/* <Field
                                                    type="text"
                                                    name="phone"
                                                    className="input100"
                                                    component={MyInput}
                                                    placeholder="Enter Phone Number"
                                                    id="defaultRegisterPhonePassword"
                                                /> */}
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-phone"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                id="error"
                                                name="phone"
                                                component="p"
                                                style={{ color: "red" }}
                                            />

                                            <div className="container-register100-form-btn">
                                                {this.state.loading ? (
                                                    <div
                                                        style={{
                                                            zIndex: "100",
                                                        }}
                                                    >
                                                        <LoadingLoader
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="login100-form-btn"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        Register
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
                <PositiveAlert
                    onClose={() => this.setState({ successLogin: false })}
                    open={this.state.successLogin}
                    title={"Error"}
                    message={this.state.errorMessage}
                />

                <PositiveAlert
                    error
                    onClose={() => this.setState({ showErrorAlert: false })}
                    open={this.state.showErrorAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(SignupMsisdn);
