import React, { Component } from 'react'
import StarRatings from 'react-star-ratings';
import classes from './style.module.css'
import { formatDate } from '../../../config/HelperFunction'
export default class Review extends Component {

  renderRating = rating => {
    return (
      <StarRatings
        rating={rating}
        starRatedColor={"#ffb508"}
        changeRating={this.changeRating}
        numberOfStars={5}
        name='rating'
        starDimension="15px"
        starSpacing="2px"
      />
    )
  }

  render() {
    const { timeAgo, reviewerName, comment, rating } = this.props
    let date = formatDate(new Date(timeAgo))
    return (
      <div className={classes.reviewContainer}>
        <div className={classes.reviewSubContainer}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h6 style={{ padding: '5px 10px 0px 0px' }}>{reviewerName}</h6>
            <div style={{ padding: '5px 0px 0px 10px' }}>
              {this.renderRating(rating)}
            </div>
          </div>
          <div style={{ padding: '5px 10px 0px 0px' }}>
            {date}
          </div>
        </div>
        <div>
          <section>
            {comment}
          </section>
        </div>
      </div>
    )
  }
}
