import React, { Component } from 'react';

class NetworkError extends Component {

    render() {
        return (
            <div>
                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/sad.png`}
                                        height='200px'
                                        width='200px'
                                        style={{ backgroundColor: 'white' }}
                                        alt=''
                                    />
                                    <h2>Network Response Failed</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default NetworkError