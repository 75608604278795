import React, { Component } from 'react'
import { SuperHOC } from '../HOC';
import NotificationMobile from '../components/Notifications/NotificationMobile'


class MobileNotification extends Component {


    render() {
        return (
            <div >
                <NotificationMobile
                    {...this.props}
                />
            </div>
        )
    }
}

export default SuperHOC(MobileNotification);
