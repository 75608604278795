import React, { Component } from 'react'
import LoadingLoader from '../components/Loader/Loader'
import { SuperHOC } from "../HOC";
import NegativeAlert from "../components/CustomAlert/CustomAlert";
import LinkExpiredError from "../components/CustomAlert/CustomAlert";
import PositiveAlert from "../components/CustomAlert/CustomAlert";
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showNegativeAlert: false,
      showLinkExpiredError: false,
      emailaddress: '',
      showEmailModal: false
    }
  }
  componentDidMount = () => {
    this.confirmEmail()
  }
  isMobile = () => {
    if (window.innerWidth < 576) {
      return true
    }
    else {
      return false
    }
  }
  resendEmail = () => {
    let body = {
      email: this.state.emailaddress,
    }
    this.props.apiManager.makeCall('resend_email', body, (res) => {
      if (res.message === '1015') {
        this.setState({ showEmailModal: false, showPositiveAlert: true })
      }
      else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }
  confirmEmail = () => {
    let body = {
      hash: this.props.match.params.item,
    }
    this.props.apiManager.makeCall('confirm/' + body.hash, {}, (res) => {
      if (res.message === '1013') {
        this.setState({ loading: false })
      }
      else if (res.message === '1011') {
        this.setState({
          showLinkExpiredError: true,
          errorMessage: res.decodedMessage,
        })
      }
      else {
        this.setState({
          showNegativeAlert: true,
          errorMessage: res.decodedMessage
        })
      }
    })
  }
  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };


  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', minHeight: 500, justifyContent: 'center', alignItems: 'center' }}>
          <LoadingLoader visible={this.state.loading} />
          <NegativeAlert
            error
            onClose={() => this.setState({ showNegativeAlert: false })}
            open={this.state.showNegativeAlert}
            title={'Error'}
            message={this.state.errorMessage}
          />
          <LinkExpiredError
            error
            onClose={() => this.setState({ showLinkExpiredError: false, showEmailModal: true })}
            open={this.state.showLinkExpiredError}
            title={'Error'}
            message={this.state.errorMessage}
          />
          <PositiveAlert
            onClose={() => this.setState({ showPositiveAlert: false })}
            open={this.state.showPositiveAlert}
            title={'Success'}
            message={'Email Resent'}
          />
          <Modal closeOnOverlayClick={true} open={this.state.showEmailModal} onClose={() => this.setState({ showEmailModal: false })} center styles={{ modal: { padding: '10px 5px 10px 5px', borderRadius: 5 } }} showCloseIcon={false}>
            <div className="modal-content quick-view-modal" style={{ border: 0, width: this.isMobile() ? 300 : 400 }}>
              <div className="modal-body" style={{ border: 0 }}>
                <h4 style={{ textAlign: 'center', paddingBottom: 10 }}>Email Address </h4>
                <p style={{ textAlign: 'center', lineHeight: 1.4 }}>
                  Please Enter Your Email Address
                </p>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="product-description border-product">
                      <form className="form-header">
                        <div className="input-group" style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                          <div id="PaymentForm">
                            <form style={{ marginTop: 20 }}>
                              <div className="form-group">
                                <input
                                  size='30'
                                  type="text"
                                  name="emailaddress"
                                  className="form-control"
                                  placeholder="Email Address"
                                  value={this.state.emailaddress}
                                  required
                                  onChange={this.handleInputChange}
                                  onFocus={this.handleInputFocus}
                                />
                                <p style={{ padding: '5px 0px 0px 0px', margin: 0, color: 'red' }}>{this.state.emptyCode ? 'Verification Code Can Not Be Empty' : this.state.sameCode ? 'Verification Code Not Same' : ''}</p>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                          <div
                            onClick={this.resendEmail}
                            style={{ backgroundColor: 'rgb(91,149,255)', width: '80%', cursor: 'pointer', fontSize: '16px', color: 'white', textAlign: 'center', paddingTop: 10, paddingBottom: 10, borderRadius: 10 }}>
                            Submit
                           </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>)
    }
    return (
      <div className="modal-content quick-view-modal" style={{ border: 0, fontSize: 15, padding: '50px 30px 50px 30px', display: 'flex', alignItems: 'center', background: 'white' }}>
        <div style={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'rgb(211,211,211,0.3)', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '0px 30px' }}>
          <div className="col-xl-6 col-lg-6 col-md-6 order-sec" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 50 }}>
            <div className="row blog-media" style={{ marginBottom: 2, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', background: 'beige', padding: 20, borderRadius: '50%', height: '150px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/assets/Black_Market_Logo_Header_Black.png`} style={{ maxHeight: '200px', cursor: 'pointer' }} alt="Black Market Logo Header Black" height='100%' width='100%' onClick={() => this.props.history.push('/')} />
            </div>
          </div>
          <h2 style={{ textAlign: 'center', color: 'rgb(224, 106, 66)', textShadow: '2px 2px #000000ab' }}>Welcome To XOX Black Market</h2>
          <div className="row footer-theme partition-f">
            <div className="col offset-xl-1">
              <div className="sub-title">
                <div className="footer-title">
                </div>
                <div className="footer-contant">
                  <ul>
                    <li style={{ textShadow: '2px 2px beige', fontSize: 16, marginTop: 5 }}><Link to={`${process.env.PUBLIC_URL}/login`} >Login</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default SuperHOC(VerifyEmail)
