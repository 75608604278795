import React, { Component } from 'react'
import { SuperHOC } from "../HOC";
import Listing from '../components/List/InfiniteList'
import 'react-multi-carousel/lib/styles.css';
import Carousel from "react-multi-carousel";
import classes from '../components/Catagory/style.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import LoadingLoader from '../components/Loader/Loader'
import { Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom'

const CustomLeftArrow = ({ onClick }) => {
  return <div className={classes.leftArrow} onClick={() => onClick()}>
    <FontAwesomeIcon icon={faChevronLeft} size="lg" color='grey' />
  </div>
};

const CustomRightArrow = ({ onClick }) => {
  return <div className={classes.rightArrow}
    onClick={() => onClick()}>
    <FontAwesomeIcon icon={faChevronRight} size="lg" color='grey' />
  </div>
};

class CategoryServices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: [],
      subMenuList: [],
      slug: this.props.location.state.slug
    }
  }

  componentDidMount() {
    this.setState({ slug: this.props.location.state.slug }, () => this.getMenu())
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.state.slug !== this.props.location.state.slug) {
      window.location.reload()
    }
  }

  getMenu = () => {
    this.props.apiManager.makeCall('get_all_categories', {}, res => {
      if (res.message) {
        this.setState({ menu: res.menu }, () => this.getSubMenu())
      }
    })
  }
  getSubMenu = () => {
    this.state.menu.map((menu, index) => {
      menu.subMenu.map((subMenu, index) => {
        subMenu.subMenu.map((subSubMenu, index) => {
          if (subSubMenu.slug === this.state.slug) {
            this.setState({
              subMenuList: subSubMenu.subMenu
            })
          }
        })
      })
    })
  }
  renderItem = (item, index, selectedIndex) => {
    const toggle = (target) => this.setState({ [target]: !this.state[target] });
    var replaced = item.name[0].value.split(' ').join('-');
    const CategoryServices = {
      pathname: `/CategoryService/${replaced}`,
      state: {
        slug: item.slug,
        name: this.props.location.state.name,
        subName: item.name[0].value,
        prevSlug: this.props.location.state.slug
      }
    }
    return (
      <div
        key={index}
        className={index === this.state.index && selectedIndex === this.state.selectedIndex ? classes.firstRow : classes.firstRowUnselected}
        onClick={() => this.props.history.push(CategoryServices)}
        style={{ borderRadius: '50%', background: `url(${item.icon.src}) center center`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}
      >
        {item.name[0].value.length > 14 ?
          <div>
            <p id={"Toolti" + item.slug} className={classes.categoryName}>{item.name[0].value.substring(0, 14) + '..'}</p>
            <Tooltip style={{ fontSize: '12px', backgroundColor: 'rgb(224, 106, 66)' }} placement="bottom" isOpen={this.state[`${item.slug}`]} target={"Toolti" + item.slug} toggle={() => toggle(`${item.slug}`)}>
              {item.name[0].value}
            </Tooltip>
          </div>
          :
          <p id={"Toolti" + item.slug} className={classes.categoryName}>{item.name[0].value}</p>
        }
      </div>
    )
  }
  goBack = () => {
    var replaced = this.props.location.state.name.split(' ').join('-');
    const prevProduct = {
      pathname: `/CategoryService/${replaced}`,
      state: {
        slug: this.props.location.state.prevSlug,
        name: this.props.location.state.name,
        // subName: item.name[0].value,
        // prevSlug: this.props.location.state.slug
      }
    }
    this.props.history.push(prevProduct)
    window.location.reload()
  }

  render() {
    const slug = this.state.slug
    if (this.state.loading) return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', }}>
      <LoadingLoader visibel={this.state.loading} />
    </div>
    return (
      <div style={{ paddingTop: '30px' }}>
        <div className="breadcrumb-section">
          <div>
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb" className="theme-breadcrumb">
                  <ol>
                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{'Services'}</li>
                    {this.props.location.state.subName ?
                      <li style={{ cursor: 'pointer' }} onClick={this.goBack} className="breadcrumb-item active" aria-current="page">{this.props.location.state.name}</li>
                      :
                      <li className="breadcrumb-item active" aria-current="page">{this.props.location.state.name}</li>}
                    {this.props.location.state.subName ?
                      <li className="breadcrumb-item active" aria-current="page">{this.props.location.state.subName}</li>
                      : ''
                    }
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {this.state.subMenuList !== '' ?
          <div className={classes.mainMenu} style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 20, boxShadow: 'rgba(195, 195, 195, 0.39) 0px 3px 5px 0' }}>
            <Carousel
              containerClass={classes.menuContainer}
              additionalTransfrom={0}
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              autoPlaySpeed={3000}
              centerMode={false}
              className=''
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass={classes.item}
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 7,
                  partialVisibilityGutter: 40
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 2,
                  partialVisibilityGutter: 30
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464
                  },
                  items: 3,
                  partialVisibilityGutter: 30
                }
              }}
              showDots={false}
              sliderClass=""
              slidesToSlide={2}
              swipeable
            >
              {
                this.state.subMenuList !== '' ?
                  this.state.subMenuList.map((inside, innerIndex) =>
                    this.renderItem(inside, innerIndex, 0)
                  )
                  : ''
              }
            </Carousel>
          </div>
          : ''}
        <div className="container" style={{ paddingTop: '30px' }}>
          <Listing
            title={'Category Services'}
            colSize={4}
            api={'get_category_products'}
            {...this.props}
            category={slug}
          />
        </div>
      </div>
    )
  }
}

export default SuperHOC(CategoryServices)