import React, { Component } from 'react'
import { NegotiationRequest } from "../components/Negotiation";

import { SuperHOC } from "../HOC";
class NegotiationRequestScreen extends Component {


  render() {
    return (
      <div>
        <NegotiationRequest
          {...this.props}
        />
      </div>
    )
  }
}


export default SuperHOC(NegotiationRequestScreen)