import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
class PageNotFound extends Component {
  render() {
    return (
      <div>
        <Breadcrumb title={'404 Page'} />
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="error-section">
                  <h1>404</h1>
                  <h2>page not found</h2>
                  <Link to="/" className="btn btn-solid">back to home</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default PageNotFound