import React, { Component } from 'react'
import StarRatings from 'react-star-ratings';
import classes from './style.module.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
export default class Rating extends Component {

  renderRating = (productRating) => {
    return (
      <StarRatings
        rating={this.props.RatedStars}
        starRatedColor={"#ffb508"}
        changeRating={this.changeRating}
        numberOfStars={5}
        name='rating'
        starDimension="15px"
        starSpacing="1px"
      />
    )
  }

  render() {
    return (
      <div className={classes.progressBar}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {this.renderRating()}
        </div>
        <div
          className={classes.rightBar}
        // style={{ width: 200,maxWidth:300, paddingLeft: '5px', height:'5px' }}
        >
          <ProgressBar variant="warning" animated="true" now={this.props.barValue} max={this.props.maxBarValue} key={1} />
        </div>
        <div style={{ marginLeft: '5px', maxwidth: '30px' }}>
          <h6> {this.props.value} </h6>
        </div>
      </div>
    )
  }
}
