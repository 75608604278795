import React from 'react';
import {Link} from 'react-router-dom'

function LogoImage(props) {

    return <a href={`${process.env.PUBLIC_URL}/`} >
                <img src={props.logo} alt="" style={props.style}/>
            </a>;
}

export default LogoImage;