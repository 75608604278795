import React, { Component } from 'react';
import { SuperHOC } from "../HOC";
import Checkout from "../components/checkout/checkout";


class CheckoutScreen extends Component {
  render() {
    return (
            <Checkout
            {...this.props}
            />
        );
  }
}
export default SuperHOC(CheckoutScreen)