import React, { Component } from 'react';
// import image from '../assets/terms-&-conditions.jpg';
import Breadcrumb from "../components/common/breadcrumb";

class RefundPolicy extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div style={{ minHeight: '100vh' }}>
        <Breadcrumb title={'Buyer Policy'} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{ marginTop: 44 }}>
                <div className="banner-section">
                  {/* <img src={image} className="img-fluid" alt="" /> */}
                </div>
              </div>
              <div className="col-sm-12">
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h2>BUYER POLICY</h2>
                </div>
                <p>When you, as a Buyer (“you” or “Buyer”) access or use the Platform and its Services, you are agreeing to the policies we have put in place for the Platform.</p>

                <h4>General</h4>
                <p>1. The Policies are legally binding contracts between you and XOX and are incorporated into these Black Market Terms and Conditions by reference.</p>
                <p>2. By accessing, browsing, and/or using the Platform, you are deemed to have irrevocably and unconditionally accepted this Policy.</p>
                <p>3. The Black Market Terms and Conditions shall prevail in the event of any inconsistency.</p>
                <p>4. Unless otherwise specified, capitalised terms in this Policy have the same meaning as in the Black Market Terms and Conditions.</p>
                <p>5. XOX reserves the right to change, add to, modify, suspend, remove or discontinue all or any part of this Buyer Policy, the Platform or the Services at any time at our sole and absolute discretion or upon notice as required by local laws. In such instance, the XOX shall use best effort notify you of such amendments or changes (including the effective date for the same) via an announcement to be published on the main page of the Platform, and you shall thereafter be bound by such modified Buyer Policy. As such, it is your responsibility to regularly visit the Platform, view the Buyer Policy and/or other Policies, and keep yourself updated on any changes made to the same. We do not take any responsibility or agree to indemnify you for losses you suffer arising from the modified Buyer Policy or your own failure to keep yourself updated on the modifications to Buyer Policy, and you irrevocably agree to hold us harmless or indemnify us from any losses that you may suffer therefrom. For the avoidance of doubt,
                newer versions of the Terms and Conditions and/or Policies supersede older versions, unless otherwise stated by XOX.</p>
                <br />

                <h4>Order and Payment</h4>
                <p>6.  Our Platform requires that you create and register an Account in order to place an Order.</p>
                <p>7.  There is no minimum Order.</p>
                <p>8.  You acknowledge that the Products are standard and not made bespoke to fit any particular requirements that you may have.</p>
                <p>9.  You will have to follow the shopping process on the Platform to follow through with an Order.</p>
                <p>10. Once Order is accepted by the Seller, Buyer  shall complete the Transaction with the Seller and to be bound by such additional terms and conditions specified by the Seller on the Seller Store on the Platform, unless (i) the Transaction is prohibited by law or these Terms and Conditions;  or (ii) the Seller materially changes the Product’s description or the Product does not conform to the Seller’s description.</p>
                <p>11. Once your shopping process is completed, you will receive an email confirmation which will act as an acknowledgment of your Order.</p>
                <p>12. Money transactions are outsourced to a third party.</p>
                <p>13. Below are the payment options supported:-</p>
                <p style={{ marginLeft: '25px'}}>a) Credit/ Debit Card – Buyers to be allowed to add and save their preferred card.</p>
                <p style={{ marginLeft: '25px'}}>b) Credit Card Instalment – Buyers are allowed to opt in to any participating instalments available.</p>
                <p style={{ marginLeft: '25px'}}>c) Online Banking / FPX – Users to be allowed to use online banking.</p>
                <p style={{ marginLeft: '25px'}}>d) Vouchers & Promocodes – Buyers would be able to choose from available/ collected vouchers be it from the shop or from Black Market. Please see Vouchers and Promocodes for detailed application.</p>
                <p>14. By confirming that the card belongs to you or that you are the legitimate holder of the card or the promotional voucher, and have sufficient funds or credit facilities to cover the cost of your Order.</p>
                <p>15. At all times, XOX takes no responsibility and assume no liability for any loss or damages to Buyer arising from shipping information and/or payment information entered by Buyer or wrong remittance by Buyer in connection with the payment for the items purchased. XOX reserve the right to check whether Buyer is duly authorised to use certain payment method, and may suspend the transaction until such authorisation is confirmed or cancel the relevant transaction where such confirmation is not available.</p>


                <h4>Voidable Transactions</h4>
                <p>16. Transactions may be voidable in the event that Buyer have committed fraud, impersonated another person to purchase products, used another person's account and banking account to make payments, breached any terms mentioned herein or failed to comply with any terms and conditions published on the Platform and/or XOX.</p>
                <p>17. XOX reserve the right to take legal action against Buyer to recover the Product from Buyer.</p>

                <h4>Vouchers & Promocodes</h4>
                <p>18. Buyer may use promotional vouchers or promocodes (collectively, “Voucher(s)”) while making payment for Products on the Platform subject to the details for the promotional vouchers.</p>
                <p>19. Vouchers are non-refundable, has no monetary value and cannot be exchanged for cash and does not accrue interest.</p>
                <p>20. Vouchers are not allowed to be used on BLACK MARKET Lifestyle and applicable for BLACK MARKET products only.</p>
                <p>21. There will be no reissuance or reimbursements for any lost or void Vouchers.</p>
                <p>22. By redeeming a Voucher, Buyer agree to be bound by these Terms and Conditions as well as our Policies.</p>
                <p>23. Unless stated otherwise, Vouchers:-</p>
                <p style={{ marginLeft: '25px'}}>(a) are only redeemable once; </p>
                <p style={{ marginLeft: '25px'}}>(b) are valid only for the period specified on the Voucher;</p>
                <p style={{ marginLeft: '25px'}}>(c) cannot be combined with other promotional vouchers and/or discounted items;</p>
                <p style={{ marginLeft: '25px'}}>(d) are only redeemable ONCE per promotional campaign;</p>
                <p>24. XOX reserve the right, at our sole discretion, to change, delete, or add to any of these Terms and Conditions, as well as substitute or replace any Voucher with another product or programme of equivalent value, at any time and may be without prior notice.</p>
                <p>25. XOX assume no liability for the loss, theft or illegibility of Voucher</p>
                <p>26. XOX reserves to the right to cancel any subsequent orders made by you using a voucher of the same campaign</p>
                <p>27. Voucher may be subject to the terms and conditions therein.</p>
                <p>28. When purchasing a Product with Voucher, if:</p>
                <p style={{ marginLeft: '25px'}}>(a) Buyer place an Order for a Product that is less than the value stated on the Voucher, Buyer will not receive a refund or residual credit;</p>
                <p style={{ marginLeft: '25px'}}>(b) Credit or amount stated on the Voucher is insufficient for the Order, Buyer can make up the difference with one of our accepted payment methods above;</p>
                <p style={{ marginLeft: '25px'}}>(c) Buyer returns a Product with a cash voucher for a specific amount, Buyer will not be refunded the cash voucher value. However, Buyer will be reimbursed for the portion paid with Buyer’s money.</p>
                <p>29. XOX will not be liable to Buyer for any loss and/or damages of any kind incurred as a result of:</p>
                <p style={{ marginLeft: '25px'}}>(a) the purchase, redemption, and/or use of any Voucher;</p>
                <p style={{ marginLeft: '25px'}}>(b) the inability to use any Voucher due to lost, forgotten, or void or as a result of Buyer providing incorrect information; or</p>
                <p style={{ marginLeft: '25px'}}>(c) the use of any Voucher by any person(s) not authorised or approved by Buyer.</p>
                <p>30. XOX may at its sole discretion discontinue or cancel any Voucher previously issued.  In such situation, XOX will not be responsible for any losses arising therefrom.</p>

                <p>Intellectual Property Rights of Site Contents</p>
                <p>31. XOX owns or has a lawful license to all rights and interests in the Platform and its contents ("Site Contents"). All title, ownership, and Intellectual Property Rights in the Platform and Site Contents shall remain with XOX, our affiliates, or the Site Contents' licensors, as applicable. All rights not expressly granted in these Terms and Conditions or by XOX are expressly reserved.</p>
                <p>32. Buyer have no express right to the use of such Intellectual Property Rights, unless with consent.</p>

                <h4>Cancellations, Refunds And Returns Policy</h4>

                <h4>(A)	Cancellation</h4>
                <p>1. Subject to the terms of this Cancellations, Refunds and Returns Policy (“this Policy”), Cancellation is possible anytime before Seller arranges shipment of the Product.</p>
                <p>2. Once Order has been shipped, Buyer is unable to cancel Order.</p>


                <h4>(B)	Returns and Refunds</h4>
                <p>3.  Subject to the terms this Policy, you are entitled to return the Product (“Returned Product”) for a refund (“Refund") within 7 (Seven) Business Days from the date Product was delivered.</p>
                <p>4.  The Returned Product shall be un-used, in its original condition (tag intact, unworn, unwashed, and unaltered) as received by Buyer upon purchase and not damaged (“Conditions for Return”). All returns which do not meet the above-mentioned criteria will be strictly declined and forfeited.</p>
                <p>5.  In the event that a Returned Product does not meet the Conditions for Return, Seller reserve the right decline returns and send the Product back to you. For the avoidance of doubt, any reference to “Seller” includes the Seller and/or XOX, wherever applicable.</p>
                <p>6.  Your entitlement for a return for a refund of the Returned Product is only limited to the fact that Product:</p>
                <p style={{ marginLeft: '25px'}}>(a) Has not been received;</p>
                <p style={{ marginLeft: '25px'}}>(b) Received is different or incorrect;</p>
                <p style={{ marginLeft: '25px'}}>(c) Does not meet the agreed specification;</p>
                <p style={{ marginLeft: '25px'}}>(d) Delivered is materially different from Seller’s description;</p>
                <p style={{ marginLeft: '25px'}}>(e) Has missing parts; or</p>
                <p style={{ marginLeft: '25px'}}>(f) By way or private agreement with Seller.</p>
                <p>7.  In the event exchange of Returned Product is made available, your are only entitled to an exchange, if the returned Product was defective. A Product is defect if it is (i) unfit for its intended use; (ii) unfit to be consumed; (iii) clear defective design; (iv) improperly assembled; and/or (v) extremely hazardous for normal use.</p>
                <p>8.  Buyer’s request for return of a Product ("Request") must be submitted via the Platform or to enq_blackmarket@xox.com.my.</p>
                <p>9.  Risk of damage to or loss of the Returned Product shall pass to the Buyer at the time of delivery or if the Buyer wrongfully fails to take delivery of the Product, the time when Seller has tendered delivery of the Returned Product.</p>
                <p>10. If the Seller rejects the Request, the Seller must provide XOX with all necessary documents or proof, whenever requested.</p>
                <p>11. Upon receipt of the Returned Product, Seller shall review and check the Returned Product to determine whether it meets the Conditions for Return. In the event that Returned Product does not meet Conditions for Return, Seller shall notify Buyer and arrange the item to be returned to the Buyer. Shipping cost shall be borne by Buyer. Any appeal should be made to enq_blackmarket@xox.com.my, whose decision shall be final.</p>
                <p>12. Buyer acknowledges and agrees that in any event XOX is involved in any dispute, XOX’s decision shall be final, conclusive and binding, and covenants and agrees that it will not bring suit or otherwise assert any claim against XOX or its affiliates in relation to such decision.</p>
                <p>13. Once the Product has been assessed and reviewed in accordance with this Policy, the Seller shall provide a final decision to either approve or reject the requested return or refund of the Product no later than 3 (Three) Business Days from the receipt of the returned Product from the Buyer, or by any revised timeline in this Policy.</p>
                <p>14. If refund if approved in accordance with paragraph 9 above, Seller shall refund the Product’s purchase price to Buyer.</p>
                <p>15. For more details, please refer to Process for Cancellations, Refunds and Returns stated in this Policy for application.</p>
                <p>16. Buyer shall indemnify XOX against all loss damages costs expenses and legal fees incurred by the Buyer in connection with the assertion and enforcement of XOX's rights under this condition.</p>
                <p>17. Refund or Return requests for Returned Product(s) in the following categories will NOT be allowed:</p>
                <p style={{ marginLeft: '25px'}}>(a) Consumer electronics;</p>
                <p style={{ marginLeft: '25px'}}>(b) Bathing suits;</p>
                <p style={{ marginLeft: '25px'}}>(c) Cosmetics and beauty products;</p>
                <p style={{ marginLeft: '25px'}}>(d) Fragrances</p>
                <p style={{ marginLeft: '25px'}}>(e) Personalized products;</p>
                <p style={{ marginLeft: '25px'}}>(f) Electronic vouchers for goods and services (vouchers that can be downloaded immediately after payment of the purchase price);</p>
                <p style={{ marginLeft: '25px'}}>(g) Compact discs (CD) and digital video discs (DVD);</p>
                <p style={{ marginLeft: '25px'}}>(h) Pharmaceuticals; and</p>
                <p style={{ marginLeft: '25px'}}>(i) Perishable items.</p>
                <p>18. Refund amount will be credited to the bank account provided in the Platform. Bank account holder’s name must be the same with Buyer’s name.</p>
                <p>19. The refund procedure specified in this Policy are subject to any additional rules and processes imposed by XOX from time to time. Buyer will be refunded through a Bank account submitted to seller/XOX.</p>
                <p>20. Refund amount will be credited to Buyer’s bank account within 14 days after refund request is approved.</p>
                <p>21. The Refund procedure specified in this Policy are subject to any additional rules and processes imposed by XOX from time to time</p>

              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}
export default RefundPolicy
