import React from 'react';
import NotificationList from '../Notifications/NotificationList';
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';


export default ({ items, isOpen, isFetching, toggleHandler, onChange, data, ...props }) => {
  return <div onClick={(e) => { toggleHandler() }} >
    <div style={{ position: 'absolute', bottom: 80, left: 42 }}>
      <NotificationBadge count={data} effect={Effect.SCALE}
      />
    </div>
    
      <FontAwesomeIcon icon={faBell} color='grey' style={{ fontSize: '25px' }} />
    

    <div className='col-xl-12 col-lg-8 col-md-4' style={{ position: 'absolute', zIndex: 1000 }}>
      <NotificationList
        {...props}
        onChange={onChange}
        notifications={items}
        isOpen={isOpen}
        isFetching={isFetching} />
    </div>
  </div>
}


