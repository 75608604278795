import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import Breadcrumb from '../common/breadcrumb'
import ProductListing from "../collection/common/product-listing";
import StickyBox from "react-sticky-box";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SlideToggle } from 'react-slide-toggle';
import LoadingLoader from './../Loader/Loader'

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityId: [],
      productData: [],
      serviceData: [],
      cities: [],
      end: false,
      endS: false,
      loading: true,
      checkedCities: [],
      minPrice: '',
      maxPrice: '',
      isNegotiable: '',
      viewLess: true,
      page: '',
      pageS: '',
      total: '',
      hideLoading : false,
    }
    this.sortByPriceAsc = this.sortByPriceAsc.bind(this);
    this.sortByPriceDesc = this.sortByPriceDesc.bind(this);
    this.sortByCreatedAt = this.sortByCreatedAt.bind(this);
    this.sortByAlphaAsc = this.sortByAlphaAsc.bind(this);
    this.sortByAlphaDesc = this.sortByAlphaDesc.bind(this);
  }

  sortByPriceAsc() {
    this.setState(productData => {
      this.state.productData.sort((a, b) => (a.price[0].figure - b.price[0].figure))
      this.state.serviceData.sort((a, b) => (a.price[0].figure - b.price[0].figure))
    }, () => {
      this.forceUpdate()
    }, () => {
      this.sortingRerendering()
    });
  }

  sortByPriceDesc() {
    this.setState(prevState => {
      this.state.productData.sort((a, b) => (b.price[0].figure - a.price[0].figure))
      this.state.serviceData.sort((a, b) => (b.price[0].figure - a.price[0].figure))
    }, () => {
      this.forceUpdate()
    }, () => this.sortingRerendering());
  }

  sortByCreatedAt() {
    this.setState(prevState => {
      this.state.productData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      this.state.serviceData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }, () => {
      this.forceUpdate()
    }, () => this.sortingRerendering());
  }

  sortByAlphaAsc() {
    this.setState(prevState => {
      this.state.productData.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
      })

      this.state.serviceData.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
      })
    }, () => {
      this.forceUpdate()
    }, () => this.sortingRerendering());
  }
  sortByAlphaDesc() {
    this.setState(prevState => {
      this.state.productData.sort(function (a, b) {
        if (a.name > b.name) {
          return -1
        }
      })
      this.state.serviceData.sort(function (a, b) {
        if (a.name > b.name) {
          return -1
        }
      })
    }, () => {
      this.forceUpdate()
    }, () => this.sortingRerendering());
  }


  sortingRerendering = () => {
    return (
      <ProductListing
        colSize={3}
        product={this.state.productData}
        loadMore={() => this.getTrendingProducts(this.state.page + 1)}
        end={this.state.end}
        {...this.props}
      />
    )
  }

  handleChange = (e) => {
    if (e === 'HighToLow') {
      this.sortByPriceDesc()
    }
    else if (e === 'LowToHigh') {
      this.sortByPriceAsc()
    }
    else if (e === 'AscOrder') {
      this.sortByAlphaAsc()
    }
    else if (e === 'DescOrder') {
      this.sortByAlphaDesc()
    }
    else if (e === 'Newest') {
      this.sortByCreatedAt()
    }
  }

  listLayout() {
    document.querySelector(".collection-grid-view").style = "opacity:0";
    document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-lg-12');
    });
    setTimeout(function () {
      document.querySelector(".product-wrapper-grid").style = "opacity: 1";
    }, 500);
  }

  gridLayout() {
    document.querySelector(".collection-grid-view").style = "opacity:1";
    document.querySelector(".product-wrapper-grid").classList.remove("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-lg-3');
    });
  }

  LayoutView = (colSize) => {
    if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
      var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
      [].forEach.call(elems, function (el) {
        el.className = '';
        el.classList.add('col-lg-' + colSize);
      });
    }
    this.props.onLayoutViewClicked(colSize);
  }

  componentDidMount = () => {
    this.getTrendingProducts();
    // this.getAllCities();
    // this.getTrendingServices();
  }

  onApiResponse = (res) => {
  }
  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  }

  getTrendingProducts = (pageNum = 1) => {
    let body = {
      page: pageNum,
      searchPhrase: this.props.match.params.item,
      cityId: this.state.cityId,
      minPrice: this.state.minPrice,
      maxPrice: this.state.maxPrice,
      negotiable: this.state.isNegotiable
    }
    this.setState({loading: true})
    this.props.apiManager.makeCall("search_products", body, res => {
      this.setState({loading: false, hideLoading: true})
      if (res.result) {
        let { page, pages, docs } = res.result;
        if (page <= pages) {
          this.setState({
            productData: this.state.productData.concat(docs),
            page: page,
            pages: pages,
            loading: false,
            total: res.result.total
          }, () => (page === pages) ? this.setState({ loadMore: true, page: page }) : null);
        } else {
          this.setState({
            loading: false
          });
        }
      }
      else {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }

  getTrendingServices = (pageNum = 1) => {
    this.setState({
      serviceData: []
    })
    let body = {
      page: pageNum,
      searchPhrase: this.props.match.params.item,
      cityId: this.state.cityId,
      minPrice: this.state.minPrice,
      maxPrice: this.state.maxPrice,
      negotiable: this.state.isNegotiable
    }
    this.props.apiManager.makeCall("search_services", body, res => {
      if (res.result) {
        let { page, pages, docs } = res.result;
        if (page <= pages) {
          this.setState({
            serviceData: this.state.serviceData.concat(docs),
            pageS: page,
            pagesS: pages,
            loading: false
          }, () => (page === pages) ? this.setState({ endS: true }) : null);
        } else {
          this.setState({
            endS: true,
            loading: false
          });
        }
      }
      else if (res.message) {
        this.setState({
          endS: true,
          loading: false
        });
      }
    })
  }

  // getAllCities = (pageNum = 1) => {
  //   this.props.apiManager.makeCall("get_all_cities", {}, res => {
  //     console.warn('abababba', res.cities)
  //     if (res) {
  //       this.setState({
  //         cities: res.cities,
  //         checkedCities: this.state.cities.cityId,
  //         loading: false
  //       });
  //     } else {
  //       this.setState({
  //         end: true,
  //         loading: false
  //       });
  //     }
  //   })
  // }

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.location.reload();
    }
  }

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  }

  clickCityHandle(cityName) {
    var checkBox = document.getElementById(cityName);
    if (checkBox.checked === true) {
      var newStateArray = this.state.cityId.slice();
      newStateArray.push(cityName);
      this.setState({ cityId: newStateArray }, () => this.handleFilter());
    }
    else {
      var result = arrayRemove(this.state.cityId, cityName);
      this.setState({ cityId: result }, () => this.handleFilter());
    }

    function arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele !== value;
      });
    }
  }
  handleFilter = () => {
    this.setState({ productData: [], serviceData: [] }, () => {
      this.getTrendingProducts()
      // this.getTrendingServices()
    })
  }

  clickNegotiableHandle() {
    var checkBox = document.getElementById("Negotiable");
    if (checkBox.checked === true) {
      this.setState({ isNegotiable: true }, () => this.handleFilter());
    }
    else {
      this.setState({ isNegotiable: false }, () => this.handleFilter());
    }
  }

  onMinPriceChange = (event) => {
    this.setState({
      minPrice: event.target.value,
    })
    if (event.key === 'Enter') {
    }
  }
  onMaxPriceChange = (event) => {
    this.setState({ maxPrice: event.target.value })
  }
  render() {
    if (this.state.loading && !this.state.hideLoading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', backgroundColor: 'white' }}>
      <LoadingLoader visible={this.state.loading} />
    </div>
    return (
      <div>
        <Breadcrumb title={'Search'} />
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-sm-3 collection-filter">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <div>
                      <div className="collection-filter-block">
                        {/*brand filter start*/}
                        <div className="collection-mobile-back">
                          <span className="filter-back" onClick={(e) => this.closeFilter(e)} >
                            <i className="fa fa-angle-left" aria-hidden="true">
                            </i> back
                           </span>
                        </div>
                        {/* <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <h3 className="collapse-block-title" onClick={onToggle}>Cities</h3>
                              <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                {this.state.cities.map((brand, index) => {
                                  return (
                                    this.state.viewLess ?
                                      index < 5 ?
                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderWidth: 3, borderColor: 'black' }}>
                                          <input type="checkbox"
                                            value={brand.cityName} onClick={() => this.clickCityHandle(brand.cityId)} defaultChecked={false}
                                            id={brand.cityId} />
                                          <label style={{ paddingLeft: 10, paddingTop: 5 }}>
                                            {brand.cityName.toUpperCase()}</label>
                                        </div>
                                        : ''
                                      :
                                      <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderWidth: 3, borderColor: 'black' }}>
                                        <input type="checkbox"
                                          value={brand.cityName} onClick={() => this.clickCityHandle(brand.cityId)} defaultChecked={false}
                                          id={brand.cityId} />
                                        <label style={{ paddingLeft: 10, paddingTop: 5 }}>
                                          {brand.cityName.toUpperCase()}</label>
                                      </div>)
                                })
                                }
                              </div>
                              <h6 style={{ color: 'rgb(224, 106, 66)', cursor: 'pointer' }} onClick={() => this.setState({ viewLess: !this.state.viewLess })}>{this.state.viewLess ? 'View More' : 'View Less'}</h6>
                            </div>
                          )}
                        </SlideToggle> */}
                        {/* <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                              <h3 className="collapse-block-title" onClick={onToggle}>Negotiable</h3>
                              <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderWidth: 3, borderColor: 'black' }}>
                                  <input type="checkbox"
                                    value={'Negotiable'} onClick={() => this.clickNegotiableHandle()} defaultChecked={false}
                                    id={"Negotiable"} />
                                  <label style={{ paddingLeft: 10, paddingTop: 5 }}>
                                    {"Negotiable"}</label>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle> */}
                        <SlideToggle>
                          {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block open">
                              <h3 className="collapse-block-title" onClick={onToggle}>price</h3>
                              <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>
                                <div className="collection-brand-filter">
                                  <div className="collection-filter-checkbox">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%" }}>
                                      <input
                                        size='10'
                                        placeholder=' Min Price'
                                        value={this.state.minPrice}
                                        type="number"
                                        onChange={this.onMinPriceChange}
                                        style={{ width: 100 }}
                                      />
                                      <input
                                        size='10'
                                        placeholder=' Max Price'
                                        value={this.state.maxPrice}
                                        type="number"
                                        onChange={this.onMaxPriceChange}
                                        style={{ width: 100 }}
                                      />
                                    </div>
                                    <div className="col-12 m-auto w-100 mt-2">
                                      <button className="btn btn-primary btn-sm btn-block mt-2" onClick={() => this.handleFilter()}>
                                        Filter
                                      </button>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </SlideToggle>
                      </div>
                    </div>
                  </StickyBox>
                  {/*side-bar banner end here*/}
                </div>
                <div className="collection-content col">
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="filter-main-btn">
                                      <span onClick={this.openFilter}
                                        className="filter-btn btn btn-theme"><i
                                          className="fa fa-filter"
                                          aria-hidden="true"></i> Filter</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="product-filter-content">
                                      <div className="search-count col-12" style={{ textAlign: 'center', borderBottom: '1px solid #dddddd', borderLeft: '1px solid #dddddd' }}>
                                        <h5>{this.state.total} items found for "{this.props.match.params.item.substring(0, 40)}" </h5>
                                      </div>
                                      {/* <div className="collection-view col-2" style={{ borderLeft: '1px solid #dddddd' }}>
                                        <ul>
                                          <li><i
                                            className="fa fa-th grid-layout-view" onClick={this.gridLayout}></i>
                                          </li>
                                          <li><i
                                            className="fa fa-list-ul list-layout-view" onClick={this.listLayout}></i>
                                          </li>
                                        </ul> 
                                      </div> */}
                                      <div className="product-page-filter col-12" style={{ textAlign: 'center' }}>
                                        <select onChange={(e) => this.handleChange(e.target.value)} style={{ borderLeft: 'none' }}>
                                          <option value="">Sorting items</option>
                                          <option value="HighToLow" onClick={this.sortByPriceDesc}>Price: High to Low</option>
                                          <option value="LowToHigh" onClick={this.sortByPriceAsc}>Price: Low to High</option>
                                          <option value="Newest" onClick={this.sortByCreatedAt}>Newest Items</option>
                                          <option value="AscOrder" onClick={this.sortByAlphaAsc}>Sort By Name: A To Z</option>
                                          <option value="DescOrder" onClick={this.sortByAlphaDesc}>Sort By Name: Z To A</option>
                                        </select>
                                      </div>
                                      {/* <div className='col-2' style={{ borderRight: '1px solid #dddddd' }}>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {/*Products Listing Component*/}
                <Tabs className="theme-tab" style={{ marginTop: 15 }}>
                    <TabList className="tab-title" >
                      <Tab style={{ paddingRight: 50, alignSelf: 'center' }}>Products</Tab>
                      {/* <Tab>Services</Tab> */}
                    </TabList>
                    <div className="tab-content-cls">
                      <TabPanel className="tab-content">
                        <div className="row product-tab">
                          {this.sortingRerendering()}
                        </div>
                      </TabPanel>
                      {/* <TabPanel className="tab-content">
                        <div className="row product-tab">
                          <ProductListing
                            colSize={8}
                            product={this.state.serviceData}
                            loadMore={() => this.getTrendingServices(this.state.pageS + 1)}
                            end={this.state.endS}
                            {...this.props}
                          />
                        </div>
                      </TabPanel> */}
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
export default Search;