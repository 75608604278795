import React from 'react'
import Loader from 'react-loader-spinner'

export default (props) => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Loader
        type="TailSpin"
        color={'red'}
        height={40}
        width={40}
        visible={props.visible}
      />
    </div>
  )
}
