import React, { Component } from 'react'
import RightSideBar from '../components/blogs/right-sidebar'
import { SuperHOC } from "../HOC";



class ShoppingCart extends Component {


  render() {
    return (
      <div style={{ minHeight: '100vh' }}>
        <RightSideBar
          {...this.props}
        />
      </div>
    )
  }
}

export default SuperHOC(ShoppingCart)