import React, { Component } from 'react'
import MerchantItems from "./MerchantItems";
import LoadingLoader from "../Loader/Loader";
export default class MerchantInfiniteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      end: false,
      loading: true
    }
  }

  componentDidMount = () => {
    this.getAllMerchants();
  }


  getAllMerchants = (pageNum = 1) => {
    let body = {
      merchant: this.props.merchant,
      page: pageNum,
      numRecords: this.props.numOfRecords,
      brandName: this.props.brandName,
      category: this.props.category,
      searchPhrase: this.props.searchPhrase
    };

    this.props.apiManager.makeCall('get_merchant_list', body, res => {
      if (res.result) {
        let { page, pages, docs } = res.result;
        if (page <= pages) {
          this.setState({
            data: this.state.data.concat(docs),
            page,
            pages,
            loading: false
          }, () => (page === pages) ? this.setState({ end: true }) : null);
        } else {
          this.setState({
            end: true,
            loading: false
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }




  render() {
    if (this.state.loading) return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '60vh', }}>
      <LoadingLoader visibel={this.state.loading} />
    </div>
    if (this.state.data.length === 0) return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '60vh', }}>
      <h3>No merchant found</h3>
    </div>
    return (
      <MerchantItems
        product={this.state.data}
        loadMore={() => this.getAllMerchants(this.state.page + 1)}
        {...this.props}
        end={this.state.end}
      />
    )
  }
}
