
import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import NavBar2 from "./common/navbar2";
import SideBar from "./common/sidebar";
import CartContainer2 from "./../../../containers/CartContainer2";
import { withRouter } from 'react-router-dom'
import TopBarWhite from "./common/topbar-white";
import LogoImage from "./common/logo";
import Notification from '../../Notifications/Notification'
import SuperHoc from '../../../HOC/SuperHOC'
import { FiSearch } from "react-icons/fi";
import { LuUser2 } from "react-icons/lu";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { IoIosArrowDown } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";


class HeaderNew extends Component {

  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      isLoading: false,
      count: '',
      search: false,
      searchPhrase: '',
      searchClicked: false,
      searchedData: [],
      menu: [],
      showCategory: false,
      showSearch: false
    }
  }
  /*=====================
       Pre loader
       ==========================*/
  componentDidMount() {
    this.initializeMenu();
    this.formVisible()
  }

  initializeMenu = () => {
    this.props.apiManager.makeCall('get_all_categories', {}, res => {
      if (res.message) {
        this.setState({ menu: res.categories })
      }
    })
  }

  handleMegaSubmenu = (category) => {
    this.setState({ showCategory: false, showSearch: false });

    let t = 'get_latest_products/' + category;
    let tp = window.btoa(t)
    let decodedPath = window.btoa('get_latest_products')
    let path = {
      pathname: `/product-listing/${'3' + tp + '?path=' + decodedPath + '?key=' + category}`,
      state: {
        apiName: 'get_latest_products',
        title: 'Products',
        category: category,
      }
    }

    if (this.props.location.pathname.split('/')[1] === 'product-listing') {
      this.props.history.replace(path);
      window.location.reload();
    } else
      this.props.history.push(path);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // document.getElementById('footer').classList.add('yaar')
    // console.log('hello y', number)

    // if (number >= 30) {
    //   if (window.innerWidth < 576) {
    //     document.getElementById("sticky").classList.remove('fixed');
    //   } else {
    //     document.getElementById("sticky").classList.add('fixed');
    //   }
    // }
    // else {
    //   document.getElementById("sticky").classList.remove('fixed');
    // }
  }

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang))
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add('open-side')
    }
  }
  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false })
    })
  };

  formVisible = () => {
    if (window.innerWidth > 576) {
      return true
      //document.getElementById('formVisible').style.display = "block";
    }
    else {
      return false
      // document.getElementById('formVisible').style.display = "none";
    }
  }




  renderNotification = () => {
    return (
      <div >
        <Notification
          {...this.props}
        />
      </div>
    )
  }

  onTextChange = (event) => {
    if (event.target.value === '%') {
    }
    else {
      if (event.target.value ? event.target.value.includes('%') : '') {
      }
      else
        this.setState({ searchPhrase: event.target.value })
      if (event.target.value.length >= 1) {
        let body = {
          searchPhrase: event.target.value
        };
        this.props.apiManager.makeCall('search_intellisense', body, res => {
          if (res.message === '1175') {//1175: successfully get searched data 
            this.setState({ searchedData: res.result.docs });
            if (res.result.docs.length === 0) {
              this.setState({ searchedData: ["noResultFound"] });
            }
          } else if (res.message === '1173') {//1173: no search phrase given
            this.setState({ searchedData: [] });
          }
        });
      } else {
        this.setState({ searchedData: [] });
      }
    }
  }

  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };

  renderSearchItem = () => {
    return (
      this.state.searchedData.map((item, index) => {
        var receivedArticleName
        if (item && item.searchString ? item.searchString.includes('%') : '') {
          let name = item.searchString.split('%')
          receivedArticleName = name[0]
        }
        else receivedArticleName = item.searchString
        if (item === 'noResultFound') {
          return (
            <p key={index} style={{ padding: 20, fontSize: 13, margin: 20, textAlign: 'center', color: 'black' }}>
              {"No Result Found"}</p>
          )
        }
        return (
          <Link to={`/search/${receivedArticleName}`} onClick={() => { this.closePanel() }}
          >
            <div key={index} style={{ padding: 0, margin: 0, alignItems: 'center' }} onClick={this.closeSearch}>
              <p style={{ paddingLeft: 10, fontSize: 13 }} onClick={() => this.setState({ searchPhrase: '' })}>
                {item.searchString}</p>
            </div>
          </Link>
        )
      }
      ));
  }

  closePanel = () => {
    this.setState({ showCategory: false, showSearch: false });
  }

  renderForm = () => {
    var receivedArticleName
    if (this.state.searchPhrase ? this.state.searchPhrase.includes('%') : '') receivedArticleName = encodeURIComponent(this.state.searchPhrase)
    else receivedArticleName = this.state.searchPhrase
    return (
      <div style={{ marginTop: '10px', marginBottom: '20px' }}>
        <form className="form-header">
          <div className="input-group">
            <input type="text" className="form-control"
              aria-label="Amount (to the nearest dollar)"
              placeholder="Search Black Market"
              width='100%'
              value={this.state.searchPhrase}
              onChange={this.onTextChange}
            />
            {/* {this.state.searchPhrase.length > 1 ? */}
            {this.state.searchPhrase.length > 0 ?
              <Link to={`${process.env.PUBLIC_URL}/search/${receivedArticleName}`} onClick={() => { this.closePanel() }}>
                <div className="input-group-append" onClick={() => this.setState({ searchPhrase: '' })}>
                  <button className="btn btn-solid"><i
                    className="fa fa-search"></i>Search
                  </button>
                </div>
              </Link>
              :
              <div className="input-group-append">
                <button className="btn btn-solid"><i
                  className="fa fa-search"></i>Search
                </button>
              </div>
            }
          </div>
        </form>
        <div style={{ position: 'absolute', marginTop: 10, zIndex: 1, backgroundColor: 'white', display: "flex", width: "80%", flexDirection: "column", alignItems: "flex-start", borderRadius: 5 }} >
          {this.state.searchPhrase ? this.renderSearchItem() : null}
        </div>
      </div>
    )
  }

  renderAnnouncementBar = () => {
    const settings = {
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      swipe: false

    };
    let items = [
      "0% Easy Payment Plan.",
      "Enjoy Cashback and Rewards When You Pay Bills.",
      "Amazing Discounts on Great Products."
    ];
    //if (this.state.bannersLoaded) return <LoadingLoader visible={this.state.bannersLoaded} />
    return (
      <Slider {...settings} className="slide-1 home-slider hideArrow">
        {
          items.map((item, index) => {
            return (
              <div className='smallText' style={{ backgroundColor: 'black', width: '100%', minHeight: '1px', padding: '10px 0', color: 'white', textAlign: 'center' }}>{item}</div>
            )
          })}
      </Slider>
    )
  }

  render() {
    const { screenWidth } = this.state;
    var receivedArticleName
    if (this.state.searchPhrase ? this.state.searchPhrase.includes('%') : '') receivedArticleName = encodeURIComponent(this.state.searchPhrase)
    else receivedArticleName = this.state.searchPhrase
    return (
      <header id="sticky" className="sticky" style={{ position: 'fixed', backgroundColor: 'white', width: '100%', zIndex: '10000' }}>
        <div style={{ backgroundColor: 'black' }}>{this.renderAnnouncementBar()}</div>
        <div className='tab-bg' style={{ backgroundColor: 'white', padding: '15px 0' }}>
          <div className="row_newlandingpage" style={{ padding: '0 5%', justifyContent: 'space-between', flexDirection: 'row' }}>
            {screenWidth > 768 ?
              <div className='row_newlandingpage' style={{ width: '30%', gap: '10%', alignItems: 'center' }}>
                <a id="newArrivalAnchor"
                  onMouseOver={() => {
                    document.getElementById("newArrivalAnchor").classList.add("underlineAnchor");
                  }}
                  onMouseOut={() => {
                    document.getElementById("newArrivalAnchor").classList.remove("underlineAnchor");
                  }}
                  href={`${process.env.PUBLIC_URL}/latest-product-listing`}
                  className='mediumText semiBoldText noTransition'
                  style={{ padding: '10px 0', color: 'black', textDecoration: 'none' }}>
                  New Arrivals</a>
                <SideBar {...this.props} />
                <div onMouseOver={() => { this.setState({ showCategory: true, showSearch: false }) }}
                  onClick={screenWidth > 768 ? () => { this.setState({ showCategory: !this.state.showCategory, showSearch: false }) } : this.openNav}
                  className='clickable row_newlandingpage mediumText semiBoldText'
                  style={{ alignItems: 'center', position: 'relative' }}>

                  <div style={{ padding: '10px 0', border: '0px solid #7D37E6', borderBottomWidth: this.state.showCategory ? '2px' : '0', borderTop: this.state.showCategory ? '2px solid transparent' : '0' }}>Shop</div>
                  <IoIosArrowDown style={{ marginLeft: '3px' }} size={10} />

                </div>
              </div>
              :
              null
            }

            {/* header, show Logo, if mobile then adjust margin and width*/}
            <LogoImage logo={`${process.env.PUBLIC_URL}/assets/Black_Market_Logo_Header_Black.png`} style={screenWidth > 768 ? { width: '120px', height: 'auto', marginLeft: '50px' } : { width: '100px', height: 'auto' }} />

            <div className='row_newlandingpage' style={screenWidth > 768 ? { width: '30%', gap: '10%', justifyContent: 'right', alignItems: 'center' } : { flex: 1, gap: '5%', justifyContent: 'right', alignItems: 'center' }}>

              {/* search */}
              <div className='clickable blackText' onClick={screenWidth > 768 ? () => { this.setState({ showSearch: !this.state.showSearch, showCategory: false }) } : this.openSearch}>
                <img src={`${process.env.PUBLIC_URL}/assets/Icons/Top navigation bar/icon_search.svg`} />
              </div>
              {/* chat */}
              {screenWidth > 400 ?
                <Link onClick={() => { this.closePanel() }} className='noHoverBlackText blackText' to={`${process.env.PUBLIC_URL}/chatScreen`}>
                  <img src={`${process.env.PUBLIC_URL}/assets/Icons/Top navigation bar/icon_chat.svg`} />
                </Link>
                :
                null
              }
              {/* account */}
              {this.props.apiManager.token ?
                <Link onClick={() => { this.closePanel() }} className='noHoverBlackText blackText' to={`${process.env.PUBLIC_URL}/account`}>
                  <img src={`${process.env.PUBLIC_URL}/assets/Icons/Top navigation bar/icon_profile.svg`} />
                </Link>
                :
                <Link onClick={() => { this.closePanel() }} className='noHoverBlackText blackText' to={`${process.env.PUBLIC_URL}/login`} data-lng="en">
                  <img src={`${process.env.PUBLIC_URL}/assets/Icons/Top navigation bar/icon_profile.svg`} />
                </Link>
              }
              {/* cart */}
              <CartContainer2 />
              {/* hamburger */}
              {screenWidth > 768 ?
                null
                :
                <NavBar2 {...this.props} />
              }
            </div>
          </div>
          {/*display if clicked search button  */}
          <div className={this.state.showSearch && screenWidth > 768 ? 'hideForTransition showForTransition' : 'hideForTransition'} style={{ padding: '0 5%', position: 'fixed', backgroundColor: 'white', zIndex: '100', width: '100%' }}>
            {this.renderForm()}
          </div>

          {/* display if clicked Shop */}
          <div className={this.state.showCategory && screenWidth > 768 ? 'hideForTransition showForTransition' : 'hideForTransition'} style={{ padding: '0 5%', position: 'fixed', backgroundColor: 'white', paddingBottom: '20px', zIndex: '100', width: '100%' }}>
            <div className='largeText boldText' style={{ marginBottom: '25px', marginTop: '25px' }}>By Categories</div>
            <div className=''>
              <ul className='activeList'>
                {this.state.menu.map((item, index) => {
                  return (
                    <li className='largeText clickable noHoverBlackText blackText' style={{ marginBottom: '15px' }} onClick={() => this.handleMegaSubmenu(item.slug)}>{item.name[0].value}</li>
                  );
                })
                }
              </ul>
            </div>
          </div>
          {/* to block the rest of the page */}
          <div onClick={() => { this.closePanel() }}
            onMouseOver={() => { this.setState({ showCategory: false }) }}
            className='clickable' style={{ width: '100%', backgroundColor: 'black', position: 'fixed', height: '100vh', opacity: '0.2', zIndex: '99', display: (this.state.showCategory || this.state.showSearch) && screenWidth > 768 ? 'block' : 'none' }}>

          </div>
        </div>
        {/* mobile search page */}
        <div id="search-overlay" className="search-overlay">
          <div>
            <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">×</span>
            <div className="overlay-content" style={{ position: 'absolute', top: '10%' }}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Search Black Market" value={this.state.searchPhrase} onChange={this.onTextChange} />
                      </div>
                      <Link to={`${process.env.PUBLIC_URL}/search/${receivedArticleName}`} onClick={() => { this.closePanel() }}>
                        <button type="submit" className="btn btn-primary" onClick={this.closeSearch}><i className="fa fa-search"></i></button>
                      </Link>
                    </form>
                    <div style={{ position: 'absolute', marginTop: 10, zIndex: 1, backgroundColor: 'white', display: "flex", width: "80%", flexDirection: "column", alignItems: "flex-start", borderRadius: 5 }} >
                      {this.state.searchPhrase ? this.renderSearchItem() : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default withRouter(SuperHoc(HeaderNew))
