import React, { Component } from 'react';
import ChatMobileSidebar from '../components/Chat/ChatMobileSidebar'
import { SuperHOC } from "../HOC";
class ChatMobileSidebarScreen extends Component {

  render() {
    return (
      <ChatMobileSidebar {...this.props}/>
    );
  }
}

export default SuperHOC(ChatMobileSidebarScreen);