import React, { Component } from 'react'
import { SuperHOC } from "../../../../HOC";
import CountContext from "./CountContext";
class GlobalCount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      data:null,
    }
  }

  componentDidMount = () => {
    this.getCartCount()
  }


  getCartCount = () => {
    if (this.props.apiManager.token !== '') {
      this.props.apiManager.makeCall('get_cart_count', {}, (res) => {
        if (res.message === '1426') {
          this.setState({ count: res.count })
          localStorage.setItem('cartCount', JSON.stringify(res.count))
        }
      })
    }
  }

  setCartCount = count => {
    this.setState({ count: count })
  }

  setData = (data) => {
    this.setState({ data: data })
  }

  render() {
    return (
      <CountContext.Provider
        value={{
          count: this.state.count,
          data:this.state.data,
          setData:this.setData,
          setCartCount: this.setCartCount,
        }}
      >
        {this.props.children}
      </CountContext.Provider>
    )
  }
}

export default SuperHOC(GlobalCount)