import React, { Component } from 'react'
import WalletHistory from '../components/Wallet/WalletHistory'
import {SuperHOC} from '../HOC'


class WalletHistoryScreen extends Component {
   
   
    render(){
        return(
            <div>
                <WalletHistory 
                api='get_wallet_histories'
                {...this.props}
                />
            </div>
        )
    }
}
export default SuperHOC(WalletHistoryScreen)