import React, { Component } from 'react'
import classes from './style.module.css'
import { selectColorIndex } from '../../../actions'
import store from './../../../store';
import Slider from 'react-slick';
import ImageZoom from '../common/product/image-zoom';


const Csettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1
};
export default class ImageContainer extends Component {

  state = {
    selected: null,
    selectedIndex: 0,
    index: ''

  }

  componentDidMount() {
    this.setState({ selected: this.props.colors && this.props.colors[0].icon && this.props.colors[0].icon.assets.src })
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () =>
        this.setState(state => ({ index: this.state.index + 1 })),
      beforeChange: (current, next) => this.setState({ selected: next })
    };
    return (
      <div className={classes.imageContainer}>
        <Slider ref={slider => (this.slider = slider)} {...settings} className="product-right-slick" >

          {this.props.colors && this.props.colors[this.state.selectedIndex].icon.assets.map((image, index) => {
            return (
              <div key={index} style={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
                <ImageZoom image={image.src} className="img-fluid image_zoom_cls-0" />
              </div>
            )
          }
          )}
        </Slider>

        {/* <div className={classes.leftImages}>
          {
            this.props.colors && this.props.colors[this.state.selectedIndex].icon.assets.map((image, index) => {
              // let selected = this.state.selected
              return <div
                key={index}
                className={!this.state.selected ? classes.image : classes.imageNotSelected}
                onClick={() => (this.slider.slickGoTo(index))}>
                <img src={image.src} className={classes.smallImage} alt='nothing' />
              </div>
            }
            )
          }
        </div> */}
         <div className='mt-3'>
        <Slider {...Csettings} className="product-right-slick" >
        {
            this.props.colors && this.props.colors[this.state.selectedIndex].icon.assets.map((image, index) => {
              // let selected = this.state.selected
              return <div
                key={index}
                // className={!this.state.selected ? classes.image : classes.imageNotSelected}
                onClick={() => (this.slider.slickGoTo(index))}>
                <img src={image.src} className={classes.sliderImage} alt='nothing' />
              </div>
            }
            )
          }
        </Slider>
        </div>
        <div>
          <ul className="color-variant">
            {
              this.props.colors ? this.props.colors.map((image, index) => {
                // let selected = this.state.selected
                return <li
                  style={{ backgroundColor: image.code ? image.code : 'grey', border: '1px solid grey' }}
                  key={index}
                  onClick={() => {
                    store.dispatch(selectColorIndex(index));
                    this.setState({ selectedIndex: index, selected: this.props.colors[index].icon.assets[0].src })
                  }}
                >
                </li>
              }
              )
                : null
            }
          </ul>
        </div>

      </div>
    )
  }
}

