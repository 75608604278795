import React, { Component } from 'react'
import MainContainer from '../components/PaymentMethod/MainContainer'
import { SuperHOC } from "../HOC";
class PaymentMehodsScreen extends Component {



  render() {


    return (
      <div>
        <MainContainer
          {...this.props}
        />
      </div>
    )
  }
}

export default SuperHOC(PaymentMehodsScreen)
