import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import InfiniteList from '../components/List/InfiniteList'
import { SuperHOC } from "../HOC";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Breadcrumb from "../components/common/breadcrumb";
class MerchantProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  componentDidMount = () => {
    var a
    var dec
    a = this.props.location.search.split('?id=')
    let b = a[1].split('&')
    dec = window.atob(b[0])
    this.setState({ email: dec })
  }

  render() {
    if (this.state.email !== '') {
      // const { email } = this.props.location.state
      return (
        <div>
          <Helmet>
            <title>XOX Black Market</title>
          </Helmet>
          <Breadcrumb title={'Seller-product'} />
          <div className="container" style={{ paddingTop: '30px' }}>
            <Tabs className="theme-tab">
              <TabList className="tab-title" style={{ borderTop: "0", borderLeft: '0', borderRight: '0' }} >
                <Tab>Products</Tab>
                <Tab>Services</Tab>
              </TabList>
              <div className="tab-content-cls">
                <TabPanel className="tab-content">
                  <div className="container">
                    <div className="top-banner-content small-section">
                      <InfiniteList
                        {...this.props}
                        merchant={this.state.email}
                        api={'get_merchant_products'}
                        // title={listName[2]}
                        colSize={3}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className="tab-content">
                  <div className="container">
                    <div className="top-banner-content small-section">
                      <InfiniteList
                        {...this.props}
                        merchant={this.state.email}
                        api={'get_merchant_services'}
                        // title={listName[2]}
                        colSize={4}
                      />
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}

export default SuperHOC(MerchantProducts)