import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { IntlProvider } from 'react-redux-multilingual';
import { Manager } from './StorageManager/Storage';
import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations';
import { getAllProducts } from './actions';
import GlobalCount from './components/common/headers/common/GlobalCount';
//Screens
import HeaderNew from "./components/common/headers/header-new";
import HeaderFour from './components/common/headers/header-four';
import FooterFour from './components/common/footers/footer-four';
import Home from './screens/Home';
import Search from './screens/SearchScreen';
import Merchant from './screens/MerchantScreen';
import ProductListing from './screens/ProductListing';
import LatestProductListing from "./screens/LatestProductListing";
import Product from './screens/Product';
import WishListScreen from './screens/WishListScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
//import OtpScreen from './screens/OtpScreen';
import OrderDetailScreen from './screens/OrderDetailScreen';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import NegotiationChatScreen from './screens/NegotiationChatScreen';
import NegotiationRequestScreen from './screens/NegotiationRequestScreen';
import ShoppingCart from './screens/ShoppingCart';
import CheckoutScreen from './screens/CheckoutScreen';
import ToPayScreen from './screens/ToPayScreen';
import AccountVerificationScreen from './screens/AccountVerificationScreen';
import AddAddress from './screens/AddAddress';
import Terms from './screens/Terms';
import RefundPolicy from './screens/RefundPolicy';
import WarrantyPolicy from './screens/WarrantyPolicy';
import ShippingPolicy from './screens/ShippingPolicy';
// import FAQs from './screens/FAQs';
import Wallet from './screens/Wallet';
import Refund from './screens/Refund';
import Schedule from './screens/Schedule';
import Checkout from './screens/Checkout';
import Complains from './screens/Complains';
import ChatScreen from './screens/ChatScreen';
import AddComplain from './screens/AddComplain';
import NetworkError from './screens/NetworkError';
import WalletHistory from './screens/WalletHistory';
import BrandProdutcs from './screens/BrandProdutcs';
import AccountScreen from './screens/AccountScreen';
import ComplainSingle from './screens/ComplainSingle';
import MerchantList from './screens/MerchantListScreen';
import CategoryProduct from './screens/CatagoryProucts';
import MerchantProducts from './screens/MerchantProducts';
import ChatMobileScreen from './screens/ChatMobileScreen';
import MobileNotification from './screens/MobileNotification';
import PageNotFoundScreen from './screens/PageNotFoundScreen';
import BookSchedule from './components/Schedule/BookSchedule';
import PaymentMehodsScreen from './screens/PaymentMehodsScreen';
import ChatMobileSidebarScreen from './screens/ChatMobileSidebarScreen';
import PageNotFound from './components/pages/404';
import Register from './screens/RegisterScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import VerifyEmail from './screens/VerifyEmail';
import ResetPassword from './screens/VerifyPassword';
import Contact from './components/pages/contact';
import About from './components/pages/about-us';
import Faq from './components/pages/faq';
import Privacy from './components/pages/Privacy';
import LoginScreen from './screens/LoginScreen';
import VerifyOTP from './screens/VerifyOTP';
import ForgetVerification from './screens/ForgetVerification';
import { toast, ToastContainer } from 'react-toastify';
// import { messaging } from './init-fcm'
// import * as firebase from 'firebase/app';
// import 'firebase/messaging';
import ScrollToTop from './ScrollToTop';
import EditProfile from './screens/EditProfile';
import ChangeEmail from './screens/ChangeEmail';
import SignupMsisdn from './screens/SignupMisidn';
import ChangingEmail from './screens/ChangingEmail';
import changeMSISDN from './screens/ChangeMSISDN';
import CategoryServices from './screens/CategoryServices';
import VerifyChangeEmail from './screens/VerifyChangeEmail';
import VerifySignupMsisdn from './screens/VerifySignupMsisdn';
import VerifyEmailRequest from './screens/VerifyEmailRequest';
import VerifyChangeMsisdn from './screens/VerifyChangeMsisdn';
import VerifyEmailApproved from './screens/VerifyEmailApproved';
import UserDetailsForRegister from './screens/UserDetailsForRegister';
import VerifyEmailThroughOTP from './screens/VerifyEmaiThroughOTP';

import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";
import "@fontsource/source-sans-pro/700.css";
import "@fontsource/source-sans-pro/900.css";

// const initializedFirebaseApp = firebase.initializeApp({
//     // Project Settings => Add Firebase to your web app
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// });
// try {
//     var messaging = initializedFirebaseApp.messaging();
//     messaging.usePublicVapidKey(
//         process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY
//     );

//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//       .register('./firebase-messaging-sw.js')
//       .then(function(registration) {
//         console.info('Registration successful, scope is:', registration.scope);
//       })
//       .catch(function(err) {
//         console.error('Service worker registration failed! Error: ', err);
//       });
//   }

//   messaging
//     .requestPermission()
//     .then(async function() {
//       const token = await messaging.getToken();
//       Manager.setItem('fcmToken', token);
//     })
//     .catch(function(err) {
//       console.error('Unable to get permission to notify. Error: ', err);
//       Manager.setItem('fcmToken', 1);
//     });
// } catch (e) {
//   console.error('Index Error: ', e);
// }

class Root extends React.Component {
  render() {
    // console.log = () => null;
    // console.warn = () => null;
    // console.error = () => null;
    store.dispatch(getAllProducts());
    return (
      <Provider store={store}>
        <GlobalCount>
          <IntlProvider translations={translations} locale="en">
            <BrowserRouter basename={'/'}>
              <LastLocationProvider>
                <div>
                  <ScrollToTop />
                  <ToastContainer />
                  <HeaderNew logoName={'logo/3.png'} />
                  <div style={{paddingTop:'115px'}}></div>
                  <Switch>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      component={Home}
                    />
                    <Route path={'/merchantlist'} component={MerchantList} />
                    <Route
                      path={`/BrandsProducts/:brandName`}
                      component={BrandProdutcs}
                    />
                    <Route
                      path={`/CategoryProduct/:slug`}
                      component={CategoryProduct}
                    />
                    <Route
                      path={`/CategoryService/:slug`}
                      component={CategoryServices}
                    />
                    <Route path={`/product/:product`} component={Product} />
                    <Route path={'/account'} component={AccountScreen} />
                    <Route path={`/chatScreen`} component={ChatScreen} />
                    <Route path={`/toPay`} component={ToPayScreen} />
                    <Route
                      path={`/verification/:user?`}
                      component={AccountVerificationScreen}
                    />
                    <Route path={`/VerifyOTP/`} component={VerifyOTP} />
                    <Route
                      path={`/ForgetVerification/`}
                      component={ForgetVerification}
                    />
                    <Route
                      path={`/UserDetailsForRegister/`}
                      component={UserDetailsForRegister}
                    />
                    <Route
                      path={`/VerifyEmailRequest/`}
                      component={VerifyEmailRequest}
                    />
                    <Route
                      path={`/VerifyEmailApproved/`}
                      component={VerifyEmailApproved}
                    />
                    <Route
                      path={`/VerifyChangeEmail/`}
                      component={VerifyChangeEmail}
                    />
                    <Route
                      path={`/VerifySignupMsisdn/`}
                      component={VerifySignupMsisdn}
                    />
                    <Route path={`/ChangeEmail/`} component={ChangeEmail} />
                    <Route path={`/ChangingEmail/`} component={ChangingEmail} />
                    <Route
                      path={`/VerifyEmailThroughOTP/`}
                      component={VerifyEmailThroughOTP}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/search/:item`}
                      component={Search}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/wishList`}
                      component={WishListScreen}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/order-history`}
                      component={OrderHistoryScreen}
                    />
                    {/* <Route path={`${process.env.PUBLIC_URL}/OtpScreen`} component={OtpScreen} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/order-detail/:item`}
                      component={OrderDetailScreen}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/merchant/:product`}
                      component={Merchant}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/seller-product/:item`}
                      component={MerchantProducts}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/product-listing/:colSize:item:path?:slug?`}
                      component={ProductListing}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/latest-product-listing/:type?`}
                      component={LatestProductListing}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/login`}
                      component={LoginScreen}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/register`}
                      component={Register}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/forgotpassword`}
                      component={ForgotPasswordScreen}
                    />
                    <Route
                      path={`/NegotiationChat/:product`}
                      component={NegotiationChatScreen}
                    />
                    <Route
                      path={`/NegotiationRequest/:product`}
                      component={NegotiationRequestScreen}
                    />
                    <Route path={`/AddAddress`} component={AddAddress} />
                    <Route path={`/Terms`} component={Terms} />
                    <Route path={`/RefundPolicy`} component={RefundPolicy} />
                    <Route
                      path={`/WarrantyPolicy`}
                      component={WarrantyPolicy}
                    />
                    <Route
                      path={`/ShippingPolicy`}
                      component={ShippingPolicy}
                    />
                    {/* <Route path={`/FAQs`} component={FAQs} /> */}
                    <Route path={`/AddComplain/:id`} component={AddComplain} />
                    <Route
                      path={`${process.env.PUBLIC_URL}/complains`}
                      component={Complains}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/VerifyEmail`}
                      component={VerifyEmailRequest}
                    />
                    <Route
                      path={`/ComplainSingle/:id`}
                      component={ComplainSingle}
                    />
                    <Route path={`/Refund`} component={Refund} />
                    <Route
                      path={`${process.env.PUBLIC_URL}/wallet`}
                      component={Wallet}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/walletHistory`}
                      component={WalletHistory}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/schedule`}
                      component={Schedule}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/bookschedule/:item`}
                      component={BookSchedule}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/404`}
                      component={PageNotFound}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contact`}
                      component={Contact}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/about`}
                      component={About}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/faq`}
                      component={Faq}
                    />
                    <Route path={`/Privacy`} component={Privacy} />
                    <Route
                      path={`${process.env.PUBLIC_URL}/network`}
                      component={NetworkError}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/mobileNotification`}
                      component={MobileNotification}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/VerifyEmail/:item`}
                      component={VerifyEmail}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/ResetPassword/:item`}
                      component={ResetPassword}
                    />
                    <Route
                      path={`/OrderHistoryScreen`}
                      component={OrderHistoryScreen}
                    />
                    {/* <Route path={`/OtpScreen`} component={OtpScreen} /> */}
                    <Route
                      path={`/OrderDetailScreen`}
                      component={OrderDetailScreen}
                    />
                    <Route path={`/change-MSISDN`} component={changeMSISDN} />
                    <Route
                      path={`/VerifyChangeMsisdn`}
                      component={VerifyChangeMsisdn}
                    />
                    <Route path={`/SignupMsisdn`} component={SignupMsisdn} />
                    <Route path={`/Edit-Profile`} component={EditProfile} />
                    <Route
                      path={`/change-password`}
                      component={ChangePasswordScreen}
                    />
                    <Route
                      path={`/verifyEmailAddress`}
                      component={VerifyEmail}
                    />
                    <Route
                      path={`/mobileChat/:id`}
                      component={ChatMobileScreen}
                    />
                    <Route
                      path={`/mobileChatSidebar`}
                      component={ChatMobileSidebarScreen}
                    />
                    <Route path={`/*`} exact component={PageNotFoundScreen} />
                  </Switch>
                  <FooterFour />
                </div>
              </LastLocationProvider>
            </BrowserRouter>
          </IntlProvider>
        </GlobalCount>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
