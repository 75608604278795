import React, { Component } from 'react'
import LoadingLoader from "../Loader/Loader";
import { formatDate } from '../../config/HelperFunction'
import ConfirmationAlert from '../CustomAlert/ConfirmationAlert'
import SuccessAlert from '../CustomAlert/CustomAlert'
import ErrorAlert from '../CustomAlert/CustomAlert'

const statuses = {
  0: 'Pending',
  1: 'Cancelled',
  3: 'Completed',
  2: 'Shipped'
}

export default class OrderList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      orders: [],
      page: '',
      pages: '',
      selectedComplain: {},
      showModal: false,
      item: {},
      showSuccess: false,
      showError: false,
      errorMessage: ''
    }
  }

  componentDidMount = () => {
    this.getOrders()
  }

  cancelOrders = () => {
    let body = {
      orderId: this.state.item.details[0]._id,
    }

    this.props.apiManager.makeCall('cancel_order', body, res => {
      if (res.message === 2149) {
        this.setState({ showSuccess: true, errorMessage: res.decodedMessage })
      }
      else {
        this.setState({ showError: true, errorMessage: res.decodedMessage })
      }

      this.setState({ showModal: false })
    })
  }

  getOrders = async () => {
    let body = {
      shippingStatus: this.props.status,
      page: this.state.page
    }

    this.props.apiManager.makeCall(this.props.api, body, res => {
      if (res.result) {
        this.setState({ pages: res.result.pages })
        let { page, pages, docs } = res.result;
        if (true) {
          this.setState({
            orders: this.state.orders.concat(docs),
            page: page + 1,
            // pages,
            loading: false
          }, () => (page === pages) ? this.setState({ end: true }) : null);
        } else {
          this.setState({
            end: true,
            loading: false
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }


  goToDetailScreen = (item) => {
    this.props.history.push(`/OrderDetailScreen/${item._id}`)
  }

  goToAddComplain = (item) => {
    this.props.history.push(`/AddComplain/${item}`)
  }
  addComplainButton(item) {
    return (
      <button className="btn btn-solid" onClick={() => this.goToAddComplain(item._id)}>
        Add Complain
      </button>
    )
  }

  goToRefund = (item) => {
    this.props.history.push(`/Refund/${item}`)
  }

  closeSuccessModal = () => {
    this.setState({ showSuccess: false, showError: false }, () => this.getOrders())
    window.location.reload()
  }

  goToOrderDetail = (item) => {
    let removePerc = item.productName ? item.productName.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    const object = {
      pathname: `order-detail/${convertNameIntoDashes}`,
      state: {
        id: item._id
      }
    }
    this.props.history.push(object)
  }

  completeOrderByUser = (orderId, email) => {
    let body = {
      'orderId': orderId,
      'merchantEmail': email,
    }

    this.setState({ loading: true })
    this.props.apiManager.makeCall('complete_order_by_user', body, res => {
      if (res.message === '2314') {
        this.setState({
          showSuccess: true,
          loading: false,
          errorMessage: res.decodedMessage
        })
      } else {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }

  render() {
    const CMPScreen = {
      pathname: `/ComplainSingle/${this.state.selectedComplain.id}`,
      state: {
        complainId: this.state.selectedComplain.complainSlug,
      }
    }

    if (this.state.loading)
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '70vh' }}>
          <LoadingLoader
            visible={this.state.loading}
          />
        </div>
      )

    return (
      this.state.orders.length !== 0 ?
        <div className="row">
          <div className="col-sm-12 ">
            <table className="table table-responsive wish-table">
              <thead style={{ textAlign: "center" }}>
                <tr className="table-head">
                  <th scope="col">Order Id</th>
                  <th scope="col">Purchased On</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Variant</th>
                  <th scope="col">Color</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Merchant</th>
                  {/* <th scope="col">Image</th> */}
                  {
                    this.props.status === 2 ? <th scope="col">Tracking Number</th> : null
                  }
                  {/* <th scope="col">Consignment #</th> */}
                  {/* <th scope="col">Details</th> */}
                  {/* <th scope="col">Complain</th> */}
                </tr>
              </thead>
              {this.state.orders.map((item, index) => {
                return (
                  <tbody key={index}>
                    {item.details.map((detail, dKey) => {
                      return (
                        <tr>
                          <td>{item.orderId}</td>
                          <td>{detail.ignoreDateFormatting ? new Date(detail.createdAt.toString()).toLocaleString() : formatDate(new Date(item.createdAt))}</td>
                          <td>{detail.productName}</td>
                          <td>{detail.specificationName}</td>
                          <td>{detail.colorName}</td>
                          <td>{detail.quantity}</td>
                          <td>{detail.priceAfterDiscount.toFixed(2)}</td>
                          <td>{item.merchantName}</td>
                          {/* <td onClick={() => this.goToOrderDetail(detail)}>
                          <img src={detail.productImage} alt="Nothing Showed Up" width="100px" height="100px" style={{ marginTop: "10px", marginBottom: "10px" }} />
                        </td> */}
                          {
                            item.shippingStatus === 2 ? <td>{item.consignmentNumber}</td> : null
                          }
                          <td>
                            {item.shippingStatus === 2 ?
                              <button className="btn btn-solid" onClick={() => this.completeOrderByUser(detail._id, item.merchantEmail)}>
                                Complete Order
                              </button>
                              : null}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                )
              })}
            </table>
            <ConfirmationAlert
              open={this.state.showModal}
              onCancel={() => this.setState({ showModal: false })}
              onAccept={this.cancelOrders}
              message='are you sure to cancel this order'
            />

            <SuccessAlert
              onClose={this.closeSuccessModal}
              open={this.state.showSuccess}
              title={'Success'}
              message={this.state.errorMessage}
            />
            <ErrorAlert
              onClose={this.closeSuccessModal}
              open={this.state.showError}
              error
              title={'Error'}
              message={this.state.errorMessage}
            />
            {(this.state.page <= this.state.pages) ?
              <div
                className="btn btn-solid"
                style={{ display: 'flex', margin: 10, marginLeft: 20, width: 150 }}
                onClick={() => this.getOrders()}> Show More
              </div>
              : <div />}
          </div>
        </div>
        :
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div >
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/orderhistory.png`} style={{ color: "grey" }} className="img-fluid mb-4" alt="" />
                    <h3>
                      <strong>Order History is Empty</strong>
                    </h3>
                    <h4>Explore more shortlist some items.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}
