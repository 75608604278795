import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Breadcrumb from "../common/breadcrumb";
import LoadingLoader from "./Loader";
import { Manager } from "../../StorageManager/Storage";
import PositiveAlert from "../CustomAlert/CustomAlert";
import "./style.css";

export default class AccountVerificationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            msisdn: "",
            previous: "",
            showPositiveAlert: false,
            showAlert: false,
            counter: 120,
            access_token: "",
            loginVerification: false,
            sendTac: true,
            resend_hash: null,
            isLogin: true,
        };
    }

    componentDidMount() {
    }

    sendTacNumber = (msisdn) => {
        if (this.props.history.location.state.isLogin === true) {
            let body = { MSISDN: msisdn, hash: this.state.access_token };
            this.props.apiManager.makeCall("sendLoginOtp", body, (res) => {
                if (res.errors !== undefined) {
                    let erro = res.errors.map((err) => {
                        return err;
                    });
                    this.setState({
                        // loading: false,
                        showPositiveAlert: true,
                        positiveResponse: true,
                        errorMessage: erro
                            ? erro
                            : "Number should start with Plus Sign",
                    });
                }
                if (res.message === 7031) {
                    Manager.setItem("accessTokenMdlwr", res.token, false);

                    this.setState({
                        counter: 120,
                        // loading: false,
                        showPositiveAlert: true,
                        errorMessage: "OTP has been Resend",
                        access_token: res.token,
                        sendTac: false,
                        resend_hash: res.token,
                    });
                } else if (res.message === 6107) {
                    this.setState({
                        loading: false,
                        showAlert: true,
                        errorMessage: "OTP Limited Exceeded!",
                    });
                } else if (res.errors[1].msg === 7026) {
                    this.setState({
                        loading: false,
                        showModal: true,
                        errorMessage: res.errors[0].msg,
                    });
                } else {
                    this.setState({
                        loading: false,
                        showAlert: true,
                        errorMessage: res.message,
                    });
                }
            });
        } else {
            let body = { MSISDN: msisdn, hash: this.state.access_token };
            this.props.apiManager.makeCall("sendRegisterOtp", body, (res) => {
                if (res.errors !== undefined) {
                    let erro = res.errors.map((err) => {
                        return err;
                    });
                    this.setState({
                        // loading: false,
                        showPositiveAlert: true,
                        positiveResponse: true,
                        errorMessage: erro
                            ? erro
                            : "Number should start with Plus Sign",
                    });
                }
                if (res.message === 7031) {
                    Manager.setItem("accessTokenMdlwr", res.token, false);

                    this.setState({
                        // counter: 60,
                        // loading: false,
                        showPositiveAlert: true,
                        errorMessage: "OTP has been Resend",
                        access_token: res.token,
                        sendTac: false,
                        resend_hash: res.token,
                    });
                } else if (res.errors[1].msg === 7026) {
                    this.setState({
                        loading: false,
                        showModal: true,
                        errorMessage: res.errors[0].msg,
                    });
                } else {
                    this.setState({
                        showAlert: true,
                        errorMessage: res.message,
                    });
                }
            });
        }
    };

    counter = () => {
        var x = this;
        var { counter } = this.state;
        setTimeout(function() {
            if (counter > 0) {
                x.setState({ counter: counter - 1 });
            }
        }, 1000);
        if (counter > 0) {
            return <div style={{color:"red"}}>TAC Will Expire in: {counter}</div>;
        } else {
        return (
            <div>
                <u
                    className="text-primary cursor-p"
                    onClick={() => this.sendTacNumber(this.props.msisdn)}
                >
                    Resend?
                </u>
            </div>
        );
        }
    };

    render() {
        if (this.state.loading)
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "70vh",
                        backgroundColor: "white",
                    }}
                >
                    <LoadingLoader visible={this.state.loading} />
                </div>
            );
        return (
            <div>
                <Breadcrumb title={"Verify Account"} />
                <Formik
                    initialValues={{ tac: "" }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.tac) {
                            errors.oldPassword = "Please enter TAC";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        // phone: Yup.string()
                        //             .required('Phone Number is required')
                        tac: Yup.string()
                            .min(6, "Add Atleast 6 number")
                            .max(
                                6,
                                "Not valid number, you can add 6 number at max"
                            )
                            .matches(
                                /^(\+?\d{1,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                                "Phone number is not valid"
                            )
                            .required("TAC field required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        values.resend_hash = this.state.resend_hash;
                        values.isLogin = this.props.history.location.state.isLogin;
                        this.props.onSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue, handleChange }) => (
                        <div className="container-login100">
                            <div className="wrap-login100 modal-content quick-view-modal">
                                <Form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        Account Verification
                                    </span>
                                    <p>
                                        <i>
                                            TAC sent to your Mobile Number.
                                            Please key-in to verify account.
                                        </i>
                                    </p>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="text"
                                            name="msisdn"
                                            id="defaultRegisterFormMsisdn"
                                            className="input100"
                                            placeholder="TAC"
                                            value={this.props.msisdn}
                                            disabled
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-phone"></i>
                                        </span>
                                    </div>
                                    <div className="wrap-input100 validate-input">
                                        <Field
                                            type="password"
                                            name="tac"
                                            id="defaultRegisterFormTac"
                                            className="input100"
                                            placeholder="TAC"
                                            onChange={handleChange}
                                        />
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <ErrorMessage
                                            style={{ color: "red" }}
                                            id="error"
                                            name="tac"
                                            component="div"
                                        />
                                    </div>

                                    <div className="container-login99-form-btn pt-2 w-50 ml-auto">
                                        {this.counter()}
                                    </div>
                                    <div className="container-login100-form-btn pt-2 w-50 ml-auto">
                                        <button
                                            className="login100-form-btn"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Verify Now
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>

                <PositiveAlert
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showAlert: false })}
                    open={this.state.showAlert}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
