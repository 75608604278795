import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SuperHOC } from "../HOC";
import { Manager } from "../StorageManager/Storage";
import LoadingLoader from "../components/Loader/Loader";
import Breadcrumb from "../components/common/breadcrumb";
import PositiveAlert from "../components/CustomAlert/CustomAlert";

class UserDetailsForRegistration extends Component {
    state = {
        loading: false,
        showPositiveAlert: false,
        showVerifyTrue: false,
    };

    componentDidMount() {
        this.props.apiManager.createSession();
        const registData = this.props.location.state;
        if (registData != null || registData != undefined) {
            this.setState({
                email: registData.email,
                msisdn: registData.msisdn,
                password: registData.password,
                loading: false,
            });
        } else {
            this.setState({ loading: false });
        }
    }

    goToRegisterScreen = (registerData) => {
        this.setState({ loading: true });
        const msisdn = this.props.location.state;
        let tempValue = msisdn.msisdn;
        let msis = tempValue.substring(2);
        let payload = {
            password: registerData.password,
            email: registerData.email,
            msisdn: `${tempValue}`.toString(),
        };

        let body = {
            email: payload.email,
            password: payload.password,
            MSISDN: payload.msisdn,
        };

        let tempError = "";

        this.props.apiManager.makeCall("registerData", body, (res) => {
            if (res.errors != undefined) {
                let erro = res.errors.map((err) => {
                    tempError = err.msg;
                    return err.msg;
                });
                if (erro) {
                    this.setState({
                        loading: false,
                        faildRegistration: true,
                        showPositiveAlert: true,
                        errorMessage: tempError,
                    });
                }
            }
            if (res.message == 7007) {
                this.setState({
                    errorMessage:
                        "Registration Successful. Kindly proceed to Login",
                });

                this.setState({
                    showPositiveAlert: false,
                    showVerifyTrue: false,
                });
                this.emailLogin(payload.email, payload.password);
            } else if (res.message === 7057) {
                this.setState({
                    loading: false,
                    faildRegistration: true,
                    errorMessage: "Error! Try with different Email!",
                    showPositiveAlert: true,
                });
            } else if (res.message === 7059) {
                this.setState({
                    loading: false,
                    faildRegistration: true,
                    errorMessage: "CANNOT REGISTER USER! MSISDN NOT VERIFIED!",
                    showPositiveAlert: true,
                });
            } else {
                this.setState({
                    loading: false,
                    faildRegistration: true,
                    errorMessage: res.message
                        ? res.message
                        : "Please enter a password at least 8 character and contain At least one uppercase.At least one lower case.At least one special character.At least one digit",
                });
            }
        });
    };

    verifyEmail = (registerData) => {
        let payload = {
            email: registerData,
        };

        let body = {
            email: payload.email,
        };

        this.props.apiManager.makeCall(
            "emailVerificationRequest",
            body,
            (res) => {
                if (res.message == 7073) {
                    this.setState({
                        loading: false,
                        showVerifyTrue: true,
                        errorMessage:
                            "Successfully Logged-In. \nEmail has been sent to your account, Please verify!",
                    });
                    setTimeout(() => {
                        this.setState({
                            showVerifyTrue: false,
                            loading:false,
                        });
                        this.props.history.replace("/");
                        window.location.href = "/";
                    }, 3000);
                } else if (res.message === 7000) {
                    this.setState({
                        showVerifyTrue: false,
                        loading: false,
                        errorMessage: "Email already Verified",
                    });
                } else {
                    this.setState({
                        loading: false,
                        showVerifyTrue: false,
                        errorMessage: "Email already Verified",
                    });
                }
            }
        );
    };

    emailLogin = (username, password) => {
        this.setState({ loading: true });

        Manager.getItem("fcmToken", true).then((result) => {
            this.setState({ fcmToken: result });
        });
        let notificationKey =
            this.state.fcmToken !== "" ? this.state.fcmToken : "1";
        let middlewareBody = {
            email: username,
            password,
        };

        this.props.apiManager.makeCall("login", middlewareBody, (midres) => {
            if (midres.errors != undefined) {
                let erro = midres.errors.map((err) => {
                    return err;
                });

                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: "Password Incorrect",
                });
            } else {
                this.setState({
                    loading: false,
                    // showModal: true,
                    // positiveResponse: true,
                    errorMessage: "",
                });
            }
            // if (midres.status && midres.code == '24355') {
            if (midres.message === 7051) {
                Manager.setItem("userEmail", middlewareBody.email);
                Manager.setItem("userStatus", 1);
                Manager.setItem("profileIdMdlwr", midres.user.userId);
                Manager.setItem("accessTokenMdlwr", midres.token, false);

                // call B2c API
                let body = {
                    role: 1,
                    notificationStatus: 1,
                    accessToken: midres.token,
                    notificationKey: notificationKey,
                };

                this.byPassb2cLogin(body, middlewareBody.email);
            } else if (midres.message === 7009) {
                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: "Password Incorrect",
                });
            } else if (midres.message === 7000) {
                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: "User Not Found",
                });
            } else if (midres.errors[0].msg === 7023) {
                this.setState({
                    showModal: true,
                    errorMessage: midres.errors[1].msg,
                    loading: false,
                });
            } else if (!midres.errors[0].msg) {
                this.setState({
                    showModal: true,
                    errorMessage:
                        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
                    loading: false,
                });
            } else {
                this.setState({
                    showModal: true,
                    errorMessage:
                        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
                    loading: false,
                });
            }
        });
    };

    byPassb2cLogin = (body, email) => {
        this.props.apiManager.makeCall("xox_by_pass_login", body, (res) => {
            if (res.token) {
                this.verifyEmail(email);
                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: "Password Incorrect",
                });
            
            } else if (
                res.message === "1001" ||
                res.message === "1002" ||
                res.message === "1003"
            ) {
                this.setState({
                    showModal: true,
                    errorMessage: "Email Or Password Incorrect",
                });
            } else {
                this.setState({
                    showModal: true,
                    errorMessage:
                        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
                });
            }
        });
    };

    render() {
        return (
            <div>
                <Breadcrumb title={"create account"} />
                <Formik
                    initialValues={{
                        // firstName: this.props.first_name,
                        // lastName: this.props.last_name,
                        // country: "60",
                        // phone: this.props.msisdn,
                        email: this.props.email,
                        password: this.props.password,
                        confirmpassword: this.props.password,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = "Please enter your email";
                        }
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                values.email
                            )
                        ) {
                            errors.email = "Invalid email address";
                        }
                        if (
                            /^(?=.\d)(?=.[a-z])(?=.[A-Z])(?=.[^a-zA-Z0-9]).{8,}$/i.test(
                                values.password
                            )
                        ) {
                            errors.password =
                                "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        // firstName: Yup.string().required(
                        //     "FirstName is required"
                        // ),

                        email: Yup.string().required("Email is required"),

                        password: Yup.string()
                            // .min(6, 'Password must be at least 6 characters')
                            .required("Password is required")
                            // .matches(/^(?=.\d)(?=.[a-z])(?=.[A-Z])(?=.[^a-zA-Z0-9]).{8,}$/, 'i')
                            .matches(
                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{8,}$/,
                                "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                            ),

                        confirmpassword: Yup.string().oneOf(
                            [Yup.ref("password"), null],
                            "Passwords must match"
                        ),
                        // .required("Confirm Password is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.goToRegisterScreen(values);

                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <section className="section-b-space">
                            <div className="container new-register">
                                <Form>
                                    <div
                                        className="row border border-light pb-5 pt-5"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <span className="login100-form-title">
                                            Create Your Account
                                        </span>
                                        <div className="col-xl-4 col-sx-12">
                                            <div className="wrap-input100 validate-input">
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    className="input100"
                                                    placeholder="Enter Email"
                                                    id="defaultRegisterFormEmail"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-envelope"></i>
                                                </span>
                                                {this.props.takenEmail !==
                                                null ? (
                                                    this.props.takenEmail ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Email is taken
                                                        </p>
                                                    ) : null
                                                ) : null}
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="email"
                                                component="p"
                                            />
                                            <div className="wrap-input100 validate-input">
                                                <Field
                                                    type="password"
                                                    name="password"
                                                    id="defaultRegisterFormPassword"
                                                    className="input100"
                                                    placeholder="Enter Password"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-lock passwordError"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="password"
                                                component="p"
                                            />
                                            <div className="wrap-input100 validate-input">
                                                <Field
                                                    type="password"
                                                    name="confirmpassword"
                                                    id="defaultRegisterFormPassword"
                                                    className="input100"
                                                    placeholder="Enter Confirm password"
                                                />
                                                <span className="focus-input100"></span>
                                                <span className="symbol-input100">
                                                    <i className="fa fa-lock passwordError"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage
                                                style={{ color: "red" }}
                                                id="error"
                                                name="confirmpassword"
                                                component="div"
                                            />

                                            <div
                                                className="container-register100-form-btn"
                                                style={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    alignSelf: "center",
                                                }}
                                            >
                                                {this.state.loading ? (
                                                    <div
                                                        style={{
                                                            // left: 100,
                                                            // top: 100,
                                                            // bottom: 50,
                                                            zIndex: "100",
                                                            // position: "absolute",
                                                        }}
                                                    >
                                                        <LoadingLoader
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="login100-form-btn"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        Register
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
                <PositiveAlert
                    error
                    onClose={() => this.setState({ showPositiveAlert: false })}
                    open={this.state.showPositiveAlert}
                    title={"error"}
                    message={this.state.errorMessage}
                />
                <PositiveAlert
                    onClose={() => this.setState({ showVerifyTrue: false })}
                    open={this.state.showVerifyTrue}
                    title={"error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}
export default SuperHOC(UserDetailsForRegistration);
