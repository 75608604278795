import React, { Component } from 'react'
import { SuperHOC } from "../HOC";
import OrderHistory from '../components/order/OrderHistory';


class OrderHistoryScreen extends Component {
  render() {
    return (
      <div>
        <OrderHistory
          {...this.props}
        />
      </div>
    )
  }
}
export default SuperHOC(OrderHistoryScreen)