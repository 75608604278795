import React, { Component } from "react";
import Login from "../components/Login/loginComponent";
import { SuperHOC } from "../HOC";
import LoadingLoader from "../components/Loader/Loader";
import { Manager } from "../StorageManager/Storage";
import CustomAlert from "../components/CustomAlert/CustomAlert";
import { withLastLocation } from "react-router-last-location";
import { login } from "../utils/session";

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fcmToken: "1",
            showModal: false,
            errorMessage: "",
            positiveResponse: false,
        };
    }

    componentDidMount() {
        this.props.apiManager.createSession();
        Manager.getItem("fcmToken", true).then((result) => {
            this.setState({ fcmToken: result });
        });
    }

    byPassb2cLogin = (body) => {
        this.props.apiManager.makeCall("xox_by_pass_login", body, async(res) => {
            if (res.token) {
                const params = new URLSearchParams(window.location.search)
                const redirect = params.get('redirect');

                try {
                    await login();
                } catch (error) {
                    console.error('Error: ', error);
                }
                this.setState({ loading: false });

                if (redirect) {
                    const lifestyleURL = new URL(redirect, process.env.REACT_APP_BLACKMARKET_URL);

                    const token = localStorage.getItem('x-token');
                    const cartToken = localStorage.getItem('token');

                    lifestyleURL.searchParams.set('token', encodeURIComponent(token));
                    lifestyleURL.searchParams.set('cartToken', encodeURIComponent(cartToken));

                    window.location.href = lifestyleURL.href;
                    return;
                }
                this.props.history.replace("/");
                window.location.href = "/";


            } else if (
                res.message === "1001" ||
                res.message === "1002" ||
                res.message === "1003"
            ) {
                this.setState({
                    showModal: true,
                    errorMessage: "Email Or Password Incorrect",
                });
            } else {
                this.setState({
                    showModal: true,
                    errorMessage:
                        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
                });
            }
        });
    };

    emailLogin = (username, password) => {
        this.setState({ loading: true });

        Manager.getItem("fcmToken", true).then((result) => {
            this.setState({ fcmToken: result });
        });
        let notificationKey =
            this.state.fcmToken !== "" ? this.state.fcmToken : "1";
        let middlewareBody = {
            email: username,
            password,
        };

        this.props.apiManager.makeCall("login", middlewareBody, (midres) => {
            if (midres.errors !== undefined) {
                let erro = midres.errors.map((err) => {
                    return err;
                });

                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: "Password Incorrect",
                });
            } else {
                this.setState({
                    loading: false,
                    // showModal: true,
                    // positiveResponse: true,
                    errorMessage: midres.decodedMessage
                        ? midres.decodedMessage
                        : "Something went wrong",
                });
            }
            // if (midres.status && midres.code == '24355') {
            if (midres.message === 7051) {
                if (
                    midres.resetPassword !== undefined &&
                    midres.resetPassword === false
                ) {
                    this.setState({
                        loading: false,
                        showModal: true,
                        positiveResponse: false,
                        errorMessage:
                            "Welcome back! We have upgraded our Black App Security to ensure you receive the best privacy experience while using our App.\n Please set a new password for your account.",
                    });
                    setTimeout(() => {
                        this.forgetPassword(middlewareBody.email);
                    }, 4000);
                } else {
                    Manager.setItem("userStatus", 1);
                    Manager.setItem("userEmail", middlewareBody.email);
                    Manager.setItem("profileIdMdlwr", midres.user.userId);
                    Manager.setItem("accessTokenMdlwr", midres.token, false);

                    // call B2c API
                    let body = {
                        role: 1,
                        notificationStatus: 1,
                        userId: midres.user.userId,
                        accessToken: midres.token,
                        notificationKey: notificationKey,
                    };

                    this.byPassb2cLogin(body);
                }
            } else if (midres.message === 7009) {
                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: "Password Incorrect",
                });
            } else if (midres.message === 7000) {
                this.setState({
                    loading: false,
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: "User Not Found",
                });
            } else if (midres.errors[0].msg === 7023) {
                this.setState({
                    showModal: true,
                    errorMessage: midres.errors[1].msg,
                    loading: false,
                });
            } else if (!midres.errors[0].msg) {
                this.setState({
                    showModal: true,
                    errorMessage:
                        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character",
                    loading: false,
                });
            } else {
                this.setState({
                    showModal: true,
                    errorMessage:
                        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character",
                    loading: false,
                });
            }
        });
    };

    forgetPassword = (email) => {
        let body = {
            email: email,
        };
        this.props.apiManager.makeCall("forgotPassword", body, (res) => {
            if (res.message === 7066 || res.message === 7031) {
                this.setState({
                    showModal: true,
                    positiveResponse: false,
                    errorMessage: `OTP has been sent to ${email}! It will expire in 2 minutes`,
                });
                setTimeout(() => {
                    this.props.history.push({
                        pathname: `/ForgetVerification`,
                        search: `id=${email}`,
                        state: { isLogin: false, hash: res.token },
                    });
                }, 3000);
            } else if (res.message === 7000) {
                this.setState({
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: `Email not found ${email}`,
                });
            } else if (res.message === 6107) {
                this.setState({
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: `OTP Limit exceeded ${email}, Please wait for 10 minutes`,
                });
            } else {
                this.setState({
                    showModal: true,
                    positiveResponse: true,
                    errorMessage: `Email not found ${email}`,
                });
            }
            //this.props.history.replace('/login');
        });
    };

    mobileLogin = (phone) => {
        this.props.history.push({
            pathname: `/verification`,
            search: `id=${phone}`,
            state: { isLogin: true },
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.lastLocation && nextProps.lastLocation.pathname) {
            if (this.props.lastLocation === null) {
                return this.setState({ location: "/" });
            }

            let pathname =
                    this.props.lastLocation.pathname +
                    this.props.lastLocation.search;
                this.setState({ location: pathname });
        }
    }
    
    render() {
        if (this.state.loading)
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "90vh",
                        backgroundColor: "white",
                    }}
                >
                    <LoadingLoader visible={this.state.loading} />
                </div>
            );
        return (
            <div style={{ minHeight: "100vh" }}>
                <Login
                    {...this.props}
                    onSubmit={(data, isEmailLogin) => {
                        if (isEmailLogin)
                            this.emailLogin(data.email, data.password);
                        else this.mobileLogin(data.phone);
                    }}
                />
                <CustomAlert
                    error={this.state.positiveResponse}
                    onClose={() => this.setState({ showModal: false })}
                    open={this.state.showModal}
                    title={"Error"}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default SuperHOC(withLastLocation(LoginScreen));
