import React, { Component } from 'react'
import PageNotFound from '../components/pages/404'
import { SuperHOC } from "../HOC";



class PageNotFoundScreen extends Component {
  render() {
    return (
      <div>
        <PageNotFound />
      </div>
    )
  }
}


export default SuperHOC(PageNotFoundScreen)