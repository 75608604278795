import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Link } from 'react-router-dom';
import ProductCart from './ProductCart'
import ServicesCart from './ServicesCart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import LoadingLoader from '../Loader/Loader'
import Modal from 'react-responsive-modal';
import CustomAlert from "../CustomAlert/CustomAlert";
import CheckoutAlert from "../CustomAlert/CustomAlert";
//import OTPInput from "otp-input-react";
import { styles } from './styles'
import CountContext from '../common/headers/common/CountContext'
class RightSidebar extends Component {
  static contextType = CountContext
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      cartJson: {},
      emptyCart: false,
      selectOnly: {},
      loading: true,
      checked: null,
      allTrue: true,
      checkedAllProducts: false,
      selection: null,
      verificationModal: false,
      verifyMessage: '',
      verificationCode: '',
      paymentTypeModal: false,
      showErrorMesssage: false,
      errorMessage: '',
      verificationError: false,
      showCheckoutErrorMesssage: false,
      showAlert : false,
    }
  }


  getCart = () => {
    this.props.apiManager.makeCall('get_cart', {}, (res) => {
      if (res.message === '1426') {
        this.setState({ cartJson: res.cartJson, loading: false }, () => {
          const { merchantProducts, merchantServices } = this.state.cartJson
          const newItem = Object.keys(merchantProducts).map(key => merchantProducts[key])
          const services = Object.keys(merchantServices).map(key => merchantServices[key])
          let totalMerchant = newItem.map(item => item.merchant)
          let trueMerchant = newItem.filter(item => item.merchant.selected === true)
          let totalServices = services.map(item => item.merchant)
          let trueServices = services.filter(item => item.merchant.selected === true)
          if (trueMerchant.length === totalMerchant.length) {
            this.setState({
              allProducts: true
            })
          }
          else if (trueMerchant.length !== totalMerchant.length) {
            this.setState({
              allProducts: false
            })
          }
          if (trueServices.length === totalServices.length) {
            this.setState({
              allServices: true
            })
          }
          else if (trueServices.length !== totalServices.length) {
            this.setState({
              allServices: false
            })
          }
        })
      }
      else if (res.message === '1425') {
        this.setState({ cartJson: {}, emptyCart: true, loading: false })
      }
      else {
        this.setState({ cartJson: {}, emptyCart: true, loading: false })
      }
    })
  }
  getCartCount = () => {
    this.props.apiManager.makeCall('get_cart_count', {}, (res) => {
      if (res.message === '1426') {
        this.context.setCartCount(res.count)
      }
    })
  }

  selectCartSingleItem = (data, callback) => {
    let merchantEmail = data.merchantEmail
    let index = data.itemIndex
    let body = {
      itemId: data._id
    }

    if (data.selected) {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('un_select_cart_single_item', body, (res) => {
        this.setState({ checked: true })
        if (res.message === '1957') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantProducts[merchantEmail].itemsJson[index].selected = false
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checked: false,
            checkedAllProducts: false
          });
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
        callback && callback();
      })
    }
    else if (!data.selected) {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('select_cart_single_item', body, (res) => {
        this.setState({ checked: true })
        if (res.message === '1943') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantProducts[merchantEmail].itemsJson[index].selected = true;
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checked: true,
          });
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
        callback && callback();
      })
    }
  }

  selectCartSingleServiceItem = (data, callback) => {
    let merchantEmail = data.merchantEmail
    let index = data.itemIndex
    let body = {
      itemId: data._id
    }

    if (data.selected) {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('un_select_cart_single_item', body, (res) => {
        if (res.message === '1998') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantServices[merchantEmail].itemsJson[index].selected = false;
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checked: false,
            checkedAllProducts: false
          });
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
        callback && callback();
      })
    }
    else if (!data.selected) {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('select_cart_single_item', body, (res) => {
        //this.setState({ checked: true })
        if (res.message === '1943') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantServices[merchantEmail].itemsJson[index].selected = true;
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
          });
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
        callback && callback();
      })
    }
  }

  selectCartMerchantItems = (item) => {
    const { itemsJson } = item
    let merchantEmail = item.merchant.merchantEmail
    let body = {
      merchantEmail: merchantEmail
    }
    const { merchantProducts } = this.state.cartJson
    const newItem = Object.keys(merchantProducts).map(key => merchantProducts[key])
    let newORnew = newItem.map(item => item.merchant)
    let filteredItem = itemsJson.filter(item => item.selected === true)
    if (filteredItem.length === itemsJson.length) {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('un_select_cart_merchant_products', body, (res) => {
        if (res.message === '1948') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantProducts[merchantEmail].merchant.selected = false;
          merchantProducts[merchantEmail].itemsJson.map((item, index) => {
            item.selected = false
            return item
          })
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checked: false,
            checkedAllProducts: false
          }, () => {
            let newAndNew = newItem.filter(item => item.merchant.selected === true)
            if (newAndNew.length === newORnew.length) {
              this.setState({
                allProducts: true
              })
            }
            else {
              this.setState({
                allProducts: false
              })
            }
          }
          );
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
    else {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('select_cart_merchant_products', body, (res) => {
        if (res.message === '1948') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantProducts[merchantEmail].merchant.selected = true;
          merchantProducts[merchantEmail].itemsJson.map((item, index) => {
            item.selected = true
            return item
          })
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checked: true,
            checkedAllProducts: true
          }, () => {
            let newAndNew = newItem.filter(item => item.merchant.selected === true)
            if (newAndNew.length === newORnew.length) {
              this.setState({
                allProducts: true
              })
            }
            else {
              this.setState({
                allProducts: false
              })
            }
          });
        }
        else if (res.message === '1963') {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
  }

  selectCartMerchantServices = (item) => {
    const { itemsJson } = item
    let merchantEmail = item.merchant.merchantEmail
    let body = {
      merchantEmail: merchantEmail
    }
    const { merchantServices } = this.state.cartJson
    const newItem = Object.keys(merchantServices).map(key => merchantServices[key])
    let newORnew = newItem.map(item => item.merchant)
    let filteredItem = itemsJson.filter(item => item.selected === true)
    if (filteredItem.length === itemsJson.length) {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('un_select_cart_merchant_services', body, (res) => {
        if (res.message === '1948') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantServices[merchantEmail].merchant.selected = false;
          merchantServices[merchantEmail].itemsJson.map((item, index) => {
            item.selected = false
            return item
          })
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checked: false,
            checkedAllProducts: false
          }, () => {
            let newAndNew = newItem.filter(item => item.merchant.selected === true)
            if (newAndNew.length === newORnew.length) {
              this.setState({
                allServices: true
              })
            }
            else {
              this.setState({
                allServices: false
              })
            }
          }
          );
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
    else {
      this.setState({ openModal: true })
      this.props.apiManager.makeCall('select_cart_merchant_services', body, (res) => {
        if (res.message === '1948') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          merchantServices[merchantEmail].merchant.selected = true;
          merchantServices[merchantEmail].itemsJson.map((item, index) => {
            item.selected = true
            return item
          })
          cartJson.merchantProducts = merchantProducts;
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checked: true,
            checkedAllProducts: true
          }, () => {
            let newAndNew = newItem.filter(item => item.merchant.selected === true)
            if (newAndNew.length === newORnew.length) {
              this.setState({
                allServices: true
              })
            }
            else {
              this.setState({
                allServices: false
              })
            }
          }
          );
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
  }


  decrementQty = (data) => {
    let merchantEmail = data.merchantEmail
    let index = data.itemIndex
    let body = {
      itemId: data._id
    }
    this.setState({ openModal: true })
    this.props.apiManager.makeCall('cart_item_decrease_quantity', body, (res) => {
      let cartJson = this.state.cartJson;
      let merchantProducts = cartJson.merchantProducts;
      let merchantServices = cartJson.merchantServices;
      cartJson = res.cart;
      merchantProducts[merchantEmail].itemsJson[index] = res.product;
      cartJson.merchantProducts = merchantProducts;
      cartJson.merchantServices = merchantServices;
      this.setState({
        cartJson,
        openModal: false
      });
    })
  }

  incrementQty = (data) => {
    let merchantEmail = data.merchantEmail
    let index = data.itemIndex
    let body = {
      itemId: data._id
    }
    this.setState({ openModal: true })
    this.props.apiManager.makeCall('cart_item_increase_quantity', body, (res) => {
      if(res.message === '2347'){
        this.setState({showAlert: true, errorMessage : 'Not enough stock. Please select other items or try again later.', openModal : false})
      }else{
        let cartJson = this.state.cartJson;
        let merchantProducts = cartJson.merchantProducts;
        let merchantServices = cartJson.merchantServices;
        cartJson = res.cart;
        merchantProducts[merchantEmail].itemsJson[index] = res.product;
        cartJson.merchantProducts = merchantProducts;
        cartJson.merchantServices = merchantServices;
        this.setState({
          cartJson,
          openModal: false
        });
      }
    })
  }

  serviceDecrementQty = (data) => {
    let merchantEmail = data.merchantEmail
    let index = data.itemIndex
    let body = {
      itemId: data._id
    }
    this.setState({ openModal: true })
    this.props.apiManager.makeCall('cart_item_decrease_quantity', body, (res) => {
      let cartJson = this.state.cartJson;
      let merchantProducts = cartJson.merchantProducts;
      let merchantServices = cartJson.merchantServices;
      cartJson = res.cart;
      merchantServices[merchantEmail].itemsJson[index] = res.product;
      cartJson.merchantProducts = merchantProducts;
      cartJson.merchantServices = merchantServices;
      this.setState({
        cartJson,
        openModal: false
      });
    })
  }

  serviceIncrementQty = (data) => {
    let merchantEmail = data.merchantEmail
    let index = data.itemIndex
    let body = {
      itemId: data._id
    }
    this.setState({ openModal: true })
    this.props.apiManager.makeCall('cart_item_increase_quantity', body, (res) => {
      let cartJson = this.state.cartJson;
      let merchantProducts = cartJson.merchantProducts;
      let merchantServices = cartJson.merchantServices;
      cartJson = res.cart;
      merchantServices[merchantEmail].itemsJson[index] = res.product;
      cartJson.merchantProducts = merchantProducts;
      cartJson.merchantServices = merchantServices;
      this.setState({
        cartJson,
        openModal: false
      });
    })
  }

  removeItem = (data) => {
    let body = {
      itemId: data._id
    }
    this.props.apiManager.makeCall('remove_cart_item', body, (res) => {
      this.setState({ openModal: true })
      if (res.message === '1961') {
        this.getCart()
        this.getCartCount()
        this.setState({
          openModal: false
        });
      }
    })
  }


  selectCartAllProducts = () => {
    const { _id } = this.state.cartJson
    let body = {
      cartId: _id
    }
    var checkBox = document.getElementById("my-checkbox");
    if (checkBox.checked === true) {
      this.props.apiManager.makeCall('select_cart_all_products', body, (res) => {
        this.setState({ openModal: true })
        if (res.message === '1949') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          let solution = merchantProducts
          Object.keys(merchantProducts).map(key => {
            merchantProducts[key].merchant.selected = true
            merchantProducts[key].itemsJson.map(item => item.selected = true)
            solution[key] = merchantProducts[key]
            return solution
          })
          cartJson.merchantProducts = solution
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checkedAllProducts: true,
            allProducts: true
          })
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
    else {
      this.props.apiManager.makeCall('un_select_cart_all_products', body, (res) => {
        this.setState({ openModal: true })
        if (res.message === '1949') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          let solution = merchantProducts
          Object.keys(merchantProducts).map(key => {
            merchantProducts[key].merchant.selected = false
            merchantProducts[key].itemsJson.map(item => item.selected = false)
            solution[key] = merchantProducts[key]
            return solution
          })
          cartJson.merchantProducts = solution
          cartJson.merchantServices = merchantServices;
          this.setState({
            cartJson,
            openModal: false,
            checkedAllProducts: false,
            allProducts: false
          })
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
  }

  selectCartAllServices = () => {
    const { _id } = this.state.cartJson
    let body = {
      cartId: _id
    }
    var checkBox = document.getElementById("my-service");
    if (checkBox.checked === true) {
      this.props.apiManager.makeCall('select_cart_all_services', body, (res) => {
        this.setState({ openModal: true })
        if (res.message === '1951') {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          let solution = merchantServices
          Object.keys(merchantServices).map(key => {
            merchantServices[key].merchant.selected = true
            merchantServices[key].itemsJson.map(item => item.selected = true)
            solution[key] = merchantServices[key]
            return solution
          })
          cartJson.merchantServices = solution
          cartJson.merchantProducts = merchantProducts;
          this.setState({
            cartJson,
            openModal: false,
            allServices: true,
            checkedAllProducts: true
          })
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
    else {
      this.props.apiManager.makeCall('un_select_cart_all_services', body, (res) => {
        this.setState({ openModal: true })
        if (res.message) {
          let cartJson = this.state.cartJson;
          let merchantProducts = cartJson.merchantProducts;
          let merchantServices = cartJson.merchantServices;
          cartJson = res.cart;
          let solution = merchantServices
          Object.keys(merchantServices).map(key => {
            merchantServices[key].merchant.selected = false
            merchantServices[key].itemsJson.map(item => item.selected = false)
            solution[key] = merchantServices[key]
            return solution
          })
          cartJson.merchantProducts = merchantProducts
          cartJson.merchantServices = solution;
          this.setState({
            cartJson,
            openModal: false,
            allServices: false,
            checkedAllProducts: false
          })
        }
        else {
          this.setState({ openModal: false, showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
  }

  componentDidMount = () => {
    this.getCart()
  };

  emptyCart = () => {
    return (
      <div style={{ minHeight: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div >
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                    <h3>
                      <strong>Your Cart is Empty</strong>
                    </h3>
                    <h4>Explore more shortlist some items.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  renderProductCart = () => {
    const { merchantProducts } = this.state.cartJson
    const newItem = Object.keys(merchantProducts).map(key => merchantProducts[key])
    if (newItem.length === 0) return null
    return (
      <>
        <div style={styles.productContainer}>
          <input
            id='my-checkbox'
            onClick={this.selectCartAllProducts}
            checked={this.state.allProducts}
            type="checkbox"
            size='large'
          //style={{ marginTop: '10px', textAlign: 'center' }}
          />
          <h3 style={{ margin: 0, padding: 0, marginLeft: 5 }}>Products</h3>
        </div>
        <div>
          <table className="table cart-table table-responsive">
            <thead>
              <tr className='table-row'>
                <th scope='col'></th>
                <th scope='col'>Image</th>
                <th scope='col'>Name</th>
                <th scope='col'>Spec</th>
                <th scope='col'>Color</th>
                <th scope='col'>Price</th>
                <th scope='col'>Qty</th>
                <th scope='col'>Action</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            {
              newItem.map((item, index) => {
                let itemJson = item.itemsJson
                return (
                  <ProductCart
                    selectCartMerchantItems={(data) => this.selectCartMerchantItems(data)}
                    selectSingleItem={(data, callback) => this.selectCartSingleItem(data, callback)}
                    decrementQty={(data) => this.decrementQty(data)}
                    incrementQty={(data) => this.incrementQty(data)}
                    removeItem={(data) => this.removeItem(data)}
                    key={index}
                    item={item}
                    itemJson={itemJson}
                    {...this.props}
                    disabled={this.state.openModal}
                    singleDisabled={this.state.openModal}
                  />
                )
              }
              )
            }
          </table>
        </div>
      </>
    )
  }

  renderServiceCart = () => {
    const { merchantServices } = this.state.cartJson
    const newItem = Object.keys(merchantServices).map(key => merchantServices[key])
    if (newItem.length === 0) return null
    return (
      <>
        <div style={styles.productContainer}>
          <input
            id='my-service'
            type="checkbox"
            size='large'
            checked={this.state.allServices}
            onClick={this.selectCartAllServices}
          />
          <h3 style={{ margin: 0, padding: 0, marginLeft: 5 }}>Services</h3>
        </div>
        <div>
          <table className="table cart-table table-responsive">
            <thead>
              <tr className='table-row'>
                <th scope='col'></th>
                <th scope='col'>Image</th>
                <th scope='col'>Name</th>
                <th scope='col'>Price</th>
                <th scope='col'>Qty</th>
                <th scope='col'>Action</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            {
              newItem.map((item, index) => {
                let itemJson = item.itemsJson
                return (
                  <ServicesCart
                    selectCartMerchantServices={(data) => this.selectCartMerchantServices(data)}
                    removeServiceItem={(data) => this.removeItem(data)}
                    selectSingleItem={(data, callback) => this.selectCartSingleServiceItem(data, callback)}
                    serviceDecrementQty={(data) => this.serviceDecrementQty(data)}
                    serviceIncrementQty={(data) => this.serviceIncrementQty(data)}
                    key={index}
                    item={item}
                    {...this.props}
                    itemJson={itemJson}
                  />
                )
              })
            }
          </table>
        </div>
      </>
    )
  }
  showError = () => {
    this.setState(
      {
        showCheckoutErrorMesssage: true,
        errorMessage: 'You already have items in checkout. Please go to checkout page to proceed'
      })
  }

  checkOutCart = () => {
    const { productsItems, servicesItems } = this.state.cartJson
    if (productsItems > 0 && servicesItems > 0) {
      let body = {
        selection: 0,
      }
      this.props.apiManager.makeCall('checkout_cart_selected_items', body, (res) => {
        if (res.message === '1916') {
          this.setState({
            verificationModal: true
          });
        }
        else if (res.message === '1930') {
          this.getCartCount()
          this.props.history.push(`/checkout`)
        }
        else if (res.message === '1424') {
          this.showError()
        }
        else {
          this.setState({ showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
    else if (productsItems > 0) {
      let body = {
        selection: 1,
      }
      this.props.apiManager.makeCall('checkout_cart_selected_items', body, (res) => {
        if (res.message === '1930') {
          this.getCartCount()
          this.props.history.push(`/checkout`)
        }
        if (res.message === '1916') {
          this.setState({
            verificationModal: true
          });
        }
        else if (res.message === '1424') {
          this.showError()
        }
        else {
          this.setState({ showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
    else if (servicesItems > 0) {
      let body = {
        selection: 2
      }
      this.props.apiManager.makeCall('checkout_cart_selected_items', body, (res) => {
        if (res.message === '1930') {
          this.props.history.push(`/checkout`)
        }
        if (res.message === '1916') {
          this.setState({
            verificationModal: true
          });
        }
        else if (res.message === '1424') {
          this.showError()
        }
        else {
          this.setState({ showErrorMesssage: true, errorMessage: res.decodedMessage })
        }
      })
    }
    else {
      this.setState({ showErrorMesssage: true, errorMessage: 'No item selected. Please try again' })
    }
  }

  details = () => {
    const {
      productsTotal,
      subTotal, total,
      deliveryCost,
      promoDiscount,
      servicesSubTotal,
      deliveryAddress,
      discount,
    } = this.state.cartJson
    const { address, cityName, provinceName, postalCode } = deliveryAddress
    
    return (
      <div className="checkout-details">
        <div className="order-box">
          <div className="title-box">
            <div style={{ paddingTop: '10px' }}>Shipping Address</div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: '5px' }}>
            <h4 style={{ fontSize: '10px', paddingLeft: 20, lineHeight: 1.5 }}>City: {cityName}</h4>
            <h4 style={{ fontSize: '10px', paddingLeft: 20, lineHeight: 1.5 }}>State: {provinceName}</h4>
            <h4 style={{ fontSize: '10px', paddingLeft: 20, lineHeight: 1.5 }}>Postalcode: {postalCode}</h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: '5px' }}>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <h4 style={{ fontSize: '12px', paddingLeft: 20, paddingRight: 5, lineHeight: 1.5 }}>{address}</h4>
              <Link style={{ fontSize: '14px', color: 'red' }} to={`/AddAddress`}>CHANGE</Link>
            </div>
          </div>
          <ul className="qty">
            <li>Item(s): <span>{this.state.cartJson.cartSelectedItems}</span></li>
            {/* <li>Services Total <span>RM {servicesSubTotal}</span></li> */}
          </ul>
          {/* <ul className="sub-total">
            <li>Shipping <span className="count">RM {deliveryCost.toFixed(2)}</span></li>
            <li>Promo Code <span className="count">RM {promoDiscount.toFixed(2)}</span></li>
            {
              discount ? <li>Discount <span className="count">RM {discount.toFixed(2)}</span></li> : null
            }
            {
              discount ? <li>Subtotal <span className="count"><del> RM {subTotal.toFixed(2)} </del></span></li> : <li>Subtotal <span className="count"> RM {subTotal.toFixed(2)}</span></li>
            }
          </ul> */}
          <ul className="total">
            <li>SubTotal: <span className="count">RM  {(total).toFixed(2)}</span></li>
          </ul>
          <div className="wishlist-buttons" style={{ padding: 0 }}>
            <div className="col-12">
              <div className="btn btn-solid"
                onClick={this.checkOutCart}
              >
                Checkout</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderModal = () => {
    return (
      <Modal
        closeOnOverlayClick={false}
        styles={{ modal: { backgroundColor: 'white' }, overlay: { background: 'transparent' } }}
        open={this.state.openModal}
        onClose={this.state.openModal}
        center
        showCloseIcon={false}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', }}>
          <LoadingLoader visible={true} />
        </div>
      </Modal>
    )
  }
  submitVerificationCode = () => {
    if (this.state.verificationCode.length === 6) {
      let body = {
        verificationCode: this.state.verificationCode,
      }
      this.props.apiManager.makeCall('add_verification_code', body, (res) => {
        if (res.message === '1910') {
          this.setState({ verificationModal: false })
        }
      })
    }
    else this.setState({ verificationError: true })
  }

  isMobile = () => {
    if (window.innerWidth < 576) {
      return true
    }
    else {
      return false
    }
  }

  renderVerificationModal = () => {
    return (
      <Modal closeOnOverlayClick={false} open={this.state.verificationModal} onClose={() => this.setState({ verificationModal: false })} center styles={{ modal: { padding: '10px 5px 10px 5px', borderRadius: 5 } }} showCloseIcon={false}>
        <div className="modal-content quick-view-modal" style={{ border: 0, width: this.isMobile() ? 300 : 400 }}>
          <div className="modal-body" style={{ border: 0 }}>
            <h4 style={{ textAlign: 'center', paddingBottom: 10 }}>Verification Code </h4>
            <p style={{ textAlign: 'center', lineHeight: 1.4 }}>
              Please set your verification code for checking out. You can update it from your profile and please dont reveal your code to others.
                </p>
            <div className="row">
              <div className="col-lg-12">
                <div className="product-description border-product">
                  <form className="form-header">
                    {/* <p className="product-title" style={{ marginBottom: 5 }}>Please Remember The Verification Code<br />(Need To Be Provided To Merchant)</p> */}
                    <div className="input-group" style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                      {/* <OTPInput
                        placeholder={'0'}
                        inputStyles={{ border: this.isMobile() ? '1px solid grey' : '', margin: 7 }}
                        value={this.state.verificationCode}
                        onChange={otp => this.setState({ verificationCode: otp })}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        secure
                      /> */}
                      <p style={{ padding: 0, margin: 0, color: 'red' }}>{this.state.verificationError ? 'Please Fill All Fields' : ''}</p>
                      {/* <input className="form-control"
                        placeholder="Enter 6 digit Verification Code"
                        type="tel"
                        maxLength="6"
                        value={this.state.verificationCode}
                        onChange={(e) => { this.setState({ verificationCode: e.target.value }) }}
                      /> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 40, paddingBottom: 5 }}>
                      <div
                        onClick={this.submitVerificationCode}
                        style={{ backgroundColor: 'rgb(91,149,255)', width: '80%', cursor: 'pointer', fontSize: '16px', color: 'white', textAlign: 'center', paddingTop: 10, paddingBottom: 10, borderRadius: 10 }}>
                        Submit
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }


  render() {
    if (this.state.loading) return <LoadingLoader visible={this.state.loading} />
    return (
      <div>
        <Breadcrumb title={'Shopping Cart'} />
        {
          !this.state.emptyCart ? <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12"
            style={styles.cartContainer}>
            <h2 style={styles.cartTimOut}>your cart will expire in <span
              style={styles.cartTimeOutText}>6:20:20</span>  please hurry to checkout</h2>
          </div> : null
        }
        {
          this.state.emptyCart ? this.emptyCart() : <section className="cart-section section-b-space">
            <div className="container">
              <div className="row" style={styles.cartRowContainer}>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12"
                  style={{ marginTop: '50px' }}
                >
                  {this.renderProductCart()}
                  {/* {this.renderServiceCart()} */}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 cart-side-bar">
                  {this.details()}
                </div>
              </div>
            </div>
          </section >
        }
        {this.renderModal()}
        {/* {this.renderVerificationModal()} */}
        <CustomAlert
          error
          onClose={() => this.setState({ showErrorMesssage: false })}
          open={this.state.showErrorMesssage}
          title={'error'}
          message={this.state.errorMessage}
        />
        <CustomAlert
          error
          onClose={() => this.setState({ showAlert: false })}
          open={this.state.showAlert}
          title={"Error"}
          message={this.state.errorMessage}
        />
        <CheckoutAlert
          error
          onClose={() => this.setState({ showCheckoutErrorMesssage: false }, () => window.location.href = ('/checkout'))}
          open={this.state.showCheckoutErrorMesssage}
          title={'error'}
          message={this.state.errorMessage}
        />
      </div >
    )
  }
}

export default RightSidebar