import React, { Component } from 'react'
import { SuperHOC } from "../HOC";
import Wishlist from "../components/wishlist/index";


class WishListScreen extends Component {
  render() {
    return (
      <div>
        <Wishlist
          {...this.props}
        />
      </div>
    )
  }
}
export default SuperHOC(WishListScreen)