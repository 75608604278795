import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import StarRatings from 'react-star-ratings'
import { toast, ToastContainer, } from 'react-toastify';
import PositiveAlert from '../../CustomAlert/CustomAlert';
import NegativeAlert from '../../CustomAlert/CustomAlert'
import CountContext from '../../common/headers/common/CountContext'
import AddAddress from '../../../screens/AddAddress'
class ProductListItem extends Component {
  static contextType = CountContext
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      stock: 'InStock',
      quantity: 1,
      image: '',
      data: [],
      end: false,
      loading: true,
      price: '',
      specifications: [],
      colorId: '',
      specId: '',
      stockQuantity: 1,
      specificationIds: [],
      specificationIdsObject: [],
      availableVariants: [],
      colorIds: [],
      current: new Map(),
      addAddressModal: false
    }
  }

  componentDidMount() {
    this.getTrendingProducts();
  }

  getQuantity(item, i) {
    const map = new Map();
    item.specQuantity.forEach(element => {
      element.price = (this.state.data.specifications[0].price[0])
      map.set(element.specificationId, element)
    })
    let price = [...map.values()]
    let specId = [...map.values()]
    this.setState({
      image: item.icon.assets[0].src,
      colorId: item.slug,
      stockQuantity: item.specQuantity[0] && item.specQuantity[0].quantity ? item.specQuantity[0].quantity : 1,
      // availableVariants: avail,
      quantity: 1,
      current: map,
      specId: specId[0] && specId[0].specificationId ? specId[0].specificationId : this.state.data.specifications[0].id,
      price: price[0] && price[0].price ? price[0].price : this.state.data.specifications[0].price[0].price,
      stock: this.state.stockQuantity > 0 ? 'InStock' : 'OutOfStock'
    })
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }

  setPrice(item) {
    let stockQuantity = this.state.current.get(item.id)
    this.setState({
      price: item.price[0],
      specId: item.id,
      quantity: 1,
      stockQuantity: stockQuantity.quantity,
      stock: this.state.stockQuantity > 0 ? 'InStock' : 'OutOfStock'
    });
  }

  onOpenModal = () => {
    this.setState({ open: true });
    this.getTrendingProducts()
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: 'InStock' })
      this.setState({ quantity: this.state.quantity - 1 })
    }
  }
  plusQty = () => {
    if (this.state.quantity < this.state.stockQuantity) {
      this.setState({ quantity: this.state.quantity < 5 ? this.state.quantity + 1 : this.state.quantity })
    }
    else {
      this.setState({ stock: 'Limit Exceeded' })
    }
    if (this.state.quantity > 4) {
      this.setState({ stock: 'Limit Exceeded' })
    }
  }
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) })
  }

  renderRating = (item) => {
    return (
      <div style={{ padding: 0, margin: 0 }}>
        <StarRatings
          rating={typeof this.props.product.productRating === 'object' ? this.props.product.productRating.rating5 : this.props.product.productRating}
          starRatedColor='#FFB508'
          changeRating={this.changeRating}
          numberOfStars={5}
          name='rating'
          starDimension="16px"
          starSpacing="2px"
        />
      </div>
    )
  }

  getTrendingProducts = () => {
    let body = {
      slug: this.props.product.slug,
    };
    if (this.props.product.requiresInstallation === true) {
      this.props.apiManager.makeCall("get_service_limited_details", body, res => {
        if (res) {
          this.setState({
            data: res.product,
            specifications: res.product.specifications,
            //current: res.product.colors[0].specQuantity[0].specificationId,
            price: res.product.specifications[0].price[0],
            colorId: res.product.colors[0].slug,
            specId: res.product.specifications[0].id,
            //stockQuantity: res.product.colors[0].specQuantity[0].quantity
          })
        }
        else {
          this.setState({
            end: true,
            loading: false
          });
        }
      })
    }
    else {
      this.props.apiManager.makeCall("get_product_limited_details", body, res => {
        if (res) {
          const map = new Map();
          let i = 0
          res.product.colors[0].specQuantity.forEach(element => {
            element.price = (res.product.specifications[i].price[0])
            map.set(element.specificationId, element)
          })
          let price = [...map.values()]
          let specificationIds = new Set()
          let specificationIdsObject = new Set()
          let colorIds = new Set()
          this.setState({
            data: res.product,
            specifications: res.product.specifications,
            colorId: res.product.colors[0].slug,
            specificationIds: specificationIds,
            colorIds: colorIds,
            specificationIdsObject: specificationIdsObject,
            current: map,
            price: price[0].price,
            specId: res.product.specifications[0].id,
            stockQuantity: res.product.colors[0].specQuantity[0].quantity ? res.product.colors[0].specQuantity[0].quantity : 1,
            stock: res.product.colors[0].specQuantity[0].quantity && res.product.colors[0].specQuantity[0].quantity > 0 ? 'InStock' : 'OutOfStock',
          })
        }
        else {
          this.setState({
            end: true,
            loading: false
          });
        }
      })
    }
  }

  getCartCount = () => {
    this.props.apiManager.makeCall('get_cart_count', {}, (res) => {
      if (res.message === '1426') {
        this.context.setCartCount(res.count)
      }
    })
  }

  addItemToCart = () => {
    const { slug, price } = this.props.product
    if (this.props.product.requiresInstallation === true) {
      let body = {
        slug,
        currency: price[0].unit,
        quantity: 1,
        colorSlug: this.state.colorId,
        specificationId: this.state.specId
      }

      this.props.apiManager.makeCall(
        this.props.product.requiresInstallation === true &&
          this.state.data.isServiceFixed === true ? 'add_fixed_service_to_cart' :
          (this.props.product.requiresInstallation) === true ? 'add_service_to_cart' : '', body, (res) => {
            if (res.message === '1933') {
              this.setState({
                showPositiveAlert: true,
                errorMessage: res.decodedMessage
              })
            }
            else if (res.message === '1934') {
              this.setState({
                showPositiveAlert: true,
                errorMessage: res.decodedMessage
              })
            }
            else if (res.message === '1411') {
              this.setState({
                addAddressModal: true
              })
            }
            else if (res.message === '1003') {
              console.info('no user')
            }
            else {
              this.setState({
                showNegativeAlert: true,
                errorMessage: res.decodedMessage
              })
            }
          })
    }

    else {
      let body = {
        slug,
        currency: price[0].unit,
        quantity: this.state.quantity,
        colorSlug: this.state.colorId,
        specificationId: this.state.specId
      }
      this.props.apiManager.makeCall('add_product_to_cart', body, (res) => {
        this.setState({loading: true})
        if (res.message === '1420') {
          this.setState({
            showPositiveAlert: true,
            loading: false,
            errorMessage: res.decodedMessage
          })
          this.getCartCount()
        }
        else if (res.message === '1003') {
          console.info('no user')
          this.setState({loading: false})
        }
        else if (res.message === '1411') {
          this.setState({
            addAddressModal: true,
            loading: false
          })
        }
        else {
          this.setState({
            showNegativeAlert: true,
            loading: false,
            errorMessage: res.decodedMessage
          })
        }
      })
    }
  }
  renderAddressModal = () => {
    return (
      <Modal
        style={{ display: 'flex' }}
        // open={true}
        open={this.state.addAddressModal}
        onClose={() => this.setState({ addAddressModal: false })}
      >
        <AddAddress />

      </Modal>
    )
  }

  isHearted = (product) => {
    return this.props.user ?
      this.props.user.favoritesProducts.indexOf(product.slug) > -1 :
      false
  }

  handleHeartpress = () => {
    let product = this.props.product
    let body = { productId: product.slug };
    if (this.isHearted(product)) {
      this.props.apiManager.makeCall('remove_favorite_product', body, (result) => {
        if (result.message) {
          if (result.message === '1077') {
            this.props.saveUser(result.buyer);
            toast.success("Product removed from favorite");
            if (result.message === '1220') {
              toast.error("already");
            }
          } else {
            this.setState({
              // showNegativeAlert: true,
              errorMessage: result.decodedMessage
            })
          }
        }
      })
    } else {
      this.props.apiManager.makeCall('add_favorite_product', body, (result) => {
        if (result.message) {
          if (result.message === '1074') {
            this.props.saveUser(result.buyer);
            toast.success("Product added to favorite");
          } else {
            this.setState({
              // showNegativeAlert: true,
              errorMessage: result.decodedMessage
            })
          }
        }
      })
    }
  }

  renderNegotiation = () => {
    return (
      <div style={{ backgroundColor: "#ff4c3b", top: 5, position: "absolute", textAlign: 'center', fontSize: '6px', padding: "6px", zIndex: 1 }}>
        <p style={{ color: "#ffffff", margin: 0, padding: 0 }}>
          Negotiable
        </p>
      </div>
    )
  }

  renderOff = () => {
    let product = this.props.product;
    return (
      <div style={{ fontSize: '14px', fontWeight: '700', backgroundColor: "silver", top: '0', position: "absolute", right: '0', transform: "rotate(0deg)", letterSpacing: '0.1em', writingMode: "vertical-rl" }}>
        <p style={{ color: "#ffffff", margin: 0, padding: 5, paddingTop: 15, paddingBottom: 15 }}>
          {product.price[0].discountPercent.toFixed(2)} % off
        </p>
      </div>
    )
  }

  render() {
    let limitedDetail = this.state.data
    const { product } = this.props;
    let RatingStars = []
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i}></i>)
    }
    // let convertNameIntoDashes = product.name.split(' ').join('-');
    // const gotoProductDetail = {
    //   pathname: `/product/${convertNameIntoDashes}`,
    //   state: {
    //     slug: product.slug
    //   }
    // }
    var enc = window.btoa(product.slug);
    let removePerc = product.name ? product.name.split('%').join(' ') : ' ';
    let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
    let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
    let redirectLink='/product/' + convertNameIntoDashes + '?id=' + enc;

    return (
      <div className="card product-box shadow" style={{ margin: 15, cursor: 'pointer' }}>
        { product.price[0].discountPercent > 0 ? <span class="discount-badge" >{product.price[0].discountPercent.toFixed(0)}% OFF</span> : null }
        <div className="img-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="front">
            {/* {
              product.isNegotiable ? this.renderNegotiation() : null
            } */}
            <a href={redirectLink} >
              <img
                src={product.color ?
                  this.state.image ? this.state.image : product.color[0].icon.asset.src
                  : product.asset.src}
                width='100%'
                height='250px'
                alt="" /></a>
            {/* {
              product.price[0].discountPercent ? this.renderOff() : null
            } */}
          </div>
          <div className="cart-info cart-wrap">
            <ToastContainer />
            <div onClick={this.handleHeartpress} >
              <i className="fa fa-heart" style={{ color: this.isHearted(product) ? 'red' : '#bbbbbb' }} aria-hidden="true"></i>
            </div>
            <div onClick={this.onOpenModal}>
              <i className="fa fa-search" aria-hidden="true"></i>
            </div>
          </div>
          {product.color ?
            <ul className="product-thumb-list" >
              {product.color.map((vari, i) =>
                <li className={`grid_thumb_img ${(vari.icon.asset.src === this.state.image) ? 'active' : ''}`} key={i}>
                  <div>
                    <img src={`${vari.icon.asset.src}`} onClick={() => this.onClickHandle(vari.icon.asset.src)} alt='hello' style={{ width: '30px', height: '40px' }} />
                  </div>
                </li>)
              }
            </ul> : ''}
        </div>
        <div className="product-detail p-3">
          <div>
            <a href={redirectLink}>
              <div style={{
                position: 'relative',
                fontSize: 14,
                height: 36,
                lineHeight: 18,
                color: '#212121',
                whiteSpace: 'pre-wrap',
                display: '-webkit-box',
                lineclamp: 2,
                boxOrient: 'vertical'
              }}>
                <h6 style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '-webkit-fill-available'
                }}>{product.name}</h6>
              </div>
            </a>
            <h4>
              RM {(product.price[0].figure - product.price[0].figure * product.price[0].discountPercent / 100).toFixed(2)}
            </h4>
            {/* {product.price[0].discountPercent === 0 ? <br />
              :
              <del><span className='money' > {product.price[0].unit} {product.price[0].figure}</span></del>
            } */}
            <div className="d-flex" style={{ justifyContent:'space-between' }}>
              <div>
                {/* {this.renderRating(product)} */}
              </div>
              <div className="">
                {product.color ?
                <ul className="color-variant">
                  {product.color.map((vari, i) => {
                    return (
                      <li key={i} onClick={() => this.onClickHandle(vari.icon.asset.src)} title={vari.name.toLowerCase()}
                        style={{ height: 23, width: 23, borderWidth: 1, borderColor: 'black', borderStyle: 'solid', backgroundColor: vari.code, borderRadius: 100, listStylePosition: 'inline' }}>
                      </li>)
                  })}
                </ul> : ''}
              </div>
            </div>
          </div>
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6  col-xs-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    <div className="quick-view-img">
                      <img
                        src={product.color ?
                          this.state.image ? this.state.image : product.color[0].icon.asset.src
                          : product.asset.src}
                        width='100%'
                        height='100%'
                        alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 rtl-text">
                    <div className="product-right">
                      <h2>
                        {product.name}
                      </h2>
                      {this.state.price ?
                        <div>
                          <h4>
                            {"RM "}
                            {(this.state.price.figure - this.state.price.figure * this.state.price.discountPercent / 100).toFixed(2)}
                          </h4>
                          {this.state.price.discountPercent === 0 ? <br />
                            :
                            <del><span className='money' >{"RM "} {this.state.price.figure.toFixed(2)}</span></del>
                          }
                        </div>
                        :
                        <div>
                          <h4>
                            {"RM "}
                            {product.price[0].figure - product.price[0].figure * product.price[0].discountPercent / 100}
                          </h4>
                          {product.price[0].discountPercent === 0 ? <br />
                            :
                            <del><span className='money' > {"RM "} {product.price[0].figure.toFixed(2)}</span></del>
                          }
                        </div>
                      }
                      {limitedDetail.colors ?
                        <ul className="color-variant">
                          {limitedDetail.colors.map((vari, i) => {
                            return (
                              <li key={i} onClick={() => this.getQuantity(vari, i)} title={vari.name.toLowerCase()}
                                style={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid', backgroundColor: vari.code, borderRadius: 100, listStylePosition: 'inline' }}>
                              </li>
                            )
                          })}
                        </ul> : ''}
                      <div className="border-product">
                        <h6 className="product-title">Product Summary</h6>
                        {limitedDetail.summary ?
                          <p>{limitedDetail.summary}</p> :
                          <p>{product.name}</p>
                        }
                      </div>
                      <div className="product-description border-product">
                        {limitedDetail.specifications ?
                          <div className="size-box" >
                            <ul>
                              {limitedDetail.specifications.map((vari, i) => {
                                {
                                  if (this.state.current.has(vari.id) && this.state.current.get(vari.id).quantity > 0) {
                                    if (this.state.current.get(vari.id).specificationId === this.state.specId)
                                      return <button className="btn" style={{ fontSize: 12, marginRight: 10, borderColor: 'rgb(224, 106, 66)', marginBottom: 5, backgroundColor: 'white', height: '16', width: '25', padding: 2, borderWidth: '0.5px' }} key={i}
                                        onClick={() => this.setPrice(vari)}
                                      >{vari.name.substring(0, 10)}
                                      </button>
                                    else return <button className="btn" style={{ fontSize: 12, marginRight: 10, borderColor: 'lightgray', marginBottom: 5, backgroundColor: 'white', height: '16', width: '25', padding: 2, borderWidth: '0.5px' }} key={i}
                                      onClick={() => this.setPrice(vari)}
                                    >{vari.name.substring(0, 10)}
                                    </button>
                                  }
                                  else {
                                    return <button disabled={true} style={{ fontSize: 12, marginRight: 10, borderColor: 'lightgray', marginBottom: 5, backgroundColor: 'lightgrey', height: '16', width: '25', padding: 2, borderWidth: '0.5px', cursor: 'context-menu' }} key={i}
                                      onClick={() => this.setPrice(vari)}
                                    >{vari.name.substring(0, 10)}
                                    </button>
                                  }
                                }
                              })}
                            </ul>
                          </div> : ''}
                        {/* <span>{this.state.stock}</span> */}
                        <h6 className="product-title">quantity</h6>
                        <div className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                                <i className="fa fa-angle-left"></i>
                              </button>
                            </span>
                            <input readOnly type="text" name="quantity" value={this.state.stockQuantity === 0 ? 0 : this.state.quantity} onChange={this.changeQty} className="form-control input-number" />
                            <span className="input-group-prepend">
                              <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                                <i className="fa fa-angle-right"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className="product-buttons" style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', }}>
                          <button className="btn btn-solid" onClick={this.addItemToCart}>add to cart</button>
                          <a href={redirectLink} style={{ textDecoration: 'none' }}  className="btn btn-solid">view detail</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <PositiveAlert
          onClose={() => this.setState({ showPositiveAlert: false })}
          open={this.state.showPositiveAlert}
          title={'Success'}
          message={this.state.errorMessage}
        />
        <NegativeAlert
          error
          onClose={() => this.setState({ showNegativeAlert: false })}
          open={this.state.showNegativeAlert}
          title={'Error'}
          message={this.state.errorMessage}
        />
        {this.renderAddressModal()}
      </div>
    )
  }
}
export default ProductListItem;