import React, { Component } from 'react'
import Breadcrumb from "../common/breadcrumb";
export default class Privacy extends Component {

  render() {
    return (
      <div>
        <Breadcrumb title={'Privacy Policy'} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h4 style={{ textAlign: 'center' }}>Privacy Policy</h4>
                <p>
                  Welcome to the Pindal.pkwebsite, operated by Azure Innovations E Services Pakistan Pvt Limited.
                  We assure your privacy and want to secure your personal details or information.
                  To know more about our Privacy Policy, kindly, review the following content of this policy.
                  This Privacy Policy based on our method of data collection, use and under certain conditions disclosure
                  of your personal details or information. This Privacy Policy also elaborates the
                  steps we have taken to protect your personal details.
                  Finally, this Privacy Policy illustrates your options related to collection, use and disclosure
                  of your personal details. By visiting the Pindal.pkdirectly or through another site,
                    you agree to the practices described in this Policy.
              </p>
                <p>
                  Data security is a matter of trust and privacy of your data is important to us. According to our policy,
                  we shall therefore only use your name and certain information regarding you. We will only collect such
                    information which is necessary for us and is relevant to our dealings with you.
              </p>
                <p>
                  All your details and other information will be with us as long as it is required to keep
                  by law or as it is
                  required for the purposes for which it was gathered.
              </p>
                <p>
                  You can visit and browse our site without providing your personal details.
                  At the time your visit to the Site you will be anonymous and we cannot identify
                  you unless you create an account on the Site and log on with your login ID and password.
              </p>
              </div>
              <div className='col-sm-12'>
                <h4>Our Data collection</h4>
                <p>
                  we may gather, store and process your data information
                  for your transaction processing on the Site and for any possible
                  claims later, and to serve you with our services. We may collect your information
                  including, but not limited to, your name, gender, date of birth, email address,
                    postal address, postal address or delivery address (if different), telephone number,
                    mobile number, fax number, transaction details, payment card details or bank account details.
                </p>
                <p>
                  We will utilize the information you provide to us to process your transaction or orders
                  and to serve you with the services and information offered by our website that you request.
                    Furthermore, your information can be used to process your account with us;
                    verify and carry out financial transactions in relation to payments you make;
                    audit the downloading of data from our website; enhance the layout and/or
                    content of the pages of our website and customize them for users;
                      identify visitors on our website; make a research on our users'
                      demographics; send you details that you may find useful
                      or which you have already requested from us, including information about our products and services,
                  in order to obtain your consent we may approach you by email with details of other products and services.
                  If you do not want to receive any marketing notifications from us, you can select out at any time.
                </p>
                <p>
                  We may transfer your data to a third party in order to make delivery of the product
                  to you (for example to our courier or supplier). You should submit to us the Site
                  information which is authentic and not misleading and you must keep it up to date.
                </p>
                <p>
                  Your actual parcel details may be saved with us but for security reasons
                  cannot be generated directly by us. However, you may acquire this information by
                    signing into your account on the Site. Here you can view the complete information
                    of your orders that have been placed and completed, those which are open and those
                    which are shortly to be dispatched or delivered and administer your address details,
                      bank details ( for refund purposes) and any communication to which you may have subscribed.
                    You undertake to treat the personal access data confidentially and not make it available to
                    unauthorized third parties. We cannot be responsible for any liability or misuse of passwords
                    unless this misuse is our fault.
                </p>
              </div>
              <div className='col-sm-12'>
                <h4>Other uses of your Personal Information </h4>
                <p>
                  We reserve the right to use your personal information for
                  recommendation and market research. Your details are anonymous and
                  it will only be used for statistical analysis. You can choose to opt
                    out of this at any time. Any answers to surveys or opinion polls we may
                    ask you to complete will be confidential and not to be forwarded on to
                      third parties. Disclosure of your email address is only necessary if
                      you would like to take part in competitions. We keep a record of the answers
                      to our surveys separately via your email address.
                </p>
                <p>
                  We may also share with you other information about us, the Site, our other websites,
                  our products, services, sales promotions, our newsletters, anything relating to other
                    companies in our group or our business partners. If you would not like to get any of
                    this supplementary information as mentioned in this paragraph or any part of it,
                      please select the 'unsubscribe' option in an email sent to you. Within 7 working
                      days of receipt of your instructions, we will terminate or cancel to send you information
                        as requested. If your instruction is not clear we will approach you for verification of the instructions.
                </p>
                <p>
                  We may further make our data anonymous about users of the Site generally
                  and use it for various purposes, including assuring the general location
                    of the users and usage of certain aspects of the Site or a link contained
                    in an email to those registered to receive them, and supplying that anonymous
                      data to third parties such as publishers. However, that anonymous data will
                    not be capable of identifying you personally.
                </p>
              </div>
              <div className='col-sm-12'>
                <h4>Competitions</h4>
                <p>
                  For the purpose of competition we use the data to inform winners and advertise
                  our offers or promotions. You can get more details where applicable in our
                    participation terms for the respective competition.
                </p>
              </div>
              <div className='col-sm-12'>
                <h4>Third Parties and Links </h4>
                <p>
                We may transfer your details to other companies in our group. We may also share your details to our agents and subcontractors to assist us with any of our uses of your data set out in our Privacy Policy. For example, we may use third parties assistance in delivering products to you, to help us to collect payments from you, to analyze data and to provide us with marketing or customer service assistance.
                </p>
                <p>
                We may exchange information with third parties for the purposes of fraud protection and credit risk reduction. We may transfer our databases containing your personal information if we sell our business or part of it. Other than as set out in this Privacy Policy, we shall NOT sell or disclose your personal data to third parties without obtaining your prior consent unless this is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law. The Site may contain advertisements of third parties and links to other sites or frames of other sites. Please be aware that we are not responsible for the privacy practices or content of those third parties or other sites, nor for any third party to whom we transfer your data in accordance with our Privacy Policy. 
                </p>
              </div>

              <div className='col-sm-12'>
                <h4>Cookies </h4>
                <p>
                The acceptance of cookies is not mandatory to access the Site. However we would appreciate that the use of the 'basket' actively on the Site and ordering is possible with the activation of these cookies. Cookies are small files that identify your computer or system to our server as a different or unique user when you access certain pages on the Site and they are saved by your Internet browser on your computer's hard drive. Cookies can be used for identification of your Internet Protocol address, saving your time while you are on, or want to enter, the Site. We only utilize cookies for your convenience in accessing the Site (for example to keep a record of who you are when you want to amend your shopping cart without having to re-entering your login details) and not for getting or using any other information about you (for example advertising). You can set your browser not to accept cookies, but this would limit your access of the Site. Please agree to our assurance that our use of cookies does not consist of any personal or private details and are free from viruses. If you want to find out more information about cookies, go to https://www.allaboutcookies.org or to find out about removing them from your browser, go to https://www.allaboutcookies.org/manage-cookies/index.html.
                </p>
                <br />
                <p>
                Pindal.pk avail the services of Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are tiny text files placed on your computer, to help the website analyze how users access the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will apply this information for the purpose of evaluation regarding your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may share this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. 
                </p>
              </div>

              <div className='col-sm-12'>
                <h4>Security </h4>
                <p>
                We have arranged certain appropriate, technical and security measures to secure unauthorized or unlawful access to or accidental loss of or destruction or damage to your data and information. When we gather data from the Site, we get your personal information and details on a protected server. We have firewalls on our servers. Our security measures mean that we may at times request you the proof of your identity before its disclosure to you. You are responsible for protection against unauthorized access to your password and to your system.
                </p>
              </div>

              <div className='col-sm-12'>
                <h4>Your rights </h4>
                <p>
                You have a right to access your data if you require your personal data and information to process your transaction further. It is also your right to access us to rectify and correct your data and information. At any time you also ask us not to use your personal data for marketing purposes.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
