
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import { withRouter } from 'react-router-dom'
import TopBarWhite from "./common/topbar-white";
import LogoImage from "./common/logo";
import Notification from '../../Notifications/Notification'
import SuperHoc from '../../../HOC/SuperHOC'


class HeaderFive extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      count: '',
      search: false,
      searchPhrase: '',
      searchClicked: false,
      searchedData: [],
    }
  }
  /*=====================
       Pre loader
       ==========================*/
  componentDidMount() {
    this.formVisible()
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang))
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add('open-side')
    }
  }
  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false })
    })
  };

  formVisible = () => {
    if (window.innerWidth > 576) {
      return true
      //document.getElementById('formVisible').style.display = "block";
    }
    else {
      return false
      // document.getElementById('formVisible').style.display = "none";
    }
  }




  renderNotification = () => {
    return (
      <div >
        <Notification
          {...this.props}
        />
      </div>
    )
  }

  onTextChange = (event) => {
    if (event.target.value === '%') {
    }
    else {
      if (event.target.value ? event.target.value.includes('%') : '') {
      }
      else
        this.setState({ searchPhrase: event.target.value })
      if (event.target.value.length >= 1) {
        let body = {
          searchPhrase: event.target.value
        };
        this.props.apiManager.makeCall('search_intellisense', body, res => {
          if (res.message === '1175') {//1175: successfully get searched data 
            this.setState({ searchedData: res.result.docs });
            if (res.result.docs.length === 0) {
              this.setState({ searchedData: ["noResultFound"] });
            }
          } else if (res.message === '1173') {//1173: no search phrase given
            this.setState({ searchedData: [] });
          }
        });
      } else {
        this.setState({ searchedData: [] });
      }
    }
  }

  renderSearchItem = () => {
    return (
      this.state.searchedData.map((item, index) => {
        var receivedArticleName
        if (item && item.searchString ? item.searchString.includes('%') : '') {
          let name = item.searchString.split('%')
          receivedArticleName = name[0]
        }
        else receivedArticleName = item.searchString
        if (item === 'noResultFound') {
          return (
            <p key={index} style={{ padding: 20, fontSize: 13, margin: 20, textAlign: 'center', color: 'black' }}>
              {"No Result Found"}</p>
          )
        }
        return (
          <Link to={`/search/${receivedArticleName}`}
          >
            <div key={index} style={{ padding: 0, margin: 0, alignItems: 'center' }} onClick={this.closeSearch}>
              <p style={{ paddingLeft: 10, fontSize: 13 }} onClick={() => this.setState({ searchPhrase: '' })}>
                {item.searchString}</p>
            </div>
          </Link>
        )
      }
      ));
  }

  renderForm = () => {
    var receivedArticleName
    if (this.state.searchPhrase ? this.state.searchPhrase.includes('%') : '') receivedArticleName = encodeURIComponent(this.state.searchPhrase)
    else receivedArticleName = this.state.searchPhrase
    return (
      <div className='col-xl-7 col-lg-7 col-md-7 serch-form-in-header'>
        <form className="form-header">
          <div className="input-group">
            <input type="text" className="form-control"
              aria-label="Amount (to the nearest dollar)"
              placeholder="Search Black Market"
              width='100%'
              value={this.state.searchPhrase}
              onChange={this.onTextChange}
            />
            {/* {this.state.searchPhrase.length > 1 ? */}
            {this.state.searchPhrase.length > 0 ?
              <Link to={`${process.env.PUBLIC_URL}/search/${receivedArticleName}`}>
                <div className="input-group-append" onClick={() => this.setState({ searchPhrase: '' })}>
                  <button className="btn btn-solid"><i
                    className="fa fa-search"></i>Search
                    </button>
                </div>
              </Link>
              :
              <div className="input-group-append">
                <button className="btn btn-solid"><i
                  className="fa fa-search"></i>Search
                  </button>
              </div>
            }
          </div>
        </form>
        <div style={{ position: 'absolute', marginTop: 10, zIndex: 1, backgroundColor: 'white', display: "flex", width: "80%", flexDirection: "column", alignItems: "flex-start", borderRadius: 5 }} >
          {this.state.searchPhrase ? this.renderSearchItem() : null}
        </div>
      </div>
    )
  }
  render() {
    var receivedArticleName
    if (this.state.searchPhrase ? this.state.searchPhrase.includes('%') : '') receivedArticleName = encodeURIComponent(this.state.searchPhrase)
    else receivedArticleName = this.state.searchPhrase
    return (
      <div>
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option"></div>
          {/*Top Header Component*/}
          <TopBarWhite {...this.props} />
          <div style={{ padding: '0 15px 5px' }}>
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left category-nav-right col-md-3">
                    <div className="brand-logo" style={{ padding: 1 }}>
                      <LogoImage logo={`${process.env.PUBLIC_URL}/assets/Black_Market_Logo_Header_Black.png`} style={{ minWidth: '120px', maxWidth: '40%', width: '40%', height: 'auto', marginLeft: '50px' }} />
                    </div>
                    <div className="navbar">
                      <div onClick={this.openNav}>
                        <div className="bar-style"> <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
                      </div>
                      <SideBar {...this.props} />
                    </div>
                  </div>
                  {this.renderForm()}
                  <div className='col-xl-1 col-lg-1 col-md-1 d-none d-lg-block text-right'>
                  <div>
                    <Link to={`${process.env.PUBLIC_URL}/chatScreen`}>
                          <i className="fa fa-envelope text-dark fa-2x"></i>
                        </Link>
                    </div>
                  </div>
                  <div className="menu-right pull-right">
                    <NavBar {...this.props} />
                    <div>
                      <div className="icon-nav" style={{ marginRight: 16 }}>
                        <ul>
                          <li className="onhover-div mobile-search">
                            <div>
                              {/* <img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`} onClick={this.openSearch} className="img-fluid" alt="" style={{ display: this.formVisible() ? 'none' : 'block' }} /> */}
                              <i className="fa fa-search" onClick={this.openSearch}></i></div>
                          </li>
                          {/* <li className="onhover-div d-none d-lg-block" >
                          <div>
                          <Link to={`${process.env.PUBLIC_URL}/mobileNotification`}>
                                <i className="fa fa-envelope"></i>
                              </Link>
                          </div>
                          </li> */}
                          {/* <li className="onhover-div mobile-setting" >
                            <div >
                              {
                                this.props.apiManager.token ?
                                  <div style={{ display: this.formVisible() ? 'block' : 'none' }}>
                                    {this.renderNotification()}
                                  </div>
                                  : null}
                              <Link to={`${process.env.PUBLIC_URL}/mobileNotification`}>
                                <i className="fa fa-bell"></i>
                              </Link>
                            </div>
                          </li> */}
                          <CartContainer {...this.props} />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="search-overlay" className="search-overlay">
          <div>
            <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">×</span>
            <div className="overlay-content" style={{ position: 'absolute', top: '10%' }}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Search Black Market" value={this.state.searchPhrase} onChange={this.onTextChange} />
                      </div>
                      <Link to={`${process.env.PUBLIC_URL}/search/${receivedArticleName}`}>
                        <button type="submit" className="btn btn-primary" onClick={this.closeSearch}><i className="fa fa-search"></i></button>
                      </Link>
                    </form>
                    <div style={{ position: 'absolute', marginTop: 10, zIndex: 1, backgroundColor: 'white', display: "flex", width: "80%", flexDirection: "column", alignItems: "flex-start", borderRadius: 5 }} >
                      {this.state.searchPhrase ? this.renderSearchItem() : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SuperHoc(HeaderFive))
