import React, { Component } from 'react'
import StarRatings from 'react-star-ratings';
import classes from './style.module.css'
// import colors from '../../config/colors'
export default class Review extends Component {

    renderRating = () => {
        return (
            <StarRatings
                rating={this.props.rating}
                changeRating={this.changeRating}
                numberOfStars={5}
                name='rating'
                starDimension="20px"
                starSpacing="3px"
                starRatedColor='#ffb508'
            />
        )
    }

    render() {
        const { timeAgo, reviewerName, comment } = this.props
        return (
            <div className={classes.reviewContainerR}>
                <div className={classes.reviewSubContainer} style={{ height: "45px", paddingTop: "15px"}}>
                    {this.renderRating()}
                    <p style={{ color: 'grey', fontSize: 14,margin:0 }}>
                        {timeAgo}
                    </p>
                </div>
                <div className={classes.reviewerName}>
                    <div>
                        <p style={{ color: 'grey', fontSize: 14,margin:0,padding:0 }}>
                            by {reviewerName}
                        </p>
                    </div>
                </div>
                <div>
                        <p style={{ fontSize: 15, padding:10}}>
                            {comment}
                        </p>
                </div>
            </div>
        )
    }
}
