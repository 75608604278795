import React, { Component } from 'react'
import Merchant from '../components/MerchantProfile/Main'
import { SuperHOC } from '../HOC'


class MerchantScreen extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <div>
                <Merchant
                    {...this.props}
                    api={'get_merchant_detail'}
                //numOfColumns={3}
                />
            </div>
        )
    }
}
export default SuperHOC(MerchantScreen)