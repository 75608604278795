import React, { Component } from 'react'
import AddComplains from '../components/Complain/AddComplains'
import { SuperHOC } from '../HOC'

class AddComplain extends Component {
    render() {
        return (
            <div>
                <AddComplains
                    {...this.props}
                />
            </div>
        )
    }
}

export default SuperHOC(AddComplain);
