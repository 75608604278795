import Review from './Review'
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

class ReviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let review = this.props.data
    if (review.length === 0) return <div style={{ paddingTop: 20, }}>
      <h4 style={{ textAlign: 'center' }}>No reviews found</h4>
    </div>
    return (
      <Container>
        {
          review.map((comment, index) => {
            return (
              <Review
                rating={comment.rating}
                key={index}
                reviewerName={comment.reviewer}
                comment={comment.comment}
                timeAgo={comment.createdAt && comment.createdAt.substring(0, 10)}
              />
            )
          })
        }
      </Container>
    );
  }
}
export default ReviewContainer;
