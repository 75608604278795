import React, { Component } from 'react';
import ProductListing from '../collection/common/product-listing'
import LoadingLoader from '../Loader/Loader'

export default class componentName extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      end: false,
      loading: true,
      loadMore: false,
      page: ''
    }
    // // this.props.apiManager = new ApiManager(this);
  }

  componentDidMount = () => {
    this.getTrendingProducts();
  }

  onApiResponse = (res) => {
  }

  getTrendingProducts = (pageNum = 1) => {
    //scroll.scrollMore(2000, { delay: 1500 })
    let body = {
      merchant: this.props.merchant,
      page: pageNum,
      numRecords: this.props.numOfRecords,
      brandName: this.props.brandName,
      category: this.props.category,
      searchPhrase: this.props.searchPhrase,
      category: this.props.slug,
    };
    this.props.apiManager.makeCall(this.props.api, body, res => {
      if (res.result) {
        let limit = this.props.limit ? this.props.limit : res.result.pages
        let { page, pages, docs } = res.result;
        if (page <= 10 && this.state.page!==page) {
          this.setState({
            data: this.state.data.concat(docs),
            page,
            pages,
            loading: false
          }, () => (page === 10) ? this.setState({ loadMore: true, page: page }) : null);
        } else {
          this.setState({
            loading: false
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }
  getTrendingProducts2 = (pageNum = 11) => {
    //scroll.scrollMore(2000, { delay: 1500 })
    let body = {
      merchant: this.props.merchant,
      page: pageNum,
      numRecords: this.props.numOfRecords,
      brandName: this.props.brandName,
      category: this.props.category,
      searchPhrase: this.props.searchPhrase
    };
    this.props.apiManager.makeCall(this.props.api, body, res => {
      if (res.result) {
        let { page, pages, docs } = res.result;
        if (page <= pages) {
          this.setState({
            data: this.state.data.concat(docs),
            page,
            pages,
            loading: false
          }, () => (page === pages) ? this.setState({ end: true, loading: false }) : null);
        } else {
          this.setState({
            loading: false,
            end: true
          });
        }
      }
      else if (res.message) {
        this.setState({
          end: true,
          loading: false
        });
      }
    })
  }


  render() {
    if (this.state.loading) return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '60vh', }}>
      <LoadingLoader visibel={this.state.loading} />
    </div>
    return (
      <React.Fragment>
        {
          this.props.title ? <div className="title4">
            <h2 className="title-inner4">{this.props.title}</h2>
            <div className="line"><span></span></div>
          </div> : <div style={{ paddingTop: '50px' }}></div>
        }
        <div className="product-wrapper-grid">
          <div className="container-fluid">
            <div className="row">
              <ProductListing
                isCategory={this.props.isCategory}
                colSize={this.props.colSize}
                product={this.state.data}
                loadMore={() => this.getTrendingProducts(this.state.page + 1)}
                end={this.state.end}
                {...this.props}
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}
            >
              {this.state.data.length > 0 ?
                this.state.page > 9 ?
                  this.state.end === false ?
                    <button
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 100px 10px 100px',
                        backgroundColor: 'transparent',
                        border: '1px solid #ed5149',
                        fontSize: 20,
                        color: '#ed5149',
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                      onClick={() => this.getTrendingProducts2(this.state.page + 1)}>Load More</button>
                    : '' : '' : ''}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
