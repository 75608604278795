import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import image from '../../assets/demo.jpg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LoadingLoader from '../Loader/Loader'
class wishList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      merchantItems: [],
      serviceItems: [],
      loading: true,
      previous: ''
    }
  }

  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) })
  }

  isHearted = (item) => {
    return this.props.user ?
      this.props.user.favoritesProducts.indexOf(item.slug) > -1 :
      false
  }

  RemoveWishList = (item) => {
    let body = {
      productId: item.slug
    };

    this.setState({loading: true})
    this.props.apiManager.makeCall('remove_favorite_product', body, (res) => {
      if (res.message) {
        this.setState({loading: false})
        if (res.message === '1077') {
          this.props.apiManager.makeCall('fav_product', {}, (res) => {
            if (res.message === '1023') {
              this.setState({ items: res.productSummary.docs })
            }
            else {
            }
          })
        }
      }
    })
  }

  componentDidMount = () => {
    let previous = document.referrer.split('/')
    let same = this.props.location.pathname.split('/')

    if (previous[3] !== same[1]) {
      this.setState({ previous: previous[3] })
    }
    else {
      this.setState({ previous: '' })
    }

    this.props.apiManager.makeCall('fav_product', {}, (res) => {
      this.setState({loading: false})
      if (res.message === '1023') {
        this.setState({ items: res.productSummary.docs, loading: false })
      }
      else {
      }
    })

    this.props.apiManager.makeCall('fav_merchant', {}, (res) => {
      if (res.message === '1023') {
        this.setState({ merchantItems: res.merchantSummary.docs, loading: false })
      }
      else {
      }
    })
  };

  RemoveMerchantFromFavorite = (item) => {
    let body = {
      email: item
    }
    this.props.apiManager.makeCall('remove_favorite_merchant', body, (res) => {
      if (res.message) {
        if (res.message === '1078') {
          this.props.apiManager.makeCall('fav_merchant', {}, (res) => {
            if (res.message === '1023') {
              this.setState({ merchantItems: res.merchantSummary.docs })
            }
            else {
            }
          })
        }
        else {
        }
      }
    })
  }

  renderEmtpy = () => {
    return (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div >
                <div className="col-sm-12 empty-cart-cls text-center">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/empty-wishlist.png`} className="img-fluid mb-4" alt="" />
                  <h3>
                    <strong>WhishList is Empty</strong>
                  </h3>
                  <h4>Explore more shortlist some items.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  render() {
    if (this.state.loading) return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '60vh', }}>
      <LoadingLoader visibel={this.state.loading} />
    </div>
    return (
      <div>
        <Breadcrumb title={'Wishlist'} parent={this.state.previous} />
        <section className="new-cart-section-wishlist section-b-space">
          <Tabs className="theme-tab" >
            <TabList className="tab-title" >
              <Tab>Products</Tab>
              <Tab>Merchant</Tab>
              {/* <Tab>Services</Tab> */}
            </TabList>
            <div className="tab-content-cls">
              <TabPanel className="tab-content">
                <div>
                  <div className="top-banner-content small-section">
                    <div>
                      <div className="row">
                        <div className='col-sm-1'></div>
                        <div className="col-sm-10">
                          {
                            this.state.items.length > 0 ? <table className="table  wish-table table-responsive">
                              <thead style={{ textAlign: "center" }}>
                                <tr className="table-row">
                                  <th scope="col">image</th>
                                  <th scope="col">product name</th>
                                  <th scope="col">price</th>
                                  {/* <th scope="col">availability</th> */}
                                  <th scope="col">action</th>
                                </tr>
                              </thead>
                              {this.state.items.map((item, index) => {
                                var enc = window.btoa(item.slug);
                                let removePerc = item.name ? item.name.split('%').join(' ') : ' ';
                                let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
                                let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>
                                        <div style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/product/' + convertNameIntoDashes + '?id=' + enc)} >
                                          <img src={item.color[0].icon.asset.src} alt="" width="100px" style={{ marginTop: "10px", marginBottom: "10px" }} />
                                        </div>
                                      </td>
                                      <td>
                                        {item.name.substring(0, 40) + '...'}
                                      </td>
                                      <td>
                                        <h2 style={{ display: 'flex', flexDirection: 'column' }}>
                                          {/* {item.price[0].figure} */}
                                         RM{(item.price[0].figure - item.price[0].figure * item.price[0].discountPercent / 100).toFixed(2)}
                                          <del>
                                            <span className="money">
                                              {
                                                item.price[0].discountPercent ? 'RM'+item.price[0].figure: null
                                              }
                                            </span>
                                          </del>
                                        </h2>
                                      </td>
                                      {/* <td>
                                        in Stock
                                    </td> */}
                                      <td>
                                        <div className="icon cursor-p" onClick={() => this.RemoveWishList(item)}>
                                          <i className="fa fa-times"></i>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>)
                              })}
                            </table> : this.renderEmtpy()
                          }
                        </div>
                        <div className='col-sm-1'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
            <TabPanel className="tab-content">
              <div>
                <div className="top-banner-content small-section">
                  <div>
                    <div className="row">
                      <div className='col-sm-1'></div>
                      <div className="col-sm-10">
                        {
                          this.state.merchantItems.length > 0 ? <table className="table wish-table table-responsive">
                            <thead style={{ textAlign: "center" }}>
                              <tr className="table-head">
                                <th scope="col">Image</th>
                                <th scope="col">Merchant name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Rating</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            {this.state.merchantItems.map((item, index) => {
                              var enc = window.btoa(item.email);
                              let removePerc = item.name ? item.name.split('%').join(' ') : ' ';
                              let removeHash = removePerc ? removePerc.split('#').join(' ') : ' ';
                              let convertNameIntoDashes = removeHash ? removeHash.split(' ').join('-') : ' ';

                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>
                                      {item.asset && item.asset.src ?
                                        <div style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/merchant/' + convertNameIntoDashes + '?id=' + enc)}                                        >
                                          {/* {src !== undefined ? src : Image} */}
                                          <img
                                            defaultValue={image}
                                            src={item.asset.src !== undefined ? item.asset.src : image}
                                            alt="" width="100px" height="100px"
                                            style={{ marginTop: "10px", marginBottom: "10px" }}
                                          />
                                        </div>
                                        :
                                        <div></div>
                                      }
                                    </td>
                                    <td>
                                      {item.name}
                                    </td>
                                    <td>
                                      {item.description && item.description[0] && item.description[0].summary ? item.description[0].summary.substring(0, 40) + '...' : 'nothing'}
                                    </td>
                                    <td>
                                      {item.totalRatings}
                                    </td>
                                    <td>
                                      <div className="icon"
                                        onClick={() => this.RemoveMerchantFromFavorite(item.email)}
                                      >
                                        <i className="fa fa-times"></i>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}
                          </table> : this.renderEmtpy()
                        }
                      </div>
                      <div className='col-sm-1'></div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </section>
      </div>
    )
  }
}

export default wishList