import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from "react-bootstrap/Button";
class Faq extends Component {

  render() {

    return (
      <div>
        <Breadcrumb title={'Faq'} />
        <section className="faq-section" style={{ marginBottom: 70 }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h2>Frequently Asked Question</h2><br />
                </div>
                <h4 style={{ margin: '15px 0'}}>Subscriber FAQs</h4>
                <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ color: '#222222', textDecoration: 'none' }}>
                      1. Why did I not receive my verification code?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                      The OTP service provider is experiencing technical difficulties, resulting in the request being delayed, mobile connection is not stable or logged on to a different account. Kindly retry after few mins.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{ color: '#222222', textDecoration: 'none' }}>
                      2. Is my personal information kept private?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                      We will treat all information you share with us as private and confidential. We may use your contact details to keep you informed of our latest promo/sale. Please refer to our Privacy Policy.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{ color: '#222222', textDecoration: 'none' }}>
                      3. How can I check my order status?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                      On BLACK Market, please click “History” and then ‘TO SHIP’.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{ color: '#222222', textDecoration: 'none' }}>
                      4. How will I know that my order is successful?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                      You will receive a confirmation email after payment is successful.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="4" style={{ color: '#222222', textDecoration: 'none' }}>
                      5. What payment options does Black Market support?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                      a) Credit/ Debit Card – Buyers to be allowed to add and save their preferred card. <br />
                      b) Credit Card Instalment – Buyers are allowed to opt in to any participating instalments available. <br />
                      c) Online Banking / FPX – Users to be allowed to use online banking.<br />
                      d) Vouchers & Promocodes – Buyers would be able to choose from available/ collected vouchers be it from the shop or from BLACK Market.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="5" style={{ color: '#222222', textDecoration: 'none' }}>
                      6. Is there a limit to the number of promocode applied in a single checkout?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                      Only 1 promocode allow for every transaction.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="6" style={{ color: '#222222', textDecoration: 'none' }}>
                      7. How do I know if an item is in stock?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>
                      You can add to cart for available stock.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="7" style={{ color: '#222222', textDecoration: 'none' }}>
                      8. Does the item in my cart reserved?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                      Yes, reserved until payment is made or the product is removed.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="8" style={{ color: '#222222', textDecoration: 'none' }}>
                      9. If the item I want is out of stock, can I still order it?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body>
                      No, not allowed to order for out-of-stock item.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="9" style={{ color: '#222222', textDecoration: 'none' }}>
                      10. What is my purchase limit per checkout?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="9">
                      <Card.Body>
                      There’s no limit per checkout.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="10" style={{ color: '#222222', textDecoration: 'none' }}>
                      11. When will you ship out my order?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="10">
                      <Card.Body>
                      Sellers will ship out the order within 3 to 5 working days. (Ship out time may vary during peak season, single-day campaigns etc.)
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="11" style={{ color: '#222222', textDecoration: 'none' }}>
                      12. What happens if I do not receive my order? Can I ask for refund?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="11">
                      <Card.Body>
                      Yes, you can click the ‘Refund’ button. You may email us to enq_blackmarket@xox.com.my
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="12" style={{ color: '#222222', textDecoration: 'none' }}>
                      13. What should I do if I receive an incorrect or faulty item?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="12">
                      <Card.Body>
                      You may click the ‘Refund’ button and proceed with refund process. You may email us to enq_blackmarket@xox.com.my
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="13" style={{ color: '#222222', textDecoration: 'none' }}>
                      14. Can I cancel or amend any of my order details?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="13">
                      <Card.Body>
                      No, once your order has been placed, we will process the order. You can cancel the order before order is “Shipped Out”.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="14" style={{ color: '#222222', textDecoration: 'none' }}>
                      15. Will I receive a full refund?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="14">
                      <Card.Body>
                      Any request by a Buyer for a refund of a Product must be approved by the Seller at all times. Refunds will be based on the value of the merchandise in the invoice. Any other circumstances should refund are to be processed via bank transfer, please note that the Refund process might take 14 working days.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="15" style={{ color: '#222222', textDecoration: 'none' }}>
                      16. My order is completed, can I still raise return/refund request?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="15">
                      <Card.Body>
                      You may request refund in accordance to the Cancellation, Return and Refunds Policy of BLACK Market.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="16" style={{ color: '#222222', textDecoration: 'none' }}>
                      17. What happens after a seller receives my return/refund request?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="16">
                      <Card.Body>
                      Sellers will approve the request before “Return/ Refund” process would take place.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="17" style={{ color: '#222222', textDecoration: 'none' }}>
                      18. Can I return item purchased?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="17">
                      <Card.Body>
                      Refund of a Product due to Buyer's change of mind that is not due to any other reason (such as Product being defective) must be approved by the Seller at all times. <br /><br />
                      Which items are non-returnable? <br />
                      a)	Consumer electronics; <br />
                      b)	Bathing suits; <br />
                      c)	Cosmetics and beauty products; <br />
                      d)	Fragrances <br />
                      e)	Personalized products; <br />
                      f)	Electronic vouchers for goods and services (vouchers that can be downloaded immediately after payment of the purchase price); <br />
                      g)	Compact discs (CD) and digital video discs (DVD); <br />
                      h)	Pharmaceuticals; and <br />
                      i)	Perishable foods.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                </Accordion>

                <h4 style={{ margin: '20px 0'}}>Public FAQs</h4>
                <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="18" style={{ color: '#222222', textDecoration: 'none' }}>
                      1. Do I need to create an account to make a purchase?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="18">
                      <Card.Body>
                      You must have an account to purchase and login to our Platform.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="19" style={{ color: '#222222', textDecoration: 'none' }}>
                      2. What are the benefits of having an account?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="19">
                      <Card.Body>
                      You must register as a User in order to access or use certain services on the Platform. Registration as a User is free unless otherwise specified by XOX. You may enjoy an account management where organizing your payment information and address details, reviewing your order history and shipping details. You will also receive news and updates from us
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="20" style={{ color: '#222222', textDecoration: 'none' }}>
                      3. How can I check my order status?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="20">
                      <Card.Body>
                      On BLACK Market, please click “History” and then ‘TO SHIP’.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="21" style={{ color: '#222222', textDecoration: 'none' }}>
                      4. How will I know that my order is successful?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="21">
                      <Card.Body>
                      You will receive a confirmation email after payment is successful.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="22" style={{ color: '#222222', textDecoration: 'none' }}>
                      5. What payment options does Black Market support?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="22">
                      <Card.Body>
                      a)	Credit/ Debit Card – Buyers to be allowed to add and save their preferred card. <br />
                      b)	Credit Card Instalment – Buyers are allowed to opt in to any participating instalments available. <br />
                      c)	Online Banking / FPX – Users to be allowed to use online banking. <br />
                      d)	Vouchers & Promocodes – Buyers would be able to choose from available/ collected vouchers be it from the shop or from BLACK Market. 
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="23" style={{ color: '#222222', textDecoration: 'none' }}>
                      6. Is there a limit to the number of promo vouchers applied in a single checkout?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="23">
                      <Card.Body>
                      Promotional vouchers are valid for the period specified on the voucher, can only be redeemed once, and cannot be combined with other promotional vouchers or discounted items.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="24" style={{ color: '#222222', textDecoration: 'none' }}>
                      7. How do I know if an item is in stock?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="24">
                      <Card.Body>
                      You can add to cart for available stock
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="25" style={{ color: '#222222', textDecoration: 'none' }}>
                      8. Does the item in my cart reserved?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="25">
                      <Card.Body>
                      Yes, reserved until payment is made or the product is removed.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="26" style={{ color: '#222222', textDecoration: 'none' }}>
                      9. If the item I want is out of stock, can I still order it?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="26">
                      <Card.Body>
                      No, not allowed to order for out-of-stock item.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="27" style={{ color: '#222222', textDecoration: 'none' }}>
                      10. What is my purchase limit per checkout?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="27">
                      <Card.Body>
                      There’s no limit per checkout.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="28" style={{ color: '#222222', textDecoration: 'none' }}>
                      11. When will you ship out my order?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="28">
                      <Card.Body>
                      Sellers will ship out the order within 3 to 5 working days. (Ship out time may vary during peak season, single-day campaigns etc.)
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="29" style={{ color: '#222222', textDecoration: 'none' }}>
                      12. What happens if I do not receive my order? Can I ask for refund?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="29">
                      <Card.Body>
                      Yes, you can click the ‘Refund’ button, or you may email us to enq_blackmarket@xox.com.my
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="30" style={{ color: '#222222', textDecoration: 'none' }}>
                      13. What should I do if I receive an incorrect or faulty item?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="30">
                      <Card.Body>
                      You may click the ‘Refund’ button and proceed with refund process. You may email us to enq_blackmarket@xox.com.my
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="31" style={{ color: '#222222', textDecoration: 'none' }}>
                      14. Can I cancel or amend any of my order details?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="31">
                      <Card.Body>
                      No, once your order has been placed, we will process the order. You can cancel the order before order is “Shipped Out”.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="32" style={{ color: '#222222', textDecoration: 'none' }}>
                      15. My order is completed, can I still raise return/refund request?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="32">
                      <Card.Body>
                      You may request refund in accordance to the Cancellation, Return and Refunds Policy of BLACK Market.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="33" style={{ color: '#222222', textDecoration: 'none' }}>
                      16. What happens after a seller receives my return/refund request?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="33">
                      <Card.Body>
                      Sellers will approve the request before “Return/ Refund” process would take place.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="34" style={{ color: '#222222', textDecoration: 'none' }}>
                      17. Will I receive a full refund?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="34">
                      <Card.Body>
                      Any request by a Buyer for a refund of a Product must be approved by the Seller at all times. Refunds will be based on the value of the merchandise in the invoice. Any other circumstances should refund are to be processed via bank transfer, please note that the Refund process might take 14 working days.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="35" style={{ color: '#222222', textDecoration: 'none' }}>
                      18. Which items are non-returnable?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="35">
                      <Card.Body>
                      a)	Consumer electronics; <br />
                      b)	Bathing suits; <br />
                      c)	Cosmetics and beauty products; <br />
                      d)	Fragrances <br />
                      e)	Personalized products; <br />
                      f)	Electronic vouchers for goods and services (vouchers that can be downloaded immediately   after payment of the purchase price); <br />
                      g)	Compact discs (CD) and digital video discs (DVD); <br />
                      h)	Pharmaceuticals; and <br />
                      i)	Perishable foods.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="36" style={{ color: '#222222', textDecoration: 'none' }}>
                      19. Can I return item purchased?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="36">
                      <Card.Body>
                      Refund of a Product due to Buyer's change of mind that is not due to any other reason (such as Product being defective) must be approved by the Seller at all times.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                </Accordion>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Faq